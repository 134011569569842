import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-session-time-out',
  templateUrl: './session-time-out.component.html',
  styleUrls: ['./session-time-out.component.css']
})
export class SessionTimeOutComponent {

  constructor(private router: Router) {

   }
}
