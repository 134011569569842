// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Services
import { AdminUserService } from 'src/app/api/services/adminUser.service';

// Models
import {AgencyGroupsForAgency } from 'src/app/models/interfaces';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class AdminGetAgencyFromAgencyIdResolver implements Resolve<Observable<AgencyGroupsForAgency>> {
  constructor(private adminUserService: AdminUserService) {}

  resolve(): Observable<AgencyGroupsForAgency> {
    return this.adminUserService.getAgencyGroupsForAgency();
  }
}
