// Angular Core
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Models
import { environment } from 'src/environments/environment';
import { UserInformation } from 'src/app/models/interfaces';
import { BookingTypes, ProductResponse, ProductPayload, SelectModifyPolicyResponse } from '@allianz/agent-max-core-lib';

// Services
import { UtilityService } from 'src/app/shared/services/utility.service';

// Third Party
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  policyServices: string;

  constructor(
    private utilityService: UtilityService,
    private httpClient: HttpClient
  ) {
    this.policyServices = this.utilityService.setTheDomainForAPICalls(environment.PolicyServices);
  }

  /**
   * updates an existing booking by booking id and policy number
   * @param {string | number} bookingId
   * @param {string} policyNumber
   * @param {ProductPayload} payload
   * @param {UserInformation} credentials
   * @returns {Observable<ProductPayload>}
   */
  public modifyPurchase(bookingID: string | number, policyNumber: string, payload: ProductPayload, credentials: UserInformation): Observable<ProductPayload> {
    return this.httpClient.put<ProductPayload>(
    `${this.policyServices}/ModifyPurchase/${bookingID}/${policyNumber}`, payload, { headers: this.utilityService.getServiceHeaders(credentials)});
  }

  /**
   * Get quote by booking id and second chance information
   * @param {BookingTypes} policyAction
   * @param {string} bookingID
   * @param {string} policyNumber
   * @param {UserInformation} credentials
   * @returns {Observable<SelectModifyPolicyResponse>}
   */
  public getBookingWithCurrentPolicyData(policyAction: BookingTypes, bookingID: string, policyNumber: string,  credentials: UserInformation): Observable<SelectModifyPolicyResponse>   {
    return this.httpClient.get<SelectModifyPolicyResponse>(`${this.policyServices}/GetBookingWithCurrentPolicyData/${policyAction}/${bookingID}/${policyNumber}`, { headers: this.utilityService.getServiceHeaders(credentials) });
  }

  /**
   * Cancel the purchase
   * @param {string | number} bookingID
   * @param {string} policyNumber
   * @param {UserInformation} credentials
   * @returns {Observable<ProductResponse>}
   */
  public cancelPurchase(bookingID: string | number, policyNumber: string, credentials: UserInformation): Observable<ProductResponse> {
    return this.httpClient.get<ProductResponse>(
      `${this.policyServices}/CancelPurchase/${bookingID}/${policyNumber}`, { headers: this.utilityService.getServiceHeaders(credentials) });
  }

}
