// Angular Core
import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// Services
import { UtilityService } from './shared/services/utility.service';

// Models
import { MainNavTabs, AdminNavTabs } from 'src/app/models/enums';
// declare gives Angular app access to ga function
// declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: string = 'AMO-Angular';
  showHeadAndFooter: boolean = false;

  constructor(
    private utilService: UtilityService,
    private router: Router,
  )
  {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event['url'] == '/session-time-out') {
          this.showHeadAndFooter = false;
        } else {
          this.showHeadAndFooter = true;
        }
        const dataLayer = (< any > window).dataLayer || [];
        dataLayer.push({
          'event': 'content-view',
          'content-name': event.urlAfterRedirects
        });
      }
    });
  }

  @HostListener('window:resize')

  /**
   * Sets the size of the window whenever the window is resized
   * All child components subscribe to the currentSizeSubject in the utilService
   * @returns {void}
   */
  onResize(): void {
    this.utilService.setSize();
  }

  /**
   * Sets Main and Admin nav tab on component load
   * @returns {void}
   */
  componentAdded(component: object): void {
    const mainNavTab: string = component.constructor.name;
    const adminNavTab: string = component.constructor.name === 'AdminComponent' ? 'agencies' : null;

    this.utilService.setTriggerAdminSaveAlert(component, false, MainNavTabs[mainNavTab], AdminNavTabs[adminNavTab]);
  }
}
