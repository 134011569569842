"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_field_class_1 = require("./input-field.class");
var SecondChanceInformation = /** @class */ (function () {
    function SecondChanceInformation() {
        this.ValidationMessages = [];
        this.IsSCAllowed = false;
        this.IsSCEnabled = new input_field_class_1.InputField();
        this.OfferType = '';
        this.ProductOffered = '';
        this.PriceOffered = '';
    }
    return SecondChanceInformation;
}());
exports.SecondChanceInformation = SecondChanceInformation;
