export enum SecondChanceOfferType {
// "Quote.rpt|TravelInsuranceQuote|Travel Insurance Quote|QuoteInEmail.rpt"
    FirstChance= 0,
// "Quote.rpt|TravelInsuranceQuote|Travel Insurance Quote|QuoteInEmail.rpt"
    SevenDaysAfterSave= 1,
// "Quote.rpt|TravelInsuranceQuote|Travel Insurance Quote|QuoteInEmail.rpt"
    ThirtyDaysPriorToDeparture= 2,
// "Quote.rpt|TravelInsuranceQuote|Travel Insurance Quote|QuoteInEmail.rpt"
    TenDaysPriorToDeparture= 3,
// "Quote.rpt|TravelInsuranceQuote|Travel Insurance Quote|QuoteInEmail.rpt"
    FiveDaysPriorToDeparture= 4,
// "Quote.rpt|TravelInsuranceQuote|Travel Insurance Quote|QuoteInEmail.rpt"
    QuoteMax= 5,
// "Quote.rpt|TravelInsuranceQuote|Travel Insurance Quote|QuoteInEmail.rpt"
    FirstChanceByCustomer= 6,
// "Quote.rpt|TravelInsuranceQuote|Travel Insurance Quote|QuoteInEmail.rpt"
    FirstChanceByETravel= 7,
// "Quote.rpt|TravelInsuranceQuote|Travel Insurance Quote|QuoteInEmail.rpt"
    FirstChanceByETravelNoAddress= 8,
// "Quote.rpt|TravelInsuranceQuote|Travel Insurance Quote|QuoteInEmail.rpt"
    FirstChanceByETravelCoBranded= 9,
// "Quote.rpt|TravelInsuranceQuote|Travel Insurance Quote|QuoteInEmail.rpt"
    FirstChanceByETravelNonCoBranded= 10,
// "Quote.rpt|TravelInsuranceQuote|Travel Insurance Quote|QuoteInEmail.rpt"
    FirstChanceByETicketCoBranded= 11,
// "Quote.rpt|TravelInsuranceQuote|Travel Insurance Quote|QuoteInEmail.rpt"
    FirstChanceByETicketNonCoBranded= 12
}
