import { KeyValue } from '@allianz/agent-max-core-lib';
import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AgentAdminSettingGridbagWithPending } from 'src/app/models/interfaces';

@Component({
  selector: 'app-admin-agent-readonly-record',
  templateUrl: './admin-agent-readonly-record.component.html',
  styleUrls: ['./admin-agent-readonly-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AdminAgentReadonlyRecordComponent  {
  @Input('AgentSettings')  agentSettings: AgentAdminSettingGridbagWithPending;
  @Input('AgentLockedOut')  agentLockedOut: number;
  @Input('reportingRightOptions')  reportingRightOptionsType: KeyValue[] = [];
  @Input('addressOptions')  addressOptionsType: KeyValue[] = [];
  @Input('administrativeRoleOptions')  administrativeRoleOptionsType: KeyValue[] = [];
  @Input('maxMailOptions')  maxMailOptionsType: KeyValue[] = [];
  @Input('sellingRoleOptions')  sellingRoleOptionsType: KeyValue[] = [];
  @Input('interfaceOptions')  interfaceOptionsType: KeyValue[] = [];
  agencyId: number;
constructor(private activatedRoute: ActivatedRoute, private router: Router) {
  this.agencyId = parseInt(this.activatedRoute.snapshot.params.agencyId, 10);
}
  /**
   * <option value="0">None</option>
   * <option value="1">Self</option>
   * <option value="2">Agency Level</option>
   * <option value="3">Main Office</option>
   * @param key select option value for reportRightOptions
   */
  public reportRightOptions(value: number): string {
    for (const op of this.reportingRightOptionsType) {
      if (op.Value === value.toString()) {
        return op.Key;
      }
    }
    return '';
  }

  /**
   * <option value="0">Use Agency Default</option>
   * <option value="1">Agent Specific</option>
   * <option value="2">Agent Can Change</option>
   * @param key select option value for addressOptions
   */
  public addressOptions(value: number): string {
    for (const op of this.addressOptionsType) {
      if (op.Value === value.toString()) {
        return op.Key;
      }
    }
    return '';
  }

  /**
   * change value of checkbox to yes when it true false otherwise
   * @param key value of any checkbox
   */
  public interpretCheckBox(key: boolean): string {
    return key ? 'Yes' : 'No';
  }
  /**
   * change value of isLockedOut to yes when it equals to 2 false otherwise
   * @param key value of isLockedOut checkbox
   */
  public interpretIsLockedOut(key: number): string {
    return key === this.agentLockedOut ? 'Yes' : 'No';
  }

  /**
   * <option value="0">Agent</option>
   * <option value="2">Administrator</option>
   * @param key select option value for addressOptions
   */
  public agentAdministrativeRoleOptions(value: number): string {
    for (const op of this.administrativeRoleOptionsType) {
      if (op.Value === value.toString()) {
        return op.Key;
      }
    }
    return '';
  }

  /**
   * <option value="0">Agent Can Change</option>
   * <option value="1">Agent Specific</option>
   * <option value="2">Use Agency Default</option>
   * <option value="3">None</option>
   * @param key select option value for maxMailOptions
   */
  public maxMailOptions(value: number): string {
    for (const op of this.maxMailOptionsType) {
      if (op.Value === value.toString()) {
        return op.Key;
      }
    }
    return '';
  }

  /**
   * <option value="0">None</option>
   * <option value="1">Self</option>
   * <option value="2">Agency Level</option>
   * <option value="3">Main Office</option>
   * @param key select option value for sellingRoleOptions
   */
  public sellingRoleOptions(value: number): string {
    for (const op of this.sellingRoleOptionsType) {
      if (op.Value === value.toString()) {
        return op.Key;
      }
    }
    return '';
  }
  /**
   * <option value="0">Use Agency Default</option>
   * <option value="1">Agent Specific</option>
   * <option value="2">Agent Can Change</option>
   * @param key select option value for sellingRoleOptions
   */
  public interfaceOptions(value: number): string {
    for (const op of this.interfaceOptionsType) {
      if (op.Value === value.toString()) {
        return op.Key;
      }
    }
    return '';
  }

  /**
   * Navigates to the admin agent profile page using the agent account id of the agent
   * initial agentAdminSettingsGridbagsResponse object
   * @returns {void}
   */
  routeToAgentProfile(): void {
    const agentAccountId = this.agentSettings.AgentAccountId;
    if (agentAccountId !== 0) {
      this.router.navigate([`admin/${agentAccountId}`]);
    } else {
      this.router.navigate([`admin/agentSettings/${this.agencyId}`], { state: { agencies: this.activatedRoute.snapshot.data.adminAgencies.Agencies } });
    }
  }
}
