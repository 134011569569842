// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Serivces
import { UniversalConfigService } from 'src/app/api/services/universal-config.service';

// Third Party
import { Observable } from 'rxjs';
import { SupplierInfo } from '@allianz/agent-max-core-lib';

@Injectable()
export class SuppliersResolver implements Resolve<Observable<SupplierInfo[]>> {
  constructor(
    private universalConfigService: UniversalConfigService
  ) { }
  resolve(): Observable<SupplierInfo[]> {
    return this.universalConfigService.getSuppliers();
  }
}
