// Angular Core
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Models
import { environment } from 'src/environments/environment';
import { Airport, Airline, State, SupplierInfo } from '@allianz/agent-max-core-lib';
import { ResourceBlock, UserInformation, ResourceFile } from 'src/app/models/interfaces';

// Services
import { UtilityService } from 'src/app/shared/services/utility.service';

// Third party
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UniversalConfigService {
  universalConfigServicesURL: string;

  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService
  ) {
    this.universalConfigServicesURL = this.utilityService.setTheDomainForAPICalls(environment.UniversalConfigServices);
  }

  /**
   * Gets list of states
   * @returns {Observable<State[]>} - Returns an observable array of states
   */
  public getStates(): Observable<State[]> {
    return this.httpClient.get<State[]>(`${this.universalConfigServicesURL}/GetStates/`);
  }

  /**
   * Get all suppliers information
   * @returns {Observable<SupplierInfo[]>}
   */
  public getSuppliers(): Observable<SupplierInfo[]> {
    return this.httpClient.get<SupplierInfo[]>(`${this.universalConfigServicesURL}/GetSuppliers/`);
  }

  /**
   * Get all destinations (states)
   * @returns {Observable<State[]>}
   */
  public getDestinations(): Observable<State[]> {
    return this.httpClient.get<State[]>(`${this.universalConfigServicesURL}/GetDestinations/`);
  }

  /**
   * Get airports
   * @param {number} maxResults
   * @param {string} filter
   * @returns {Observable<Airport[]>}
   */
  public getAirports(maxResults: number, filter: string): Observable<Airport[]> {
    return this.httpClient.get<Airport[]>(`${this.universalConfigServicesURL}/GetAirports/${maxResults}/${filter}`);
  }

  /**
   * Get airlines
   * @param {number} maxResults
   * @param {string} filter
   * @returns {Observable<Airline[]>}
   */
  public getAirlines(maxResults: number, filter: string): Observable<Airline[]> {
    return this.httpClient.get<Airline[]>(`${this.universalConfigServicesURL}/GetAirlines/${maxResults}/${filter}`);
  }

  /**
   * Gets the agency resource blocks for the resource center
   * @param {number} groupID - currently only is 1 (hard coded in the angularjs app)
   * @param {UserInformation} userInfo
   * @returns {Observable<ResourceBlock>}
   */
  public getAgencyResourceBlocks(groupID: number, userInfo: UserInformation): Observable<ResourceBlock[]> {
    return this.httpClient.get<ResourceBlock[]>(`${this.universalConfigServicesURL}/GetAgencyResourceBlocks/${groupID}`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * Get agency resource item for the file by ID
   * @param {number} itemID
   * @param {UserInformation} userInfo
   * @returns {Observable<ResourceFile>}
   */
  public getAgencyResourceItemFileForId(itemID: number, userInfo: UserInformation): Observable<ResourceFile> {
    return this.httpClient.get<ResourceFile>(`${this.universalConfigServicesURL}/GetAgencyResourceItemFileForId/${itemID}`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * Get agency resource item file from Url
   * @param {number} itemID
   * @param {UserInformation} userInfo
   * @returns {Observable<any>}
   */
  public getSecureFile(url: string, userInfo: UserInformation): Observable<any> {
    return this.httpClient.get<any>(url, {
      headers: this.utilityService.getServiceHeaders(userInfo),
      responseType: 'blob' as 'json'
    });
  }

  /**
   * Check whether URL is from secure allianz docs
   * @param {string} url
   * @returns {boolean}
   */
  public isSecureFileURL(url: string) {
    return url.indexOf("UniversalConfigServices.svc/GetSecureFile") > 0;
  }
  
}
