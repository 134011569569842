// Angular Core
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  copyright: Date = new Date();
  ngOnInit(): void { }

}
