// Angular Core
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AgentmaxService } from 'src/app/api/services/agentmax.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

// Components
import { PendingComponent } from './pending-tab/pending.component';
import { QuoteTabComponent } from './quote-tab/quote-tab.component';
import { ConfirmedComponent } from './confirmed-tab/confirmed-tab.component';
import { GroupTabComponent } from './group-tab/group-tab.component';
import { ModalComponent } from '../shared/components/modal/modal.component';

// Models
import { QueueRequest, RetryPendingRequest, RetryPendingResponse, QueueResponse, Queue } from 'src/app/models/interfaces';
import { ErrorMessage, FileTypes } from '@allianz/agent-max-core-lib';
import { QueueTypes, SortTypes } from '../models/enums';

// Third Party
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Paging } from '../models/classes/paging.class';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-queues',
  templateUrl: './queues.component.html',
  styleUrls: ['./queues.component.scss']
})
export class QueuesComponent implements OnInit {
  bsModalRef: BsModalRef;
  queueLoadingErrorMessages: ErrorMessage[] = [];
  queuesSubscription: Subscription;
  sortDesc: FormControl = new FormControl(true);
  search: FormControl = new FormControl('');
  visibilityType: FormControl = new FormControl('Agent');
  sortBy: FormControl = new FormControl('SortableDepartureDate');
  queueType: FormControl = new FormControl(QueueTypes.Pending);
  queues: FormControl = new FormControl([]);
  queuesFormGroup: FormGroup;
  @ViewChild(PendingComponent, { static: true })
  public pendingComponent: PendingComponent;
  @ViewChild(QuoteTabComponent, { static: true })
  public quoteComponent: QuoteTabComponent;
  @ViewChild(ConfirmedComponent, { static: true })
  public confirmedComponent: ConfirmedComponent;
  @ViewChild(GroupTabComponent, { static: true })
  public groupTabComponent: GroupTabComponent;
  retryPending: FormGroup;
  statusType: FormControl = new FormControl('PurchaseRejected');
  failureType: FormControl = new FormControl('PriceMisMatch');
  emailAddress: FormControl = new FormControl('');
  showRetryPendingResponse: boolean = false;
  retryErrorMessages: ErrorMessage[] = [];
  retryPendingResponse: RetryPendingResponse;
  canManagedGroup: boolean;
  paging: Paging = new Paging();
  readonly DefaultItemsPerPageSize: number = 50;
  pageSize: FormControl = new FormControl(this.DefaultItemsPerPageSize);
  QuotesCount: number = 0;
  PendingCount: number = 0;
  ConfirmedCount: number = 0;
  GroupTravelCount: number = 0;
  previousSearch: string = '';

  private readonly onDestroy: Subject<void> = new Subject<void>();

  constructor(
    private appStateService: AppStateService,
    private agentmaxService: AgentmaxService,
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private activatedRoute: ActivatedRoute,
    private bsModalService: BsModalService,
    private router: Router
  ) {
    this.queuesFormGroup = this.formBuilder.group({
      sortDesc: this.sortDesc,
      visibilityType: this.visibilityType,
      sortBy: this.sortBy,
      queueType: this.queueType,
      queues: this.queues,
      pageSize: this.pageSize,
      search: this.search
    });
    this.retryPending = this.formBuilder.group({
      statusType: this.statusType,
      failureType: this.failureType,
      emailAddress: this.emailAddress
    });
    // check if agent can manage groups before get queue
    this.canManagedGroup = this.appStateService.getCanManageGroups();
  }

  ngOnInit(): void {
    this.setSortBy(this.appStateService.getQueueType());
    this.appStateService.isAdminObservable$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((val) => {
        const isAdmin = val;
      });
    if (this.appStateService.getQueueType() !== null) {
      this.queueType.setValue(this.appStateService.getQueueType());
    }
    this.visibilityType.patchValue(this.appStateService.getQueueBookingFilter());
    if (!this.visibilityType.value) {
      this.visibilityType.patchValue('Agent');
      this.appStateService.setQueueBookingFilter(this.visibilityType.value);
    }

    if (this.appStateService.getQueueType() === QueueTypes.Groups) {
      if (this.canManagedGroup) {
        this.getQueues(true);
      }
    } else {
      this.getQueues(true);
    }

    let pageSizeStored = this.appStateService.getPageSize();

    if(pageSizeStored !== null)
    {
      this.pageSize.setValue(pageSizeStored);
      this.paging.itemsPerPage = pageSizeStored;
    }
    else
    {
      this.pageSize.setValue(this.DefaultItemsPerPageSize);
      this.paging.itemsPerPage = this.DefaultItemsPerPageSize;
      this.appStateService.setPageSize(this.DefaultItemsPerPageSize);
    }
  }

  /**
   * this is basically copied over from the old codebase wholesale,
   * sets the name of the options in the sortby dropdown based on the active tab in session
   * @param {string} direction - asc or desc
   * @returns {string}
   */
  GetSortByHeader(direction: string): string {
    if (direction === SortTypes.DESC) {
      if (this.sortBy.value === SortTypes.SortablePurchaseDate || this.sortBy.value === SortTypes.SortableSavedDate ||
        this.sortBy.value === SortTypes.SortableDepartureDate || this.sortBy.value === SortTypes.ConfirmationNumber ||
        this.sortBy.value === SortTypes.FormattedCreateDate || this.sortBy.value === SortTypes.PurchaseDate ||
        this.sortBy.value === SortTypes.SavedDate || this.sortBy.value === SortTypes.DepartureDate) {
        return SortTypes.Newest;
      } else {
        return SortTypes.ReverseAlpha;
      }
    } else if (direction === SortTypes.ASC) {
      if (this.sortBy.value === SortTypes.SortablePurchaseDate || this.sortBy.value === SortTypes.SortableSavedDate ||
         this.sortBy.value === SortTypes.SortableDepartureDate || this.sortBy.value === SortTypes.ConfirmationNumber ||
         this.sortBy.value === SortTypes.FormattedCreateDate || this.sortBy.value === SortTypes.PurchaseDate ||
         this.sortBy.value === SortTypes.SavedDate || this.sortBy.value === SortTypes.DepartureDate) {
        return SortTypes.Oldest;
      } else {
        return SortTypes.Alpha;
      }
    }

    return SortTypes.DESC;
  }

  /**
   * sets the active queue tab in session
   * @param {string} queueType - name of the queuetype
   * @returns {void}
   */
  setActiveQueue(queueType: string, changeSortBy = true, clearPagination = false): void {
    this.queueType.setValue(queueType);
    this.appStateService.setQueueType(this.queueType.value);

    if(changeSortBy)
      this.setSortBy(queueType);

      if(clearPagination)
      {
      this.paging = new Paging();
      this.paging.itemsPerPage = this.pageSize.value;
      }

    this.getQueues();
  }

  /**
   * sets sort byt options in the drop down in session
   * @param {string} queueType - name of the queuetype
   * @returns {void}
   */
  setSortBy(queueType: string): void {
    if (queueType === QueueTypes.Confirmed) {
      this.sortBy.setValue(SortTypes.PurchaseDate);
    } else {
      this.sortBy.setValue(SortTypes.SavedDate);
    }
  }

  /**
   * catches an emitted event and gets queues
   * @returns {void}
   */
  onDeleteQuote(): void {
    this.getQueues(true);
  }

  /**
   *  route to the addgroup page
   * @returns {void}
   */
  addNewGroup(): void {
    this.appStateService.setCurrentBookingQuote(null);
    this.router.navigate(['small-group/create-group/details']);
  }

  isSearchInputInvalid(): boolean{
    return this.search.value && this.search.value.length < 4;
  }

  showSearchInputInvalidMessage(): void{
    const initialState = { text: 'At least 4 characters are required to complete a search; please update your search criteria.' };
    this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  /**
   * does the work of calling the service and getting the quotes/products
   *  no params, but does care about whats in the session storage, namely
   *  'this.QueueType.value' and 'this.VisibilityType.value'
   *  @returns {void}
   */
  getQueues(updateCounters = false, updateVisibilityTypeField = false): void {

    if(updateVisibilityTypeField)
    {
      this.visibilityType.setValue(this.getRadioInputValue('visibilityType'));
      this.search.setValue('');
    }
    // else if(this.isSearchInputInvalid())
    // {
    //   this.showSearchInputInvalidMessage();
    //   return;
    // }
    

    if (this.appStateService.getQueueType() === QueueTypes.Groups) {
      if (this.canManagedGroup) {
        this.getQueuesByCategory(updateCounters, null, updateVisibilityTypeField);
      }
    } else {
      this.getQueuesByCategory(updateCounters, null, updateVisibilityTypeField);
    }
  }

  /**
   * actually sorts the queues using the function 'dynamicSort' defined immediately below this
   * cares about the sessions value 'QueueType', so it only sorts the active queue
   * @returns {void}
   */
  sortCurrentQueue(): void {
    if (this.queueType.value === QueueTypes.Pending) {
      this.pendingComponent.Queues.sort(this.dynamicSort(this.sortBy.value, this.sortDesc.value));
    }
    if (this.queueType.value === QueueTypes.Quotes) {
      this.quoteComponent.Queues.sort(this.dynamicSort(this.sortBy.value, this.sortDesc.value));
    }
    if (this.queueType.value === QueueTypes.Confirmed) {
      this.confirmedComponent.Queues.sort(this.dynamicSort(this.sortBy.value, this.sortDesc.value));
    }
    if (this.queueType.value === QueueTypes.Groups) {
      this.groupTabComponent.Queues.sort(this.dynamicSort(this.sortBy.value, this.sortDesc.value));
    }
  }

  updatePageSize(itemsPerPage: number, refreshGrid: boolean = false): void
  {
    this.paging.updatePaging(itemsPerPage);

    this.appStateService.setPageSize(itemsPerPage);

    if(refreshGrid)
      this.setActiveQueue(this.queueType.value, false);
  }

  /**
   * sorting method for arrays. Calling it like:
   *            myArray.sort(this.dynamicSort(myProperty, true));
   *
   *            (good candidate for utils file when we have one)
   * @param {string} property - name of the property in the aray to sort by
   * @param {boolean} sortDesc - whether you want it to sort by descending or not
   * @returns {Array<string>} - returns the sorted array
   */
  dynamicSort(property: string, sortDesc: boolean): (a: object, b: object) => number {
    if (property[0] === '-') {
      property = property.substr(1);
    }
    return function sort(a: object, b: object): number {
      // make date sortable
      if (property === SortTypes.FormattedCreateDate) {
        a[property] = new Date(a[property]);
        b[property] = new Date(b[property]);
      }
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;

      // make date formatted
      if (property === SortTypes.FormattedCreateDate) {
        a[property] = formatDate(new Date(a[property]), 'MM/dd/yyyy', 'en-US');
        b[property] = formatDate(new Date(b[property]), 'MM/dd/yyyy', 'en-US');
      }

      if (sortDesc) {
        return result * -1;
      } else {
        return result;
      }
    };
  }

  /**
   * Rety pending bookings
   * @returns {void}
   */
  retryPendingSubmit(): void {
    const retryPendingRequest: RetryPendingRequest = {
      BookingStatustype: this.retryPending.value.statusType,
      EmailAddress: this.retryPending.value.emailAddress,
      FailureType: this.retryPending.value.failureType,
      VisibilityType: this.queuesFormGroup.value.visibilityType
    };
    this.agentmaxService.bulkRetryPendingBookings(retryPendingRequest, this.appStateService.getCredentials())
      .subscribe((response) => {
        this.retryErrorMessages = [];
        if (response.ErrorMessages.length === 0) {
          this.showRetryPendingResponse = true;
          this.retryPendingResponse = response;
          this.utilityService.openFile(response.FileContent, FileTypes.Excel);
        } else {
          this.retryErrorMessages = response.ErrorMessages;
        }
      });
  }

  /**
   * Filters confirmed queue to only contain records with purchase dates equal to or after today's date minus AgencyQueueMaxDays number of days
   * @returns {void}
   */
  filterConfirmedQueue(confirmedQueue: Queue[]): Queue[] {
    const filteredConfirmedQueue: Queue[] = [];
    const agencyQueueMaxDays: number = this.activatedRoute.snapshot.data.AgencySettings.AgencyQueueMaxDays;
    const confirmedQueueCutoffDate: Date = moment(new Date()).subtract(agencyQueueMaxDays, 'days').toDate();
    const formattedConfirmedQCutoffDate: string = moment(confirmedQueueCutoffDate, 'DD MM YYYY hh:mm:ss').format('MM/DD/YYYY');

    for (const record of confirmedQueue) {
      if (moment(new Date(record.FormattedPurchaseDate)).isSameOrAfter(moment(formattedConfirmedQCutoffDate, 'MM DD YYYY'))) {
        filteredConfirmedQueue.push(record);
      }
    }

    return filteredConfirmedQueue;
  }

  /**
   * Sends the response to the component based on queue type
   * @param {QueueResponse} response
   * @param {string} queueType
   * @returns {void}
   */
  private componentQueuesResults(response: QueueResponse, queueType: string): void {
    if (queueType === QueueTypes.Pending) {
      this.pendingComponent.Queues = response.Queue;
    }
    if (queueType === QueueTypes.Quotes) {
      this.quoteComponent.Queues = response.Queue;
    }
    if (queueType === QueueTypes.Confirmed) {
      this.confirmedComponent.Queues = response.Queue;
    }
    if (queueType === QueueTypes.Groups) {
      this.groupTabComponent.Queues = response.Queue;
    }
  }

  private setPage(page: number): void {
    this.paging.currentPage = page;
    this.setActiveQueue(this.queueType.value, false);
  }

  private updateCounters(queueResponse: QueueResponse): void {
    this.QuotesCount = queueResponse.QuotesCount;
    this.PendingCount = queueResponse.PendingCount;
    this.ConfirmedCount = queueResponse.ConfirmedCount;
    this.GroupTravelCount = queueResponse.GroupTravelCount;
  }

  private clearCounters(): void
  {
    this.QuotesCount = 0;
    this.PendingCount = 0;
    this.ConfirmedCount = 0;
    this.GroupTravelCount = 0;
  }

  private getRadioInputValue(controlName: string): string
  {
    let valueChecked = '';
    document.getElementsByName(controlName).forEach((item: HTMLInputElement) => {
      if(item && item.checked)
        {
          valueChecked = item.value;
        }
    });

    return valueChecked;
  }
/**
 * private method calles by get queues method to do the work of calling the service and getting the quotes/products
 */
  public getQueuesByCategory(updateCounters = false, SortFieldValue: boolean | null = null, updateVisibilityTypeField = false): void {

    if(!updateVisibilityTypeField && this.isSearchInputInvalid())
    {
      this.showSearchInputInvalidMessage();
      return;
    }

    if(SortFieldValue !== null)
    {
      this.sortDesc.setValue(SortFieldValue);
    }

    this.appStateService.setQueueBookingFilter(this.visibilityType.value);
    const agencyQueueMaxDays: number = this.activatedRoute.snapshot.data.AgencySettings.AgencyQueueMaxDays;


    if(!updateCounters && this.search.value !== this.previousSearch)
      updateCounters = true;


    const queuesRequest: QueueRequest = {
      NumberOfRows: this.paging.itemsPerPage,
      StartRowNum: this.paging.currentPage,
      QueueCategoryType: this.queueType.value,
      VisibilityType: this.visibilityType.value,
      SortBy: this.sortBy.value,
      SortOrder: this.sortDesc.value,
      AgencyConfirmedQueueMaxDays: agencyQueueMaxDays,
      UpdateBookingsCount: updateCounters,
      Search: this.search.value
    };

    this.previousSearch = this.search.value;

    this.agentmaxService.getQueuesByCategory(queuesRequest, this.appStateService.getCredentials())
      .subscribe((result) => {
        this.queueLoadingErrorMessages = [];

        this.paging.updateRowsCount(result.RowsCount);

        if(updateCounters)
          this.updateCounters(result);

        if (result.ErrorMessages.length === 0)
        {
          this.componentQueuesResults(result, queuesRequest.QueueCategoryType);
        }
        else
        {
          result.ErrorMessages.find((error, i) => {
            if (error.Code === '556') {
              this.queueLoadingErrorMessages.push({Code: '556', Description: 'Insufficient Privileges.  The currently logged in user does not have Full Web Access'});
              return true;
            } else {
              this.queueLoadingErrorMessages = result.ErrorMessages;
            }
          });
        }
      },
      (error) =>
      {
        if(updateCounters)
          this.clearCounters();
      });
  }

  public inputValidator(event: any) {
    const pattern = /^[ a-zA-Z0-9,.-]*$/;
    if (!pattern.test(event.target.value)){
      event.target.value = event.target.value.replace(/[^ a-zA-Z0-9,.-]/g, "");
    }
  }
}
