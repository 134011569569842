import { HelpDataAbout } from './help-data-about.class';

export class HelpData {
    HelpEmailAddress: string;
    About: HelpDataAbout;
    HtmlTable: string;

    constructor() {
        this.HelpEmailAddress = 'agentservices@allianzassistance.com';
        this.About = new HelpDataAbout();
        this.HtmlTable = '';
    }
}
