// Angular core
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Services
import { UtilityService } from 'src/app/shared/services/utility.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';

// Models
import { environment } from 'src/environments/environment';
import { NotificationResponse, NotificationCount, UserInformation } from 'src/app/models/interfaces';
import { NotificationSaleType } from 'src/app/models/enums';

// Third Party
import { Observable } from 'rxjs';
import { publishLast, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notificationServiceUrl: string;
  userInfo: UserInformation;
  headers: HttpHeaders;

  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService,
    private appStateService: AppStateService,
  ) {
    this.notificationServiceUrl = this.utilityService.setTheDomainForAPICalls(environment.NotificationService);
  }

  /**
   * Get all notifications by current logged in user
   * @returns {Observable<NotificationResponse>}
   */
  public getNotifications(): Observable<NotificationResponse> {
    return this.httpClient.get<NotificationResponse>(
      `${this.notificationServiceUrl}/Notification`, { headers: this.utilityService.getServiceHeaders(this.appStateService.getCredentials()) }
    ).pipe(publishLast(), refCount());
  }

  /**
   * Get all the notifications for a logged in user by sale type
   * @param {NotificationSaleType} saleType
   * @returns {Observable<NotificationResponse>}
   */
  public getNotificationsBySaleType(saleType: NotificationSaleType): Observable<NotificationResponse> {
    return this.httpClient.get<NotificationResponse>(
      `${this.notificationServiceUrl}/Notification?SaleType=${saleType}`, { headers: this.utilityService.getServiceHeaders(this.appStateService.getCredentials()) }
    ).pipe(publishLast(), refCount());
  }

  /**
   * Gets the total number of notifications by logged in user
   * @returns {Observable<NotificationCount>}
   */
  public getNotificationCount(): Observable<NotificationCount> {
    return this.httpClient.get<NotificationCount>(
      `${this.notificationServiceUrl}/NotificationCount`, { headers: this.utilityService.getServiceHeaders(this.appStateService.getCredentials()) }
    ).pipe(publishLast(), refCount());
  }

  /**
   * Save notifications used for loading notifications in DB potentially not a call that would be made in AMO
   * @param {NotificationResponse} notifications
   * @returns {Observable<NotificationResponse>}
   */
  public saveNotifications(notifications: NotificationResponse): Observable<NotificationResponse> {
    return this.httpClient.post<NotificationResponse>(
      `${this.notificationServiceUrl}/SaveNotification`, notifications, { headers: this.utilityService.getServiceHeaders(this.appStateService.getCredentials()) }
    );
  }

  /**
   * Updates notifications
   * @param {NotificationResponse} notifications
   * @returns {Observable<NotificationResponse>}
   */
  public updateNotifications(notifications: NotificationResponse): Observable<NotificationResponse> {
    return this.httpClient.put<NotificationResponse>(
      `${this.notificationServiceUrl}/UpdateNotification`, notifications, { headers: this.utilityService.getServiceHeaders(this.appStateService.getCredentials()) }
    ).pipe(publishLast(), refCount());
  }

}
