// Angular Core
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

// Models
import { Queue } from '../../models/interfaces';
import { ErrorMessage } from '@allianz/agent-max-core-lib/models/interfaces';
import { QueueTypes } from 'src/app/models/enums';

// Services
import { QuoteService } from '../../api/services/quote.service';
import { AppStateService } from '../../shared/services/app-state.service';
import { LoadingService } from 'src/app/api/services/loading.service';

@Component({
  selector: 'app-group-tab',
  templateUrl: './group-tab.component.html',
  styleUrls: ['./group-tab.component.scss']
})
export class GroupTabComponent {
  @Input() Queues: Queue[] = [];
  @Input() manageGroupMenuErrors: ErrorMessage[] = [];
  validationMessages: ErrorMessage[] = [];
  $index;

  constructor(
    private quoteService: QuoteService,
    private router: Router,
    private appStateService: AppStateService,
    private loadingService: LoadingService
  ) { }

  /**
   * empties the manageGroupMenuErrors array
   * @returns {void}
   */
  clearErrors(): void {
    this.validationMessages = [];
  }

  /**
   * Copy HTML to clipboard
   * @param {string} inputID
   * @returns {void}
   */
  copyToClipBoard(inputID: string): void {
    // Get and select the text field
    const copyText = document.getElementById(inputID) as HTMLInputElement;
    copyText.select();
    // Copy the text inside the text field
    document.execCommand('copy');
  }

  /**
   * On cancel policy
   * @param {Queue} queueItem
   * @returns {void}
   */
  deleteGroup(queueItem: Queue): void {
    if(confirm("Are you sure you want to delete this Group?")) {
      this.loadingService.open();
      this.quoteService.deleteQuote(queueItem.BookingId, this.appStateService.getCredentials())
        .subscribe(
          (result) => {
            if(result === false){
              alert("Selected group cannot be deleted because the group is not empty.");
            }
            else {
              var index = this.Queues.indexOf(queueItem);
              if (index > -1) {
                this.Queues.splice(index, 1);
              }
            }
          },
          (error) => { },
          () => this.loadingService.close()
        );
    }
    else {
      console.log("cancel delete");
      this.loadingService.close();
      }
  }

  /**
   * On cancel policy
   * @param {Queue} queueItem
   * @returns {void}
   */
  openGroup(queueItem: Queue): void {
    this.appStateService.setQueueType(QueueTypes.Groups);
    this.appStateService.setCurrentBookingQuote(null);
    this.appStateService.setCurrentBookingId(queueItem.BookingId);
    this.loadingService.open();
    this.quoteService.getSmallGroupQuoteByID(queueItem.BookingId, this.appStateService.getCredentials())
      .subscribe((response) => {
        if (response.ErrorMessages.length === 0) {
          this.appStateService.setCurrentBookingQuote(response);
          this.router.navigate([`small-group/modifyGroup/${queueItem.BookingId}/details`]);
        }
        this.validationMessages = response.ErrorMessages;
      },
      (error) => { },
      () => this.loadingService.close());
  }
}
