// Angular Core
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// Third Party
import { Observable, Subscription } from 'rxjs';
import { AccordionConfig } from 'ngx-bootstrap';

// Models
import { TravelerFormGroup } from 'src/app/models/classes';
import { TravelInformation, Booking, State, BookingTypes, TravelerInformation } from '@allianz/agent-max-core-lib';

// Services
import { UtilityService } from '../../services/utility.service';
import { AppStateService } from '../../services/app-state.service';
import { UniversalConfigService } from 'src/app/api/services/universal-config.service';

// TODO: remove and use whats in the utility service
export function getAccordionConfig(): AccordionConfig {
  return Object.assign(new AccordionConfig(), { isAnimated: true });
}

@Component({
  selector: 'app-traveler-information',
  templateUrl: './traveler-information.component.html',
  styleUrls: ['./traveler-information.component.scss'],
  providers: [{ provide: AccordionConfig, useFactory: getAccordionConfig }]
})

export class TravelerInformationComponent implements OnInit, OnDestroy {

  get firstName(): AbstractControl { return this.travelerInformationForm.get('PLirstName'); }
  get lastName(): AbstractControl { return this.travelerInformationForm.get('PFastName'); }
  get dateOfBirth(): AbstractControl { return this.travelerInformationForm.get('dateOfBirth'); }
  get addressLine1(): AbstractControl { return this.travelerInformationForm.get('addressLine1'); }
  get addressLine2(): AbstractControl { return this.travelerInformationForm.get('addressLine2'); }
  get city(): AbstractControl { return this.travelerInformationForm.get('city'); }
  get stateOfResidence(): AbstractControl { return this.travelerInformationForm.get('stateOfResidence'); }
  get zipCode(): AbstractControl { return this.travelerInformationForm.get('zipCode'); }
  get phone(): AbstractControl { return this.travelerInformationForm.get('phone'); }
  get email(): AbstractControl { return this.travelerInformationForm.get('email'); }
  get travelerArray(): FormArray { return this.travelerInformationForm.get('travelerArray') as FormArray; }
  public MAX_OTHER_TRAVELERS: number = 8;
  public mask: Array<string | RegExp> = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public dobMask: Array<string | RegExp> = this.utilityService.dobMask;

  formattedDateOfBirth: FormControl = new FormControl('', Validators.pattern(''));
  travelerInformationForm: FormGroup;
  @Input('isModify') isModify: boolean = false;
  @Input('model') TravelInformation: TravelInformation;
  @Input() booking: Booking;
  @Output() changingTravelers: EventEmitter<number> = new EventEmitter<number>();
  widget: string;
  isSmallGroup: boolean = false;
  isMaxTravelers: boolean = false;
  isOpen: boolean = true;

  firstNameControl: FormControl = new FormControl('');
  lastNameControl: FormControl = new FormControl('');
  dateOfBirthControl: FormControl = new FormControl('');
  stateOfResidenceControl: FormControl = new FormControl('');
  public states: Observable<State[]>;
  travelerInformationSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private universalConfigService: UniversalConfigService,
    private utilityService: UtilityService,
    private appStateService: AppStateService,
    private activatedRoute: ActivatedRoute
  ) {
    this.travelerInformationForm = this.formBuilder.group({
      PFirstName: this.firstNameControl,
      PLastName: this.lastNameControl,
      dateOfBirth: this.dateOfBirthControl,
      addressLine1: [''],
      addressLine2: '',
      city: [''],
      stateOfResidence: this.stateOfResidenceControl,
      zipCode: [''],
      phone: [''],
      email: [''],
      travelerArray: this.formBuilder.array([])
    });

    this.travelerInformationSubscription = this.phone.valueChanges.subscribe((obj) => {
      const newValue = String(obj).replace(new RegExp(/\D+/g), '');
      this.phone.setValue(newValue, {
        emitEvent: false,
        emitModelToViewChange: false,
        emitViewToModelChange: false
      });
    });
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.data.pageHeader) {
      this.isSmallGroup = true;
      if (!this.TravelInformation) {
        this.TravelInformation = new TravelInformation();
      }
    }
    this.states = this.universalConfigService.getStates();
    this.initTravelerArray();
    this.changingTravelers.emit(this.travelerArray.length);
    if (this.isModify) {
      this.firstNameControl.disable();
      this.lastNameControl.disable();
      this.dateOfBirthControl.disable();
      this.stateOfResidenceControl.disable();
    }
    if (this.TravelInformation.ValidationMessages.length === 0 && this.TravelInformation.PrimaryTraveler) {
      this.travelerInformationForm.patchValue({
        PFirstName: this.TravelInformation.PrimaryTraveler.Info.FirstName.Value,
        PLastName: this.TravelInformation.PrimaryTraveler.Info.LastName.Value,
        dateOfBirth: this.TravelInformation.PrimaryTraveler.Info.FormattedDateOfBirth.Value,
        addressLine1: this.TravelInformation.PrimaryTraveler.Address.Address1.Value,
        addressLine2: this.TravelInformation.PrimaryTraveler.Address.Address2.Value,
        city: this.TravelInformation.PrimaryTraveler.Address.City.Value,
        stateOfResidence: this.TravelInformation.PrimaryTraveler.Address.State.Value,
        zipCode: this.TravelInformation.PrimaryTraveler.Address.ZipCode.Value,
        phone: this.TravelInformation.PrimaryTraveler.PhoneNumber.Value,
        email: this.TravelInformation.PrimaryTraveler.EmailAddress.Value
      });
    }
  }

  /**
   * Event handler for the accordion opening functionality
   * @param {boolean} open
   * @returns {void}
   */
  isOpenChange(open: boolean): void {
    this.isOpen = open;
  }

  /**
   * Checks to see if is disabled
   * @returns {boolean}
   */
  isDisabled(): boolean {
    return this.appStateService.getBookingInfoViewType() === BookingTypes.Pending;
  }

  /**
   * Can add travelers to the form object
   * @returns {boolean}
   */
  canAddTravelers(): boolean {
    return this.travelerArray.length < this.MAX_OTHER_TRAVELERS;
  }

  /**
   * Add traveler to the form object
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} dob
   * @param {number} ageIfNoBirthDate
   * @returns {void}
   */
  addTraveler(firstName: string = '', lastName: string = '', dob: string = '', ageIfNoBirthDate: number = 0): void {
    if (this.travelerArray.length < this.MAX_OTHER_TRAVELERS - 1) {
      let newGroup: TravelerFormGroup;
      newGroup = new TravelerFormGroup({
        FirstName: new FormControl(firstName),
        LastName: new FormControl(lastName),
        FormattedDateOfBirth: new FormControl(dob),
        AgeIfNoBirthDate: new FormControl(ageIfNoBirthDate)
      });
      if (this.booking.OrderInformation.IsPurchaseConfirmed) {
        newGroup.disable();
      }
      this.travelerArray.push(newGroup);
    }
    if (this.travelerArray.length === this.MAX_OTHER_TRAVELERS - 1) {
      this.isMaxTravelers = true;
    }
  }

  /**
   * Remove specific traveler from form object
   * @param {number} index
   * @returns {void}
   */
  removeTraveler(index: number): void {
    this.travelerArray.removeAt(index);
    if (this.travelerArray.length < this.MAX_OTHER_TRAVELERS - 1) {
      this.isMaxTravelers = false;
    }
  }

  /**
   * Update the travel information object date of birth
   * @returns {void}
   */
  updateFormattedDateOfBirth(): void {
    this.TravelInformation.PrimaryTraveler.Info.FormattedDateOfBirth.Value = this.travelerInformationForm.controls.dateOfBirth.value;
  }

  /**
   * calls calculate age of primary traveler
   * @param {TravelInformation} travelInformation
   * @returns {number}
   */
  calculateAge(travelInformation: TravelInformation): number {
    if (this.dateOfBirthControl.value !== '') {
      return this.utilityService.calculateAge(this.dateOfBirthControl.value);
    } else {
      return travelInformation.PrimaryTraveler.Info.AgeIfNoBirthDate.Value || this.utilityService.calculateAge(travelInformation.PrimaryTraveler.Info.FormattedDateOfBirth.Value);
    }
  }

  /**
   * calls calculate age of other traveler
   * @param {TravelInformation} travelInformation
   * @param {FormGroup} control
   * @returns {number}
   */
  calculateFromControlAge(traveler: TravelerInformation, control: FormGroup): number {
    if (control.controls.FormattedDateOfBirth.value !== '') {
      return this.utilityService.calculateAge(control.controls.FormattedDateOfBirth.value);
    } else {
      if (traveler) {
        return traveler.AgeIfNoBirthDate.Value || this.utilityService.calculateAge(traveler.FormattedDateOfBirth.Value);
      } else {
        return 0;
      }
    }
  }

  ngOnDestroy(): void {
    this.travelerInformationSubscription.unsubscribe();
  }

  /**
   * Inits Traveler array for the form object
   * @returns {void}
   */
  initTravelerArray(): void {
    // this.travelerInformationForm.controls.travelerArray.clear();
    this.travelerArray.clear();
    // If # of travelers are > 1
    if (typeof this.TravelInformation !== 'undefined'
      && typeof this.TravelInformation.OtherTravelers !== 'undefined'
      && this.TravelInformation.OtherTravelers.length > 0
    ) {
      for (const t of this.TravelInformation.OtherTravelers) {
        this.addTraveler(t.FirstName.Value, t.LastName.Value, t.FormattedDateOfBirth.Value);
      }
      if (this.travelerArray.length === 0) {
        this.addTraveler();
      }
    }
  }
}
