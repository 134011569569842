export enum BookingStatusDropdownOptions {
    Open = 'Open',
    View = 'View',
    Delete = 'Delete',
    Email = 'Email',
    Cancel = 'Cancel',
    Modify= 'Modify',
    Print= 'Print',
    PrintEmail = 'Print/Email'
}
