// Angular Core
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

// Core Lib
import { ErrorMessage } from '@allianz/agent-max-core-lib';

// Models
import { InterfaceSettings, GDSInterfaceSettings, AdminInterfaceSettings } from 'src/app/models/interfaces';
import { ActivatedRoute } from '@angular/router';

// Services
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AlertType } from 'src/app/models/enums/alert-type.enum';
import { UserService } from 'src/app/api/services/user.service';
import { UserProfileRoute } from 'src/app/models/enums';
import { GdsService } from 'src/app/api/services/gds.service';

//Environment Variables
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-interface-settings-form',
  templateUrl: './interface-settings-form.component.html',
  styleUrls: ['./interface-settings-form.component.scss']
})
export class InterfaceSettingsFormComponent implements OnInit {
  errorMessages: ErrorMessage[] = [];
  interfaceSettingsForm: FormGroup;
  selectedInterface: GDSInterfaceSettings;
  isEditingGDSSettings: boolean = false;
  interfaceSettingsSaved: boolean = false;
  adminAgentRoute: boolean = false;
  @Input('userOptions') userOptions: InterfaceSettings;
  @Input('adminRoute') adminRoute: UserProfileRoute;
  @Input('isActive') isActive: boolean = true;
  formUpdated: boolean = false;
  alertType: typeof AlertType = AlertType;
  validationMessages: ErrorMessage[] = [];
  selectedInterfaceIndex: number;
  constructor(
    private activatedRoute: ActivatedRoute,
    private adminuserService: AdminUserService,
    private userService: UserService,
    private appStateService: AppStateService,
    private gdsService: GdsService
  ) {
    this.interfaceSettingsForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.adminAgentRoute = this.activatedRoute.snapshot.data.adminAgentRoute;
  }

  /**
   * Set up the form dynamicaly based on what interface setting was selected
   * @param {GDSInterfaceSettings} interfaceSettings
   * @returns {void}
   */
  setUpTheSettingsForm(interfaceIndex: number, interfaceSettings: GDSInterfaceSettings): void {
    this.selectedInterface = interfaceSettings;
    this.selectedInterfaceIndex = interfaceIndex;
    //
    // All the logic to create dynamic forms
    // if we ever need to update it. As of
    // now the update button in the js code
    // is commented out thus can never be updated.
    // This is expensive especially if we are not
    // using the form outside of display purposes.
    //
    const groups = {};
    this.selectedInterface.Sections.forEach((section) => {
      section.Controls.forEach( (control) => {
        groups[control.Key] = new FormControl(control.Value);
      });
      section.Controls.forEach( (control) => {
        groups[control.Key] = new FormControl(control.Value);
      });
    });
    this.interfaceSettingsForm = new FormGroup(groups);
    this.interfaceSettingsForm.valueChanges.subscribe(() => this.onClosedAlert());
    this.onClosedAlert();
    this.isEditingGDSSettings = true;
  }

  /**
   * submits the form for admin route--currently the only type in mvp that needs to be submitted
   * @returns {void}
   */
  submit(): void {
    this.onClosedAlert();
    this.updateInterfaceSettingsObjectFromForm(this.selectedInterfaceIndex);
    if (this.adminAgentRoute) {
      this.saveAdminUserOptionsInterfaceSettings(this.selectedInterfaceIndex, true);
      } else {
        this.saveUserOptionsInterfaceSettings(this.selectedInterfaceIndex, true);
      }
    this.updateAppletSettings();
    }

  /**
   * toggles the GDS interface and saves to database.  Groups are handled on the back end through the same endpoint
   * so there is no need to use multiple calls depending on the route here.
   * @param {number} interfaceIndex - which interface index is beign updated (sabre, apollo, etc.)
   * @returns {void}
   */
  toggleInterface(interfaceIndex: number, interfaceSettings: GDSInterfaceSettings): void {
    this.selectedInterface = interfaceSettings;
    this.onClosedAlert();
    this.userOptions.GDSInterfaceSettings[interfaceIndex].IsEnabled = !this.userOptions.GDSInterfaceSettings[interfaceIndex].IsEnabled;
    if (this.adminRoute === UserProfileRoute.AdminViewAgent) {
      this.saveAdminUserOptionsInterfaceSettings(interfaceIndex, false);
    } else {
      this.saveUserOptionsInterfaceSettings(interfaceIndex, false);
    }
    this.updateAppletSettings();
  }
  public onClosedAlert(): void {
    if (this.formUpdated) {
      this.formUpdated = false;
      this.validationMessages = [];
    }
  }
  public trackByDataSource(index: number, gdsInterfaceSettings: GDSInterfaceSettings): string {
    return gdsInterfaceSettings.DataSource;
  }

  public showSettingTable(): void {
    this.isEditingGDSSettings = false;
    this.onClosedAlert();
  }
  private saveUserOptionsInterfaceSettings(interfaceIndex: number, isSubmit: boolean): void {
    this.userService.saveInterfaceSettings(this.userOptions, this.appStateService.getCredentials()).subscribe((res) => {
      this.formUpdated = true;
      this.handleResponse(res, isSubmit, interfaceIndex);
    }, (err) => {
      if (!isSubmit) {
        this.userOptions.GDSInterfaceSettings[interfaceIndex].IsEnabled = !this.userOptions.GDSInterfaceSettings[interfaceIndex].IsEnabled;
      }
    });
  }
  private saveAdminUserOptionsInterfaceSettings(interfaceIndex: number, isSubmit: boolean): void {
    const adminInterfaceSettingsRequest: AdminInterfaceSettings = this.createAdminInterfaceSettingsRequest();
    this.adminuserService.updateAgentInterfaceSettings(adminInterfaceSettingsRequest).subscribe((response) => {
      this.formUpdated = true;
      this.handleResponse(response, isSubmit, interfaceIndex);
    }, (err) => {
      if (!isSubmit) {
        this.userOptions.GDSInterfaceSettings[interfaceIndex].IsEnabled = !this.userOptions.GDSInterfaceSettings[interfaceIndex].IsEnabled;
      }
    });
  }
  private handleResponse(response: AdminInterfaceSettings | InterfaceSettings, isSubmit: boolean, interfaceIndex: number): void {
    if (response.ErrorMessages && response.ErrorMessages.length > 0) {
      if (!isSubmit) {
        this.userOptions.GDSInterfaceSettings[interfaceIndex].IsEnabled = !this.userOptions.GDSInterfaceSettings[interfaceIndex].IsEnabled;
      }
      this.validationMessages = response.ErrorMessages;
    } else if (response.OperationErrorMessages && response.OperationErrorMessages.length > 0) {
      if (!isSubmit) {
        this.userOptions.GDSInterfaceSettings[interfaceIndex].IsEnabled = !this.userOptions.GDSInterfaceSettings[interfaceIndex].IsEnabled;
      }
      this.validationMessages = response.OperationErrorMessages;
    } else {
      this.userOptions = response;
      if (isSubmit) {
        this.interfaceSettingsForm.markAsPristine();
      }
      this.appStateService.setInterfaceSettings(response);
      this.appStateService.setCurrentInterfaceSettings(response.GDSInterfaceSettings.find((saveResponseInterfaceSettings) => saveResponseInterfaceSettings.Key === this.selectedInterface.Key));
    }
  }

  /**
   * Updates the interface settings request to reflect the form changes
   * @returns {void}
   */
  private updateInterfaceSettingsObjectFromForm(interfaceIndex: number): void {
    /*Object.keys(this.interfaceSettingsForm.controls).forEach((key) => {
      this.selectedInterface.Sections.forEach((section) => section.GroupControls.forEach((groupControl) => {
        const tempString = String(this.interfaceSettingsForm.get(key).value);
        const valueString = tempString.charAt(0).toUpperCase() + tempString.slice(1);
        groupControl.Value = groupControl.Key === key ? valueString : groupControl.Value;
      }));
    });*/

    Object.keys(this.interfaceSettingsForm.controls).forEach( (key) => {
      this.selectedInterface.Sections.forEach( (section) => section.GroupControls.forEach( (groupControl) => {
        const tempString = String(this.interfaceSettingsForm.get(key).value);
        const valueString = tempString.charAt(0).toUpperCase() + tempString.slice(1);
        groupControl.Value = groupControl.Key === key ?  valueString : groupControl.Value;
      }));
    });
    Object.keys(this.interfaceSettingsForm.controls).forEach( (key) => {
      this.selectedInterface.Sections.forEach( (section) => section.Controls.forEach( (control) => {
        const tempString = String(this.interfaceSettingsForm.get(key).value);
        const valueString = tempString.charAt(0).toUpperCase() + tempString.slice(1);
        control.Value = control.Key === key ?  valueString : control.Value;
      }));
    });
    console.log('SELECTED ::', this.selectedInterface);
    this.userOptions.GDSInterfaceSettings.forEach((gdi) => this.selectedInterface.Key === gdi.Key ? this.selectedInterface : gdi);
    // const interfaceIndex = this.userOptions.GDSInterfaceSettings.indexOf(this.userOptions.GDSInterfaceSettings.find((is) => is.Key === this.selectedInterface.Key));
    this.userOptions.GDSInterfaceSettings[interfaceIndex] = this.selectedInterface;
  }
  /**
   * Creates the interface settings request from the updated form
   * @returns {AdminInterfaceSettings} admin requeste object for back end
   */
  private createAdminInterfaceSettingsRequest(): AdminInterfaceSettings {
    return {
      AgentId: this.activatedRoute.snapshot.params.agentId,
      ErrorMessages: [],
      IsEditable: this.userOptions.IsEditable,
      IsValid: true,
      IsVisible: true,
      Message: '',
      OperationErrorMessages: [],
      TransactionNumber: null,
      TransactionStatus: null,
      GDSInterfaceSettings: this.userOptions.GDSInterfaceSettings
    };
  }

  /**
   * Download link for AgentMax GDS Interfaces
   * @returns {string}
   */
  downloadAgentMaxInterface(): string {
    return environment.AgentMaxInterfaceURL;
  }
  
  /**
   * Updates applet settings to reflect the form changes
   * @returns {void}
   */  
  private updateAppletSettings():void {
    if (this.appStateService.getGUID()) {
      this.gdsService.registerGDSLogin(this.appStateService.getCredentials(), this.appStateService.getGUID())
        .subscribe((response) => {
          if (response.ErrorMessages.length > 0) {
            this.validationMessages = response.ErrorMessages;
          }
        });
    }
  }
}
