// Angular Core
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Models
import { Booking, KeyValue } from '@allianz/agent-max-core-lib';
import { environment } from 'src/environments/environment';
import { UserInformation, Feedback, FeedbackResponse, RetryPendingResponse, ReportSettings, EmailInput } from 'src/app/models/interfaces';
import { PrintOrEmailReportType, PrintOrEmailOfferType } from 'src/app/models/enums';

// Services
import { UtilityService } from 'src/app/shared/services/utility.service';

// Third Party
import { Observable } from 'rxjs';

import { CryptoService } from './crypto-service.service';


@Injectable({
  providedIn: 'root'
})
export class ReportService {
  reportServicesUrl: string;

  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService,
    private cryptoService: CryptoService
  ) {
    this.reportServicesUrl = this.utilityService.setTheDomainForAPICalls(environment.ReportServices);
  }

  /**
   * Send User Feedback
   * @param {Feedback} userFeedback
   * @param {UserInformation} userInfo
   * @returns {Observable<FeedbackResponse>}
   */
  public sendUserFeeback(userFeedback: Feedback, userInfo: UserInformation): Observable<FeedbackResponse> {
    return this.httpClient.put<FeedbackResponse>(
      `${this.reportServicesUrl}/SendUserFeedback`,
      userFeedback,
      { headers: this.utilityService.getServiceHeaders(userInfo) }
    );
  }

  /**
   * View or email agency document from print or email route
   * @param {string} docRequestType
   * @param {PrintOrEmailReportType} reportDocumentType
   * @param {EmailInput} emailInput
   * @param {UserInformation} userInfo
   * @returns {Observable<RetryPendingResponse>}
   */
  public viewOrEmailAgencyDocument(docRequestType: string, reportDocumentType: PrintOrEmailReportType, emailInput: EmailInput, userInfo: UserInformation): Observable<RetryPendingResponse> {
    return this.httpClient.put<RetryPendingResponse>(
      `${this.reportServicesUrl}/ViewOrEmailAgencyDocument/${docRequestType}/${reportDocumentType}`,
      emailInput,
      { headers: this.utilityService.getServiceHeaders(userInfo) }
    );
  }

  /**
   * View or email web quote document from print or email route
   * @param {string} docRequestType
   * @param {PrintOrEmailReportType} reportDocumentType
   * @param {PrintOrEmailOfferType} scOfferType
   * @param {Booking} booking
   * @param {string} programProductId
   * @param {EmailInput} emailInput
   * @param {UserInformation} userInfo
   * @return {Observable<RetryPendingResponse>}
   */
  public viewOrEmailWebQuote(
    docRequestType: string,
    reportDocumentType: PrintOrEmailReportType,
    scOfferType: PrintOrEmailOfferType,
    booking: Booking,
    programProductId: string,
    emailInput: EmailInput,
    userInfo: UserInformation
  ): Observable<RetryPendingResponse> {
    return this.httpClient.put<RetryPendingResponse>(
      `${this.reportServicesUrl}/ViewOrEmailWebQuote/${docRequestType}/${reportDocumentType}/${scOfferType}/${booking.BookingID}/${programProductId}`,
      emailInput,
      { headers: this.utilityService.getServiceHeaders(userInfo) }
    );
  }
  /**
   * View or email booking docum  ent from print or email route
   * @param {string} docRequestType
   * @param {PrintOrEmailReportType} reportDocumentType
   * @param {Booking} booking
   * @param {EmailInput} emailInput
   * @param {UserInformation} userInfo
   * @returns {Observable<RetryPendingResponse>}
   */
  public viewOrEmailBookingDocument(docRequestType: string, reportDocumentType: PrintOrEmailReportType, booking: Booking, emailInput: EmailInput, userInfo: UserInformation): Observable<RetryPendingResponse> {
    return this.httpClient.put<RetryPendingResponse>(
      `${this.reportServicesUrl}/ViewOrEmailBookingDocument/${docRequestType}/${reportDocumentType}/${booking.BookingID}`,
      emailInput,
      { headers: this.utilityService.getServiceHeaders(userInfo) }
    );
  }

  /**
   * Get the sales report defaults for the reports page
   * @param {UserInformation} userInfo
   * @returns {Observable<ReportSettings>}
   */
  public getSalesReportsDefaults(userInfo: UserInformation): Observable<ReportSettings> {
    return this.httpClient.get<ReportSettings>(`${this.reportServicesUrl}/GetSalesReportsDefaults`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * View or email sales report from print or email route
   * @param {string} requestType
   * @param {Booking} booking
   * @param {EmailInput} emailInput
   * @param {UserInformation} userInfo
   * @returns {Observable<RetryPendingResponse>}
   */
  public viewOrEmailSalesReport(requestType: string, booking: Booking, emailInput: EmailInput, userInfo: UserInformation): Observable<RetryPendingResponse> {
    const salesReportBody = {
      EmailInput: emailInput,
      SalesReportCriteria: booking.SalesReportCriteria
    };
    return this.httpClient.put<RetryPendingResponse>(`${this.reportServicesUrl}/ViewOrEmailSalesReport/${requestType}`, salesReportBody, { headers: this.utilityService.getServiceHeaders(userInfo) }
    );
  }

  /**
   * View or email generic document from print or email route
   * @param { string } docRequestType
   * @param { PrintOrEmailReportType } reportDocumentType
   * @param { EmailInput } emailInput
   * @param { UserInformation } userInfo
   * @returns { Observable<RetryPendingResponse>}
   */
  public viewOrEmailGenericDocument(docRequestType: string, reportDocumentType: PrintOrEmailReportType, emailInput: EmailInput, userInfo: UserInformation): Observable<RetryPendingResponse> {
    return this.httpClient.put<RetryPendingResponse>(
      `${this.reportServicesUrl}/ViewOrEmailGenericDocument/${docRequestType}/${reportDocumentType}`,
      emailInput,
      { headers: this.utilityService.getServiceHeaders(userInfo) }
    );
  }

  /**
   * View or email product comparison from print or email route
   * @param {string} requestType
   * @param {Booking} booking
   * @param {Array<string>} programProductIds
   * @param {EmailInput} emailInput
   * @param {UserInformation} userInfo
   * @returns {Observable<RetryPendingResponse>}
   */
  public viewOrEmailProductComparison(requestType: string, booking: Booking, programProductIds: string[], emailInput: EmailInput, userInfo: UserInformation): Observable<RetryPendingResponse> {
    const objbody = {
      EmailInput: emailInput,
      WebBooking: booking,
      ProgramProductIds: programProductIds
    };
    return this.httpClient.put<RetryPendingResponse>(`${this.reportServicesUrl}/ViewOrEmailProductComparison/${requestType}`, objbody, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * View or email resend fulfillment from print or email route
   * @param {Booking} booking
   * @param {string} policyNum
   * @param {Array<string>} emailAddresses
   * @param {UserInformation} userInfo
   * @returns {Observable<RetryPendingResponse>}
   */
  public resendFulfillment(booking: Booking, policyNum: string, emailAddresses: string[], userInfo: UserInformation): Observable<RetryPendingResponse> {
    policyNum = this.cryptoService.CryptoData(policyNum);
    var re = new RegExp('%', 'g');
    policyNum = policyNum.replace(re, '@');
    
    return this.httpClient.put<RetryPendingResponse>(`${this.reportServicesUrl}/ResendFulfillment/${booking.BookingID}/${policyNum}/AMAX2`, emailAddresses, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * Gets the program products list used in help product comparison
   * @param {Booking} booking
   * @param {UserInformation} userInfo
   * @returns {Observable<KeyValue[]>}
   */
  public getProgramProductsList(booking: Booking, userInfo: UserInformation): Observable<KeyValue[]> {
    return this.httpClient.get<KeyValue[]>(`${this.reportServicesUrl}/GetProgramProductsList/${booking.BookingID}`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

}
