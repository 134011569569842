// Angular Core
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Models
import { Booking, BillingInformation, BookingAgentInformation, SupplierInfo, State, ProductPayload } from '@allianz/agent-max-core-lib';
import { UserInformation, QueueResponse, QueueRequest, RetryPendingRequest, RetryPendingResponse } from 'src/app/models/interfaces';
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from 'src/app/shared/services/utility.service';

// Third party
import { Observable } from 'rxjs';
import { publishLast, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgentmaxService {
  MaxmailQuoteService: string;
  urlBase: string;
  queueServices: string;

  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService
  ) {
    this.MaxmailQuoteService = this.utilityService.setTheDomainForAPICalls(environment.MaxmailQuoteService);
    this.urlBase = this.utilityService.setTheDomainForAPICalls(environment.AgentMaxBaseService);
    this.queueServices = this.utilityService.setTheDomainForAPICalls(environment.QueueServices);
  }

  /**
   * Gets list of states
   * @returns {Observable<State[]>} - Returns an observable array of states
   */
  public getStates(): Observable<State[]> {
    return this.httpClient.get<State[]>(`${this.urlBase}/GetStates/`);
  }

  /**
   * Get all suppliers information
   * @returns {Observable<SupplierInfo[]>}
   */
  public getSuppliers(): Observable<SupplierInfo[]> {
    return this.httpClient.get<SupplierInfo[]>(`${this.urlBase}/GetSuppliers/`);
  }

  /**
   * Get all destinations (states)
   * @returns {Observable<State[]>}
   */
  public getDestinations(): Observable<State[]> {
    return this.httpClient.get<State[]>(`${this.urlBase}/GetDestinations/`);
  }

  //
  // TODO MOVE TO MAXMAIL APP
  //

  /**
   * Gets agent information from user token id and booking id
   * @param {string} bookingID
   * @param {string} token
   * @returns {Observable<BookingAgentInformation>}
   */
  public getAgentInformation(bookingID: string, credentials: UserInformation): Observable<BookingAgentInformation> {
    return this.httpClient.put<BookingAgentInformation>(`${this.urlBase}/GetAgentInformation`, { BookingId: bookingID }, { headers: this.utilityService.getServiceHeaders(credentials) });
  }

  // TODO: return type
  public submitPurchase(booking: Booking, billingInfo: BillingInformation, token: string): Observable<object> {
    const purchaseRequest: ProductPayload = {
      Booking: booking,
      BillingInformation: billingInfo,
      ErrorMessages: []
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': token
    });
    return this.httpClient.put(`${this.urlBase}/SaveAndPurchase`, purchaseRequest, { headers });
  }

  /**
   * Get Queues by category
   * @param {QueueRequest} queuesRequest
   * @param {UserInformation} credentials
   * @returns {Observable<QueueResponse>}
   */
  public getQueuesByCategory(queuesRequest: QueueRequest, credentials: UserInformation): Observable<QueueResponse> {
    return this.httpClient.put<QueueResponse>(
      `${this.queueServices}/GetQueuesByCategory`, queuesRequest, { headers: this.utilityService.getServiceHeaders(credentials) }
    );
  }

  /**
   * Get groups for queues. queues for groups?
   * @param {QueueRequest} queuesRequest
   * @param {UserInformation} credentials
   * @returns {Observable<QueueResponse>}
   */
  public getGroups(visibilityType: string, credentials: UserInformation): Observable<QueueResponse> {
    return this.httpClient.get<QueueResponse>(
      `${this.queueServices}/GetGroups/${visibilityType}`, { headers: this.utilityService.getServiceHeaders(credentials) }
    );
  }

  /**
   * Bulk retry pending bookings for queues
   * @param {RetryPendingRequest} retryPendingRequest
   * @param {UserInformation} credentials
   * @returns {Observable<RetryPendingResponse>}
   */
  public bulkRetryPendingBookings(retryPendingRequest: RetryPendingRequest, credentials: UserInformation): Observable<RetryPendingResponse> {
    return this.httpClient.put<RetryPendingResponse>(
      `${this.queueServices}/BulkRetryPendingBookings`, retryPendingRequest, { headers: this.utilityService.getServiceHeaders(credentials) }
    ).pipe(publishLast(), refCount());
  }
}
