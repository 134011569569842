"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_field_class_1 = require("./input-field.class");
var input_field_collection_class_1 = require("./input-field-collection.class");
var SalesReportCriteria = /** @class */ (function () {
    function SalesReportCriteria() {
        this.AgentAccountId = new input_field_class_1.InputField();
        this.AgentCode = new input_field_class_1.InputField();
        this.FormattedBeginDate = new input_field_class_1.InputField();
        this.FormattedEndDate = new input_field_class_1.InputField();
        this.ReportScopeTypeString = new input_field_class_1.InputField();
        this.SalesReportTypeString = new input_field_class_1.InputField();
        this.AgencyIdList = new input_field_collection_class_1.InputFieldCollection();
    }
    return SalesReportCriteria;
}());
exports.SalesReportCriteria = SalesReportCriteria;
