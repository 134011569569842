// Angular Core
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

// Models
import { Agency } from 'src/app/models/interfaces';
import { KeyValue } from '@allianz/agent-max-core-lib';

// 3rd Party Imports
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

// Services
import { UtilityService } from '../../shared/services/utility.service';

// Components
import { AgenciesComponent } from '../tabs/agencies/agencies.component';
import { ProductListComponent } from '../tabs/product-list/product-list.component';
import { InterfaceSettingsComponent } from '../tabs/interface-settings/interface-settings.component';
import { AgencySettingsComponent } from '../tabs/agency-settings/agency-settings.component';
import { AgentSettingsComponent } from '../tabs/agent-settings/agent-settings.component';
import { MaxmailComponent } from '../tabs/maxmail/maxmail.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  agencies: Agency[] = Array<Agency>();
  isDesktop: boolean;

  // Determines whether or not to show the main Administration header
  detailView: boolean = false;

  /* Main Tab Setup */
  homeTab: KeyValue = { Value: 'Home', Key: 'home' };
  adminTab: KeyValue = { Value: 'Administration', Key: 'admin' };
  userOptionsTab: KeyValue = { Value: 'User Options', Key: 'useroptions' };
  resourceTab: KeyValue = { Value: 'Resource Center', Key: 'resourcecenter' };
  waiverTab: KeyValue = { Value: 'Waiver', Key: 'printoremail' };
  reportsTab: KeyValue = { Value: 'Reports', Key: 'reports' };
  helpTab: KeyValue = { Value: 'Help', Key: 'help' };
  logOutTab: KeyValue = { Value: 'Logout', Key: 'login' };

  /* #region  Tab Setup */
  agenciesTab: KeyValue = { Value: 'Agencies', Key: 'agencies' };
  productListTab: KeyValue = { Value: 'Product List', Key: 'product' };
  interfaceSettingsTab: KeyValue = { Value: 'Interface Settings', Key: 'interface' };
  agencySettingsTab: KeyValue = { Value: 'Agency Settings', Key: 'agency' };
  agentSettingsTab: KeyValue = { Value: 'Agent Settings', Key: 'agent' };
  maxMailTab: KeyValue = { Value: 'MaxMail', Key: 'maxmail' };
  /* children section */
  @ViewChild(AgenciesComponent, { static: false }) agenciesComponent: AgenciesComponent;
  @ViewChild(ProductListComponent, { static: false }) productListComponent: ProductListComponent;
  @ViewChild(InterfaceSettingsComponent, { static: false }) interfaceSettingsComponent: InterfaceSettingsComponent;
  @ViewChild(AgencySettingsComponent, { static: false }) agencySettingsComponent: AgencySettingsComponent;
  @ViewChild(AgentSettingsComponent, { static: false }) agentSettingsComponent: AgentSettingsComponent;
  @ViewChild(MaxmailComponent, { static: false }) maxmailComponent: MaxmailComponent;
  bsModalRef: BsModalRef;
  agenciesErrors: boolean = true;
  activeMainTab: string = null;
  nextMainTab: KeyValue = { Value: '', Key: '' };
  component: object = null;
  isAdminFormDirty: boolean = false;
  // The order of this array determines the display order in the template
  tabArr: KeyValue[] = [this.agenciesTab, this.productListTab, this.interfaceSettingsTab, this.agencySettingsTab, this.agentSettingsTab, this.maxMailTab];
  // Sets the default tab
  activeTab: KeyValue = this.agenciesTab;
  // Set up main tab array
  mainTabArr: KeyValue[] = [this.homeTab, this.adminTab, this.userOptionsTab, this.resourceTab, this.waiverTab, this.reportsTab, this.helpTab, this.logOutTab];

  private readonly onDestroy: Subject<void> = new Subject<void>();

  /* #endregion */

  constructor(
    private router: Router,
    private utilityService: UtilityService,
    private bsModalService: BsModalService,
    private activatedRoute: ActivatedRoute
  ) {
    this.utilityService.getTriggerAdminSaveAlert()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((trigger) => {
        if (trigger.component && trigger.component.constructor.name === 'AdminComponent') {
          this.component = trigger.component;
        }
        if (this.activeMainTab !== trigger.activeMainTab) {
          this.activeMainTab = trigger.activeMainTab;
        }
        if (trigger.nextMainTab && trigger.nextMainTab !== this.activeMainTab) {
          this.nextMainTab = this.mainTabArr.find((tab) => {
            return tab.Key === trigger.nextMainTab;
          });

          if (this.nextMainTab) {
            this.goToMainTab(this.nextMainTab);
          }
        }
        if (trigger.isAdminFormDirty) {
          this.isAdminFormDirty = true;
        } else {
          this.isAdminFormDirty = false;
        }
      });
  }

  ngOnInit(): void {
    this.utilityService.getSize().subscribe((isDesktop: boolean) => {
      this.isDesktop = isDesktop;
    });
    this.activatedRoute.queryParamMap
      .pipe(takeUntil(this.onDestroy))
      .subscribe((qParamMap) => {
        const tab = qParamMap.get('tab');
        if (tab) {
          const tabObj = this.tabArr.find((val) => val.Key === tab);
          if (tabObj) {
            this.activeTab = tabObj;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  /**
   * Triggered if errors exist on agencies form under admin agencies tab
   * @returns {void}
   */
  isAgenciesErrors(agenciesErrors: boolean): void {
    if (agenciesErrors) {
      this.agenciesErrors = true;
      // TODO: When errors on agencies tab Agencies tab should remain active but for some reason i can't get it to work - plw
      this.activeTab = this.agenciesTab;
    } else {
      this.agenciesErrors = false;
    }
  }

  /**
   * If changes made on activetab and user clicks main AMO nav tab modal is displayed. If user clicks YES group settings are saved and user is directed to selected main tab page. If user clicks NO user is directed to selected main tab page.
   * @returns {void}
   */
  goToMainTab(mainTab: KeyValue): void {
    const previousActiveTab = this.activeTab;
    const initialState = {
      text: `<p>Changes to groupings on this page have been made. Would you like to Apply the changes?</p>`,
      dialogIndicator: 'admin-save-group-settings'
    };
    if (previousActiveTab.Key === this.agenciesTab.Key && this.agenciesComponent && this.agenciesComponent.agenciesForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .subscribe((ac: boolean) => {
          if (ac && mainTab) {
            this.agenciesComponent.updateVisibilityGroupsForAgency(null, mainTab);
          } else {
            if (mainTab) {
              this.router.navigate([mainTab.Key]);
            } else {
              const extras: NavigationExtras = {
                queryParams: {
                  tab: 'agencies'
                }
              };
              this.router.navigate(['admin'], extras);
            }
          }
        });
    } else if (previousActiveTab.Key === this.productListTab.Key && this.productListComponent && this.productListComponent.productGroupForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .subscribe((ac: boolean) => {
          if (ac && mainTab) {
            this.productListComponent.updateProductGroupsForAgency(null, mainTab);
          } else {
            if (mainTab) {
              this.router.navigate([mainTab.Key]);
            } else {
              const extras: NavigationExtras = {
                queryParams: {
                  tab: 'product'
                }
              };
              this.router.navigate(['admin'], extras);
            }
          }
        });
    } else if (previousActiveTab.Key === this.interfaceSettingsTab.Key && this.interfaceSettingsComponent && this.interfaceSettingsComponent.interfaceSettingsForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .subscribe((ac: boolean) => {
          if (ac && mainTab) {
            this.interfaceSettingsComponent.updateInterfaceGroupsForAgency(null, mainTab);
          } else {
            if (mainTab) {
              this.router.navigate([mainTab.Key]);
            } else {
              const extras: NavigationExtras = {
                queryParams: {
                  tab: 'interface'
                }
              };
              this.router.navigate(['admin'], extras);
            }
          }
        });
    } else if (previousActiveTab.Key === this.agencySettingsTab.Key && this.agencySettingsComponent && this.agencySettingsComponent.agencySettingsForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .subscribe((ac: boolean) => {
          if (ac && mainTab) {
            this.agencySettingsComponent.updateAgencySettingsGroupsForAgency(null, mainTab);
          } else {
            if (mainTab) {
              this.router.navigate([mainTab.Key]);
            } else {
              const extras: NavigationExtras = {
                queryParams: {
                  tab: 'agency'
                }
              };
              this.router.navigate(['admin'], extras);
            }
          }
        });
    } else if (previousActiveTab.Key === this.agentSettingsTab.Key && this.agentSettingsComponent && this.agentSettingsComponent.agentSettingsGroupForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .subscribe((ac: boolean) => {
          if (ac && mainTab) {
            this.agentSettingsComponent.updateAgentSettingsGroupsForAgency(null, mainTab);
          } else {
            if (mainTab) {
              this.router.navigate([mainTab.Key]);
            } else {
              const extras: NavigationExtras = {
                queryParams: {
                  tab: 'agent'
                }
              };
              this.router.navigate(['admin'], extras);
            }
          }
        });
    } else if (previousActiveTab.Key === this.maxMailTab.Key && this.maxmailComponent && this.maxmailComponent.maxMailSettingsGroupForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .subscribe((ac: boolean) => {
          if (ac && mainTab) {
            this.maxmailComponent.updateMaxmailSettingsGroupsForAgency(null, mainTab);
          } else {
            if (mainTab) {
              this.router.navigate([mainTab.Key]);
            } else {
              const extras: NavigationExtras = {
                queryParams: {
                  tab: 'maxmail'
                }
              };
              this.router.navigate(['admin'], extras);
            }
          }
        });
    }
  }

  /**
   * If changes made on activetab and user clicks back modal is displayed. If user clicks YES group settings are saved and user is directed back to home page. If user clicks NO user is directed to home page.
   * @returns {void}
   */
  goBack(): void {
    const previousActiveTab = this.activeTab;
    const initialState = {
      text: `<p>Changes to groupings on this page have been made. Would you like to Apply the changes?</p>`,
      dialogIndicator: 'admin-save-group-settings'
    };
    if (previousActiveTab.Key === this.agenciesTab.Key && this.agenciesComponent && this.agenciesComponent.agenciesForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .pipe(takeUntil(this.onDestroy))
        .subscribe((ac: boolean) => {
          if (ac) {
            this.agenciesComponent.updateVisibilityGroupsForAgency(null, null, true);
          } else {
            this.router.navigate(['home']);
          }
        });
    } else if (previousActiveTab.Key === this.productListTab.Key && this.productListComponent && this.productListComponent.productGroupForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .pipe(takeUntil(this.onDestroy))
        .subscribe((ac: boolean) => {
          if (ac) {
            this.productListComponent.updateProductGroupsForAgency(null, null, true);
          } else {
            this.router.navigate(['home']);
          }
        });
    } else if (previousActiveTab.Key === this.interfaceSettingsTab.Key && this.interfaceSettingsComponent && this.interfaceSettingsComponent.interfaceSettingsForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .pipe(takeUntil(this.onDestroy))
        .subscribe((ac: boolean) => {
          if (ac) {
            this.interfaceSettingsComponent.updateInterfaceGroupsForAgency(null, null, true);
          } else {
            this.router.navigate(['home']);
          }
        });
    } else if (previousActiveTab.Key === this.agencySettingsTab.Key && this.agencySettingsComponent && this.agencySettingsComponent.agencySettingsForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .pipe(takeUntil(this.onDestroy))
        .subscribe((ac: boolean) => {
          if (ac) {
            this.agencySettingsComponent.updateAgencySettingsGroupsForAgency(null, null, true);
          } else {
            this.router.navigate(['home']);
          }
        });
    } else if (previousActiveTab.Key === this.agentSettingsTab.Key && this.agentSettingsComponent && this.agentSettingsComponent.agentSettingsGroupForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .pipe(takeUntil(this.onDestroy))
        .subscribe((ac: boolean) => {
          if (ac) {
            this.agentSettingsComponent.updateAgentSettingsGroupsForAgency(null, null, true);
          } else {
            this.router.navigate(['home']);
          }
        });
    } else if (previousActiveTab.Key === this.maxMailTab.Key && this.maxmailComponent && this.maxmailComponent.maxMailSettingsGroupForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .pipe(takeUntil(this.onDestroy))
        .subscribe((ac: boolean) => {
          if (ac) {
            this.maxmailComponent.updateMaxmailSettingsGroupsForAgency(null, null, true);
          } else {
            this.router.navigate(['home']);
          }
        });
    } else {
      this.router.navigate(['home']);
    }
  }

  /**
   * ♫ Take me home, country roads ♪
   * @returns {void}
   */
  goHome(): void {
    this.goBack();
  }

  /**
   * This method is called in the template when switching tabs or accordions
   * @param {KeyValue} tab the tab selected
   * @returns {void}
   */
  changeTab(tab: KeyValue): void {
    const previousActiveTab = this.activeTab;
    const initialState = {
      text: `<p>Changes to groupings on this page have been made. Would you like to Apply the changes?</p>`,
      dialogIndicator: 'admin-save-group-settings'
    };
    if (previousActiveTab.Key === this.agenciesTab.Key && this.agenciesComponent && this.agenciesComponent.agenciesForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .pipe(takeUntil(this.onDestroy))
        .subscribe((ac: boolean) => {
          if (ac) {
            this.routeToActiveTab(tab);
            this.agenciesComponent.updateVisibilityGroupsForAgency(tab);
          } else {
            this.routeToActiveTab(tab);
          }
        });
    } else if (previousActiveTab.Key === this.productListTab.Key && this.productListComponent && this.productListComponent.productGroupForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .pipe(takeUntil(this.onDestroy))
        .subscribe((ac: boolean) => {
          if (ac) {
            this.productListComponent.updateProductGroupsForAgency(tab);
          } else {
            this.routeToActiveTab(tab);
          }
        });
    } else if (previousActiveTab.Key === this.interfaceSettingsTab.Key && this.interfaceSettingsComponent && this.interfaceSettingsComponent.interfaceSettingsForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .pipe(takeUntil(this.onDestroy))
        .subscribe((ac: boolean) => {
          if (ac) {
            this.interfaceSettingsComponent.updateInterfaceGroupsForAgency(tab);
          } else {
            this.routeToActiveTab(tab);
          }
        });
    } else if (previousActiveTab.Key === this.agencySettingsTab.Key && this.agencySettingsComponent && this.agencySettingsComponent.agencySettingsForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .pipe(takeUntil(this.onDestroy))
        .subscribe((ac: boolean) => {
          if (ac) {
            this.agencySettingsComponent.updateAgencySettingsGroupsForAgency(tab);
          } else {
            this.routeToActiveTab(tab);
          }
        });
    } else if (previousActiveTab.Key === this.agentSettingsTab.Key && this.agentSettingsComponent && this.agentSettingsComponent.agentSettingsGroupForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .pipe(takeUntil(this.onDestroy))
        .subscribe((ac: boolean) => {
          if (ac) {
            this.agentSettingsComponent.updateAgentSettingsGroupsForAgency(tab);
          } else {
            this.routeToActiveTab(tab);
          }
        });
    } else if (previousActiveTab.Key === this.maxMailTab.Key && this.maxmailComponent && this.maxmailComponent.maxMailSettingsGroupForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .pipe(takeUntil(this.onDestroy))
        .subscribe((ac: boolean) => {
          if (ac) {
            this.maxmailComponent.updateMaxmailSettingsGroupsForAgency(tab);
          } else {
            this.routeToActiveTab(tab);
          }
        });
    } else {
      this.routeToActiveTab(tab);
    }
  }

  /**
   * This is used to capture events from the child components going into detail view
   * when we are in detail view we want to hide the outer header
   * @param {boolean} inDetailView should the template render in detail view
   */
  detailViewToggle(inDetailView: boolean): void {
    this.detailView = inDetailView;
  }

  public routeToActiveTab(tab: KeyValue): void {
    this.activeTab = tab;
    const extras: NavigationExtras = {
      queryParams: {
        tab: tab.Key
      }
    };
    this.router.navigate([], extras);
  }

}
