"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InputFieldCollection = /** @class */ (function () {
    function InputFieldCollection() {
        this.Values = new Array();
        this.IsValid = true;
        this.IsEditable = true;
        this.ErrorMessages = new Array();
    }
    return InputFieldCollection;
}());
exports.InputFieldCollection = InputFieldCollection;
