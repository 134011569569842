// Angular Core
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

// Models
import { KeyValue  } from '@allianz/agent-max-core-lib';
import { SortTypes } from '../models/enums';

// Components
import { GroupDetailsComponent } from './group-details/group-details.component';
import { GroupPoliciesComponent } from './group-policies/group-policies.component';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { UtilityService } from '../shared/services/utility.service';

// Third Party
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-modify-small-group',
  templateUrl: './modify-small-group.component.html',
  styleUrls: ['./modify-small-group.component.scss']
})
export class ModifySmallGroupComponent implements OnInit, OnDestroy {
  @ViewChild(GroupDetailsComponent, { static: false })
  public groupDetailsComponent: GroupDetailsComponent;
  @ViewChild(GroupPoliciesComponent, {static: false})
  public groupPoliciesComponent: GroupPoliciesComponent;
  detailsTab: KeyValue = { Key: 'details', Value: 'Group Details' };
  policiesTab: KeyValue = { Key: 'policies', Value: 'Policies' };
  paymentTab: KeyValue = { Key: 'agent-payment', Value: 'Agent Payment' };
  isDesktop: boolean;
  tabArr: KeyValue[] = [this.detailsTab, this.policiesTab, this.paymentTab];
  // Determines whether or not to show the main Administration header
  detailView: boolean = false;
  activeTab: KeyValue;
  pageHeader: string;
  subscriptions: Subscription[] = [];
  disableTabs: boolean = false;
  goToQueues: boolean;
  modifyGroupFormGroup: FormGroup;
  visibilityType: FormControl = new FormControl('Primary');
  sortDesc: FormControl = new FormControl(true);
  sortBy: FormControl = new FormControl('SortableDepartureDate');
  supplierIsInValid: boolean;
  groupNameInValid: boolean;
  initSupplierArrayLength: number;

  constructor(
    private appStateService: AppStateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private formBuilder: FormBuilder
  ) {
    this.pageHeader = this.activatedRoute.snapshot.data.pageHeader;
    this.activeTab = this.activatedRoute.snapshot.data.activeTab as KeyValue;
    this.modifyGroupFormGroup = this.formBuilder.group({
      sortDesc: this.sortDesc,
      visibilityType: this.visibilityType,
      sortBy: this.sortBy
    });
  }

  ngOnInit(): void {
    if (this.groupDetailsComponent) {
      this.groupDetailsComponent.quoteFormComponent.GroupName.setErrors(null);
      this.groupDetailsComponent.quoteFormComponent.Suppliers.setErrors(null);
    }
    this.subscriptions.push(
      this.utilityService.getSize().subscribe((isDesktop: boolean) => {
        this.isDesktop = isDesktop;
      })
    );
    if (!this.visibilityType.value) {
      this.visibilityType.patchValue('Primary');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((res) => res
    .unsubscribe());
  }

  /**
   * gets most recent policies for group
   * @returns {void}
   */
  fetchPoliciesForGroup(): void {
    this.groupPoliciesComponent.fetchPoliciesForGroup();
  }

  /**
   * actually sorts the queues using the function 'dynamicSort' defined immediately below this
   * cares about the sessions value 'QueueType', so it only sorts the active queue
   * @returns {void}
   */
  sortCurrentQueue(): void {
    this.groupPoliciesComponent.sortPolicies();
  }

  /**
   * Calls showPolicies on groupPolicies component
   * @returns {void}
   */
  showPolicies(): void {
    this.groupPoliciesComponent.insertPolicyCheckboxes();
  }

  /**
   * this is basically copied over from the old codebase wholesale,
   * sets the name of the options in the sortby dropdown based on the active tab in session
   * @param {string} direction - asc or desc
   * @returns {string}
   */
  GetSortByHeader(direction: string): string {
    if (direction === SortTypes.DESC) {
      if (this.sortBy.value === SortTypes.SortableDepartureDate || this.sortBy.value === SortTypes.ConfirmationNumber) {
        return SortTypes.Newest;
      } else {
        return SortTypes.ReverseAlpha;
      }
    } else if (direction === SortTypes.ASC) {
      if (this.sortBy.value === SortTypes.SortableDepartureDate || this.sortBy.value === SortTypes.ConfirmationNumber) {
        return SortTypes.Oldest;
      } else {
        return SortTypes.Alpha;
      }
    }

    return SortTypes.DESC;
  }

  /**
   * Checks if required fields have values for tab enabling
   * @returns {boolean}
   * @param {any} groupDetailsCheck
   */
  isSupplierNewInValid(supplierArray: any): boolean {
    const checkForNewSupplier = (supplierObj) => Array.isArray(supplierObj['supplier']) ? supplierObj['supplier'][0] === null : false;
    const isSupplierNew: boolean = supplierArray ? supplierArray.SupplierArray.some(checkForNewSupplier) : false;

    this.supplierIsInValid = this.groupDetailsComponent.quoteFormComponent.supplierListComponent.SupplierArray().controls.length === 0 || !this.groupDetailsComponent.quoteFormComponent.supplierListComponent.SupplierArray() || isSupplierNew || this.groupDetailsComponent.quoteFormComponent.supplierListComponent.SupplierListForm.invalid;

    return this.supplierIsInValid;
  }

  /**
   * Checks if required fields have values for tab enabling
   * @returns {boolean}
   * @param {any} groupDetailsCheck
   */
   isSupplierInValid(supplierDetailsCheck: any): boolean {
    const isSupplierInputBlank: boolean = supplierDetailsCheck ? supplierDetailsCheck['supplier'] === '' || supplierDetailsCheck['supplier'] === null : false;

    this.supplierIsInValid = this.groupDetailsComponent.quoteFormComponent.supplierListComponent.SupplierArray().controls.length === 0 || !this.groupDetailsComponent.quoteFormComponent.supplierListComponent.SupplierArray() || isSupplierInputBlank || this.groupDetailsComponent.quoteFormComponent.supplierListComponent.SupplierListForm.invalid;

    return this.supplierIsInValid;
  }

  /**
   * Checks if required fields have values for tab enabling
   * @returns {boolean}
   * @param {any} groupDetailsCheck
   */
  isDatesValid(groupDetailsCheck: any): boolean {
    return groupDetailsCheck.DepartureDate !== '' || groupDetailsCheck.ReturnDate !== '';
  }

  /**
   * Checks if product is selected
   * @returns {boolean}
   */
  isProductSelected(): boolean {
    return this.groupDetailsComponent.isProductSelected();
  }

  /**
   * Checks if required fields have values for tab enabling
   * @returns {boolean}
   * @param {any} groupDetailsCheck
   */
  isQuoteFormInComplete(groupDetailsCheck: any): boolean {
    this.groupNameInValid = groupDetailsCheck.GroupName === '';

    const supplier = groupDetailsCheck.supplier !== undefined ? groupDetailsCheck : null;
    const newSupplier = groupDetailsCheck.SupplierArray !== undefined ? groupDetailsCheck : null;
    const suppliersInValid = newSupplier ? this.isSupplierNewInValid(newSupplier) : this.isSupplierInValid(supplier);
    const destinationsInvalid = groupDetailsCheck.Destinations === '';
    const depositDateInvalid = groupDetailsCheck.InitialDepositDate === '';
    const datesInvalid = !this.isDatesValid(groupDetailsCheck);
    const productNotSelected = !this.isProductSelected();

    return this.groupNameInValid || suppliersInValid || destinationsInvalid || depositDateInvalid || datesInvalid || productNotSelected;
  }

  /**
   * Check the group form to see is the user can toggle the policies and agentpayment tab
   * @param {any} groupDetailsCheck
   * @returns {void}
   */
  checkGroupDetails(groupDetailsCheck: any): void {
    if (!this.activatedRoute.snapshot.params.groupid) {
      if (this.activeTab.Key === 'details' && groupDetailsCheck !== 'undefined') {
        if (this.isQuoteFormInComplete(groupDetailsCheck) || this.groupDetailsComponent.quoteFormComponent.supplierListComponent.SupplierListForm.invalid) {
          this.disableTabs = true;
        } else {
          this.disableTabs = false;
        }
      }
    }
    if (groupDetailsCheck) {
      if (this.isQuoteFormInComplete(groupDetailsCheck) || this.groupDetailsComponent.quoteFormComponent.supplierListComponent.SupplierListForm.invalid) {
        this.disableTabs = true;
      } else {
        this.disableTabs = false;
      }

      if (this.groupDetailsComponent) {
        if (this.isQuoteFormInComplete(groupDetailsCheck) || this.groupDetailsComponent.quoteFormComponent.supplierListComponent.SupplierListForm.invalid) {
          this.disableTabs = true;
        } else {
          this.disableTabs = false;
        }
      }
    }
  }

  /**
   * ♫ Take me home, country roads ♪
   * @returns {void}
   */
  goBack(): void {
    if (this.activeTab.Key === 'details') {
      const quoteFormComponent = this.groupDetailsComponent.quoteFormComponent;
      const groupNameInValid = quoteFormComponent.GetQuoteForm.controls.GroupName.value === '';

      if (
        (quoteFormComponent.GetQuoteForm.dirty || quoteFormComponent.supplierListComponent.SupplierListForm.dirty || this.groupDetailsComponent.additionalInformationComponent.form.dirty ) && !this.groupNameInValid && !this.supplierIsInValid) {
        this.goToQueues = true;
        this.groupDetailsComponent.toggleSaveGroupModal();
      } else if ((quoteFormComponent.GetQuoteForm.dirty || quoteFormComponent.supplierListComponent.SupplierListForm.dirty || this.groupDetailsComponent.additionalInformationComponent.form.dirty) && this.groupNameInValid || this.supplierIsInValid) {
        this.goToQueues = false;
        this.groupDetailsComponent.toggleSaveQuoteFormErrorModal();
      } else {
        this.router.navigate(['queues']);
      }
    } else if (this.activeTab.Key === 'agent-payment') {
      this.changeTab({Key: 'policies', Value: 'Policies'});
    } else {
      this.router.navigate(['queues']);
    }
  }

  /**
   * This method is called in the template when switching tabs or accordions
   * @param {KeyValue} tab the tab selected
   * @returns {void}
   */
  changeTab(tab: KeyValue): void {
    if (!this.activatedRoute.snapshot.params.groupid) {
      this.groupDetailsComponent.activeTabToggle();
    } else {
      // if we are changing tabs we aren't navigating back to queues
      this.goToQueues = false;
      // Checks if the group details component exists and if it has been edited prompts to save
      if (this.groupDetailsComponent && (this.groupDetailsComponent.quoteFormComponent.GetQuoteForm.dirty || this.groupDetailsComponent.quoteFormComponent.supplierListComponent.SupplierListForm.dirty || this.groupDetailsComponent.additionalInformationComponent.form.dirty) && (this.groupDetailsComponent.quoteFormComponent.GetQuoteForm.controls.GroupName.value !== '' || !this.supplierIsInValid || !this.groupNameInValid)
      ) {
        this.groupDetailsComponent.toggleSaveGroupModal();
      } else if (this.groupDetailsComponent && (this.groupDetailsComponent.quoteFormComponent.GetQuoteForm.dirty || this.groupDetailsComponent.quoteFormComponent.supplierListComponent.SupplierListForm.dirty || this.groupDetailsComponent.additionalInformationComponent.form.dirty) && (this.groupDetailsComponent.quoteFormComponent.GetQuoteForm.controls.GroupName.value !== '' || this.supplierIsInValid || this.groupNameInValid)) {
        this.groupDetailsComponent.toggleSaveQuoteFormErrorModal();
      } else {
        let url = '';
        const urlParts = this.router.url.split('/');
        // remove blank space at begining of array
        urlParts.pop();
        // remove current path from end of array
        urlParts.shift();
        urlParts.forEach((part) => url += `/${part}`);
        this.router.navigate([`/${url}/${tab.Key}`]).finally(() => {
          this.activeTab = this.activatedRoute.snapshot.data.activeTab as KeyValue;
        });
      }
    }
  }

  /**
   * Toggles the active tab from event emitter in small group details
   * @param {KeyValue} $event - Active tab object
   * @returns {void}
   */
  toggleActiveTab($event: KeyValue): void {
    this.activeTab = $event;
  }

  /**
   * This is used to capture events from the child components going into detail view
   * when we are in detail view we want to hide the outer header
   * @param {boolean} inDetailView should the template render in detail view
   * @returns {void}
   */
  detailViewToggle(inDetailView: boolean): void {
    this.detailView = inDetailView;
  }

  openResourceCenter(): string {
    return environment.ResourceCenter;
  }
}
