// Angular Core
import { Component, OnInit } from '@angular/core';

// Third Party
import { BsModalRef } from 'ngx-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  public modalResponse: Subject<boolean> = new Subject();
  text: string;
  dialogIndicator: string;
  safeHTML: SafeHtml;
  confirmButtonName: string = 'Yes';
  cancelButtonName: string = 'No';
  constructor(
    public bsModalRef: BsModalRef,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.safeHTML = this.sanitizer.bypassSecurityTrustHtml(this.text);
  }

  /**
   * Confirm cancel policy from dialog modal
   * @returns {void}
   */
  confirm(): void {
    this.modalResponse.next(true);
    this.bsModalRef.hide();
  }

  /**
   * Close the policy conifrmation dialog modal
   * @returns {void}
   */
  closeThisDialog(): void {
    this.modalResponse.next(false);
    this.bsModalRef.hide();
  }

}
