"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var primary_traveler_class_1 = require("./primary-traveler.class");
var TravelInformation = /** @class */ (function () {
    function TravelInformation() {
        this.OtherTravelers = new Array();
        this.PrimaryTraveler = new primary_traveler_class_1.PrimaryTraveler();
    }
    return TravelInformation;
}());
exports.TravelInformation = TravelInformation;
