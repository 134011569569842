import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  private key = '4512631236589784';
  private iv = '4512631236589784';
  constructor() {
    this.key = CryptoJS.enc.Utf8.parse('4512631236589784');
    this.iv = CryptoJS.enc.Utf8.parse('4512631236589784');
  }
  public CryptoData(value: string): string {
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encodeURIComponent(encrypted);
  }
  public DecryptData(decString: string): string {
    var decrypted = CryptoJS.AES.decrypt(decodeURIComponent(decString), this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}