import { BookingAgentInformation } from '@allianz/agent-max-core-lib';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { listenToTriggers } from 'ngx-bootstrap/utils';

// Constants
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-brochure',
  templateUrl: './brochure.component.html',
  styleUrls: ['./brochure.component.css']
})
export class BrochureComponent implements OnInit {
  AgentInformation: BookingAgentInformation;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.AgentInformation = this.route.snapshot.data.agentInformation;
  }

  startOrdering(): void {
    window.open(environment.BrochureOnlineOrderingURL);
  }

  printBrochure(){
    let url = environment.BrochurePrintedVersionURL.replace('{0}',this.AgentInformation.Accam);
    url = url.replace('{0}',this.AgentInformation.Accam);
    window.open(url);
  }

}
