// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Models
import { ResourceBlock } from 'src/app/models/interfaces';

// Serivces
import { AppStateService } from '../services/app-state.service';
import { UniversalConfigService } from 'src/app/api/services/universal-config.service';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class GetResourceBlocksResolver implements Resolve<Observable<ResourceBlock[]>> {
  constructor(
    private appStateService: AppStateService,
    private universalConfigService: UniversalConfigService
  ) { }
  resolve(): Observable<ResourceBlock[]> {
    // groupId was hardcoded in the JS APP
    return this.universalConfigService.getAgencyResourceBlocks(1, this.appStateService.getCredentials());
  }
}
