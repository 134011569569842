// Angular Core
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';

// Service
import { UniversalConfigService } from 'src/app/api/services/universal-config.service';
import { AppStateService } from '../../services/app-state.service';

// Models
import { FlightInformation, Booking, Airline, Airport, BookingTypes } from '@allianz/agent-max-core-lib';

// Third Party
import { AccordionConfig, TypeaheadMatch } from 'ngx-bootstrap';
import { Subscription, Observable } from 'rxjs';

// TODO: Remove and use whats in the utility service
export function getAccordionConfig(): AccordionConfig {
  return Object.assign(new AccordionConfig(), { isAnimated: true });
}

@Component({
  selector: 'app-flight-information',
  templateUrl: './flight-information.component.html',
  styleUrls: ['./flight-information.component.scss'],
  providers: [{ provide: AccordionConfig, useFactory: getAccordionConfig }]
})
export class FlightInformationComponent implements OnInit, OnDestroy {

  get isMaxFlights(): boolean {
    return this.flightArray.length >= this.MAX_FLIGHTS;
  }
  public mask: Array<string | RegExp> = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  flightInformationForm: FormGroup;
  flightArray: FormArray = this.formBuilder.array([]);
  emailAddress: FormControl = new FormControl('');
  optOutFromAlerts: FormControl = new FormControl(false);
  sendEmailAlert: FormControl = new FormControl(false);
  sendTextAlert: FormControl = new FormControl(false);
  cellPhone: FormControl = new FormControl('');
  airports: Observable<Airport[]>;
  airlines: Observable<Airline[]>;
  flightInformationSubscriptions: Subscription[] = [];
  @Input('model') booking: Booking;
  isOpen: boolean = true;
  private MAX_RESULTS: number = 10;
  private MAX_FLIGHTS: number = 15;

  constructor(
    private formBuilder: FormBuilder,
    private universalConfigSerivce: UniversalConfigService,
    private appStateService: AppStateService
  ) {
    this.flightInformationForm = this.formBuilder.group({
      emailAddress: this.emailAddress,
      optOutFromAlerts: this.optOutFromAlerts,
      sendEmailAlert: this.sendEmailAlert,
      sendTextAlert: this.sendTextAlert,
      cellPhone: this.cellPhone,
      flightArray: this.flightArray
    });
  }

  ngOnInit(): void {
    this.initFlightArray();
  }

  /**
   * Checks if is disabled
   * @returns {boolean}
   */
  isDisabled(): boolean {
    return this.appStateService.getBookingInfoViewType() === BookingTypes.Pending;
  }

  /**
   * Retrieves airport names for typeahead by index
   * @param {number} index - index of flight array
   * @returns {void}
   */
  getAirports(index: number): void {
    const value = this.flightArray.value[index].airport;
    if (value) {
      this.airports = this.universalConfigSerivce.getAirports(this.MAX_RESULTS, value);
    }
  }

  /**
   * Retrieves airline names for typeahead by index
   * @param {number} index - index of flight array
   * @returns {void}
   */
  getAirlines(index: number): void {
    const value = this.flightArray.value[index].airline;
    if (value) {
      this.airlines = this.universalConfigSerivce.getAirlines(this.MAX_RESULTS, value);
    }
  }

  /**
   * Update Airport and AirportFSCode value in Flight Array by index
   * @param {number} index - index of flight array
   * @param {TypeaheadMatch} event
   * @returns {void}
   */
  changeAirport(index: number, event: TypeaheadMatch): void {
    this.flightArray.at(index).patchValue({ airport: event.item.DisplayDescription });
    this.flightArray.at(index).patchValue({ AirportFSCode: event.item.FSCode });
  }

  /**
   * Update Airline and AirlineFSCode value in Flight Array by index
   * @param {number} index - index of flight array
   * @param {TypeaheadMatch} event
   * @returns {void}
   */
  changeAirline(index: number, event: TypeaheadMatch): void {
    this.flightArray.at(index).patchValue({ airline: event.item.DisplayDescription });
    this.flightArray.at(index).patchValue({ AirlineFSCode: event.item.FSCode });
  }

  /**
   * Update text message and email alerts. If opted out, remove check marks
   * on the other two boxes. If not, text message, email, or both alerts allowed.
   * @param {FormGroup} name
   * @param {boolean} optOut
   * @returns {void}
   */
  flightInformationOpt(name: FormGroup, optOut: boolean): void {
    if (optOut) {
      this.flightInformationForm.patchValue({ sendTextAlert: false, sendEmailAlert: false, optOutFromAlerts: name.value.optOutFromAlerts });
    } else {
      this.flightInformationForm.patchValue({ optOutFromAlerts: false, sendTextAlert: name.value.sendTextAlert, sendEmailAlert: name.value.sendEmailAlert });
    }
  }

  /**
   * Event handler for accordion open functionality
   * @param {boolean} open
   * @returns {void}
   */
  isOpenChange(open: boolean): void {
    this.isOpen = open;
  }

  /**
   * Remove a flight from the flight array by index
   * @param {number} index
   * @returns {void}
   */
  removeFlight(index: number): void {
    this.flightArray.removeAt(index);
  }

  /**
   * Add a flight to the end of the flight array
   * @param {number} flight
   * @returns {void}
   */
  addFlight(flight?: FlightInformation): void {
    flight = flight || new FlightInformation();
    if (!this.isMaxFlights) {
      const flightGroup = this.formBuilder.group({
        airport: new FormControl(flight.AirportName.Value),
        airline: new FormControl(flight.AirlineName.Value),
        flightNumber: new FormControl(flight.FlightNumber.Value),
        flightDate: new FormControl(flight.FormattedFlightDate.Value),
        AirlineFSCode: flight.AirlineFSCode.Value,
        AirportFSCode: flight.AirportFSCode.Value
      });
      this.flightArray.push(flightGroup);
    }
  }

  ngOnDestroy(): void {
    this.flightInformationSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Intialize the flight array
   * @returns {void}
   */
  private initFlightArray(): void {
    if (
      typeof this.booking.FlightItineraryInformation !== 'undefined' &&
      typeof this.booking.FlightItineraryInformation.Flights !== 'undefined' &&
      this.booking.FlightItineraryInformation.Flights.length > 0
    ) {
      for (const t of this.booking.FlightItineraryInformation.Flights) {
        this.addFlight(t);
      }
      if (this.flightArray.length === 0) {
        this.addFlight();
      }
      this.flightInformationForm.patchValue({
        cellPhone: this.booking.FlightItineraryInformation.AlertCommunicationInfo.CellPhone.Value,
        emailAddress: this.booking.FlightItineraryInformation.AlertCommunicationInfo.EmailAddress.Value,
        sendEmailAlert: this.booking.FlightItineraryInformation.AlertCommunicationInfo.SendEmailAlert.Value,
        sendTextAlert: this.booking.FlightItineraryInformation.AlertCommunicationInfo.SendTextAlert.Value,
        optOutFronAlerts: this.booking.FlightItineraryInformation.AlertCommunicationInfo.OptOutFromAlerts.Value,
      });
    }
  }
}
