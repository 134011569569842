// Angular Core
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

// Models
import { CreateWebAccountResponse } from 'src/app/models/interfaces';

// Serivces
import { UserService } from 'src/app/api/services/user.service';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class ActivateWebAccountByAdminResolver implements Resolve<Observable<CreateWebAccountResponse>> {
  constructor(
    private userService: UserService
  ) { }
  resolve(route: ActivatedRouteSnapshot): Observable<CreateWebAccountResponse> {
    return this.userService.activateWebAccountByAdmin(route.params['emailaddress'], route.params['agentaccountid'], route.params['agencyid']);
  }
}
