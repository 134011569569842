// Angular Core
import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Models
import { environment } from 'src/environments/environment';
import { UserInformation, GDSLoginRequest, GDSLoginResponse } from 'src/app/models/interfaces';

// Services
import { UtilityService } from 'src/app/shared/services/utility.service';

// Third party
import { Observable } from 'rxjs';
import { Booking } from '@allianz/agent-max-core-lib';
import { UpdatePnrResponse } from 'src/app/models/interfaces/update-pnr-response';
import { UpdatePNRRequest } from 'src/app/models/interfaces/update-pnr-request.interface';
import { GDSHandlerResult } from 'src/app/models/interfaces/gds-handler-result.interface';

@Injectable({
  providedIn: 'root'
})
export class GdsService {
  gdsServiceEndpoint: string;

  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService
  ) {
    this.gdsServiceEndpoint = this.utilityService.setTheDomainForAPICalls(environment.GDSServices);
  }

  /**
   * RegisterGDSLogin by user
   * @param {UserInformation} userInfo - The user information object
   * @param {string} guid - The GUID passed from the URL
   * @returns {<Observable{GDSLoginResponse}>}
   */

  public registerGDSLogin(userInfo: UserInformation, guid: string): Observable<GDSLoginResponse> {
    const gdsLoginRequest: GDSLoginRequest = {
      ErrorMessages: [],
      IsValid: true,
      Message: '',
      TransactionNumber: null,
      TransactionStatus: null,
      channelID: guid
    };
    return this.httpClient.put<GDSLoginResponse>(`${this.gdsServiceEndpoint}/RegisterGDSLogin`, gdsLoginRequest, { headers: this.utilityService.getServiceHeaders(userInfo) }
    );
  }

  /**
   * Gets the latest quote saved by the logged in GDS user
   * @param {UserInformation} userInfo
   * @returns {void}
   */
  public getLatestSavedGdsQuote(userInfo: UserInformation, bookingId:string): Observable<Booking> {
    return this.httpClient.get<Booking>(`${this.gdsServiceEndpoint}/GetQuote/${bookingId}`, { headers: this.utilityService.getServiceHeaders(userInfo) }
    );
  }
  
  /**
   * Save quote
   * @param {Booking} quote
   * @param {UserInformation} credentials
   * @returns {Observable<Booking>}
   */
   public SaveGDSBooking(quote: Booking, credentials: UserInformation): Observable<Booking> {
    return this.httpClient.put<Booking>(`${this.gdsServiceEndpoint}/SaveGDSBooking`, quote, { headers: this.utilityService.getServiceHeaders(credentials) });
  }

  /**
   * Create PNR
   * @param {string} PNRCode
   * @param {UserInformation} userInfo
   * @returns {Observable<Booking>}
   */
   public createPNR(PNRCode: string, userInfo: UserInformation): Observable<Booking> {
    return this.httpClient.put<Booking>(`${this.gdsServiceEndpoint}/CreatePNR`, PNRCode, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * Update PNR
   * @param {UpdatePNRRequest} pnrRequest
   * @param {UserInformation} credentials
   * @returns {boolean}
   */
   public updatePNR(pnrRequest:UpdatePNRRequest, credentials: UserInformation): Observable<UpdatePnrResponse> {
    return this.httpClient.put<UpdatePnrResponse>(
      `${this.gdsServiceEndpoint}/UpdatePNR`, pnrRequest, { headers: this.utilityService.getServiceHeaders(credentials) }
    );
  }

   /**
   * Export PNR
   * @param {UpdatePNRRequest} pnrRequest
   * @param {UserInformation} credentials
   * @returns {boolean}
   */
    public exportPNR(pnrRequest:UpdatePNRRequest, credentials: UserInformation): Observable<GDSHandlerResult> {
      return this.httpClient.put<GDSHandlerResult>(
        `${this.gdsServiceEndpoint}/ExportPNR`, pnrRequest, { headers: this.utilityService.getServiceHeaders(credentials) }
      );    
    }

  /**
   * Verify If it's Update or Export button needs to be shown in the UI
   * @param {string} PNRCode
   * @param {UserInformation} credentials
   * @returns {boolean}
   */
   public checkUpdateOrExportButtonUIByPNR(PNRCode:string, credentials: UserInformation): Observable<boolean> {
    return this.httpClient.put<boolean>(
      `${this.gdsServiceEndpoint}/CheckUpdateOrExportButtonUIByPNR`, PNRCode, { headers: this.utilityService.getServiceHeaders(credentials) }
    );
   }
}
