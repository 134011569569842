// Angular Core
import { Injectable } from '@angular/core';
import { FormControl, ValidatorFn, FormGroup, FormArray } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import { formatDate } from '@angular/common';

// Models
import { UserInformation } from 'src/app/models/interfaces';
import { InsurancePaymentMethodType, MainNavTabs, AdminNavTabs } from 'src/app/models/enums';
import { FileTypes, Booking, TravelerInformation, BillingInformation, FlightInformation, SupplierCategoryType, SupplierInfo } from '@allianz/agent-max-core-lib';

// Components
import { TripInformationComponent } from '../components/trip-information/trip-information.component';
import { SupplierListComponent } from '../components/supplier-list/supplier-list.component';
import { TravelerInformationComponent } from '../components/traveler-information/traveler-information.component';
import { CheckoutComponent } from '../components/checkout/checkout.component';
import { FlightInformationComponent } from '../components/flight-information/flight-information.component';

// Third party
import * as moment from 'moment';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import { AdditionalInformationComponent } from '../components/additional-information/additional-information.component';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  MOBILE_PAGE_WIDTH: number = 767;
  isDesktop: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(window.innerWidth > this.MOBILE_PAGE_WIDTH);
  dobMask: Array<string | RegExp> = [/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/, '/', /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/];
  ageMask: Array<string | RegExp> = [/[1-9]/, /[0-9]/, /[0-9]/];
  adminSaveAlertSubject: Subject<object> = new Subject<any>();

  /**
   * Triggers communication from components to let app know when to display alert messaging that asks user if they'd like to save changes prior to navigation
   * @param {object} component - currently loaded component
   * @param {boolean} isAdminFormDirty - indicates changes have been made on AdminForm
   * @param {MainNavTabs} activeMainTab
   * @param {AdminNavTabs} activeAdminTab
   * @param {string} nextMainTab
   * @returns {void}
   */
  setTriggerAdminSaveAlert(component: object, isAdminFormDirty?: boolean, activeMainTab?: MainNavTabs, activeAdminTab?: AdminNavTabs, nextMainTab?: string, isBackButtonClick?: boolean): void {
    this.adminSaveAlertSubject.next({ component, isAdminFormDirty, activeMainTab, activeAdminTab, nextMainTab, isBackButtonClick });
  }

  /**
   * Listens for reasons to display AdminSaveAlerts
   * @returns {void}
   */
  getTriggerAdminSaveAlert(): Observable<any> {
    return this.adminSaveAlertSubject.asObservable();
  }

  /**
   * Set the correct origin for api calls
   * @param {string} endpoint - come from the environment file
   * @returns {string}
   */
  setTheDomainForAPICalls(endpoint: string): string {
    // whereever we are coming from
    let origin = window.location.origin;
    // if it is local development change the port
    origin = origin.includes('localhost') ? origin.replace('4200', '37764') : origin;
    return `${origin}${endpoint}`;
  }

  /**
   * Gets the api service headers
   * @param {UserInformation} userInfo
   * @returns {HttpHeaders}
   */
  getServiceHeaders(userInfo: UserInformation): HttpHeaders {

    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': userInfo.TokenId,
      'UserId': `User${userInfo.UserId}`
    });
  }

  /**
   * Date picker custom validator
   * @param {string} format - date format
   * @returns {ValidatorFn} - Validator Function
   */
  dateValidator(format: string = 'MM/DD/YYYY'): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      const val = moment(control.value, format, true);
      if (control.touched && !val.isValid()) {
        return { invalidDate: true };
      }
      return null;
    };
  }

  /**
   * Custom password validator
   * @param {FormControl} formControlEqual
   * @returns {ValidatorFn} - Validator Function
   */
  pwValidator(formControlEqual: FormControl): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      if (control.value && formControlEqual.value) {
        if (control.value !== formControlEqual.value) {
          return { invalidPw: true };
        }
        return null;
      }
    };
  }

  /**
   * Sets if the view is Desktop or not
   * @returns {void}
   */
  setSize(): void {
    this.isDesktop.next(window.innerWidth > this.MOBILE_PAGE_WIDTH);
  }

  /**
   * given a birthday, returns an age
   * @param {string} birthdaydatestring
   * @returns {number}
   */
  calculateAge(birthdaydatestring: string): number {
    if (!birthdaydatestring) { return 0; }
    const today = new Date();
    const birthDate = new Date(birthdaydatestring);
    let age: number = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  /**
   * Gets if the view is Desktop or not
   * @returns {Observable<boolean>}
   */
  getSize(): Observable<boolean> {
    return this.isDesktop.asObservable();
  }

  /**
   * Opens a file returned from server
   * @param {string} fileContent
   * @param {FileTypes} fileType
   * @returns {void}
   */
  openFile(fileContent: string, fileType: FileTypes): void {
    // Convert base 64 to ArrayBuffer
    const binaryString: string = window.atob(fileContent);
    const length: number = binaryString.length;
    const bytes: Uint8Array = new Uint8Array(length);
    let file: Blob;

    bytes.forEach((byte, index) => {
      bytes[index] = binaryString.charCodeAt(index);
    });

    if (fileType === FileTypes.pdf) {
      file = new Blob([bytes.buffer as BlobPart], { type: FileTypes.PDF });
    } else {
      file = new Blob([bytes.buffer as BlobPart], { type: fileType });
    }

    const fileURL: string = URL.createObjectURL(file);

    // IE 10 / 11
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, fileURL);
    }

    // If is phone
    if (window.matchMedia('(max-width: 767px)').matches || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
      // download file
      saveAs(file, fileURL);
    } else if (fileType !== FileTypes.BLANK) {
      window.open(fileURL);
    } else {
      return;
    }
  }

  /**
   * saves a template file from the server
   * @param {string} fileContent
   * @param {FileTypes} fileType
   * @returns {void}
   */
  saveTemplate(fileContent: string, fileType: FileTypes, filename: string): void {
    // Convert base 64 to ArrayBuffer
    const binaryString: string = window.atob(fileContent);
    const length: number = binaryString.length;
    const bytes: Uint8Array = new Uint8Array(length);
    bytes.forEach((byte, index) => {
      bytes[index] = binaryString.charCodeAt(index);
    });
    const file: Blob = new Blob([bytes.buffer as BlobPart], { type: fileType });

    // IE 10 / 11
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, filename);
    } else {
      saveAs(file, filename);
    }
  }

  /**
   * converts an input date of birth to a NUMBER age in years
   * @param {Date} dateOfBirth
   * @returns {number}
   */
  dateOfBirthToAge(dateOfBirth: Date): number {
    const today = new Date();
    const m = today.getMonth() - dateOfBirth.getMonth();
    let age = today.getFullYear() - dateOfBirth.getFullYear();
    if (m < 0 || (m === 0 && today.getDate() < dateOfBirth.getDate())) {
      age--;
    }
    return age;
  }

  /**
   * turns empty input to null for the service layer to ingest
   * @param {string | number | null} item
   * @returns {any | null}
   */
  isEmptyAssignNull(item: string | number | null): any | null {
    return item !== '' ? item : null;
  }

  /**
   * Disable the credit card field if for downgrade but allow for upgrade
   * @param {Booking} booking
   * @param {number} priceBeforeModify
   * @param {CheckoutComponent} checkoutComponent
   * @returns {void}
   */
  disableCreditCardFields(booking: Booking, priceBeforeModify: number, checkoutComponent: CheckoutComponent): void {
    if ((booking.OrderInformation.Price - priceBeforeModify) === 0 || (booking.OrderInformation.Price - priceBeforeModify) < 0) {
      checkoutComponent.nameOnCard.disable();
      checkoutComponent.cardNumber.disable();
      checkoutComponent.expMonth.disable();
      checkoutComponent.expYear.disable();
    }
  }

  /**
   * utility function, could be useful to move
   *  to the utility service if we ever interacted with credit cards anywhere else
   * @param {any} data
   * @returns {string}
   */
  getCreditCardCodeString(data: any): string {
    let ccCodeString = '';
    const cardData = data;

    if (
      cardData.card_type !== null &&
      typeof cardData.card_type !== 'undefined'
    ) {
      // TODO: enum
      switch (cardData.card_type.name.toLowerCase()) {
        case 'visa':
          ccCodeString = 'VI';
          break;
        case 'amex':
          ccCodeString = 'AX';
          break;
        case 'discover':
          ccCodeString = 'DS';
          break;
        case 'mastercard':
          ccCodeString = 'MC';
          break;
        case 'diners_club_carte_blanche':
          ccCodeString = 'DC';
          break;
        case 'diners_club_international':
          ccCodeString = 'DC';
      }
    }

    return ccCodeString;
  }

  /**
   * Update the additional information part of the booking
   * @param {Booking} booking
   * @param {AdditionalInformationComponent} additionalInformationComponent
   * @returns {Booking}
   */
  updateAdditionalInformation(booking: Booking, additionalInformationComponent: AdditionalInformationComponent): Booking {
    booking.AdditionalInformation.AgentID.Value = additionalInformationComponent.AgentID.value;
    booking.AdditionalInformation.TripID.Value = additionalInformationComponent.TripID.value;
    booking.ProductInformation.ProgramProducts[0].Product.WaiverPrice = additionalInformationComponent.waiverPrice.value;
    return booking;
  }

  /**
   * Update the trip information supplier list part of the booking
   * @param {SupplierInfo[]} bookingSupplierList
   * @param {SupplierListComponent} supplierListComponent
   * @returns {SupplierInfo[]}
   */
  updateSupplierList(bookingSupplierList: SupplierInfo[], supplierListComponent: SupplierListComponent): SupplierInfo[] {
    // empty supplier list
    bookingSupplierList = [];
    // loop through all supplier elements on screen
    for (const sup of supplierListComponent.SupplierArray().controls) {
      // if supplier element has a value
      if (sup.value.supplier) {
        // and that value is an array
        if (Array.isArray(sup.value.supplier)) {
          // where first element is null
          if (sup.value.supplier[0] === null) {
            // set supplier value to an empty supplier object
            let emptySupplier: SupplierInfo =  {
              Category: undefined,
              CategoryType: SupplierCategoryType.Other,
              Code: '',
              Description: '',
              IsCoveredSupplier: false
            };
            // and push empty supplier to booking supplier list
            bookingSupplierList.push(emptySupplier);
          }
        } else {
          // if supplier element has a searchable value filter list generated by materialized view (master supplier list)
          supplierListComponent.suppliers.filter((supp: SupplierInfo) => {
            const supplier = supp.Description.toLowerCase();
            const searchString = sup.value.supplier ? sup.value.supplier.toLowerCase() : '';
            // if master list contains a supplier with a description that matches value in supplier element
            if (supplier === searchString) {
              // push the valid supplier object to booking supplier list
              bookingSupplierList.push(supp);
            }
          });
        }
      } else if (!sup.value.supplier) {
          // if supplier element value is undefined or null set supplier value to an empty supplier object
          let emptySupplier: SupplierInfo =  {
            Category: undefined,
            CategoryType: SupplierCategoryType.Other,
            Code: '',
            Description: '',
            IsCoveredSupplier: false
          };
          // and push empty supplier to booking supplier list
          bookingSupplierList.push(emptySupplier);
      } else {
        return bookingSupplierList;
      }
    }
    return bookingSupplierList;
  }

  /**
   * Update the trip information part of the booking
   * @param {Booking} booking
   * @param {TripInformationComponent} tripInformationComponent
   * @param {SupplierListComponent} supplierListComponent
   * @returns {Booking}
   */
  updateTripInfo(booking: Booking, tripInformationComponent: TripInformationComponent, supplierListComponent: SupplierListComponent): Booking {
    booking.TripInformation.TotalTripCost.Value = tripInformationComponent.TripInformationForm.controls.TotalTripCost.value.toString().replace(',','');
    if (booking.IsGroupBooking && tripInformationComponent.TripInformationForm.controls.AverageTripCostPerTraveler.value) {
      booking.TripInformation.AverageTripCost = tripInformationComponent.TripInformationForm.controls.AverageTripCostPerTraveler.value;
    }
    booking.TripInformation.Destination.Value = tripInformationComponent.TripInformationForm.controls.Destination.value;

    // update supplier list object according to user input
    booking.TripInformation.SupplierList.Values = this.updateSupplierList(booking.TripInformation.SupplierList.Values, supplierListComponent);

    if (booking.TripInformation.IsFinalPaymentDateRuleActive) {
      booking.TripInformation.HasFinalPaymentDateQuestionBeenAnswered = tripInformationComponent.TripInformationForm.controls.FinalPayment.value;
      if (tripInformationComponent.TripInformationForm.controls.FinalPaymentDate.value !== '') {
        // verify the form field is filled to run format on
        // final payment is deselected return empty string
        booking.TripInformation.FormattedFinalDepositDate.Value =
          tripInformationComponent.TripInformationForm.controls.FinalPayment.value ?
            formatDate(tripInformationComponent.TripInformationForm.controls.FinalPaymentDate.value,
              'MM/dd/yyyy',
              'en-US'
            ) : '';
      }
    }
    if (tripInformationComponent.TripInformationForm.controls.DepartureDate.value !== '') {
      booking.TripInformation.FormattedDepartureDate.Value = formatDate(
        tripInformationComponent.TripInformationForm.controls.DepartureDate.value,
        'MM/dd/yyyy',
        'en-US'
      );
    }
    if (tripInformationComponent.TripInformationForm.controls.InitialDeposit.value !== '') {
      booking.TripInformation.FormattedInitialDepositDate.Value = formatDate(
        tripInformationComponent.TripInformationForm.controls.InitialDeposit.value,
        'MM/dd/yyyy',
        'en-US'
      );
    }
    if (tripInformationComponent.TripInformationForm.controls.ReturnDate.value !== '') {
      booking.TripInformation.FormattedReturnDate.Value = formatDate(
        tripInformationComponent.TripInformationForm.controls.ReturnDate.value,
        'MM/dd/yyyy',
        'en-US'
      );
    }
    return booking;
  }

  /**
   * Updates the Traveler information part of the booking based on the sub component form
   * @param {Booking} booking
   * @param {TravelerInformationComponent} travelerInformationComponent
   * @returns {Booking}
   */
  updateTravelerInfo(booking: Booking, travelerInformationComponent: TravelerInformationComponent): Booking {
    booking.TravelInformation.OtherTravelers = [];
    for (const t of travelerInformationComponent.travelerArray.controls) {
      const travelerInformation = new TravelerInformation();
      travelerInformation.FirstName.Value = (t as FormGroup).controls.FirstName.value;
      travelerInformation.LastName.Value = (t as FormGroup).controls.LastName.value;
      if ((t as FormGroup).controls.FormattedDateOfBirth) {
        travelerInformation.AgeIfNoBirthDate.Value = this.dateOfBirthToAge(
          new Date((t as FormGroup).controls.FormattedDateOfBirth.value)
        );
        travelerInformation.FormattedDateOfBirth.Value = (t as FormGroup).controls.FormattedDateOfBirth.value;
      }
      booking.TravelInformation.OtherTravelers.push(travelerInformation);
    }
    booking.TravelInformation.PrimaryTraveler.Info.FirstName.Value = travelerInformationComponent.travelerInformationForm.controls.PFirstName.value;
    booking.TravelInformation.PrimaryTraveler.Info.LastName.Value = travelerInformationComponent.travelerInformationForm.controls.PLastName.value;
    booking.TravelInformation.PrimaryTraveler.Info.FormattedDateOfBirth.Value = travelerInformationComponent.travelerInformationForm.controls.dateOfBirth.value.substring(0, 10);
    booking.TravelInformation.PrimaryTraveler.Address.Address1.Value = travelerInformationComponent.travelerInformationForm.controls.addressLine1.value;
    booking.TravelInformation.PrimaryTraveler.Address.Address2.Value = travelerInformationComponent.travelerInformationForm.controls.addressLine2.value;
    booking.TravelInformation.PrimaryTraveler.Address.City.Value = travelerInformationComponent.travelerInformationForm.controls.city.value;
    booking.TravelInformation.PrimaryTraveler.Address.State.Value = travelerInformationComponent.travelerInformationForm.controls.stateOfResidence.value;
    booking.TravelInformation.PrimaryTraveler.Address.ZipCode.Value = travelerInformationComponent.travelerInformationForm.controls.zipCode.value;
    if (travelerInformationComponent.travelerInformationForm.controls.phone.value !== null) {
      booking.TravelInformation.PrimaryTraveler.PhoneNumber.Value = travelerInformationComponent.travelerInformationForm.controls.phone.value.replace(/\D/g, '');
    }
    booking.TravelInformation.PrimaryTraveler.EmailAddress.Value = travelerInformationComponent.travelerInformationForm.controls.email.value;
    return booking;
  }

  /**
   * Update the flight info from sub component
   * @param {Booking} booking
   * @param {FlightInformationComponent} flightInformationComponent
   * @returns {Booking}
   */
  updateFlightInfo(booking: Booking, flightInformationComponent: FlightInformationComponent): Booking {
    // not all products have flight component
    if (flightInformationComponent) {
      const indexesTrackedForRemoval: number[] = [];
      booking.FlightItineraryInformation.AlertCommunicationInfo.OptOutFromAlerts.Value = flightInformationComponent.optOutFromAlerts.value;
      booking.FlightItineraryInformation.AlertCommunicationInfo.SendEmailAlert.Value = flightInformationComponent.sendEmailAlert.value;
      booking.FlightItineraryInformation.AlertCommunicationInfo.SendTextAlert.Value = flightInformationComponent.sendTextAlert.value;
      booking.FlightItineraryInformation.AlertCommunicationInfo.CellPhone.Value = flightInformationComponent.cellPhone.value.replace(/\D/g, '');
      booking.FlightItineraryInformation.AlertCommunicationInfo.EmailAddress.Value = flightInformationComponent.emailAddress.value;
      booking.FlightItineraryInformation.Flights = [];

      (flightInformationComponent.flightArray.controls).forEach((flight, index) => {
        const flightInformation = new FlightInformation();
        flightInformation.AirlineFSCode.Value = (flight as FormGroup).controls.AirlineFSCode.value;
        flightInformation.AirportFSCode.Value = (flight as FormGroup).controls.AirportFSCode.value;
        flightInformation.AirlineName.Value = (flight as FormGroup).controls.airline.value;
        flightInformation.AirportName.Value = (flight as FormGroup).controls.airport.value;
        flightInformation.FlightNumber.Value = (flight as FormGroup).controls.flightNumber.value;
        if ((flight as FormGroup).controls.flightDate.value !== '') {
          flightInformation.FormattedFlightDate.Value = formatDate((flight as FormGroup).controls.flightDate.value, 'MM/dd/yyyy', 'en-US');
        }
        flightInformation.IsEditable = true;
        // if the flight control was created but not interacted with track to remove
        if (
          flightInformation.AirlineFSCode.Value === '' &&
          flightInformation.AirportFSCode.Value === '' &&
          flightInformation.AirlineName.Value === '' &&
          flightInformation.AirportName.Value === '' &&
          flightInformation.FlightNumber.Value === '' &&
          flightInformation.FormattedFlightDate.Value === ''
        ) {
          indexesTrackedForRemoval.push(index);
        } else {
          booking.FlightItineraryInformation.Flights.push(flightInformation);
        }
      });
      // reverse the indexes to remove starting at the last control
      indexesTrackedForRemoval.reverse().forEach((i) => (flightInformationComponent.flightArray as FormArray).removeAt(i));
    }
    return booking;
  }

  /**
   * update Billing information from sub component form
   * @param {Booking} booking
   * @param {CheckoutComponent} checkoutComponent
   * @returns {Booking}
   */
  updateBillingInfo(booking: Booking, checkoutComponent: CheckoutComponent): Booking {
    const billingInfo = new BillingInformation();
    if (checkoutComponent.checkoutForm.controls.sameAddress.value) {
      billingInfo.CardholderAddress1.Value = this.isEmptyAssignNull(booking.TravelInformation.PrimaryTraveler.Address.Address1.Value);
      billingInfo.CardholderAddress2.Value = this.isEmptyAssignNull(booking.TravelInformation.PrimaryTraveler.Address.Address2.Value);
      billingInfo.CardholderCity.Value = this.isEmptyAssignNull(booking.TravelInformation.PrimaryTraveler.Address.City.Value);
      billingInfo.CardholderState.Value = this.isEmptyAssignNull(booking.TravelInformation.PrimaryTraveler.Address.State.Value);
      billingInfo.CardholderZipCode.Value = this.isEmptyAssignNull(booking.TravelInformation.PrimaryTraveler.Address.ZipCode.Value);
    } else {
      billingInfo.CardholderAddress1.Value = this.isEmptyAssignNull(checkoutComponent.addressLine1.value);
      billingInfo.CardholderAddress2.Value = this.isEmptyAssignNull(checkoutComponent.addressLine2.value);
      billingInfo.CardholderCity.Value = this.isEmptyAssignNull(checkoutComponent.city.value);
      billingInfo.CardholderState.Value = this.isEmptyAssignNull(checkoutComponent.stateOfResidence.value);
      billingInfo.CardholderZipCode.Value = this.isEmptyAssignNull(checkoutComponent.zipCode.value);
    }
    billingInfo.CardholderFullName.Value = this.isEmptyAssignNull(checkoutComponent.nameOnCard.value);
    billingInfo.CardholderPhoneNumber.Value = this.isEmptyAssignNull(booking.TravelInformation.PrimaryTraveler.PhoneNumber.Value);
    billingInfo.IsPayMethodCashWithheld.Value = checkoutComponent.paymentMethod.value === InsurancePaymentMethodType.CW;
    billingInfo.FulfillmentPrimaryEmail.Value = this.isEmptyAssignNull(checkoutComponent.primaryEmail.value);
    billingInfo.FulfillmentSecondaryEmail.Value = this.isEmptyAssignNull(checkoutComponent.secondaryEmail.value);
    billingInfo.IsFulfillmentByMail.Value = this.isEmptyAssignNull(checkoutComponent.isFulfillmentByMail.value);
    if (billingInfo.IsPayMethodCashWithheld.Value) {
      billingInfo.CardTypeCodeString.Value = null;
      billingInfo.CardholderCountryCode.Value = '';
      billingInfo.CardExpirationMonth.Value = null;
      billingInfo.CardExpirationYear.Value = null;
      billingInfo.CardNumber.Value = null;
    } else {
      billingInfo.CardExpirationMonth.Value = checkoutComponent.expMonth.value || 0;
      billingInfo.CardExpirationYear.Value = checkoutComponent.expYear.value || 0;
      billingInfo.CardNumber.Value = this.isEmptyAssignNull(checkoutComponent.cardNumber.value);
      if (checkoutComponent.ccData) {
        billingInfo.CardTypeCodeString.Value = this.getCreditCardCodeString(checkoutComponent.ccData);
      }
      billingInfo.CardholderCountryCode.Value = '';
    }
    // default to CC for submission. otherwise bad request
    booking.OrderInformation.PaymentMethod = checkoutComponent.paymentMethod.value || 0;
    billingInfo.ReceiptNumber.Value = this.isEmptyAssignNull(checkoutComponent.receiptNumber.value);
    booking.OrderInformation.BillingInformation = billingInfo;
    return booking;
  }

}
