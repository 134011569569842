export enum IsOpen {
    generalIsOpen = 'generalIsOpen',
    interfaceIsOpen = 'interfaceIsOpen',
    agencySettingIsOpen = 'agencySettingIsOpen',
    quoteMaxIsOpen = 'quoteMaxIsOpen',
    profileIsOpen = 'profileIsOpen',
    addressIsOpen = 'addressIsOpen',
    maxMailIsOpen = 'maxMailIsOpen',
    securityIsOpen = 'securityIsOpen',
    agenciesIsOpen = 'agenciesIsOpen',
    agentManagementIsOpen = 'agentManagementIsOpen',
    createAgentIsOpen = 'createAgentIsOpen'
}
