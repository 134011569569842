import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AgencyGroupsForAgency } from 'src/app/models/interfaces';

@Injectable()
export class AdminGetAgencyGroupsForAgencyResolver implements Resolve<Observable<AgencyGroupsForAgency>> {
    constructor(
        private adminUserService: AdminUserService
    ) { }

    resolve(): Observable<AgencyGroupsForAgency> {
        return this.adminUserService.getAgencyGroupsForAgency();
    }
}
