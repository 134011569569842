// Angular Core
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Components
import { AdditionalInformationComponent } from './components/additional-information/additional-information.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { FlightInformationComponent } from './components/flight-information/flight-information.component';
import { TravelerInformationComponent } from './components/traveler-information/traveler-information.component';
import { TripInformationComponent } from './components/trip-information/trip-information.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { QuoteFormComponent } from './components/quote-form/quote-form.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { ModalComponent } from './components/modal/modal.component';
import { UploadRosterModalComponent } from './components/upload-roster-modal/upload-roster-modal.component';
import { DeletePolicyModalComponent } from './components/delete-policy-modal/delete-policy-modal.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { InterfaceSettingsFormComponent } from './components/interface-settings-form/interface-settings-form.component';
import { MyAgenciesFormComponent } from './components/my-agencies-form/my-agencies-form.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { GenericClientValidationComponent } from './components/generic-client-validation/generic-client-validation.component';
import { SecondChancesFormComponent } from './components/second-chances-form/second-chances-form.component';
import { SupplierListComponent } from './components/supplier-list/supplier-list.component';

// Third Party
import { TypeaheadModule, AccordionModule, BsDatepickerModule, TabsModule, TooltipModule, AlertModule, ModalModule, PopoverModule } from 'ngx-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { NguCarouselModule } from '@ngu/carousel';

@NgModule({
  declarations: [
    AdditionalInformationComponent,
    CheckoutComponent,
    FlightInformationComponent,
    TravelerInformationComponent,
    TripInformationComponent,
    FooterComponent,
    HeaderComponent,
    QuoteFormComponent,
    ModalComponent,
    UploadRosterModalComponent,
    PasswordStrengthComponent,
    LoadingIndicatorComponent,
    DeletePolicyModalComponent,
    SecondChancesFormComponent,
    AddressFormComponent,
    InterfaceSettingsFormComponent,
    MyAgenciesFormComponent,
    ProfileFormComponent,
    GenericClientValidationComponent,
    SupplierListComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    TextMaskModule,
    RouterModule,
    NguCarouselModule,
    TextMaskModule,
    DragDropModule
  ],
  exports: [
    TripInformationComponent,
    FlightInformationComponent,
    TravelerInformationComponent,
    AdditionalInformationComponent,
    SecondChancesFormComponent,
    CheckoutComponent,
    AccordionModule,
    TabsModule,
    AlertModule,
    ModalModule,
    BsDatepickerModule,
    TextMaskModule,
    AlertModule,
    FooterComponent,
    HeaderComponent,
    QuoteFormComponent,
    ModalComponent,
    UploadRosterModalComponent,
    DeletePolicyModalComponent,
    PasswordStrengthComponent,
    TooltipModule,
    NguCarouselModule,
    PopoverModule,
    DragDropModule,
    AddressFormComponent,
    InterfaceSettingsFormComponent,
    MyAgenciesFormComponent,
    ProfileFormComponent,
    SupplierListComponent
  ],
  entryComponents: [
    ModalComponent,
    UploadRosterModalComponent,
    DeletePolicyModalComponent,
    LoadingIndicatorComponent
  ]

})
export class SharedModule {}
