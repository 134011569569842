// TODO: move to models lib package
export enum PrintOrEmailTypes {
    Help,
    PurchaseReceipt,
    ProductComparison,
    PurchaseReceiptAndConfirmation,
    Waiver,
    Quote,
    QuoteTool,
    SalesReport,
    QuoteMaxEmail,
    MaxMailEmail,
    StorefrontQuote
}
