import { Quarter } from '../interfaces/quarter.interface';

export const QUARTERS: Quarter[] = [
  {
    name: 'Q1',
    startDate: '01/01/',
    endDate: '03/31/'
  },
  {
    name: 'Q2',
    startDate: '04/01/',
    endDate: '06/30/'
  },
  {
    name: 'Q3',
    startDate: '07/01/',
    endDate: '09/30/'
  },
  {
    name: 'Q4',
    startDate: '10/01/',
    endDate: '12/31/'
  }
];
