// Angular Core
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Models
import { InterfaceSettings, UserOptions } from 'src/app/models/interfaces';
import { IsOpen, UserProfileRoute } from '../models/enums';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { UtilityService } from '../shared/services/utility.service';
import { Observable } from 'rxjs';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-user-options',
  templateUrl: './user-options.component.html',
  styleUrls: ['./user-options.component.scss']
})
export class UserOptionsComponent implements OnInit, AfterViewInit  {
  userOptions: UserOptions;
  userInterfaceSettings: Observable<InterfaceSettings>;
  @Input('adminRoute') adminRoute: UserProfileRoute  =  UserProfileRoute.AgentUserProfile;
  userProfileRoute: typeof UserProfileRoute = UserProfileRoute;
  isOpenType: typeof IsOpen = IsOpen;
  isOpenKeys: string[] = Object.keys(IsOpen);
  activeArray: {} = {};
  isDesktop: boolean;
  allowAgentsToChangeCodeOnWeb: boolean;

  @ViewChild('tabset', {static: false}) tabset?: TabsetComponent;

  constructor(
    private appStateService: AppStateService,
    private utilityService: UtilityService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngAfterViewInit(): void {
    this.tabset.tabs.sort((first:TabDirective, second:TabDirective) => {  
      return (first.id > second.id) ?  1 :  -1 
      } 
    );
  }

  ngOnInit(): void {
    this.userOptions = this.route.snapshot.data.userOptions;
    if (this.route.snapshot.data.agencySettings) {
      this.allowAgentsToChangeCodeOnWeb = this.route.snapshot.data.agencySettings.AllowAgentsToChangeCodeOnWeb;
    }
    if (this.userOptions.IsActive === null || this.userOptions.IsActive === undefined) {
      this.userOptions.IsActive = true;
    }
    if (this.adminRoute === UserProfileRoute.AgentUserProfile && this.userOptions.ErrorMessages.length === 0) {
      this.appStateService.setUserOptions(this.userOptions);
    }
    this.utilityService.getSize().subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
      this.checksAllTabs();
    });
    this.isOpenKeys.forEach((obj) => {
      this.activeArray[obj] = false;
    });
    
    this.activeArray[IsOpen.profileIsOpen] = true;
    this.userInterfaceSettings = this.appStateService.getInterfaceSettingsSubject().asObservable();
    this.userInterfaceSettings.subscribe((settings) => {
      this.userOptions.InterfaceSettings = settings;
      if (this.adminRoute === UserProfileRoute.AgentUserProfile && this.userOptions.ErrorMessages.length === 0) {
        this.appStateService.setUserOptions(this.userOptions);
      }
    });
    
  }

  /**
   * if all tabs are deactive, make the first tab active
   * @returns {void}
   */
  checksAllTabs(): void {
    if (Object.values(this.activeArray).every((element) => element === false)) {
      this.activeArray[IsOpen.profileIsOpen] = true;
      if (!this.isDesktop) {
        this.onAccordionOpenChange(IsOpen.profileIsOpen, true);
      }
    }
  }

  /**
   * Called on tab selection change
   * @param {IsOpen} activeVar - tab to be active
   * @returns {void}
   */
  onTabSelectionChange(activeVar: IsOpen): void {
    this.activeArray[activeVar] = true;
  }

  /**
   * Called on tab deselection change
   * @param {IsOpen} activeVar - tab to be deactive
   * @returns {void}
   */
  onTabDeselectionChange(activeVar: IsOpen): void {
    this.activeArray[activeVar] = false;

    // if all tabs are deactive, make the first tab active
    this.activeArray[IsOpen.generalIsOpen] = true;
    if (!this.isDesktop) {
      this.onAccordionOpenChange(IsOpen.generalIsOpen, true);
    }
  }

  /**
   * ♫ ...To the place, I be-loong
   * West Virginia ♪
   * @returns {void}
   */
  goHome(): void {
    this.router.navigate(['home']);
  }

  /**
   * Called on accordion change
   * @param {IsOpen} openVar - accordion to be manipulated
   * @param {boolean} event - boolean to decide to open or close accordion
   * @returns {void}
   */
  onAccordionOpenChange(openVar: IsOpen, event: boolean): void {
    this.activeArray[openVar] = event;

    // scroll to top of accordion
    // timeout to ensure that the accordion is open before scrolling
    if (event) {
      setTimeout(() => {
        document.getElementById(`uo-accordion-${openVar}`).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
      }, 450);
    }
  }
}
