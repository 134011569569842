// Angular Core
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// Models
import { UserInformation, Agency, AdminMaxMailSecondChance } from 'src/app/models/interfaces';
import { MaxMail2ndChanceSettings, KeyValue, ErrorMessage } from '@allianz/agent-max-core-lib';

// Services
import { UserService } from 'src/app/api/services/user.service';
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
// Third Party
import { Subscription, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-second-chances-form',
  templateUrl: './second-chances-form.component.html',
  styleUrls: ['./second-chances-form.component.scss']
})
export class SecondChancesFormComponent implements OnInit, OnDestroy {
  validationMessages: ErrorMessage[] = [];
  secondChanceForm: FormGroup;
  autoEnableSecondChance: FormControl = new FormControl();
  offers: FormArray = this.formBuilder.array([]);
  userCredentials: UserInformation;
  maxmailPrograms: KeyValue[];
  maxmailProgramsByControl: any = [];
  secondChanceSettingsSaved: boolean = false;
  isLoaded: boolean = false;
  @Input('userOptions') userOptions: MaxMail2ndChanceSettings;
  @Input('isActive') isActive: boolean = true;
  secondChancesSubscriptions: Subscription;
  adminMaxMailSubscriptions: Subscription[] = [];
  adminAgenciesPage: boolean = false;
  adminAgentPage: boolean = false;
  groupId: number;
  agencyId: number;
  agencies: Agency[];
  agency: Agency;
  headerText: string;

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private adminUserService: AdminUserService,
  ) {
    this.adminAgentPage = this.activatedRoute.snapshot.data.adminAgentRoute;
    this.adminAgenciesPage = this.activatedRoute.snapshot.data.adminAgenciesRoute;
    this.secondChanceForm = this.formBuilder.group({
      autoEnableSecondChance: this.autoEnableSecondChance,
      offers: this.offers
    });
  }

  ngOnInit(): void {
    if (this.adminAgenciesPage) {
      this.initFormDataForAdmin();
      this.initOffersForAdmin();
    } else {
      this.initOffersForUserOptions();
    }
  }
  /**
   * Populates the form with maxmail second chance settings based on a user options service call.
   * @returns {void}
   */
  initOffersForUserOptions(): void {
    this.userCredentials = this.appStateService.getCredentials();
    this.secondChancesSubscriptions = this.userService.getProgramProductsForMaxMail(this.userCredentials)
        .subscribe((programs) => {
        this.maxmailPrograms = programs;
        this.isLoaded = true;
        if (this.offers.controls.length > 0) {
          this.offers.controls.forEach((offer, index) => {
            this.maxmailProgramsByControl[index] = _.cloneDeep(this.maxmailPrograms);
            if (offer.value.isEnabled) {
              if (this.maxmailPrograms.filter((program) => offer.value.programProductId === program.Key).length === 0) {
                this.maxmailProgramsByControl[index].push({Key: offer.value.programProductId, Value: 'No longer available'});
              }
            } else {
              const programProdIdControl = offer.get('programProductId');
              programProdIdControl.setValue(this.maxmailProgramsByControl[index][0]['Key']);
            }
          });
        }
    });
    this.secondChanceForm.patchValue({autoEnableSecondChance: this.userOptions.AutoEnableSecondChance.Value});
    this.loadOffers(this.userOptions);
  }
  /**
   * Initiailizes agency and group id to be used throughout the second chance form for the admin route
   */
  initFormDataForAdmin(): void {
    this.agencies = this.activatedRoute.snapshot.data.adminAgencies.Agencies;
    this.activatedRoute.paramMap.subscribe( (paramMap) => {
      this.agencyId = parseInt(paramMap.get('agencyId'), 10);
      this.groupId = parseInt(paramMap.get('groupId'), 10);
      if (isNaN(this.groupId)) {
        this.groupId = 0;
      }
    });
    this.agency = this.agencies.find((agency) => agency.AgencyID === this.agencyId);
    if (this.groupId !== 0) {
      this.headerText = `Group ${this.groupId}`;
    } else {
      this.headerText = `${this.agency.LongName} (${this.agency.ACCAM})`;
    }
  }
  /**
   * Populates the form with maxmail second chance settings based on an admin service call.
   */
  initOffersForAdmin(): void {
    this.userCredentials = this.appStateService.getCredentials();
    forkJoin([
      this.userService.getProgramProductsForMaxMail(this.userCredentials),
      this.adminUserService.getAgencyConfigAgencyMaxMailSettingsBag(this.agencyId)
    ]).pipe(
      map((results) => ({
        offers: results[0],
        adminMaxMailSettings: results[1]
      }))
    ).subscribe((response) => {
      this.userOptions = response.adminMaxMailSettings;
      this.maxmailPrograms = response.offers;
      this.loadOffers(this.userOptions);
      if (this.offers.controls.length > 0) {
        this.offers.controls.forEach((offer, index) => {
          this.maxmailProgramsByControl[index] = _.cloneDeep(this.maxmailPrograms);
          if (offer.value.isEnabled) {
            if (this.maxmailPrograms.filter((program) => offer.value.programProductId === program.Key).length === 0) {
              this.maxmailProgramsByControl[index].push({Key: offer.value.programProductId, Value: 'No longer available'});
            }
          } else {
            const programProdIdControl = offer.get('programProductId');
            programProdIdControl.setValue(this.maxmailProgramsByControl[index][0]['Key']);
          }
        });
      }
      this.secondChanceForm.patchValue({autoEnableSecondChance: this.userOptions.AutoEnableSecondChance.Value});
      this.isLoaded = true;
      });
  }

  /**
   * Can submit the form
   * @returns {boolean}
   */
  canSubmit(): boolean {
    return this.secondChanceForm.dirty && this.secondChanceForm.invalid || this.secondChanceForm.pristine && this.secondChanceForm.valid;
  }

  /**
   * Submits the maxmail second chances settings form based on the current route of the page
   * @returns {void}
   */
  submit(): void {
    let submitObservable: Observable<any>;
    if (this.secondChanceForm.dirty && this.secondChanceForm.valid) {
      if (this.adminAgentPage) {
        const updatedSettings = this.createSecondChanceSettingsForAdminAgent(this.secondChanceForm);
        submitObservable = this.adminUserService.updateAgentMaxmailSettings(updatedSettings);
      } else if (!this.adminAgenciesPage) {
        const updatedSettings = this.getUpdatedSettings(this.secondChanceForm);
        submitObservable = this.userService.saveMaxMailSettings(updatedSettings, this.userCredentials);
      } else if (this.adminAgenciesPage) {
        const updatedSettings = this.getUpdatedSettings(this.secondChanceForm);
        submitObservable = this.adminUserService.UpdateAgencyConfigAgencyMaxMailSettingsBag(updatedSettings, this.agencyId.toString());
      }
      submitObservable.subscribe((settings) => {
        this.validationMessages = settings.ErrorMessages || settings.ValidationMessages;
        this.secondChanceSettingsSaved = this.validationMessages.length === 0;
        if (this.secondChanceSettingsSaved) {
            this.secondChanceForm.markAsPristine();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.secondChancesSubscriptions) {
      this.secondChancesSubscriptions.unsubscribe();
    }
    if (this.adminMaxMailSubscriptions) {
      this.adminMaxMailSubscriptions.forEach((adminSubscription) => {
        adminSubscription.unsubscribe();
      });
    }
  }

  /**
   * Loads current offers
   * @param {MaxMail2ndChanceSettings} userOptions
   */
  private loadOffers(userOptions: MaxMail2ndChanceSettings): void {
    userOptions.MaxMail2ndChanceOffers.forEach((offer) => {
      const currentOffer = this.formBuilder.group({
        isEnabled: new FormControl(offer.IsEnabled.Value),
        programProductId: new FormControl(offer.ProgramProductId.Value || ''),
        label: offer.OfferTypeText.Value
      });
      this.offers.push(currentOffer);
    });
  }

  /**
   * Get updated settings for the second chances to submit to server
   * @param {FormGroup} form
   * @returns {MaxMail2ndChanceSettings}
   */
  private getUpdatedSettings(form: FormGroup): MaxMail2ndChanceSettings {
    const updatedSettings = this.userOptions;
    updatedSettings.AutoEnableSecondChance.Value = form.value.autoEnableSecondChance;

    form.value.offers.forEach((offer, index) => {
      updatedSettings.MaxMail2ndChanceOffers[index].IsEnabled.Value = offer.isEnabled;
      updatedSettings.MaxMail2ndChanceOffers[index].ProgramProductId.Value = offer.programProductId;
    });
    return updatedSettings;
  }

  /**
   * Converts the front end form into a request object for admin
   * @param {FormGroup} form form being manipulated on the front end
   * @returns {AdminMaxMailSecondChance} - the request object being sent to the back end
   */
  private createSecondChanceSettingsForAdminAgent(form: FormGroup): AdminMaxMailSecondChance {
    const secondChanceSettings: AdminMaxMailSecondChance = {
      AgentId: this.activatedRoute.snapshot.params.agentId,
      SecondChanceEnabled: form.value.autoEnableSecondChance,
      MaxMailSecondChanceOffers: this.userOptions.MaxMail2ndChanceOffers
    };
    form.value.offers.forEach((offer, index) => {
      secondChanceSettings.MaxMailSecondChanceOffers[index].IsEnabled.Value = offer.isEnabled;
      secondChanceSettings.MaxMailSecondChanceOffers[index].ProgramProductId.Value = offer.programProductId;
    });
    return secondChanceSettings;
  }
}
