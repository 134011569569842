// Angular Core
import { Component, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { FormGroup } from '@angular/forms';

// services
import { ReportService } from 'src/app/api/services/report.service';
import { AppStateService } from '../../services/app-state.service';
import { UtilityService } from '../../services/utility.service';
import { QuoteService } from 'src/app/api/services/quote.service';
import { LoadingService } from 'src/app/api/services/loading.service';

// Models
import { FileTypes, ErrorMessage } from '@allianz/agent-max-core-lib';
import { EmailInput, RosterUploadRequest } from 'src/app/models/classes';
import { PrintOrEmailReportType } from 'src/app/models/enums';
import { MAX_ROSTER_ROWS } from 'src/app/models/constants';

// Third Party
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-upload-roster-modal',
  templateUrl: './upload-roster-modal.component.html',
  styleUrls: ['./upload-roster-modal.component.scss']
})
export class UploadRosterModalComponent implements OnInit {
  public modalResponse: Subject<boolean> = new Subject();
  public modalPoliciesResponse: Subject<boolean> = new Subject();
  validationMessages: ErrorMessage[] = [];
  successfulUpload: boolean = false;
  text: string;
  dialogIndicator: string;
  safeHTML: SafeHtml;
  emailInput: EmailInput;
  isEmailSendSuccessful: boolean;
  uploadRosterFormGroup: FormGroup;
  roster: ArrayBuffer | string;
  rosterName: string = '';

  constructor(
    public loadingService: LoadingService,
    public bsModalRef: BsModalRef,
    private appStateService: AppStateService,
    private sanitizer: DomSanitizer,
    private utilityService: UtilityService,
    private reportService: ReportService,
    private quoteService: QuoteService
  ) { }

  ngOnInit(): void {
    this.safeHTML = this.sanitizer.bypassSecurityTrustHtml(this.text);
  }

  /**
   * Confirm cancel policy from dialog modal
   * @returns {void}
   */
  confirm(): void {
    this.modalResponse.next(true);
    this.bsModalRef.hide();
  }

  /**
   * Close the policy conifrmation dialog modal
   * @returns {void}
   */
  closeThisDialog(): void {
    this.modalResponse.next(false);
    this.bsModalRef.hide();
  }

  /**
   * gets and saves the roster template excel file
   * @returns {void}
   */
  getTemplate(): void {
    this.reportService.viewOrEmailGenericDocument('0', PrintOrEmailReportType.RosterTemplate, this.emailInput, this.appStateService.getCredentials())
      .subscribe((response) => {
        this.utilityService.saveTemplate(response.FileContent, FileTypes.XLTX, 'GroupRosterTemplate.xltx');
      });
  }

  /**
   * gets and saves the roster instructions
   * @returns {void}
   */
  getInstructions(): void {
    this.reportService.viewOrEmailGenericDocument('0', PrintOrEmailReportType.RosterInstructions, this.emailInput, this.appStateService.getCredentials())
      .subscribe((response) => {
        this.utilityService.saveTemplate(response.FileContent, FileTypes.PDF, 'RosterInstructions.pdf');
      });
  }

  /**
   * handler for the hidden select dialog changing
   * @param files: FileList
   * @returns {void}
   */
  selectRoster(files: FileList): void  {
    const file = files.item(0);
    // Max size of image 5MB
    const MAX_SIZE = 5 * 1000 * 1000;
    if (file.size > MAX_SIZE) {
      alert('The selected file is too large, it must be less than 5 Megabytes in size');
      return;
    }
    const allowedFileTypes: FileTypes[] = [
      FileTypes.XLSX,
      FileTypes.XLS
     ];
    if (allowedFileTypes.filter((type) => file.type === type).length === 0) {
      alert('The selected file must be in an .xltx or .xls format');
      return;
    }
    this.rosterName = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.roster = reader.result;
    };
  }

  // todo:, AllowAgencyCreditCard && AllowCashWithheld are CURRENTLY always true, butttt
  // they are technically set at the agency level and can be disabled
  // the admin service changes include this information

  /**
   * builds the request object for the upload roster request
   * @param files: FileList
   * @returns {void}
   */
  uploadRoster(): void {
    this.loadingService.open();
    const payload = new RosterUploadRequest();
    payload.AllowAgencyCreditCard = true; // always true
    payload.AllowCashWithheld = true; // always true
    payload.FileName = this.rosterName;
    payload.Base64 = (this.roster) ? this.roster.toString().split(',')[1] : '';
    payload.MaxBookingsAllowedInGroup = MAX_ROSTER_ROWS;
    payload.GroupBooking = this.appStateService.getCurrentBookingQuote();
    this.quoteService.rosterUpload(payload, this.appStateService.getCredentials())
      .subscribe((result) => {
        if (result.IsUploadSuccessful) {
          this.loadingService.close();
          this.successfulUpload = result.IsUploadSuccessful;
          this.modalPoliciesResponse.next(result.IsUploadSuccessful);
          this.bsModalRef.hide();
        } else {
          this.validationMessages = result.ErrorMessages;
        }
      },
      (error) => this.loadingService.close(),
      () => this.loadingService.close());
  }

}
