"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PolicyPageType;
(function (PolicyPageType) {
    PolicyPageType[PolicyPageType["View"] = 0] = "View";
    PolicyPageType[PolicyPageType["Checkout"] = 1] = "Checkout";
    PolicyPageType[PolicyPageType["Modify"] = 2] = "Modify";
    PolicyPageType[PolicyPageType["Cancel"] = 3] = "Cancel";
    PolicyPageType[PolicyPageType["EditFlights"] = 4] = "EditFlights";
    PolicyPageType[PolicyPageType["Quote"] = 5] = "Quote";
})(PolicyPageType = exports.PolicyPageType || (exports.PolicyPageType = {}));
