// Angular Core
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

// Models
import { MaxMail2ndChanceSettings, ErrorMessage, KeyValue, BookingAgentInformation, Booking } from '@allianz/agent-max-core-lib';
import { EmailInput } from '../models/classes';
import { PrintOrEmailReportType, PrintOrEmailOfferType } from '../models/enums';

// Components
import { ModalComponent } from '../shared/components/modal/modal.component';

// 3rd party
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';

// Services
import { AppStateService } from '../shared/services/app-state.service';
import { QuoteService } from '../api/services/quote.service';
import { ReportService } from '../api/services/report.service';
import { LoadingService } from '../api/services/loading.service';

@Component({
  selector: 'app-maxmail-settings',
  templateUrl: './maxmail-settings.component.html',
  styleUrls: ['./maxmail-settings.component.scss']
})
export class MaxmailSettingsComponent implements OnInit {
  maxMailSCValidationMessages: ErrorMessage[] = new Array<ErrorMessage>();
  maxMail2ndChanceSettings: MaxMail2ndChanceSettings;
  programProductsForMaxMail: KeyValue[] = new Array<KeyValue>();
  booking: Booking = new Booking();
  maxmailSettingsForm: FormGroup;
  is2ndChanceEnabled: FormControl = new FormControl(false, [Validators.required]);
  isOfferEnabled: FormControl = new FormControl(false, [Validators.required]);
  IsEmailSendSuccessful: boolean = false;
  SCSettingsSaved: boolean = false;
  agentInformation: BookingAgentInformation;
  emailInput: EmailInput = new EmailInput();
  bsModalRef: BsModalRef;
  offerArray: FormArray = this.formBuilder.array([]);
  maxmailSubscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private quoteService: QuoteService,
    private reportService: ReportService,
    private appStateService: AppStateService,
    private formBuilder: FormBuilder,
    private bsModalService: BsModalService,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    // Route resolver data
    this.maxMail2ndChanceSettings = this.route.snapshot.data.maxmailSecondChanceSettings;
    this.programProductsForMaxMail = this.route.snapshot.data.maxmailProgramProducts;
    this.maxmailSettingsForm = this.formBuilder.group({
      is2ndChanceEnabled: this.is2ndChanceEnabled,
      isOfferEnabled: this.isOfferEnabled,
      offerArray: this.offerArray
    });
  }

  ngOnInit(): void {
    this.booking.BookingID = this.appStateService.getCurrentBookingId();
    this.maxmailSettingsForm.patchValue({ is2ndChanceEnabled: this.maxMail2ndChanceSettings.IsEnabled.Value });
    this.initOfferArray();
  }

  ngOnDestroy(): void {
    this.maxmailSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * calls the service to update the maxmail settings based on user input
   * @returns {void}
   */
  updateMaxMailSettings(): void {
    this.maxMail2ndChanceSettings = this.getUpdatedSettings(this.maxmailSettingsForm);
    this.loadingService.open();
    this.maxmailSubscriptions.push(
      this.quoteService.saveMaxMailSettingsByBookingId(this.booking.BookingID, this.maxMail2ndChanceSettings, this.appStateService.getCredentials())
        .subscribe((result) => {
          if (result.IsSaveSuccesful) {
            this.maxMail2ndChanceSettings = result;
            this.maxMailSCValidationMessages = this.maxMailSCValidationMessages.concat(result.ValidationMessages).concat(result.OperationErrorMessages);
            this.SCSettingsSaved = true;
            this.maxmailSettingsForm.markAsPristine();
          } else {
            this.SCSettingsSaved = false;
            this.maxMailSCValidationMessages = this.maxMailSCValidationMessages.concat(result.ValidationMessages).concat(result.OperationErrorMessages);
          }
        },
        (error) => { },
        () => this.loadingService.close())
    );
  }

  /**
   * checks if there are errormessages in the form
   * @returns {void}
   */
  hasErrorMessages(): boolean {
    return this.maxMailSCValidationMessages.length > 0;
  }

  /**
   * gets the product name, and if it's the current product appends 'Current Product' to it.
   * May be worth refactoring futher.
   * @param {KeyValue} offerType
   * @returns {void}
   */
  getProductName(product: KeyValue): string {
    if (product.Key !== '-1') {
      return product.Value;
    }
    let productName: string = 'Product in Quote';
    if (this.maxMail2ndChanceSettings.ProductsInQuote.length > 0) {
      const productNames: string[] = [];
      this.maxMail2ndChanceSettings.ProductsInQuote.forEach((productIterable) => {
        this.programProductsForMaxMail.forEach((programProductIterable) => {
          if (programProductIterable.Key === productIterable.toString()) {
            productNames.push(programProductIterable.Value);
          }
        });
      });
      if (productNames.length === 0) {
        productName = 'No longer available';
      } else {
        productName = `Current Product [ ${productNames.join()} ]`;
      }
    }
    return productName;
  }

  /**
   * brings you to the queues page
   * @returns {void}
   */
  onCloseMaxMailsettings(): void {
    this.router.navigate(['queues']);
  }

  /**
   * sends a sample email to the agent based on their registered email addreess and the selected product
   * @param {string} offerType
   * @param {string} offerProductId
   * @returns {void}
   */
  onEmailSample(offerType: PrintOrEmailOfferType, offerProductId: string): void {
    this.agentInformation = this.appStateService.getAgentInformation();
    this.IsEmailSendSuccessful = false;
    this.emailInput.CCToFromAddress = false;
    this.emailInput.ToEmailAddress = this.agentInformation.EmailAddress;
    this.emailInput.ToName = `${this.agentInformation.FirstName} ${this.agentInformation.LastName}`;
    this.emailInput.FromEmailAddress = this.agentInformation.EmailAddress;
    this.emailInput.FromName = `${this.agentInformation.FirstName} ${this.agentInformation.LastName}`;
    this.loadingService.open();

    this.maxmailSubscriptions.push(
      this.reportService.viewOrEmailWebQuote('Email', PrintOrEmailReportType.BookingLevelSample, offerType, this.booking, offerProductId, this.emailInput, this.appStateService.getCredentials())
        .subscribe((result) => {
          let message: string = '';
          if (result.IsValid && result.ErrorMessages.length === 0) {
            message = 'Email Sent';
          } else {
            message = `Error sending the Email: ${result.ErrorMessages[0].Description}`;
          }
          const initialState = { text: message };
          this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
          this.bsModalRef.content.closeBtnName = 'Close';
        },
        (err) => {
          this.loadingService.close();
          const initialState = { text: 'Error sending the Email' };
          this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
          this.bsModalRef.content.closeBtnName = 'Close';
        },
        () => this.loadingService.close())
    );
  }

  /**
   * Get updated settings for the second chances to submit to server
   * @param {FormGroup} form
   * @returns {MaxMail2ndChanceSettings}
   */
  private getUpdatedSettings(form: FormGroup): MaxMail2ndChanceSettings {
    const updatedSettings = this.maxMail2ndChanceSettings;
    updatedSettings.IsEnabled.Value = this.is2ndChanceEnabled.value;
    form.value.offerArray.forEach((offer, index) => {
      updatedSettings.MaxMail2ndChanceOffers[index].IsEnabled.Value = offer.IsEnabled;
      updatedSettings.MaxMail2ndChanceOffers[index].ProgramProductId.Value = offer.ProgramProductId;
    });
    return updatedSettings;
  }

  /**
   * Intialize the offer formgroup array,
   * @returns {void}
   */
  private initOfferArray(): void {
    for (const offer of this.maxMail2ndChanceSettings.MaxMail2ndChanceOffers) {
      const offerGroup = this.formBuilder.group({
        FormattedOfferDate: new FormControl(offer.FormattedOfferDate),
        IsEnabled: new FormControl(offer.IsEnabled.Value),
        IsEditable: new FormControl(offer.ProgramProductId.IsEditable),
        OfferStatus: new FormControl(offer.OfferStatus),
        OfferTypeString: new FormControl(offer.OfferTypeString),
        OfferTypeText: new FormControl(offer.OfferTypeText),
        OfferTypeValue: new FormControl(offer.OfferTypeValue),
        ProgramProductId: new FormControl(offer.ProgramProductId.Value)
      });
      this.offerArray.push(offerGroup);
    }
  }

}
