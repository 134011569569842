export class Paging 
{
    //variables
    itemsPerPage: number = 50;
    currentPage: number = 1;
    queueList: number[];
    rowsCount: number;

    private generateQueueList(rowsCount: number): void
    {
        this.queueList = [];

        for(let queue = 1; queue < rowsCount; queue++)
        this.queueList.push(queue);      
    }

    public updateRowsCount(rowsCount: number) : void
    {
        this.rowsCount = rowsCount;
        this.generateQueueList(rowsCount);
    }

    public updatePaging(itemsPerPage: number): void
    {        
        this.itemsPerPage = itemsPerPage;
        this.currentPage = 1;

        this.generateQueueList(this.rowsCount);
    }

}