"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_field_class_1 = require("./input-field.class");
// TODO: implements TravelerInformation
var TravelerInformation = /** @class */ (function () {
    function TravelerInformation() {
        this.AgeIfNoBirthDate = new input_field_class_1.InputField();
        this.FirstName = new input_field_class_1.InputField();
        this.FormattedDateOfBirth = new input_field_class_1.InputField();
        this.LastName = new input_field_class_1.InputField();
    }
    return TravelerInformation;
}());
exports.TravelerInformation = TravelerInformation;
