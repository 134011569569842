import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent implements OnInit {
  COLORS: string[] = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];
  MINIMUM_PW_LENGTH: number = 8;
  BAR_CHUNK_LENGTH: number = 20;
  TOTAL_BAR_LENGTH: number = 100;
  characterRegex: RegExp = new RegExp(/[$-/:-?{-~!"^_`\[\]]/);
  capitalRegex: RegExp = new RegExp(/[A-Z]+/);
  lowerCaseRegex: RegExp = new RegExp(/[a-z]+/);
  numberRegex: RegExp = new RegExp(/[0-9]+/);
  passwordStrength: number;

  @Input('model') passwordStrengthSubject: Subject<any>;

  ngOnInit(): void {
    this.passwordStrengthSubject.subscribe((event) => {
      this.passwordStrength = this.updatePasswordStrength(event);
      this.passwordStrength = this.passwordStrength === 0 ? 1 : this.passwordStrength;

      // Update bar color and length based on password strength
      const barColor = document.getElementById('barColor');
      barColor.style.background = this.COLORS[this.passwordStrength - 1];
      barColor.style.width = `${this.passwordStrength * this.BAR_CHUNK_LENGTH}px`;

      const backgroundBar = document.getElementById('backgroundBar');
      backgroundBar.style.background = '#dddddd';
      backgroundBar.style.width = `${this.TOTAL_BAR_LENGTH - (this.passwordStrength * this.BAR_CHUNK_LENGTH)}px`;
    });
  }

  /**
   * Calculate the password strenght using regex
   * @param {string} event - the new password input
   * @returns {number}
   */
  updatePasswordStrength(event: string): number {
    const strengthNumber: boolean[] = [
      this.characterRegex.test(event),
      this.capitalRegex.test(event),
      this.lowerCaseRegex.test(event),
      this.numberRegex.test(event),
      event.length >= this.MINIMUM_PW_LENGTH];

    const filteredArray = strengthNumber.filter((x) => {
      return x === true;
    });

    return filteredArray.length;
  }

}
