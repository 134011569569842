// Angular Core
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Models
import { Booking, AgencyProducts } from '@allianz/agent-max-core-lib';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { QuoteService } from 'src/app/api/services/quote.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

// Components
import { TravelerInformationComponent } from 'src/app/shared/components/traveler-information/traveler-information.component';
import { TripInformationComponent } from 'src/app/shared/components/trip-information/trip-information.component';
import { SupplierListComponent } from 'src/app/shared/components/supplier-list/supplier-list.component';
import { ProgramProductListComponent } from 'src/app/shared/components/program-product-list/program-product-list.component';
import { CheckoutComponent } from 'src/app/shared/components/checkout/checkout.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

// Third Party
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subscription, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-individual-policy',
  templateUrl: './individual-policy.component.html',
  styleUrls: ['./individual-policy.component.scss']
})
export class IndividualPolicyComponent implements OnInit, OnDestroy {
  @ViewChild(TravelerInformationComponent, { static: true })
  public travelerInformationComponent: TravelerInformationComponent;
  @ViewChild(TripInformationComponent, { static: true })
  public tripInformationComponent: TripInformationComponent;
  @ViewChild(SupplierListComponent, { static: true })
  public supplierListComponent: SupplierListComponent;
  @ViewChild(ProgramProductListComponent, { static: true })
  public programProductListComponent: ProgramProductListComponent;
  @ViewChild(CheckoutComponent, { static: false })
  public checkoutComponent: CheckoutComponent;
  bsModalRef: BsModalRef;
  pageHeader: string = '';
  groupId: number;
  booking: Booking;
  isModify: boolean = false;
  quoteSaved: boolean = false;
  quoteChanged: boolean;
  changedTravelers: number;
  priceBeforeModify: number;
  policySubscriptions: Subscription[] = [];
  parentGroupBooking: Booking;
  agencyProducts: AgencyProducts;
  canSaveRecord: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appStateService: AppStateService,
    private bsModalService: BsModalService,
    private quoteService: QuoteService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.groupId = this.route.snapshot.params.groupid;
    this.booking = this.route.snapshot.data.booking;
    this.agencyProducts = this.route.snapshot.data.agencyProducts;
    if (this.booking.BookingID === 0) {
      // fills out the form based on group policy
      this.parentGroupBooking = this.appStateService.getCurrentBookingQuote();
      this.booking = this.updateGroupInfoToNewBooking(this.parentGroupBooking);
      // filter the list of program product returned by the also returned bookings program product from group selection
      this.agencyProducts.ProgramProducts = this.route.snapshot.data.agencyProducts.ProgramProducts
        .filter((product) => product.ProgramProductId === this.route.snapshot.data.agencyProducts.Quote.ProductInformation.ProgramProducts[0].ProgramProductId);
    } else {
      // modifying policy use that policies product
      this.agencyProducts.ProgramProducts = this.booking.ProductInformation.ProgramProducts;
    }
    this.pageHeader = this.booking.GroupName.Value;
    this.priceBeforeModify = this.booking.OrderInformation.Price;
    this.validatePolicyHoldersName();
    // merge four fields observables to one and delay notify subscribers
    // i.e if DepartureDate right after stateOfResidence change within 2 seconds
    // mergeA will only call subscribe once instead of two times one for changes in DepartureDate field
    // one for stateOfResidence field
    // adding delay will prevent calling reCalculatePrice() multiple times while
    // user editing fields
    const source1 = this.travelerInformationComponent.stateOfResidence.valueChanges;
    const source2 = this.travelerInformationComponent.dateOfBirth.valueChanges;
    const source3 = this.tripInformationComponent.DepartureDate.valueChanges;
    const source4 = this.tripInformationComponent.TotalTripCost.valueChanges;
    const mergeA = merge(source1, source2, source3, source4).pipe(debounceTime(2000));
    mergeA.subscribe((val) => {
      if (this.validatePriceChange()) {
        this.reCalculatePrice();
      }
    });
    this.onChanges();
  }

  ngOnDestroy(): void {
    this.policySubscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * listen for changes on the form and update pricing info as needed
   * @returns {void}
   */
  onChanges(): void {
    this.travelerInformationComponent.firstNameControl.valueChanges.subscribe((val) => {
      this.validatePolicyHoldersName();
    });
    this.travelerInformationComponent.lastNameControl.valueChanges.subscribe((val) => {
      this.validatePolicyHoldersName();
    });
  }

  /**
   * has anything been modified in individual policy component
   * @returns {boolean}
   */
  isIndividualGroupFormModified(): boolean {
    return this.travelerInformationComponent.travelerInformationForm.dirty || this.tripInformationComponent.TripInformationForm.dirty || this.checkoutComponent.checkoutForm.dirty;
  }

  /**
   * is called when rCalculatePrice is called by "Check Price" button click in checkout component
   * @returns {void}
   */
  isCheckPriceButtonClick(checkPriceButtonClick: boolean): void {
    this.programProductListComponent.checkPriceButtonClick = checkPriceButtonClick;
  }

  /**
   * Go back to group policy list
   * @returns {void}
   */
  goBack(): void {
    const isModified = this.isIndividualGroupFormModified();
    if (isModified) {
      const initialState = {
        text: 'Do you want to save this policy to the group?',
        dialogIndicator: 'individual-policy-small-group'
      };
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.closeBtnName = 'Close';
      this.policySubscriptions.push(
        this.bsModalRef.content.modalResponse.subscribe((modalResponse) => {
          if (modalResponse) {
            this.bookingSaveQuote();
            if (this.quoteSaved) {
              this.router.navigate([`small-group/modifyGroup/${this.groupId}/policies`]);
            }
          } else {
            this.appStateService.setCurrentBookingQuote(null);
            this.appStateService.setCurrentBookingId(this.groupId);
            this.router.navigate([`small-group/modifyGroup/${this.groupId}/policies`]);
          }
        })
      );
    } else {
      this.appStateService.setCurrentBookingQuote(null);
      this.appStateService.setCurrentBookingId(this.groupId);
      this.router.navigate([`small-group/modifyGroup/${this.groupId}/policies`]);
    }
  }

  /**
   * Event to be injected into trip info from traveler info
   * @param {number} numberOfOtherTravelers
   * @returns {void}
   */
  changingTravelers(numberOfOtherTravelers: number): void {
    this.changedTravelers = numberOfOtherTravelers;
  }

  /**
   * saves the quote
   * @returns {void}
   */
  bookingSaveQuote(): void {
    this.updateBooking();
    // incomplete Booking status
    this.booking.BookingStatus = 19;
    this.booking.IsGroupBooking = false;
    this.quoteService.saveQuote(this.booking, this.appStateService.getCredentials())
      .subscribe((result) => {
        this.quoteSaved = result.HasValidationErrors === false && result.IsValid;
        this.booking = result;
        this.appStateService.setCurrentBookingQuote(null);
        this.appStateService.setCurrentBookingId(this.booking.BookingID);
        this.tripInformationComponent.TripInformation.ValidationMessages = result.TripInformation.ValidationMessages;
        this.travelerInformationComponent.TravelInformation.ValidationMessages = result.TravelInformation.ValidationMessages;
        this.booking.ErrorMessages = result.ErrorMessages.concat(result.SecondChanceInformation.ValidationMessages);
        if (this.tripInformationComponent.TripInformationForm.dirty) {
          this.tripInformationComponent.TripInformationForm.markAsPristine();
        }
        if (this.tripInformationComponent.supplierListComponent.SupplierListForm.dirty) {
          this.tripInformationComponent.supplierListComponent.SupplierListForm.markAsPristine();
        }
        if (this.quoteSaved) {
          this.appStateService.setCurrentBookingQuote(this.booking);
          this.appStateService.setCurrentBookingId(this.groupId);
          this.router.navigate([`small-group/modifyGroup/${this.groupId}/policies`]);
        }
      });
  }

  /**
   * checks to see if the policy holders name is filled out for submission
   * @returns {boolean}
   */
  validatePolicyHoldersName(): void {
    this.canSaveRecord = (this.travelerInformationComponent.firstNameControl.value !== null &&
      this.travelerInformationComponent.firstNameControl.value !== '') &&
      (this.travelerInformationComponent.lastNameControl.value !== null &&
        this.travelerInformationComponent.lastNameControl.value !== '');
  }

  /**
   * checks to see that all price fields are valid for submission
   * @returns {boolean}
   */
  validatePriceChange(): boolean {
    let dateString: string;
    const dateStringRegExp: RegExp = new RegExp('^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)[0-9][0-9]$');
    dateString = this.travelerInformationComponent.dateOfBirth.value;

    const travelerInformationFormDirty = (this.travelerInformationComponent.travelerInformationForm.dirty || this.tripInformationComponent.TripInformationForm.dirty);
    const isStateOfResidence = this.travelerInformationComponent.stateOfResidence.value !== '' && this.travelerInformationComponent.stateOfResidence !== null;
    const isDateString = dateString !== '' && dateString !== null;
    const isDateStringRegExpTest = dateStringRegExp.test(dateString);
    const isDepartureDateValue = this.tripInformationComponent.DepartureDate.value !== '' && this.tripInformationComponent.DepartureDate.value !== null;
    const isTotalTripCost = this.tripInformationComponent.TotalTripCost.value !== '' && this.tripInformationComponent.TotalTripCost.value !== null;
    const isValidatePriceChange = isStateOfResidence && isDateString && isDateStringRegExpTest && isDepartureDateValue && isTotalTripCost && travelerInformationFormDirty;

    return isValidatePriceChange;
  }

  /**
   * used in the modify workflow, updates the bookings price based on a change in user data.
   * @returns {void}
   */
  reCalculatePrice(): void {
    this.updateBooking();
    this.priceBeforeModify = this.booking.OrderInformation.Price;
    this.booking.IsGroupBooking = false;
    this.quoteService.reCalculatePriceForSmallGroup(this.booking, this.appStateService.getCredentials()).subscribe((result) => {
      this.booking = result;
      if (this.tripInformationComponent.TripInformationForm.dirty) {
        this.tripInformationComponent.TripInformationForm.markAsPristine();
      }
      if (this.tripInformationComponent.supplierListComponent.SupplierListForm.dirty) {
        this.tripInformationComponent.supplierListComponent.SupplierListForm.markAsPristine();
      }
      if (this.checkoutComponent) {
        this.checkoutComponent.checkoutForm.enable();
      }
      this.quoteChanged = this.tripInformationComponent.TripInformationForm.dirty || this.tripInformationComponent.supplierListComponent.SupplierListForm.dirty;
      if (result.ProductInformation && result.ProductInformation.ProgramProducts && result.ProductInformation.ProgramProducts.length > 0) {
        this.agencyProducts.ProgramProducts = result.ProductInformation.ProgramProducts;
      }
      this.updateBooking();
    });
  }

  /**
   * submits the purchase based on the formdata calls the
   * quoteservice to purchase, and if any errors are found updates the form
   * @returns {void}
   */
  bookingPurchase(): void {
    this.updateBooking();
    // GroupQuoteReadyForPurchase
    this.booking.BookingStatus = 6;
    this.booking.TravelInformation.PrimaryTraveler.Info.AgeIfNoBirthDate.Value = this.utilityService.calculateAge(this.booking.TravelInformation.PrimaryTraveler.Info.FormattedDateOfBirth.Value);
    this.quoteService.submitGroupPurchase(this.booking, this.appStateService.getCredentials())
      .subscribe((result) => {
        if (result.IsPurchaseSuccessful) {
          this.appStateService.setCurrentBookingQuote(null);
          this.appStateService.setCurrentBookingId(this.groupId);
          this.router.navigate([`small-group/modifyGroup/${this.groupId}/policies`]);
        }
        this.booking = result.Booking;
        if (this.travelerInformationComponent.travelerArray.value.length !== this.booking.TravelInformation.OtherTravelers.length) {
          this.travelerInformationComponent.TravelInformation.OtherTravelers = this.booking.TravelInformation.OtherTravelers;
          this.travelerInformationComponent.initTravelerArray();
        }
        this.tripInformationComponent.TripInformation.ValidationMessages = result.Booking.TripInformation.ValidationMessages;
        this.travelerInformationComponent.TravelInformation.ValidationMessages = result.Booking.TravelInformation.ValidationMessages;
        this.checkoutComponent.billingInfoValidation = result.BillingInformation.ValidationMessages;
        this.checkoutComponent.purchaseErrors = result.ErrorMessages;
        if (this.tripInformationComponent.TripInformationForm.dirty) {
          this.tripInformationComponent.TripInformationForm.markAsPristine();
        }
        if (this.tripInformationComponent.supplierListComponent.SupplierListForm.dirty) {
          this.tripInformationComponent.supplierListComponent.SupplierListForm.markAsPristine();
        }
      });
  }
  modifyPurchase(): void { }
  retryPendingBooking(): void { }

  /**
   * updates the booking object based on all the various sub components
   * @returns {void}
   */
  updateBooking(): void {
    // check actual route config for suppliers
    if (this.tripInformationComponent) {
      this.booking = this.utilityService.updateTripInfo(this.booking, this.tripInformationComponent, this.tripInformationComponent.supplierListComponent);
    }
    if (this.travelerInformationComponent) {
      this.booking = this.utilityService.updateTravelerInfo(this.booking, this.travelerInformationComponent);
    }
    if (this.checkoutComponent) {
      this.booking = this.utilityService.updateBillingInfo(this.booking, this.checkoutComponent);
    }
    if (this.booking.ProductInformation.ProgramProducts.length === 0) {
      this.booking.ProductInformation.ProgramProducts = this.agencyProducts.ProgramProducts;
    }
    this.booking.BookingGroupID = this.groupId;
    this.appStateService.setCurrentBookingQuote(this.booking);
  }

  /**
   * Updates new booking with parent booking info
   * @param {Booking} parentGroupBooking
   * @returns {Booking}
   */
  private updateGroupInfoToNewBooking(parentGroupBooking: Booking): Booking {
    this.booking.BookingGroupID = parentGroupBooking.BookingGroupID;
    this.booking.BookingStatus = 19; // GroupQuoteIncomplete
    this.booking.GroupCreatorEmailAddress = parentGroupBooking.GroupCreatorEmailAddress;
    this.booking.GroupName = parentGroupBooking.GroupName;
    this.booking.GroupRefNo = parentGroupBooking.GroupRefNo;
    this.booking.IsGroupBooking = false;
    if (parentGroupBooking.ProductInformation && parentGroupBooking.ProductInformation.ProgramProducts.length > 0) {
      this.booking.ProductInformation.ProgramProducts = parentGroupBooking.ProductInformation.ProgramProducts;
    }
    this.booking.TripInformation = parentGroupBooking.TripInformation;
    this.booking.TripInformation.AverageTripCost = 0;
    this.booking.TripInformation.TotalTripCost.Value = 0;
    return this.booking;
  }

}
