// Angular Core
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

// Models
import { ReportSettings, UserInformation, Quarter, PrintOrEmailOptions } from '../models/interfaces';
import { SalesReportTypes, SalesReportScopeTypes, GroupSalesReportType, DateRangeTypes, ReportRightString, PrintOrEmailDocumentType, PrintOrEmailReportType, PrintOrEmailTypes } from '../models/enums';
import { ReportData } from '../models/classes';
import { QUARTERS } from '../models/constants';
import { ErrorMessage, Booking, FileTypes } from '@allianz/agent-max-core-lib';

// Services
import { AppStateService } from '../shared/services/app-state.service';
import { UtilityService } from '../shared/services/utility.service';
import { ReportService } from '../api/services/report.service';
import { LoadingService } from '../api/services/loading.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  TOTAL_YEARS: number = 9;
  MONTHS: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  reportSettings: ReportSettings;
  salesReportTypes: typeof SalesReportTypes = SalesReportTypes;
  salesReportScopeTypes: typeof SalesReportScopeTypes = SalesReportScopeTypes;
  groupReportTypes: typeof GroupSalesReportType = GroupSalesReportType;
  dateRangeTypes: typeof DateRangeTypes = DateRangeTypes;
  reportingRightString: typeof ReportRightString = ReportRightString;
  reportsForm: FormGroup;
  today: Date = new Date();
  currYear: string = this.today.getFullYear().toString();
  reportType: FormControl = new FormControl(this.salesReportTypes.salesReport);
  salesReportScopeType: FormControl = new FormControl(this.salesReportScopeTypes.currentAgentAccount);
  dateRange: FormControl = new FormControl(this.dateRangeTypes.yearToDate);
  options: FormControl = new FormControl('');
  selectedYear: FormControl = new FormControl(this.currYear);
  selectedMonth: FormControl = new FormControl(this.today.getMonth());
  selectedQuarter: FormControl = new FormControl(QUARTERS[this.getQuarter(this.today.getMonth()) - 1]);
  begDate: FormControl = new FormControl(this.today);
  endDate: FormControl = new FormControl(this.today);
  otherAgent: FormControl = new FormControl('');
  otherAgencyId: FormControl = new FormControl('');
  showDetailLines: FormControl = new FormControl(true);
  showProductSummaries: FormControl = new FormControl(true);
  showMaxMailProdSummaries: FormControl = new FormControl(false);
  showGroupDetails: FormControl = new FormControl(true);
  years: number[] = [];
  monthRange: string[] = [];
  availableQuarters: Quarter[] = [];
  validationMessages: ErrorMessage[] = [];
  reportData: ReportData = new ReportData();
  agentInfo: UserInformation;

  constructor(
    private reportService: ReportService,
    private appStateService: AppStateService,
    private utilityService: UtilityService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    this.reportsForm = new FormGroup({
      reportType: this.reportType,
      salesReportScopeType: this.salesReportScopeType,
      dateRange: this.dateRange,
      options: this.options,
      selectedYear: this.selectedYear,
      selectedMonth: this.selectedMonth,
      selectedQuarter: this.selectedQuarter,
      begDate: this.begDate,
      endDate: this.endDate,
      otherAgent: this.otherAgent,
      otherAgencyId: this.otherAgencyId,
      showDetailLines: this.showDetailLines,
      showProductSummaries: this.showProductSummaries,
      showMaxMailProdSummaries: this.showMaxMailProdSummaries,
      showGroupDetails: this.showGroupDetails
    });
   }

  ngOnInit(): void {
    this.reportSettings = this.route.snapshot.data.reportSettings;
    this.setYears();
    this.setMonthRange();
    this.setQuarters();
    this.agentInfo = this.appStateService.getCredentials();
  }

  /**
   * Unchecks one check box if the other is already checked
   * @param {any} event
   * @returns {void}
   */
  updateProductSummaries(event: any): void {
    if (event.target.id === 'showProductSummaries' && event.target.checked) {
      this.showMaxMailProdSummaries.setValue(false);
    } else if (event.target.id === 'showMaxMailProdSummaries' && event.target.checked) {
      this.showProductSummaries.setValue(false);
    }
  }

  /**
   * Check if access to reports
   * @returns {void}
   */
  hasReportsAccess(): boolean {
    return this.reportSettings.AgentReportingRightString !== this.reportingRightString.none;
  }

  /**
   * Checks if access to agent
   * @returns {boolean}
   */
  hasReportsAgentAccess(): boolean {
    return this.reportSettings.AgentReportingRightString === this.reportingRightString.self;
  }

  /**
   * Checks if access to Agency
   * @returns {boolean}
   */
  hasReportsAgencyAccess(): boolean {
    return this.reportSettings.AgentReportingRightString === this.reportingRightString.agency;
  }

  /**
   * Update the months and quarters available when the year changes
   * @returns {void}
   */
  updateReportParams(): void {
    this.setMonthRange();
    this.setQuarters();
  }

  /**
   * Populate the years array with the last 9 years including the current
   * @returns {void}
   */
  setYears(): void {
    for (let i = this.today.getFullYear(); this.years.length < this.TOTAL_YEARS; i--) {
      this.years.push(i);
    }
  }

  /**
   * Set the months available to select based on the year
   * @returns {void}
   */
  setMonthRange(): void {
    if (this.currYear === this.selectedYear.value) {
      this.monthRange = this.MONTHS.slice(0, this.today.getMonth() + 1);
    } else {
      this.monthRange = this.MONTHS;
    }
  }

  /**
   * Set the quarters available to select based on the year
   * @returns {void}
   */
  setQuarters(): void {
    if (this.currYear === this.selectedYear.value) {
      this.availableQuarters = QUARTERS.slice(0, this.getQuarter(this.today.getMonth()));
    } else {
      this.availableQuarters = QUARTERS;
    }
  }

  /**
   * Calculate the quarter based on the month param
   * @param {number} month
   * @returns {void}
   */
  getQuarter(month: number): number {
    return Math.ceil((month + 1) / 3);
  }

  /**
   * Get the last day in the month provided in the given year
   * @param {number} month
   * @param {number} year
   * @returns {Date}
   */
  daysInMonth(month: number, year: number): Date {
    return new Date(year, +month + 1, 0);
  }

  /**
   * Set the report dates based on the type of date chosen in the date range
   * @returns {void}
   */
  setReportDates(): void {
    if (this.dateRange.value === this.dateRangeTypes.yearToDate) {
      this.reportData.SalesReportCriteria.FormattedBeginDate.Value = formatDate(new Date(this.selectedYear.value, 0, 1), 'MM/dd/yyyy', 'en-US');
      this.reportData.SalesReportCriteria.FormattedEndDate.Value = formatDate(this.today, 'MM/dd/yyyy', 'en-US');
    } else if (this.dateRange.value === this.dateRangeTypes.yearly) {
      this.reportData.SalesReportCriteria.FormattedBeginDate.Value = formatDate(new Date(this.selectedYear.value, 0, 1), 'MM/dd/yyyy', 'en-US');
      this.reportData.SalesReportCriteria.FormattedEndDate.Value = formatDate(new Date(this.selectedYear.value, 11, 31), 'MM/dd/yyyy', 'en-US');
    } else if (this.dateRange.value === this.dateRangeTypes.monthly) {
      this.reportData.SalesReportCriteria.FormattedBeginDate.Value = formatDate(new Date(this.selectedYear.value, this.selectedMonth.value, 1), 'MM/dd/yyyy', 'en-US');
      this.reportData.SalesReportCriteria.FormattedEndDate.Value = formatDate(this.daysInMonth(this.selectedMonth.value, this.selectedYear.value), 'MM/dd/yyyy', 'en-US');
    } else if (this.dateRange.value === this.dateRangeTypes.quarterly) {
      this.reportData.SalesReportCriteria.FormattedBeginDate.Value = `${this.selectedQuarter.value.startDate}${this.selectedYear.value}`;
      this.reportData.SalesReportCriteria.FormattedEndDate.Value = `${this.selectedQuarter.value.endDate}${this.selectedYear.value}`;
    } else if (this.dateRange.value === this.dateRangeTypes.customDates) {
      this.reportData.SalesReportCriteria.FormattedBeginDate.Value = formatDate(this.begDate.value, 'MM/dd/yyyy', 'en-US');
      this.reportData.SalesReportCriteria.FormattedEndDate.Value = formatDate(this.endDate.value, 'MM/dd/yyyy', 'en-US');
    }
  }

  /**
   * Set the SalesReportTypeString value based on the report type and the options
   * @returns {void}
   */
  setReportDocumentType(): void {
    // If a group report
    if (this.reportType.value === this.groupReportTypes.groupSummaryReport) {
      this.reportData.SalesReportCriteria.SalesReportTypeString.Value = (this.showGroupDetails.value) ?
        this.groupReportTypes.groupDetailsReport : this.groupReportTypes.groupSummaryReport;
    // or an unsummarized report
    } else if (this.reportType.value === this.salesReportTypes.unsummarizedSalesReport) {
      this.reportData.SalesReportCriteria.SalesReportTypeString.Value = this.salesReportTypes.unsummarizedSalesReport;
    // or a summarized report
    } else {
      if (this.showDetailLines.value && this.showProductSummaries.value) {
        this.reportData.SalesReportCriteria.SalesReportTypeString.Value = this.salesReportTypes.salesReport;
      } else if (this.showDetailLines.value && this.showMaxMailProdSummaries.value) {
        this.reportData.SalesReportCriteria.SalesReportTypeString.Value = this.salesReportTypes.maxMailSalesReport;
      } else if (!this.showDetailLines.value && this.showProductSummaries.value) {
        this.reportData.SalesReportCriteria.SalesReportTypeString.Value = this.salesReportTypes.salesProductSummaryReport;
      } else if (!this.showDetailLines.value && this.showMaxMailProdSummaries.value) {
        this.reportData.SalesReportCriteria.SalesReportTypeString.Value = this.salesReportTypes.maxMailSalesProductSummaryReport;
      } else if (this.showDetailLines.value && !this.showMaxMailProdSummaries.value && !this.showProductSummaries.value) {
        this.reportData.SalesReportCriteria.SalesReportTypeString.Value = this.salesReportTypes.salesDetailsSummarizedReport;
      } else {
        this.reportData.SalesReportCriteria.SalesReportTypeString.Value = this.salesReportTypes.salesSummaryReport;
      }
    }
  }

  /**
   * Set the Agency information based on the Purchase Queue Criteria
   * @returns {void}
   */
  setAgencyInfo(): void {
    // Set agent code only if looking for another agent
    if (this.salesReportScopeType.value === this.salesReportScopeTypes.specificAgentCode) {
      this.reportData.SalesReportCriteria.AgentCode.Value = this.otherAgent.value;
    } else {
      this.reportData.SalesReportCriteria.AgentCode.Value = '';
    }

    this.reportData.SalesReportCriteria.AgencyIdList.Values = [];
    if (this.salesReportScopeType.value === this.salesReportScopeTypes.specificAgencyId) {
      if (this.otherAgencyId.value !== '') {
        this.reportData.SalesReportCriteria.AgencyIdList.Values.push(this.otherAgencyId.value);
      }
    } else if (this.salesReportScopeType.value === this.salesReportScopeTypes.allAgencies) {
      this.reportSettings.BranchList.forEach((element) => {
        this.reportData.SalesReportCriteria.AgencyIdList.Values.push(element.Key);
      });
    } else {
      this.reportData.SalesReportCriteria.AgencyIdList.Values.push(this.agentInfo.AgencyID);
    }
  }

  /**
   * Decides whether to generate the report or email it to
   * @param {string} option
   * @returns {void}
   */
  requestReport(option: string): void {
    this.setReportDates();
    this.setReportDocumentType();
    this.setAgencyInfo();
    this.reportData.SalesReportCriteria.AgentAccountId.Value = this.agentInfo.AgentAccountID;
    this.reportData.SalesReportCriteria.ReportScopeTypeString.Value = this.salesReportScopeType.value;
    const booking = new Booking();
    booking.SalesReportCriteria = this.reportData.SalesReportCriteria;
    if (option === 'View') {
      this.loadingService.open();
      this.reportService.viewOrEmailSalesReport(option, booking, {}, this.appStateService.getCredentials())
        .subscribe((response) => {
          if (response.IsValid) {
            // If the file type is 2/7 means xls/xlsx, otherwise a PDF
            if (response.FileType === 2 || response.FileType === 7) {
              this.utilityService.openFile(response.FileContent, FileTypes.Excel);
            } else {
              this.utilityService.openFile(response.FileContent, FileTypes.PDF);
            }
          }
          this.validationMessages = response.ErrorMessages;
        },
        (error) => { },
        () => this.loadingService.close());
    } else {
      const options: PrintOrEmailOptions = {
        HeaderName: 'Send Sales Report',
        DocumentType: PrintOrEmailDocumentType.SalesReport,
        ReportType: PrintOrEmailReportType.ClientGeneratedHtmlDocument,
        BookingId: 0,
        EmailInput: {
          ToName: '',
          ToEmailAddress: '',
          SecondaryEmailAddress: '',
          FromEmailAddress: this.appStateService.getAgentInformation().EmailAddress,
          FromName: `${this.appStateService.getAgentInformation().FirstName} ${this.appStateService.getAgentInformation().LastName}`
        }
      };
      this.appStateService.setPrintOrEmailReportName(PrintOrEmailTypes.SalesReport);
      this.appStateService.setPrintOrEmailData(booking, options);
      this.router.navigate(['printoremail']);
    }
  }

}
