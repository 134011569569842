"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FileTypes;
(function (FileTypes) {
    FileTypes["Excel"] = "application/vnd.ms-excel";
    FileTypes["PDF"] = "application/pdf";
    FileTypes["pdf"] = "pdf";
    FileTypes["XLS"] = "application/vnd.ms-excel";
    FileTypes["XLSX"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    FileTypes["XLTX"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.template";
    FileTypes["DOC"] = "application/msword";
    FileTypes["DOCX"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    FileTypes["PNG"] = "image/png";
    FileTypes["JPEG"] = "image/jpeg";
    FileTypes["JPG"] = "image/jpg";
    FileTypes["GIF"] = "image/gif";
    FileTypes["TIF"] = "image/tif";
    FileTypes["TIFF"] = "image/tiff";
    FileTypes["BMP"] = "image/bmp";
    FileTypes["BLANK"] = "";
})(FileTypes = exports.FileTypes || (exports.FileTypes = {}));
