"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var traveler_information_class_1 = require("./traveler-information.class");
var input_field_class_1 = require("./input-field.class");
var address_class_1 = require("./address.class");
var PrimaryTraveler = /** @class */ (function () {
    function PrimaryTraveler() {
        this.Info = new traveler_information_class_1.TravelerInformation();
        this.Address = new address_class_1.Address();
        this.EmailAddress = new input_field_class_1.InputField();
        this.PhoneNumber = new input_field_class_1.InputField();
    }
    return PrimaryTraveler;
}());
exports.PrimaryTraveler = PrimaryTraveler;
