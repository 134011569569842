import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppStateService } from '../services/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  validForAdminRoute: boolean = true;
  constructor(
    private appStateService: AppStateService,
    private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      if (!this.appStateService.isAdmin()) {
        this.router.navigate(['home']);
        return false;
      }
      return true;
  }
}
