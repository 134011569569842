// Angular Core
import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// Models
import {  Agency, AdminSaveAgencies } from 'src/app/models/interfaces';
import { ErrorMessage } from '@allianz/agent-max-core-lib';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { UserService } from 'src/app/api/services/user.service';
import { AdminUserService } from 'src/app/api/services/adminUser.service';

// Components
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

// Third Party
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-agencies-form',
  templateUrl: './my-agencies-form.component.html',
  styleUrls: ['./my-agencies-form.component.scss']
})
export class MyAgenciesFormComponent implements OnInit, OnDestroy {
  validationMessages: string[] = [];
  agencyAccountForm: FormGroup;
  myAgenciesArray: FormArray = this.formBuilder.array([]);
  showAddAccam: boolean = false;
  accam: FormControl = new FormControl('', [Validators.required]);
  agenciesValidationMessages: ErrorMessage[] = [];
  agenciesSaved: boolean = false;
  bsModalRef: BsModalRef;
  @Input('userOptions') myAgencies: Agency[];
  @Input('isActive') isActive: boolean = true;
  myAgencySubscription: Subscription;
  isWebPrimary: FormControl = new FormControl();
  adminRoute: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private userService: UserService,
    private adminUserService: AdminUserService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute
  ) {
    this.agencyAccountForm = this.formBuilder.group({
      accam: this.accam,
      myAgenciesArray: this.myAgenciesArray
    });
  }

  ngOnInit(): void {
    this.adminRoute = this.activatedRoute.snapshot.data.adminAgentRoute;
    this.initMyAgenciesArray();
  }

  /**
   * Adss an agency to the myAgencies formArray
   * @param {Agency} agency
   * @returns {void}
   */
  addAgency(agency: Agency): void {
    if (agency) {
      if (agency.IsWebPrimary === true) {
        this.isWebPrimary.setValue(agency.ACCAM);
      }
      const agencyGroup = this.formBuilder.group({
        isWebPrimary: this.isWebPrimary,
        accam: agency.ACCAM,
        longName: agency.LongName,
        nickname: agency.NickName,
        accessLevel: agency.WebAccessType
      });
      this.myAgenciesArray.push(agencyGroup);
    }
  }

  /**
   * Get the Agency account after the user inputs the Accam of the new agency
   * @returns {void}
   */
  getAgencyAccountForAdd(): void {
    this.myAgencySubscription = this.userService.getAgencyAccountForAdd(this.accam.value, this.appStateService.getCredentials())
      .subscribe((result) => {
        if (result.IsValid) {
          if (!this.myAgencies.some((item) => item.ACCAM === result.ACCAM)) {
            this.myAgencies.push(result);
            this.addAgency(result);
            this.myAgenciesArray.markAsDirty();
          } else {
            const initialState = { text: 'Accam already added.' };
            this.bsModalRef = this.modalService.show(ModalComponent, { initialState });
          }
        }
        this.agenciesValidationMessages = result.ErrorMessages;
      });
  }

  /**
   * Removes an agnecy by index
   * @param {number} index
   * @returns {void}
   */
  removeAgency(index: number): void {
    this.myAgencies.splice(index, 1);
    this.myAgenciesArray.controls.splice(index, 1);
    this.myAgenciesArray.markAsDirty();
  }

  /**
   * Changes the value of the radio button
   * @param {FormGroup} control
   * @returns {void}
   */
  isWebPrimaryChange(control: FormGroup): void {
    this.isWebPrimary.setValue(control.value.accam);
  }

  /**
   * Initialize the myAgencies array based on current user information
   * @returns {void}
   */
  initMyAgenciesArray(): void {
    if (this.myAgencies && this.myAgencies.length > 0) {
      for (const i of this.myAgencies) {
        this.addAgency(i);
      }
    }
    if (!this.isActive) {
      this.agencyAccountForm.disable();
    }
  }

  /**
   * Check if the myAgencies array has changed
   * @returns {boolean}
   */
  isMyAgenciesChanged(): boolean {
    return  (!this.isActive) || (this.myAgenciesArray.dirty && this.myAgenciesArray.invalid || this.myAgenciesArray.pristine && this.myAgenciesArray.valid);
  }

  /**
   * Update the userOptions information with AgentMax service call
   * @returns {void}
   */
  submit(): void {
    if (this.myAgenciesArray.valid && this.myAgenciesArray.dirty && this.adminRoute) {
      const adminRequest: AdminSaveAgencies = {
        AgentId: this.activatedRoute.snapshot.params.agentId,
        Agencies: this.getUpdatedMyAgencies(this.myAgenciesArray)
      };
      this.adminUserService.updateAgentAgencies(adminRequest)
        .subscribe((result) => {
          this.agenciesValidationMessages = result.ErrorMessages;
          this.agenciesSaved = (this.agenciesValidationMessages.length === 0) ?   true : false;
          if (this.agenciesSaved) {
            this.myAgencies = result.Agencies;
          }
          this.myAgenciesArray.markAsPristine();
        });
    } else if (this.myAgenciesArray.valid && this.myAgenciesArray.dirty) {
      const updatedMyAgencies = this.getUpdatedMyAgencies(this.myAgenciesArray);
      this.userService.saveAgencyAccounts(updatedMyAgencies, this.appStateService.getCredentials())
        .subscribe((result) => {
          this.agenciesValidationMessages = result.ErrorMessages;
          this.agenciesSaved = (this.agenciesValidationMessages.length === 0) ? true : false;
          if (this.agenciesSaved) {
            const userOptions = this.appStateService.getUserOptions();
            userOptions.Agencies = result.Agencies;
            this.appStateService.setUserOptions(userOptions);
          }
          this.myAgenciesArray.markAsPristine();
        });
    }
  }

  ngOnDestroy(): void {
    if (this.myAgencySubscription) {
      this.myAgencySubscription.unsubscribe();
    }
  }

  /**
   * Update the myAgencies array with the changed information
   * @param {FormArray} formArray
   * @return {Agency[]}
   */
  private getUpdatedMyAgencies(formArray: FormArray): Agency[] {
    const updatedMyAgencies = this.myAgencies;
    for (let i = 0; i < formArray.controls.length; i++) {
      updatedMyAgencies[i].IsWebPrimary = (this.isWebPrimary.value === formArray.at(i).value.accam) ? true : false;
      updatedMyAgencies[i].NickName = formArray.at(i).value.nickname;
    }
    return updatedMyAgencies;
  }

}
