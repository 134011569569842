// Angular Core
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

// Models
import { Queue } from 'src/app/models/interfaces';
import { QueueTypes } from 'src/app/models/enums';
import { BookingTypes } from '@allianz/agent-max-core-lib';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.scss'],
})
export class PendingComponent{
  @Input() Queues: Queue[] = [];

  constructor(
    private appStateService: AppStateService,
    private router: Router
  ) { }

  /**
   * Modify Pending quote
   * @param {Queue} queueItem
   * @returns {void}
   */
  modifyPending(queueItem: Queue): void {
    this.appStateService.setCurrentBookingId(queueItem.BookingId);
    this.appStateService.setBookingInfoSourceType(BookingTypes.ExistingBooking);
    this.appStateService.setBookingInfoViewType(BookingTypes.Pending);
    this.appStateService.setQueueType(QueueTypes.Pending);
    this.router.navigate(['bookinginfo']);
  }

}
