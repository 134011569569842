import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingService } from '../api/services/loading.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoadingServiceInterceptor implements HttpInterceptor {
    constructor(
        private loadingService: LoadingService
      ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loadingService.open();
    return next.handle(request).pipe(finalize(() => this.loadingService.close()));
  }
}
