"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_field_class_1 = require("./input-field.class");
var AdditionalInformation = /** @class */ (function () {
    function AdditionalInformation() {
        this.TripID = new input_field_class_1.InputField();
        this.AgentID = new input_field_class_1.InputField();
        this.AllowAgentCodeOverride = false;
        this.Notes = new input_field_class_1.InputField();
        this.PartnerPolicyNumbers = '';
        this.PartnerSubmittedPrice = 0;
        this.ValidationMessages = new Array();
    }
    return AdditionalInformation;
}());
exports.AdditionalInformation = AdditionalInformation;
