// Angular Core
import { Component, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { QuoteService } from 'src/app/api/services/quote.service';
import { SharedBusinessLogic } from 'src/app/shared/services/shared-business-logic.service';
import { LoadingService } from 'src/app/api/services/loading.service';

// Models
import { Queue, PrintOrEmailOptions } from 'src/app/models/interfaces';
import { BookingTypes, Booking, BookingAgentInformation } from '@allianz/agent-max-core-lib';
import { PrintOrEmailTypes, QueueTypes, PrintOrEmailDocumentType, PrintOrEmailReportType, PrintOrEmailOfferType } from 'src/app/models/enums';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

// Third Party
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-quote-tab',
  templateUrl: './quote-tab.component.html',
  styleUrls: ['./quote-tab.component.scss']
})
export class QuoteTabComponent implements OnDestroy {
  Queues: Queue[] = [];
  quoteTabSubscriptions: Subscription[] = [];
  bsModalRef: BsModalRef;
  @Output() deleteQuoteEventEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() copyQuoteEventEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private quoteService: QuoteService,
    private appStateService: AppStateService,
    private router: Router,
    private bsModalService: BsModalService,
    private sharedBusinessLogic: SharedBusinessLogic,
    private loadingService: LoadingService
  ) { }

  ngOnDestroy(): void {
    this.quoteTabSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * routes you to the maxmail settings for the given bookingid
   * @param {string} bookingId
   * @returns {void}
   */
  onOpenMaxMailSettings(bookingId: string): void {
    this.appStateService.setCurrentBookingId(bookingId);
    this.router.navigate(['maxmailsettings']);
  }

  /**
   * emits an event to teh parent so it knows to update teh queues list
   * @returns {void}
   */
  onDeleteQuote(): void {
    this.deleteQuoteEventEmitter.emit('onDeleteQuote');
  }

  /**
   * calls a shared business logic service to copy the given quote to a new one.
   * @param {string} BookingId
   * @returns {void}
   */
  onCopyToNewQuote(bookingID: string): void {
    this.quoteTabSubscriptions.push(this.sharedBusinessLogic.copyToNewQuote(bookingID, this.appStateService.getQueueType()));
  }

  /**
   * shows a dialog confirming whther to cancel the quote, then deletes and reloads teh queues if yes
   * @param {string} BookingId
   * @returns {void}
   */
  showDialogCancelConfirm(BookingId: string): void {
    let initialState = { text: 'Are you sure you want to delete this quote?', dialogIndicator: true };
    this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
    const deleteModalSubscription = this.bsModalRef.content.modalResponse.subscribe((modalResponse) => {
      deleteModalSubscription.unsubscribe();
      this.onDeleteQuote();
      if (modalResponse) {
        this.loadingService.open();
        this.quoteTabSubscriptions.push(
          this.quoteService.deleteQuote(BookingId, this.appStateService.getCredentials())
            .subscribe((response) => {
              if (response === true) {
                this.onDeleteQuote();
                initialState = { text: 'Your quote is deleted', dialogIndicator: false };
                this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
              }
            },
            () => this.loadingService.close())
        );
      }
    },
    (error) => { },
    () => this.loadingService.close());

  }

  /**
   * opens a previously saved quote
   * @param {string} BookingId
   * @returns {void}
   */
  onOpenSavedQuote(BookingId: string): void {
    this.appStateService.setBookingInfoViewType(BookingTypes.Quote);
    this.appStateService.setBookingInfoSourceType(BookingTypes.ExistingBooking);
    this.appStateService.setCurrentBookingId(BookingId);
    this.router.navigate(['bookinginfo']);
  }

  /**
   * sends user to print/email said quote
   * @param {Queue} queueItem
   * @returns {void}
   */
  onPrintOrEmailQuote(queueItem: Queue): void {
    const currentBooking = new Booking();
    currentBooking.BookingID = queueItem.BookingId;
    currentBooking.PrimaryInsuredName = queueItem.PrimaryInsured;
    const agentInfo: BookingAgentInformation = this.appStateService.getAgentInformation();
    const options: PrintOrEmailOptions = {
      HeaderName: 'Print Or Email Quote',
      DocumentType: PrintOrEmailDocumentType.BookingDocument,
      ReportType: PrintOrEmailReportType.WebQuoteByAgent,
      BookingId: currentBooking.BookingID,
      OfferType: PrintOrEmailOfferType.FirstChance,
      ShowViewButton: true,
      EmailInput: {
        FromName: `${agentInfo.FirstName} ${agentInfo.LastName}`,
        FromEmailAddress: agentInfo.EmailAddress,
        ToName: '',
        ToEmailAddress: '',
        SecondaryEmailAddress: ''
      }
    };
    this.appStateService.setPrintOrEmailReportName(PrintOrEmailTypes.Quote);
    this.appStateService.setPrintOrEmailData(currentBooking, options);
    this.appStateService.setQueueType(QueueTypes.Quotes);
    this.router.navigate(['printoremail']);
  }

}
