export enum MainNavTabs {
  HomeComponent = 'home',
  AdminComponent = 'admin',
  UserOptionsComponent = 'useroptions',
  ResourceCenterComponent = 'resourcecenter',
  PrintOrEmailComponent = 'printoremail',
  ReportsComponent = 'reports',
  HelpComponent = 'help',
  LoginComponent = 'login'
}
