import {  Booking } from '@allianz/agent-max-core-lib';

export class RosterUploadRequest {
    GroupBooking: Booking;
    FileName: string;
    Base64: string;
    AllowAgencyCreditCard: boolean;
    AllowCashWithheld: boolean;
    MaxBookingsAllowedInGroup: number;

    constructor() {
        this.GroupBooking = new Booking();
    }
}
