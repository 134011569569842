import { TravelInformation, TripInformation } from '@allianz/agent-max-core-lib';

export class SimpleBooking {
    TravelInformation: TravelInformation;
    TripInformation?: TripInformation;
    constructor() {
        this.TravelInformation = new TravelInformation();
        this.TripInformation = new TripInformation();
    }
}
