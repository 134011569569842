// Angular Core
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Models
import { BookingAgentInformation, FileTypes } from '@allianz/agent-max-core-lib';
import { environment } from '../../environments/environment';
import { ResourceBlock } from '../models/interfaces';

// Services
import { AppStateService } from '../shared/services/app-state.service';
import { UniversalConfigService } from '../api/services/universal-config.service';
import { UtilityService } from '../shared/services/utility.service';
import { LoadingService } from '../api/services/loading.service';

@Component({
  selector: 'app-resource-center',
  templateUrl: './resource-center.component.html',
  styleUrls: ['./resource-center.component.scss']
})
export class ResourceCenterComponent implements OnInit {
  adminStatus: boolean;
  agentInfo: BookingAgentInformation;
  agencyHelpPhoneNumberParsed: string;
  resourceBlock: ResourceBlock[];

  constructor(
    private appStateService: AppStateService,
    private utilityService: UtilityService,
    private universalConfigService: UniversalConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.appStateService.isAdminObservable$.subscribe((status) => {
      this.adminStatus = status;
    });
    this.agentInfo = this.appStateService.getAgentInformation();
    this.agencyHelpPhoneNumberParsed = this.agentInfo.AgencyHelpPhoneNumber || environment.DefaultHelpPhoneNumber;
    this.resourceBlock = this.route.snapshot.data.resourceBlocks;
  }

  /**
   * Navigate to the admin page
   * @returns {void}
   */
  toAdmin(): void {
    if (this.adminStatus) {
      this.router.navigate(['admin']);
    }
  }

  /**
   * Get the file for the current resource
   * @param {number} itemId
   * @returns {void}
   */
  getFile(itemId: number): void {
    this.loadingService.open();
    this.universalConfigService.getAgencyResourceItemFileForId(itemId, this.appStateService.getCredentials())
      .subscribe((data) => {
        this.utilityService.openFile(data.FileContent, data.FileExt as FileTypes);
      },
      (error) => { },
      () => this.loadingService.close());
  }

  downloadBlob(href: string, filename: string) {
    var element = document.createElement('a');
    element.setAttribute('href', href);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  getSecureFile(event: any, url: string): void {
    if (this.universalConfigService.isSecureFileURL(url)) {
      event = event || window.event;
      event.preventDefault();
      this.loadingService.open();
      this.universalConfigService.getSecureFile(url, this.appStateService.getCredentials()).subscribe((data) => {
        const urlToOpen = window.URL.createObjectURL(data);
        if (data.type === 'application/octet-stream') {
          this.downloadBlob(urlToOpen, url.split("/").pop());
        } else {
          let tab = window.open();
          tab.location.href = urlToOpen;
        }
        this.loadingService.close();
      },
        (error) => {
          console.log(error);
          this.loadingService.close();
        }
      );
    }
  }
}
