export enum AgentBusinessType {
    Unknown = 0,
    Cruise = 1,
    CruiseAndTour = 2,
    TourAndPackages = 3,
    Group = 4,
    Golf = 5,
    Ski = 6,
    Corporate = 7
}
