"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var confirmation_details_class_1 = require("./confirmation-details.class");
var billing_information_class_1 = require("./billing-information.class");
var OrderInformation = /** @class */ (function () {
    function OrderInformation() {
        this.IsPriceChanged = undefined;
        this.IsPurchaseConfirmed = undefined;
        this.IsCanceled = undefined;
        this.Price = undefined;
        this.ValidationMessages = new Array();
        this.PaymentMethod = undefined;
        this.IsCashWithHeldEnabled = undefined;
        this.IsCashWithHeldVisible = undefined;
        this.IsConfirmationEmailedToAgent = undefined;
        this.AgentEmail = undefined;
        this.ConfirmationDetails = new confirmation_details_class_1.ConfirmationDetails();
        this.BillingInformation = new billing_information_class_1.BillingInformation();
    }
    return OrderInformation;
}());
exports.OrderInformation = OrderInformation;
