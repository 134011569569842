"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_field_class_1 = require("./input-field.class");
var Address = /** @class */ (function () {
    function Address() {
        this.Address1 = new input_field_class_1.InputField();
        this.Address2 = new input_field_class_1.InputField();
        this.City = new input_field_class_1.InputField();
        this.State = new input_field_class_1.InputField();
        this.CountryCode = new input_field_class_1.InputField();
        this.ZipCode = new input_field_class_1.InputField();
    }
    return Address;
}());
exports.Address = Address;
