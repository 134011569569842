// Angular Core
import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

// Models
import { BookingAgentInformation, BookingTypes, Booking } from '@allianz/agent-max-core-lib';

// Services
import { AppStateService } from '../../services/app-state.service';

// Third Party
import { AccordionConfig } from 'ngx-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// TODO: remove and use whats in the utility service
export function getAccordionConfig(): AccordionConfig {
  return Object.assign(new AccordionConfig(), { isAnimated: true });
}

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
  providers: [{ provide: AccordionConfig, useFactory: getAccordionConfig }]
})

// MAY HAVE TO ADD OnChanges back to implements
export class AdditionalInformationComponent implements OnInit {
  isAdmin: boolean = true;
  TripID: FormControl = new FormControl('');
  AgentID: FormControl = new FormControl('');
  waiverPrice: FormControl = new FormControl(0);
  form: FormGroup;
  @Input('booking') booking: Booking;
  @Input('allowBookingAgentCodeOverride') allowBookingAgentCodeOverride: boolean = false;
  // TODO - WHY ARE WE INPUTTING THIS VALUE FROM PARENT
  // @Input('agentId') agentId: string;
  isWaiverProduct: boolean = false;
  isSmallGroup: boolean = false;
  isOpen: boolean = true;
  agentInformation: BookingAgentInformation;
  agentId: string;

  private readonly onDestroy: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private router: Router
  ) {
    this.agentInformation = this.appStateService.getAgentInformation();

    this.isSmallGroup = (this.router.url.indexOf('/small-group') !== -1);
    this.form = this.formBuilder.group({
      TripID: this.TripID,
      AgentID: this.AgentID,
      WaiverPrice: this.waiverPrice
    });
  }

  ngOnInit(): void {
    this.appStateService.isAdminObservable$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((val) => {
        this.isAdmin = val;
      });
    if (this.isSmallGroup) {
      this.form.patchValue({
        AgentID: this.booking.AdditionalInformation.AgentID.Value
      });
    } else {
      this.isWaiverProduct = this.booking.ProductInformation.ProgramProducts[0].Product.IsWaiverProduct;
      this.form.patchValue({
        TripID: this.booking.AdditionalInformation.TripID.Value,
        AgentID: this.booking.AdditionalInformation.AgentID.Value,
        WaiverPrice: this.booking.ProductInformation.ProgramProducts[0].Product.WaiverPrice
      });
    }
    if (!this.allowBookingAgentCodeOverride) {
      this.AgentID.disable();
    }
  }

  // TODO - MAYBE REMOVE - If Agent is READONLY WHY CHANGE ITS DISPLAY?
  // ngOnChanges(changes: SimpleChanges): void {
  //   if (this.isSmallGroup) {
  //     this.form.patchValue({
  //       AgentID: this.agentId = changes.agentId.currentValue
  //     });
  //   }
  // }

  /**
   * Checks if is disabled
   * @returns {boolean}
   */
  isDisabled(): boolean {
    return this.appStateService.getBookingInfoViewType() === BookingTypes.Pending;
  }

  /**
   * Accordion is open change function
   * @param {boolean} open
   * @returns {void}
   */
  isOpenChange(open: boolean): void {
    this.isOpen = open;
  }
}
