export class HelpDataAbout {
    AgentName: string;
    ACCAM: string;
    AgentEmail: string;
    AgentMaxVersion: string;
    BrowserName: string;
    BrowserVersion: string;
    OperatingSystem: string;
    AgentAccountID: number;
    TimeStamp: Date;

    constructor() {
        this.AgentName = '';
        this.ACCAM = '';
        this.AgentEmail = '';
        this.AgentMaxVersion = '2.2';
        this.BrowserName = '';
        this.BrowserVersion = '';
        this.OperatingSystem = '';
        this.AgentAccountID = null;
        this.TimeStamp = new Date();
    }
}
