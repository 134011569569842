export enum BookingStatus {
    NewBooking = 'Quote',
    SavedQuote = 'Quote',
    DeletedBooking = '--Deleted--',
    PurchaseRejected = 'Purchase Rejected',
    PurchaseConfirmed = 'Confirmed',
    PurchaseCanceled = 'Canceled',
    PurchasePending = 'Purchase Pending',
    CancelPending = 'Cancel Pending',
    CancelRejected = 'Error: Cancel Rejected',
    ModifyPending = 'Modify Pending',
    ModifyRejected = 'Error: Modify Rejected',
    PNRExportPending = 'PNR Pending - Confirmed',
    TransmissionAcknowledgementPending = 'Trans Ack Pending',
    OriginalBookingPurchaseConfirmed = 'Orig Booking Confirmed',
    CanceledPNRExportPending = 'PNR Pending - Canceled',
    ModifiedPNRExportPending = 'PNR Pending - Modified',
    ModifyRejectionAcknowledged = 'Modify Reject Acked',
    Invalid = 'Invalid',
    OriginalBookingModifyCancel = 'Orig Booking Mod Cxl',
    GroupQuoteIncomplete = 'Incomplete Record',
    GroupQuoteReadyForPurchase = 'Ready For Purchase',
    GroupBooking = 'Group',
    GroupPurchasePending = 'Group Purchase Pending',
    GroupPurchaseFailed = 'Last Purchase Failed',
    DeletedGroupBooking = 'Deleted Group',
    GroupQuotePurchaseSubmitted = 'Purchase Submitted',
    PaymentRecord = 'Payment Record',
    SavedGDSQuote = 'Quote',
    SavedApiDataPassQuote = 'Quote'
}
