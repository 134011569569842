export enum PrintOrEmailReportType {
    WebQuoteByCustomer = 'WebQuoteByCustomer',
    WebQuoteByAgent = 'WebQuoteByAgent',
    ClientGeneratedHtmlDocument = 'ClientGeneratedHtmlDocument',
    HelpRequest = 'HelpRequest',
    TravelInsuranceReceipt = 'TravelInsuranceReceipt',
    TravelInsuranceWaiver = 'TravelInsuranceWaiver',
    TravelInsuranceQuote = 'TravelInsuranceQuote',
    BookingLevelSample = 'BookingLevelSample',
    RosterTemplate = 'RosterTemplate',
    RosterInstructions = 'RosterInstructions'
}
