// Angular Core
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Models
import {
  UserInformation,
  InterfaceSettings,
  AgenciesForAdministration,
  AgencySettings,
  AgencyGroupsForAgency,
  AgencyGroup,
  AdminProducts,
  AgentAdminSettingsResponse,
  AgentAdminSettingGridbags,
  AgentAdminSettingGridbag,
  CreateAdminAgentRequest,
  CreateAdminAgentResponse,
  AdminUserOptions,
  AgentAdminSettingGridbagsWithPending,
  AgentProfile,
  AdminAgentProfile,
  AdminAddressAndLogo,
  AdminInterfaceSettings,
  AdminSaveAgencies,
  AdminMaxMailSecondChance,
  MyAgenciesResponse,
  AgencyAddressAndLogo,
  AddressAndLogo
} from 'src/app/models/interfaces';
import { DefaultCreateALinkSettings, CreateALinkSettingsResponse, MaxMail2ndChanceSettings, KeyValue, ProgramProduct } from '@allianz/agent-max-core-lib';
import { environment } from 'src/environments/environment';

// Services
import { UtilityService } from 'src/app/shared/services/utility.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';

// Third party
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { publishLast, refCount } from 'rxjs/operators';
import { AgentAdminSearchRequest } from '../../models/interfaces/agent-admin-search-request.interface';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {
  adminUserServiceEndpoint: string;
  userInfo: UserInformation;

  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService,
    private appStateService: AppStateService
  ) {
    this.userInfo = this.appStateService.getCredentials();
    this.adminUserServiceEndpoint = this.utilityService.setTheDomainForAPICalls(environment.AdminUserService);
  }

  public GetIsAgentMaxAdmin(userInfo: UserInformation): Observable<any> {
    return this.httpClient.get<any>(`${this.adminUserServiceEndpoint}/IsAgentMaxAdmin`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }


  /**
   * gets a list of selling roles for a new agent
   * @return {Observable<KeyValue>}
   */
  public getSellingRoles(): Observable<KeyValue[]> {
    return this.get<KeyValue[]>(`${this.adminUserServiceEndpoint}/SellingRoles`, true, 'SELLING_ROLES');
  }
  /**
   * gets a agency address and logo fro agency id
   * @return {Observable<KeyValue>}
   */
  public getAgencyAddressAndLogo(agencyId: number, userInfo: UserInformation): Observable<AddressAndLogo> {
    return this.httpClient.get<AddressAndLogo>(`${this.adminUserServiceEndpoint}/AgencyAddressAndLogo/${agencyId}`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }
  /**
   * gets a list of reporting rights for a new agent
   * @return {Observable<KeyValue>}
   */
  public getReportingRights(): Observable<KeyValue[]> {
    return this.get<KeyValue[]>(`${this.adminUserServiceEndpoint}/ReportingRights`, true, 'REPORTING_RIGHTS');
  }
  /**
   * gets a list of interface optionS management.
   * @return {Observable<KeyValue>}
   */
  public getInterfaceOptions(): Observable<KeyValue[]> {
    return this.get<KeyValue[]>(`${this.adminUserServiceEndpoint}/InterfaceOptionsSourceType`, true, 'INTERFACE_OPTIONS_SOURCE_TYPE');
  }
  /**
   * gets a list of Address Options Source Type for a new agent
   * @return {Observable<KeyValue>}
   */
  public getAddressOptionsSourceType(): Observable<KeyValue[]> {
    return this.get<KeyValue[]>(`${this.adminUserServiceEndpoint}/AddressOptionsSourceType`, true, 'ADDRESS_OPTIONS_SOURCE_TYPE');
  }
  /**
   * gets a list of Agent MaxMail options management.
   * @return {Observable<KeyValue>}
   */
  public getSCByMaxMailOptionsSourceType(): Observable<KeyValue[]> {
    return this.get<KeyValue[]>(`${this.adminUserServiceEndpoint}/SCByMaxMailOptionsSourceType`, true, 'MAX_MAIL_OPTIONS_SOURCE_TYPE');
  }
  /**
   * gets a list of administrative roles for a new agent
   * @return {Observable<KeyValue>}
   */
  public getAdministrativeRoles(): Observable<KeyValue[]> {
    return this.get<KeyValue[]>(`${this.adminUserServiceEndpoint}/AdministrativeRoles`, true, 'ADMINISTRATIVE_ROLES');
  }

  /**
   * gets a list of agencies with info for the admin tab based on the user
   * @return {Observable<AgenciesForAdministration>} -
   */
  public getAgenciesForAdministration(): Observable<AgenciesForAdministration> {
    return this.get<AgenciesForAdministration>(`${this.adminUserServiceEndpoint}/GetAgenciesForAdminstration`, false, 'AGENCIES_ADMIN');
  }

  /**
   * @deprecated you should used getAgenciesForAdministration instead
   */
  // TODO: Figure out the difference here
  public GetAgenciesForAdministration(userInfo: UserInformation): Observable<AgenciesForAdministration> {
    return this.httpClient.get<AgenciesForAdministration>(`${this.adminUserServiceEndpoint}/GetAgenciesForAdminstration`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * Creates an agent through administration path
   * @param adminAgentRequest {Observable<CreateAdminAgentResponse>}
   */
  public createAdminAgent(adminAgentRequest: CreateAdminAgentRequest): Observable<CreateAdminAgentResponse> {
    return this.httpClient.put<CreateAdminAgentResponse>(`${this.adminUserServiceEndpoint}/CreateAgent`, adminAgentRequest, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * gets a list of agency groups for the user's admin agency
   * @return {Observable<AgencyGroupsForAgency>} - subscription to the request
   */
  public getAgencyGroupsForAgency(): Observable<AgencyGroupsForAgency> {
    return this.get<AgencyGroupsForAgency>(`${this.adminUserServiceEndpoint}/GetAgencyGroup/GridBagsForAgency/${this.userInfo.AgencyID}`, false, 'AGENCY_GROUPS_ADMIN');
  }
  /**
   * delete a list of agency groups for the user's admin agency
   * from cache
   */
  public deleteCachedAgencyGroupsForAgency(): void {
    this.appStateService.setCacheFromNetwork(null, 'AGENCY_GROUPS_ADMIN');
  }
  /**
   * Updates the agent's profile and preferences, returning an agent profile.  An admin updates an agent within their agency.
   * @param {AdminAgentProfile} adminAgentRequest - admin agent request object being passed to the back end
   * @return {Observable<AgentProfile>} - subscription to the request
   */
  public updateAgentProfileAndPreferences(adminAgentRequest: AdminAgentProfile): Observable<AgentProfile> {
    return this.httpClient.put<AgentProfile>(`${this.adminUserServiceEndpoint}/SaveAgentProfile`, adminAgentRequest, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * Updates the agent's address and logo, returning an address and logo object.  An admin updates an agent within their agency.
   * @param {AdminAddressAndLogo} adminAddressAndLogoRequest - admin address request object being passed to the back end
   * @return {Observable<AdminAddressAndLogo>} - subscription to the request
   */
  public updateAgentAddressAndLogo(adminAddressAndLogoRequest: AdminAddressAndLogo): Observable<AdminAddressAndLogo> {
    return this.httpClient.put<AdminAddressAndLogo>(`${this.adminUserServiceEndpoint}/SaveAddressAndLogo`, adminAddressAndLogoRequest, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }
  /**
   * Updates the agency address and logo, returning an address and logo object
   * @param {AgencyAddressAndLogo} agencyAddressAndLogo - admin agency address Babd logo request object being passed to the back end
   * @return {Observable<AgencyAddressAndLogo>} - subscription to the request
   */
  public UpdateAgencyAddressAndLogo(agencyAddressAndLogo: AgencyAddressAndLogo): Observable<AddressAndLogo> {
    return this.httpClient.put<AddressAndLogo>(`${this.adminUserServiceEndpoint}/UpdateAgencyAddressAndLogo`, agencyAddressAndLogo, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * Updates the agent's maxmail settings.  An admin updates an agent within their agency.
   * @param {AdminMaxMailSecondChance} adminMaxMailRequest - admin address request object being passed to the back end
   * @return {Observable<AdminMaxMailSecondChance>} - subscription to the request
   */
  public updateAgentMaxmailSettings(adminMaxMailRequest: AdminMaxMailSecondChance): Observable<AdminMaxMailSecondChance> {
    return this.httpClient.put<AdminMaxMailSecondChance>(`${this.adminUserServiceEndpoint}/SaveMaxmailSettings`, adminMaxMailRequest, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * Updates the agent's agencies.  An admin updates an agent within their agency.
   * @param {AdminSaveAgencies} adminAgenciesRequest - admin address request object being passed to the back end
   * @return {Observable<MyAgenciesResponse>} - subscription to the request
   */
  public updateAgentAgencies(adminAgenciesRequest: AdminSaveAgencies): Observable<MyAgenciesResponse> {
    return this.httpClient.put<MyAgenciesResponse>(`${this.adminUserServiceEndpoint}/SaveAgencyAccounts`, adminAgenciesRequest, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * Updates the agent's interface settings.  An admin updates an agent within their agency.
   * @param {AdminInterfaceSettings} adminInterfaceSettingsRequest - admin address request object being passed to the back end
   * @return {Observable<AdminInterfaceSettings>} - subscription to the request
   */
  public updateAgentInterfaceSettings(adminInterfaceSettingsRequest: AdminInterfaceSettings): Observable<AdminInterfaceSettings> {
    return this.httpClient.put<AdminInterfaceSettings>(`${this.adminUserServiceEndpoint}/SaveInterfaceSettings`, adminInterfaceSettingsRequest, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * updates a list of product groups for the user's admin agency
   * @param {AgencyGroup[]} agencyGroups - list of agency groups for the request
   * @return {Observable<AgencyGroupsForAgency>} - subscription to the request
   */
  public updateProductGroupsForAgency(agencyGroups: AgencyGroup[]): Observable<AgencyGroupsForAgency> {
    const agencyGroupsForAgency: AgencyGroupsForAgency = {
      ErrorMessages: [],
      IsValid: true,
      Message: '',
      AgencyId: this.userInfo.AgencyID.toString(),
      TransactionNumber: null,
      TransactionStatus: null,
      AgencyGroupList: agencyGroups
    };
    return this.httpClient.put<AgencyGroupsForAgency>(`${this.adminUserServiceEndpoint}/UpdateAgencyGroups/Products`, agencyGroupsForAgency, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * updates a list of interface groups for the user's admin agency
   * @param {AgencyGroup[]} agencyGroups - list of agency groups for the request
   * @return {Observable<AgencyGroupsForAgency>} - subscription to the request
   */
  public updateInterfaceGroupsForAgency(agencyGroups: AgencyGroup[]): Observable<AgencyGroupsForAgency> {
    const agencyGroupsForAgency: AgencyGroupsForAgency = {
      ErrorMessages: [],
      IsValid: true,
      Message: '',
      AgencyId: this.userInfo.AgencyID.toString(),
      TransactionNumber: null,
      TransactionStatus: null,
      AgencyGroupList: agencyGroups
    };
    return this.httpClient.put<AgencyGroupsForAgency>(`${this.adminUserServiceEndpoint}/UpdateAgencyGroups/InterfaceSettings`, agencyGroupsForAgency, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * updates a list of agency settings groups for the user's admin agency
   * @param {AgencyGroup[]} agencyGroups - list of agency groups for the request
   * @return {Observable<AgencyGroupsForAgency>} - subscription to the request
   */
  public updateAgencySettingsGroupsForAgency(agencyGroups: AgencyGroup[]): Observable<AgencyGroupsForAgency> {
    const agencyGroupsForAgency: AgencyGroupsForAgency = {
      ErrorMessages: [],
      IsValid: true,
      Message: '',
      AgencyId: this.userInfo.AgencyID.toString(),
      TransactionNumber: null,
      TransactionStatus: null,
      AgencyGroupList: agencyGroups
    };
    return this.httpClient.put<AgencyGroupsForAgency>(`${this.adminUserServiceEndpoint}/UpdateAgencyGroups/AgencySettings`, agencyGroupsForAgency, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * updates a list of visibility groups for the user's admin agency
   * @param {AgencyGroup[]} agencyGroups - list of agency groups for the request
   * @return {Observable<AgencyGroupsForAgency>} - subscription to the request
   */
  public updateVisibilityGroupsForAgency(agencyGroups: AgencyGroup[]): Observable<AgencyGroupsForAgency> {
    const agencyGroupsForAgency: AgencyGroupsForAgency = {
      ErrorMessages: [],
      IsValid: true,
      Message: '',
      AgencyId: this.userInfo.AgencyID.toString(),
      TransactionNumber: null,
      TransactionStatus: null,
      AgencyGroupList: agencyGroups
    };
    return this.httpClient.put<AgencyGroupsForAgency>(`${this.adminUserServiceEndpoint}/UpdateAgencyGroups/VisibilityGroups`, agencyGroupsForAgency, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * updates a list of agent settings groups for the user's admin agency
   * @param {AgencyGroup[]} agencyGroups - list of agency groups for the request
   * @return {Observable<AgencyGroupsForAgency>} - subscription to the request
   */
  public updateAgentSettingsGroupsForAgency(agencyGroups: AgencyGroup[]): Observable<AgencyGroupsForAgency> {
    const agencyGroupsForAgency: AgencyGroupsForAgency = {
      ErrorMessages: [],
      IsValid: true,
      Message: '',
      AgencyId: this.userInfo.AgencyID.toString(),
      TransactionNumber: null,
      TransactionStatus: null,
      AgencyGroupList: agencyGroups
    };
    return this.httpClient.put<AgencyGroupsForAgency>(`${this.adminUserServiceEndpoint}/UpdateAgencyGroups/AgentSettings`, agencyGroupsForAgency, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * Get Agent Information by user
   * @param {number} accountId - The user information object
   * @returns {<Observable{AdminUserOptions}>}
   */
  public getUserOptions(accountId: number): Observable<AdminUserOptions> {
    return this.httpClient.get<AdminUserOptions>(`${this.adminUserServiceEndpoint}/GetUserOptions/${accountId}`, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * updates a list of maxmail settings groups for the user's admin agency
   * @param {AgencyGroup[]} agencyGroups - list of agency groups for the request
   * @return {Observable<AgencyGroupsForAgency>} - subscription to the request
   */
  public updateMaxmailSettingsGroupsForAgency(agencyGroups: AgencyGroup[]): Observable<AgencyGroupsForAgency> {
    const agencyGroupsForAgency: AgencyGroupsForAgency = {
      ErrorMessages: [],
      IsValid: true,
      Message: '',
      AgencyId: this.userInfo.AgencyID.toString(),
      TransactionNumber: null,
      TransactionStatus: null,
      AgencyGroupList: agencyGroups
    };
    return this.httpClient.put<AgencyGroupsForAgency>(`${this.adminUserServiceEndpoint}/UpdateAgencyGroups/MaxMailSettings`, agencyGroupsForAgency, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * gets the default agency settings for a given agency for the admin tab
   * @param {number} agencyId
   * @return {Observable<AgencySettings>} -
   */
  public getAgencySettings(agencyId: number, userInfo: UserInformation): Observable<AgencySettings> {
    return this.httpClient.get<AgencySettings>(`${this.adminUserServiceEndpoint}/GetAgencySettings/${agencyId}`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * gets the default CAL settings for a given agency for the admin tab
   * @param {number} agencyId
   * @param {UserInformation} userInfo - User Credentials object
   * @return {Observable<CreateALinkSettingsResponse>}
   */
  public getDefaultCreateALinkSettings(agencyId: number, userInfo: UserInformation): Observable<CreateALinkSettingsResponse> {
    return this.httpClient.get<CreateALinkSettingsResponse>(`${this.adminUserServiceEndpoint}/GetDefaultCreateALinkSettings/${agencyId}`, { headers: this.utilityService.getServiceHeaders(userInfo) }).pipe(publishLast(), refCount());
  }

  /**
   * saves the provided agencySettings object for the administration
   * @param {AgencySettings} agencySettings - User Credentials object
   * @param {UserInformation} userInfo - User Credentials object
   * @return {Observable<AgencySettings>} - Interface Settings Model
   */
  public saveAgencySettings(agencySettings: AgencySettings, userInfo: UserInformation): Observable<AgencySettings> {
    return this.httpClient.put<AgencySettings>(`${this.adminUserServiceEndpoint}/SaveAgencySettings`, agencySettings, { headers: this.utilityService.getServiceHeaders(userInfo) }).pipe(publishLast(), refCount());
  }

  /**
   * saves the provided creaeALinkSettings object for the administration
   * @param {UserInformation} userInfo - User Credentials object
   * @param {DefaultCreateALinkSettings} createALinkSettings - User Credentials object
   * @return {Observable<DefaultCreateALinkSettings>} - Interface Settings Model
   */
  public saveCreateALinkSettings(createALinkSettings: DefaultCreateALinkSettings, userInfo: UserInformation): Observable<DefaultCreateALinkSettings> {
    return this.httpClient.put<DefaultCreateALinkSettings>(`${this.adminUserServiceEndpoint}/SaveCreateALinkSettings`, createALinkSettings, { headers: this.utilityService.getServiceHeaders(userInfo) }).pipe(publishLast(), refCount());
  }

  /**
   * Gets information for the interface settings tab
   * @param {UserInformation} userInfo - User Credentials object
   * @param {number} agencyId
   * @return {Observable<InterfaceSettings>} - Interface Settings Model
   */
  public getGDSInterfaceSettings(agencyId: number, userInfo: UserInformation): Observable<InterfaceSettings> {
    return this.httpClient.get<InterfaceSettings>(`${this.adminUserServiceEndpoint}/GetGDSInterfaceSettings/${agencyId}`, { headers: this.utilityService.getServiceHeaders(userInfo) }).pipe(publishLast(), refCount());
  }

  /**
   * Gets the list of products, both selected and available products, for the Admin agency
   * @param {number} agencyId - agency ID
   * @param {UserInformation} userInfo user credentials object
   * @return {Observable<AdminProducts>} - Admin Products Model
   */
  public getProductsForAdmin(agencyId: number, userInfo: UserInformation): Observable<AdminProducts> {
    return this.httpClient.get<AdminProducts>(`${this.adminUserServiceEndpoint}/GetAgencyConfig/AgencyProductBag/${agencyId}`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * Updates the list of products, both selectd and available products, for the Admin agency
   * @param {AdminProducts} agencyInfo
   * @return {Observable<AdminProducts>} - Admin Products Model
   */
  public updateProductsForAdmin(agencyInfo: AdminProducts): Observable<AdminProducts> {
    return this.httpClient.put<AdminProducts>(`${this.adminUserServiceEndpoint}/UpdateAgencyConfig/AgencyProductBag`, agencyInfo, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * Gets the default agent admin settings for the agency or for the agent settings group the agency is in
   * @param {number} agencyId - ID for the agency
   * @return {Observable<AgentAdminSettingsResponse>} - Default Agent Admin Settings Model
   */
  public getDefaultAgentAdminSettingsGridBag(agencyId: number): Observable<AgentAdminSettingsResponse> {
    return this.httpClient.get<AgentAdminSettingsResponse>(`${this.adminUserServiceEndpoint}/GetAgencyConfig/DefaultAgentAdminSettingsGridBag/${agencyId}`, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * Updates the default agent admin settings for an agency or an agent setttings group
   * @param {AgentAdminSettingsResponse} defaultAgentAdminSettingsGridBag - The model that represents default agent settings
   * @return {Observable<AgentAdminSettingsResponse>} - Default agent admin settings response model
   */
  public updateDefaultAgentAdminSettingsGridBag(defaultAgentAdminSettingsGridBag: AgentAdminSettingsResponse): Observable<AgentAdminSettingsResponse> {
    return this.httpClient.put<AgentAdminSettingsResponse>(`${this.adminUserServiceEndpoint}/UpdateAgencyConfig/DefaultAgentAdminSettings`, defaultAgentAdminSettingsGridBag, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * Gets all of the agent profiles for the Agency as a AgentAdminSettingsGridBags object
   * @param {number} agencyId The agencyId for all the agent profiles in the response
   * @param {number} recordType Represents whether to search for all agents - RecordType = 0, active agents RecordType = 2, or inactive agents RecordType = 1
   * @return {Observable<AgentAdminSettingGridbagsWithPending>} - Model for An AgentAdminSettingsGridbags which holds information on
   * all the agent profiles for the agency
   */
  public getAgentAdminSettingsGridBagsForAgency(agentAdminSearchRequest: AgentAdminSearchRequest): Observable<AgentAdminSettingGridbagsWithPending> {
    return this.httpClient.put<AgentAdminSettingGridbagsWithPending>(`${this.adminUserServiceEndpoint}/GetAgentAdminSettings/GridBagForAgency`, agentAdminSearchRequest, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * Updates agent settings for a single agent's profile
   * @param {AgentAdminSettingGridbag} agentSettings - Represents an agent profile containing the agents data fields
   * @return {Observable<AgentAdminSettingGridbag>} - AgentAdminSettingGridbag model
   */
  public updateAdminSettingsGridBagForAgent(agentSettings: AgentAdminSettingGridbag): Observable<AgentAdminSettingGridbag> {
    return this.httpClient.put<AgentAdminSettingGridbag>(`${this.adminUserServiceEndpoint}/UpdateAdminSettings/GridBagForAgent/`, agentSettings, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * Updates an array of agent profiles whose settings have changed
   * @param {AgentAdminSettingGridbag} agentSettings - Represents an agent profile containing the agents data fields
   * @return {Observable<AgentAdminSettingGridbags>} - AgentAdminSettingGridbags model that contains a list of agent profile settings
   */
  public updateAdminSettingsGridBagForAgentList(agentSettings: AgentAdminSettingGridbag[]): Observable<AgentAdminSettingGridbags> {
    return this.httpClient.put<AgentAdminSettingGridbags>(`${this.adminUserServiceEndpoint}/UpdateAdminSettings/GridBagForAgentList/`, agentSettings, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * Gets the max mail settings for a specific agency or group
   * @param {number} agencyId - the ID for the agency
   * @return {Observable<MaxMail2ndChanceSettings>} Max mail settings model
   */
  public getAgencyConfigAgencyMaxMailSettingsBag(agencyId: number): Observable<MaxMail2ndChanceSettings> {
    return this.httpClient.get<MaxMail2ndChanceSettings>(`${this.adminUserServiceEndpoint}/GetAgencyConfig/MaxMailSettings/${agencyId}`, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * Updates the max mail settings for an agency or group depending on if the agency is apart of a maxmail settings group
   * @param {MaxMail2ndChanceSettings} maxMail2ndChanceSettings - the maxmail settings for the agency that are being updated
   * @param {number} agencyId - the ID for the agency
   * @return {Observable<MaxMail2ndChanceSettings>} Max mail settings model
   */
  public UpdateAgencyConfigAgencyMaxMailSettingsBag(maxMail2ndChanceSettings: MaxMail2ndChanceSettings, agencyId: string): Observable<MaxMail2ndChanceSettings> {
    return this.httpClient.put<MaxMail2ndChanceSettings>(`${this.adminUserServiceEndpoint}/UpdateAgencyConfig/AgencyMaxMailSettingsBag/${agencyId}`, maxMail2ndChanceSettings, { headers: this.utilityService.getServiceHeaders(this.userInfo) });
  }

  /**
   * Saves information for the interface settings tab
   * @param {UserInformation} userInfo - User Credentials object
   * @param {number} agencyId
   * @return {Observable<InterfaceSettings>} - Interface Settings Model
   */
  public saveGDSInterfaceSettings(interfaceSettings: InterfaceSettings, userInfo: UserInformation): Observable<InterfaceSettings> {
    return this.httpClient.put<InterfaceSettings>(`${this.adminUserServiceEndpoint}/SaveGDSInterfaceSettings`, interfaceSettings, { headers: this.utilityService.getServiceHeaders(userInfo) }).pipe(publishLast(), refCount());
  }

  private get<T>(url: string, cache: boolean, networkKey?: string): Observable<T> {
    this.userInfo = this.appStateService.getCredentials();
    if (!cache) {
      return this.httpClient.get<T>(url, { headers: this.utilityService.getServiceHeaders(this.userInfo) }).pipe(publishLast(), refCount());
    }
    const cacheCheck = this.appStateService.getCacheFromNetwork<T>(networkKey);
    if (cacheCheck) { return of(cacheCheck); }
    return this.httpClient.get<T>(url, { headers: this.utilityService.getServiceHeaders(this.userInfo) }).pipe(
      tap({ next: (val: T) => { this.appStateService.setCacheFromNetwork(val, networkKey); } })
    );
  }

  /**
   * Gets the details for a given programProductId
   * @param {UserInformation} userInfo - User Credentials object
   * @param {number} programProductId - the ID for the selected program product
   * @return {Observable<ProgramProduct>} ProgramProduct settings model
   */
   public getProgramProductDetails(programProductIds: number[], userInfo: UserInformation): Observable<ProgramProduct> {
    return this.httpClient.put<ProgramProduct>(`${this.adminUserServiceEndpoint}/GetProgramProducts`,programProductIds, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

}
