"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BookingTypes;
(function (BookingTypes) {
    BookingTypes["Quote"] = "Quote";
    BookingTypes["NewBooking"] = "NewBooking";
    BookingTypes["Modify"] = "Modify";
    BookingTypes["ModifyPolicy"] = "ModifyPolicy";
    BookingTypes["Policy"] = "Policy";
    BookingTypes["Cancel"] = "Cancel";
    BookingTypes["EditFlights"] = "EditFlights";
    BookingTypes["Pending"] = "Pending";
    BookingTypes["ExistingBooking"] = "ExistingBooking";
    BookingTypes["CopiedBooking"] = "CopiedBooking";
})(BookingTypes = exports.BookingTypes || (exports.BookingTypes = {}));
