// Angular components
import { Component } from '@angular/core';

// Models
import {  DefaultAgentProfile } from 'src/app/models/interfaces';
import { UserProfileRoute } from 'src/app/models/enums';

@Component({
  selector: 'app-create-agent',
  templateUrl: './create-agent.component.html',
  styleUrls: ['./create-agent.component.scss']
})
export class CreateAgentComponent {
  agentProfile: DefaultAgentProfile;
  adminRouteType: UserProfileRoute = UserProfileRoute.AdminCreateAgent;
}
