// Angular Core
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

// Services
import { UserService } from '../api/services/user.service';
import { AppStateService } from '../shared/services/app-state.service';

// Models
import { AgentProfile, ValidateSecurityModel } from '../models/interfaces';
import { BookingAgentInformation, ErrorMessage } from '@allianz/agent-max-core-lib';
import { WebAccountType, AgentEmailType } from '../models/enums';
import { LoadingService } from '../api/services/loading.service';

@Component({
  selector: 'app-validate-security',
  templateUrl: './validate-security.component.html',
  styleUrls: ['./validate-security.component.scss']
})
export class ValidateSecurityComponent implements OnInit {

  webAccountType: typeof WebAccountType = WebAccountType;
  agentEmailType: typeof AgentEmailType = AgentEmailType;
  agentProfile: AgentProfile;
  validateSecurityForm: FormGroup;
  subscriptions: Subscription[] = [];
  reminderQuestion: string;
  reminderAnswer: string = '';
  tokenValidationMessages: ErrorMessage[] = [];
  securityValidationMessages: ErrorMessage[] = [];
  token: string;
  response: ValidateSecurityModel;
  AgentInformation: BookingAgentInformation;
  canManageGroups: boolean = false;
  
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private appStateService: AppStateService,
    private router: Router,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService
  ) {
    this.validateSecurityForm = this.formBuilder.group({
      reminderAnswer: this.reminderAnswer
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('pendingemailtoken');
    if (this.token) {
      this.appStateService.setEmailToken(this.token);
      this.getForgotPasswordInfo(this.token);
    } else {
      this.token = this.appStateService.getEmailToken();
      this.agentProfile = this.appStateService.getAgentProfile();
      this.reminderQuestion = this.agentProfile.Security.ReminderQuestion.Value;
    }
  }

  /**
   * gets the information needed for the security question flow based on
   * the token from the users email/query string parameters
   * @param {string} pendingemailtoken
   * @return {void}
   */
  getForgotPasswordInfo(pendingemailtoken: string): void {
    this.loadingService.open();
    this.subscriptions.push(
      this.userService.getForgotPasswordInfo(pendingemailtoken)
        .subscribe((response) => {
          this.response = response;
          if (response.IsValid) {
            this.appStateService.setAgentProfile(response.AgentProfile);
            this.reminderQuestion = response.AgentProfile.Security.ReminderQuestion.Value;
            switch (response.WebAccountTypeCode) {
              case this.webAccountType.ExistingUser:
                if (response.EmailTypeCode === this.webAccountType.ExistingUser) {
                  this.router.navigate(['validatesecurity']);
                } else if (response.EmailTypeCode === this.webAccountType.NewUser) {
                  this.router.navigate(['userprofile']);
                } else if (response.EmailTypeCode === this.webAccountType.AlreadyValidated) {
                  // Admin Approval- dont do anything, appropriate messages are configured in HTML
                  this.setUserDataInAppState();
                } else {
                  this.router.navigate(['login']);
                }
                break;
              case this.webAccountType.NewUser:
                if (response.EmailTypeCode === this.agentEmailType.CreateAccount || response.EmailTypeCode === this.agentEmailType.ForgotPasswordByAgent || response.EmailTypeCode === this.agentEmailType.ForgotPasswordByAdmin) {
                  this.router.navigate(['userprofile']);
                }
                break;
              case this.webAccountType.AlreadyValidated:
                if (response.EmailTypeCode === this.agentEmailType.AdminApprovalForAccess) {
                  // Admin Approval- dont do anything, appropriate messages are configured in HTML
                  this.setUserDataInAppState();
                } else {
                  this.router.navigate(['login']);
                }
                break;
            }
          } else {
            this.tokenValidationMessages = response.ErrorMessages;
          }
        },
        (error) => { },
        () => this.loadingService.close())
    );
  }

  setUserDataInAppState() : void {
    this.AgentInformation = this.route.snapshot.data.agentInformation;
    this.appStateService.setAgentInformation(this.AgentInformation);
    this.canManageGroups = this.route.snapshot.data.canManageGroups;
    this.appStateService.setCanManageGroups(this.canManageGroups);
}

  /**
   * submits the provided reminder answer to the service for validation
   * routes to user profile if the user was right
   * @return {void}
   */
  onVerifySecurity(): void {
    this.securityValidationMessages = [];
    this.agentProfile.Security.ReminderAnswer.Value = this.validateSecurityForm.value.reminderAnswer;
    this.loadingService.open();
    this.subscriptions.push(
      this.userService.validateSecurity(this.token, this.agentProfile)
        .subscribe((response) => {
          if (response.IsValid) {
            this.appStateService.setAgentProfile(response.AgentProfile);
            if (response.WebAccountTypeCode === this.webAccountType.InvalidUser) {
              this.router.navigate(['login']);
            } else {
              this.router.navigate(['userprofile']);
            }
          } else {
            this.securityValidationMessages = response.ErrorMessages;
          }
        },
        (error) => { },
        () => this.loadingService.close())
    );
  }

  /**
   * get's content based on the response code in the session
   *
   * @return {string}
   */
  alertinfo(): string {
    if (this.response.WebAccountTypeCode === this.webAccountType.InvalidUser) {
      return 'User is not valid.';
    } else if (this.response.WebAccountTypeCode === this.webAccountType.AlreadyValidated) {
      return 'Email is already validated by other AgentMax Administrator';
    } else if (this.response.WebAccountTypeCode === this.webAccountType.LockedOutUser) {
      return `Access was rejected for ${this.response.AgentEmailAddress} - ${this.response.AgentName} by other AgentMax Administrator`;
    } else if (this.response.WebAccountTypeCode === this.webAccountType.AccessRejected) {
      return `Access is rejected for ${this.response.AgentEmailAddress} - ${this.response.AgentName}`;
    } else if (this.response.WebAccountTypeCode === this.webAccountType.AccessApproved) {
      return `Access is approved for ${this.response.AgentEmailAddress} - ${this.response.AgentName}`;
    } else if (this.response.WebAccountTypeCode === this.webAccountType.NewUser) {
      return `Redirect to complete your Profile ${this.response.AgentEmailAddress} - ${this.response.AgentName}`;
    } else {
      return 'Unknown Error';
    }
  }

}
