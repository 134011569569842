// Angular Core
import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// Services
import { UserService } from '../api/services/user.service';
import { LoadingService } from '../api/services/loading.service';

// Models
import { ErrorMessage } from '@allianz/agent-max-core-lib';

// Third Party
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnDestroy {
  validationMessages: ErrorMessage[] = [];
  hideContentBlock: boolean = false;
  status: string = '';
  emailAddress: FormControl = new FormControl();
  forgotPasswordForm: FormGroup;
  emailSubscription: Subscription;
  maxLoginAttemptsExceeded: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private loadingService: LoadingService,
    private route: ActivatedRoute
  ) {
    this.forgotPasswordForm = this.formBuilder.group({
      emailAddress: this.emailAddress
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.maxLoginAttemptsExceeded = !!params['maxLoginAttemptsExceeded'];
      if (this.maxLoginAttemptsExceeded) {
        this.validationMessages = [ { Code: '', Description: 'You have exceeded the maximum number of unsuccessful login attempts, please submit your e-mail address to reset your password.' } ]
      }
      this.emailAddress.setValue(params['emailAddress']);
    });
  }

  ngOnDestroy(): void {
    if (this.emailSubscription) {
      this.emailSubscription.unsubscribe();
    }
  }
  /**
   * sends a forgot password email to the supplied email address
   * @param {string} emailAddress
   * @returns {Observable<ForgotPasswordPayload>}
   */
  onSendEmail(): void {
    this.loadingService.open();
    this.emailSubscription = this.userService.forgotPassword(this.forgotPasswordForm.value.emailAddress)
      .subscribe((response) => {
        if (response.IsValid && response.Message !== 'Error') {
          this.hideContentBlock = true;
        } else {
          this.validationMessages = response.ErrorMessages;
        }
      },
      (error) => { },
      () => this.loadingService.close());
  }
}
