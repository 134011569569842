"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InputField = /** @class */ (function () {
    function InputField() {
        this.Value = null;
        this.IsValid = true;
        this.IsEditable = true;
        this.ErrorMessages = new Array();
    }
    return InputField;
}());
exports.InputField = InputField;
