// Angular Core
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Models
import { ErrorMessage } from '@allianz/agent-max-core-lib';
import { ForgotPasswordStatus } from 'src/app/models/enums';

@Component({
  selector: 'app-generic-client-validation',
  templateUrl: './generic-client-validation.component.html',
  styleUrls: ['./generic-client-validation.component.scss']
})
export class GenericClientValidationComponent {
  validationMessages: ErrorMessage[] = [];
  isFormSubmissionComplete: boolean = false;
  status: number = 0;
  possibleResponses: string[] = [
    `Thank you! Please click on the link in your email to validate your account.`,
    `Thank you! Account already exists, please click here to <a href="${location.origin}${location.pathname}#/login">login</a>`
  ];

  constructor(private activatedRoute: ActivatedRoute) {
    // this route doesnt follow the same paradigm as the others so I had to add a conditional as to cut down on component creation
    if (this.activatedRoute.snapshot.data.resetPasswordByAdmin) {
      this.possibleResponses = [];
      if (this.activatedRoute.snapshot.data.resetPasswordByAdmin.EmailStatus === ForgotPasswordStatus.EmailLimitExceeded) {
        this.possibleResponses.push('Maximum number of Emails allowed to be sent has reached, please try later.');
      } else {
        this.possibleResponses.push(`An email has been sent to ${this.activatedRoute.snapshot.paramMap.get('emailaddress')} to reset Password.`);
      }
      this.validationMessages = this.activatedRoute.snapshot.data.resetPasswordByAdmin.ErrorMessages;
    } else {
      // checks both resolves to see which exists and gets the status and validation messages for it
      if (this.activatedRoute.snapshot.data.activateWebAccountByAdmin) {
        this.status = this.activatedRoute.snapshot.data.activateWebAccountByAdmin.StatusCode;
        this.validationMessages = this.activatedRoute.snapshot.data.activateWebAccountByAdmin.ErrorMessages;
      } else if (this.activatedRoute.snapshot.data.createWebAccount) {
        this.status = this.activatedRoute.snapshot.data.createWebAccount.StatusCode;
        this.validationMessages = this.activatedRoute.snapshot.data.createWebAccount.ErrorMessages;
      }
    }
    // checks to see if the submission was successful based on validation messages returned
    this.isFormSubmissionComplete = this.validationMessages.length === 0;
  }
  /**
   * get the message based on the route and the status code returned by resolver
   * @returns {string}
   */
  getTheMessage(): string {
    return this.possibleResponses[this.status];
  }

}
