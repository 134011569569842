// Angular Core
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Location } from '@angular/common';

// Service
import { AppStateService } from '../shared/services/app-state.service';
import { ReportService } from '../api/services/report.service';
import { UtilityService } from '../shared/services/utility.service';

// Models
import { Booking, ErrorMessage, BookingAgentInformation, KeyValue, FileTypes } from '@allianz/agent-max-core-lib';
import { PrintOrEmailOptions, EmailInput, RetryPendingResponse } from '../models/interfaces';
import { PrintOrEmailTypes, PrintOrEmailDocumentType, PrintOrEmailReportType } from '../models/enums';

// Third Party
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-print-or-email',
  templateUrl: './print-or-email.component.html',
  styleUrls: ['./print-or-email.component.scss']
})
export class PrintOrEmailComponent implements OnInit {
  data: { bookingData: string | Booking, options: PrintOrEmailOptions };
  quote: Booking;
  emailInput: EmailInput;
  headerName: string;
  printOrEmailForm: FormGroup;
  isWaiver: boolean;
  ccToFromAddress: boolean = true;
  agentInformation: BookingAgentInformation;
  fromEmailAddress: string;
  fromName: string;
  isEmailSendSuccessful: boolean = false;
  errorMessages: ErrorMessage[] = new Array();
  isHelpEmail: boolean = false;
  isGroupEmail: boolean = false;
  isQuoteToolEmail: boolean = false;
  isSalesReportEmail: boolean = false;
  isProdCompare: boolean = false;
  isEmailToCustomer: boolean = false;
  showViewButton: boolean = true;
  showResendFulfillment: boolean = false;
  showfromName: boolean = null;
  fromEmail: boolean = null;
  products: KeyValue[] = new Array();
  printEmailSubscriptions: Subscription[] = [];

  constructor(
    private appStateService: AppStateService,
    private formBuilder: FormBuilder,
    private reportService: ReportService,
    private utilityService: UtilityService,
    private location: Location
  ) {
    this.printOrEmailForm = this.formBuilder.group({
      fromName: new FormControl(''),
      fromEmailAddress: new FormControl(''),
      toEmailAddress: new FormControl(''),
      toName: new FormControl(''),
      secondaryEmailAddress: new FormControl(''),
      messageFromUser: new FormControl(''),
      ccToFromAddress: new FormControl(this.ccToFromAddress),
      product1: new FormControl('-1'),
      product2: new FormControl('-1'),
      product3: new FormControl('-1')
    });
  }

  ngOnInit(): void {
    this.data = this.appStateService.getPrintOrEmailData();
    this.agentInformation = this.appStateService.getAgentInformation();
    this.quote = this.data.bookingData as Booking;
    this.headerName = this.data.options.HeaderName || 'Email';
    this.emailInput = this.data.options.EmailInput;

    this.checkBooleans();

    if (this.appStateService.getPrintOrEmailReportName() === PrintOrEmailTypes.ProductComparison) {
      this.initProductComparisonForm();
    } else {
      this.initSavedQuoteForm();
    }
  }

  /**
   * Go back to the previous route
   * @returns {void}
   */
  back(): void {
    this.location.back();
  }

  /**
   * View or email button on print email form
   * @param {string} requestType
   * @returns {void}
   */
  onViewOrEmail(requestType: string): void {
    this.emailInput = this.updateEmailInput();
    if (this.data.options.DocumentType === PrintOrEmailDocumentType.AgencyDocument) {
      this.printEmailSubscriptions.push(
        this.reportService.viewOrEmailAgencyDocument(requestType, this.data.options.ReportType, this.emailInput, this.appStateService.getCredentials())
          .subscribe((response) => {
            this.printEmailSuccess(requestType, response);
          })
      );
    } else if (this.data.options.DocumentType === PrintOrEmailDocumentType.BookingDocument) {
      if (
        this.data.options.ReportType === PrintOrEmailReportType.WebQuoteByAgent ||
        this.data.options.ReportType === PrintOrEmailReportType.WebQuoteByCustomer ||
        this.data.options.ReportType === PrintOrEmailReportType.TravelInsuranceQuote
      ) {
        if (requestType === 'Email') {
          this.data.options.ReportType = PrintOrEmailReportType.WebQuoteByAgent;

          this.printEmailSubscriptions.push(
            this.reportService.viewOrEmailWebQuote(requestType, this.data.options.ReportType, this.data.options.OfferType, this.quote, '-1', this.emailInput, this.appStateService.getCredentials())
              .subscribe((response) => {
                this.printEmailSuccess(requestType, response);
              })
          );
        } else if (requestType === 'View') {
          this.data.options.ReportType = PrintOrEmailReportType.TravelInsuranceQuote;
          this.printEmailSubscriptions.push(
            this.reportService.viewOrEmailBookingDocument(requestType, this.data.options.ReportType, this.quote, this.emailInput, this.appStateService.getCredentials())
              .subscribe((response) => {
                this.printEmailSuccess(requestType, response);
              })
          );
        }
      } else {
        this.printEmailSubscriptions.push(
          this.reportService.viewOrEmailBookingDocument(requestType, this.data.options.ReportType, this.quote, this.emailInput, this.appStateService.getCredentials())
            .subscribe((response) => {
              this.printEmailSuccess(requestType, response);
            })
        );
      }
    } else if (this.data.options.DocumentType === PrintOrEmailDocumentType.GenericDocument) {
      this.emailInput.MessageFromSystem = this.data.options.IFrameUrl;
      this.printEmailSubscriptions.push(
        this.reportService.viewOrEmailGenericDocument(requestType, this.data.options.ReportType, this.emailInput, this.appStateService.getCredentials())
          .subscribe((response) => {
            this.printEmailSuccess(requestType, response);
          })
      );
    } else if (this.data.options.DocumentType === PrintOrEmailDocumentType.SalesReport) {
      this.printEmailSubscriptions.push(
        this.reportService.viewOrEmailSalesReport(requestType, this.quote, this.emailInput, this.appStateService.getCredentials())
          .subscribe((response) => {
            this.printEmailSuccess(requestType, response);
          })
      );
    } else if (this.data.options.DocumentType === PrintOrEmailDocumentType.CompareProductDocument) {
      const programProductIds: string[] = [
        this.printOrEmailForm.controls.product1.value,
        this.printOrEmailForm.controls.product2.value,
        this.printOrEmailForm.controls.product3.value
      ];
      this.printEmailSubscriptions.push(
        this.reportService.viewOrEmailProductComparison(requestType, this.quote, programProductIds, this.emailInput, this.appStateService.getCredentials())
          .subscribe((data) => {
            this.printEmailSuccess(requestType, data);
          })
      );
    }
  }

  /**
   * Resend fulfillment on the print or email form
   * @returns {void}
   */
  resendFulfillment(): void {
    const emailAddresses = [];
    emailAddresses.push(this.printOrEmailForm.controls.toEmailAddress.value);
    emailAddresses.push(this.printOrEmailForm.controls.secondaryEmailAddress.value);
    this.printEmailSubscriptions.push(
      this.reportService.resendFulfillment(this.quote, this.data.options.PolicyNumber, emailAddresses, this.appStateService.getCredentials())
        .subscribe((response) => {
          if (response.IsValid) {
            this.isEmailSendSuccessful = response.IsEmailSendSuccessful;
          } else {
            this.errorMessages = response.ErrorMessages;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.appStateService.setPrintOrEmailReportName(null);
    this.appStateService.setPrintOrEmailData(new Booking());
    this.printEmailSubscriptions.forEach((subscription) => subscription.unsubscribe());
    this.appStateService.triggerWaiverRouteStatus(false);
  }

  /**
   * Updates the email input based on form before submission
   * @returns {EmailInput}
   */
  private updateEmailInput(): EmailInput {
    if (this.emailInput.FromEmailAddress !== '') {
      
      if(this.appStateService.getPrintOrEmailReportName() === PrintOrEmailTypes.ProductComparison){
        this.fromEmailAddress = this.agentInformation.EmailAddress
        this.fromName = this.agentInformation.FirstName + this.agentInformation.LastName;
      }
      else{
        this.fromEmailAddress = this.printOrEmailForm.controls.fromEmailAddress.value || environment.DefaultEmailAddress;
        this.fromName = this.printOrEmailForm.controls.fromName.value || environment.DefaultEmailAddress;
      }
    }
    return {
      ToName: this.printOrEmailForm.controls.toName.value,
      ToEmailAddress: this.printOrEmailForm.controls.toEmailAddress.value,
      FromName: this.fromName,
      FromEmailAddress: this.fromEmailAddress,
      SecondaryEmailAddress: this.printOrEmailForm.controls.secondaryEmailAddress.value,
      MessageFromUser: this.printOrEmailForm.controls.messageFromUser.value,
      CCToFromAddress: this.printOrEmailForm.controls.ccToFromAddress.value,
    };
  }

  /**
   * Init the saved quote print or email form
   * @returns {void}
   */
  private initSavedQuoteForm(): void {
    this.printOrEmailForm.patchValue({
      fromEmailAddress: this.fromEmailAddress = this.emailInput.FromEmailAddress,
      fromName: this.fromName = this.emailInput.FromName,
      toEmailAddress: this.emailInput.ToEmailAddress,
      toName: this.emailInput.ToName,
      ccToFromAddress: this.ccToFromAddress
    });
  }

  /**
   * Filter the list of products of the ACCAM if the user came to this screen
   * from the Email Product Comparison. Otherwhise just list the default products list.
   * @returns {KeyValue[]}
   */  
  private filterProducts(products: KeyValue[]): KeyValue[]
  {   
    if(this.data.options.ProductsOffered === null) return products;

    return products.filter(product => 
      {        
        return this.data.options.ProductsOffered.findIndex((x) => x === parseInt(product.Key)) > -1;        
      });
  }

  /**
   * init Product comparison print or email form
   * @returns {void}
   */
  private initProductComparisonForm(): void {
    const productsSubscription = this.reportService.getProgramProductsList(this.quote, this.appStateService.getCredentials())
      .subscribe((products) => {
        productsSubscription.unsubscribe();
        this.products = this.filterProducts(products);
      });
    this.printOrEmailForm.patchValue({
      fromEmailAddress: '',
      fromName: '',
      toEmailAddress: this.emailInput.ToEmailAddress,
      toName: this.emailInput.ToName,
      ccToFromAddress: this.ccToFromAddress
    });
  }

  /**
   * Checks any booleans coming in from the components
   * bootstrapping the print email component
   * @returns {void}
   */
  private checkBooleans(): void {
    this.showResendFulfillment = this.data.options.ShowResendFulfillment;
    this.showViewButton = this.data.options.ShowViewButton;
    this.isProdCompare = this.data.options.IsProdCompare ? this.data.options.IsProdCompare : false;
    this.isQuoteToolEmail = this.data.options.IsQuoteToolEmail;
    this.isHelpEmail = this.data.options.IsHelpEmail ? this.data.options.IsHelpEmail : false;
    this.isWaiver = this.data.options.IsWaiver ? this.data.options.IsWaiver : false;
    this.showfromName = this.data.options.ShowFromName ? this.data.options.ShowFromName : null;
    this.fromEmail = this.data.options.ShowFromEmail ? this.data.options.ShowFromEmail : null;
    this.isGroupEmail = this.data.options.IsGroupEmail ? this.data.options.IsGroupEmail : false;
    this.appStateService.triggerWaiverRouteStatus(this.isWaiver);
  }

  /**
   * Print/email success or download the file
   * @param {string} requestType
   * @param {RetryPendingResponse} data
   * @returns {void}
   */
  private printEmailSuccess(requestType: string, data: RetryPendingResponse): void {
    if (requestType === 'View') {
      if (data.IsValid) {
        this.utilityService.openFile(data.FileContent, FileTypes.PDF);
      } else {
        this.errorMessages = data.ErrorMessages;
      }
    } else if (requestType === 'Email') {
      if (data.IsValid) {
        this.isEmailSendSuccessful = data.IsEmailSendSuccessful;
      } else {
        this.errorMessages = data.ErrorMessages;
      }
    }
  }

}
