// Angualar Core
import { Component, OnDestroy } from '@angular/core';

// Services
import { AppStateService } from '../shared/services/app-state.service';

@Component({
  selector: 'app-become-more',
  templateUrl: './become-more.component.html',
  styleUrls: ['./become-more.component.scss']
})
export class BecomeMoreComponent implements OnDestroy {

  constructor(private appStateService: AppStateService) {
    this.appStateService.setBannerDisplayStatus(false);
  }

  ngOnDestroy(): void {
    this.appStateService.setBannerDisplayStatus(true);
  }
}
