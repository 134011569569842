// Angular Core
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { UserService } from 'src/app/api/services/user.service';
import { AdminUserService } from 'src/app/api/services/adminUser.service';

// Models
import { AgentProfile, UserInformation, AdminAgentProfile, DefaultAgentProfile, AgencyGroupsForAgency, AgencyGroup, UserOptions } from 'src/app/models/interfaces';
import { KeyValue, ErrorMessage } from '@allianz/agent-max-core-lib';
import { CreateAdminAgentRequest } from 'src/app/models/interfaces/create-admin-agent-request.interface';

// Third Party
import { AlertType, ForgotPasswordStatus, UserProfileRoute } from 'src/app/models/enums';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit {
  isAdmin: boolean = true;
  validationMessages: ErrorMessage[] = [];
  profilePreferenceForm: FormGroup;
  userCredentials: UserInformation;
  businessTypes: KeyValue[];
  locations: KeyValue[];
  roles: KeyValue[];
  sellingRoles: KeyValue[];
  reportingRightsValues: KeyValue[];
  administrativeRoles: KeyValue[];
  isLoaded: boolean = false;
  isValidACCAM: boolean = false;
  profileSaved: boolean = false;
  @Input('userOptions') userOptions: UserOptions;
  @Input('adminRoute') adminRoute: UserProfileRoute = UserProfileRoute.AgentUserProfile;
  @Input('isWebEnabled') isWebEnabled: boolean = true;
  @Input('isActive') isActive: boolean = true;
  @Input('pendingAgent') pendingAgent: boolean = false;
  @Input('allowAgentsToChangeCodeOnWeb') allowAgentsToChangeCodeOnWeb: boolean = false;
  userProfileRoute: typeof UserProfileRoute = UserProfileRoute;
  agencyLongName: string;
  currentAgencyId: number;
  agencyGroup: AgencyGroup;
  accam: string = '';
  webEnableMsg: string = '';
  alertType: typeof AlertType = AlertType;
  bsModalRef: BsModalRef;

  private readonly onDestroy: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private userService: UserService,
    private bsModalService: BsModalService,
    private adminUserService: AdminUserService
  ) {
      this.profilePreferenceForm = this.formBuilder.group({
        accam: new FormControl(),
        agentEmail: new FormControl(null, [Validators.required]),
        firstName: new FormControl(null, [Validators.required]),
        lastName: new FormControl(null, [Validators.required]),
        agentCode: new FormControl(null, [Validators.required]),
        agentOtherRole: new FormControl(null, [Validators.required]),
        workLocation: new FormControl(null, [Validators.required]),
        businessType: new FormControl(null, [Validators.required]),
        isConfirmationEmailedToAgent: new FormControl(),
        allowNotifications: new FormControl(true),
        sellingRole: new FormControl(),
        reportingRights: new FormControl(),
        administrativeRole: new FormControl(),
        allowSecondChanceByMaxMail: new FormControl(true),
        allowGroupTravel: new FormControl(true),
        fullWebAccess: new FormControl(true),
        userLockedOut: new FormControl(false)
      });
      this.isLoaded = true;
  }

  ngOnInit(): void {
    this.isAdmin = this.appStateService.isAdmin();
    this.userCredentials = this.appStateService.getCredentials();
    if (this.adminRoute === this.userProfileRoute.AdminCreateAgent) {
      this.businessTypes = this.activatedRoute.snapshot.firstChild.data.businessTypes;
      this.locations = this.activatedRoute.snapshot.firstChild.data.locations;
      this.roles = this.activatedRoute.snapshot.firstChild.data.profileRoles;
      this.sellingRoles = this.activatedRoute.snapshot.firstChild.data.sellingRoles;
      this.reportingRightsValues = this.activatedRoute.snapshot.firstChild.data.reportingRights;
      const agencyGroups: AgencyGroupsForAgency = this.activatedRoute.snapshot.firstChild.data.agencyGroups;
      this.agencyGroup = agencyGroups.AgencyGroupList.find((ag) => ag.AgencyId.toString() === this.activatedRoute.snapshot.params.agencyId.toString());
      this.accam = this.agencyGroup.Accam;
      this.administrativeRoles = this.activatedRoute.snapshot.firstChild.data.administrativeRoles;
      this.profilePreferenceForm.controls.sellingRole.setValidators([Validators.required]);
      this.profilePreferenceForm.controls.reportingRights.setValidators([Validators.required]);
      this.profilePreferenceForm.controls.administrativeRole.setValidators([Validators.required]);
      this.profilePreferenceForm.controls.allowSecondChanceByMaxMail.setValidators([Validators.required]);
      this.profilePreferenceForm.controls.allowGroupTravel.setValidators([Validators.required]);
      this.profilePreferenceForm.controls.fullWebAccess.setValidators([Validators.required]);
      this.profilePreferenceForm.controls.userLockedOut.setValidators([Validators.required]);
      this.profilePreferenceForm.controls.isConfirmationEmailedToAgent.setValidators([Validators.required]);
      if (this.activatedRoute.snapshot.params.agencyId) {
          this.adminUserService.getDefaultAgentAdminSettingsGridBag(this.activatedRoute.snapshot.params.agencyId)
          .subscribe((res) => {
            const _defaultAgentProfile: DefaultAgentProfile = {
              SellingAccam: res.DefaultAgentAdminSettings.LoginSwitchToAccam,
              SellingRole: res.DefaultAgentAdminSettings.AgentSellingRole,
              AllowedReports: res.DefaultAgentAdminSettings.AgentReportingRight,
              AdminRole: res.DefaultAgentAdminSettings.AgentAdministrativeRole,
              CanManageGroup: res.DefaultAgentAdminSettings.AgentCanManageGroup,
              LockedOut: res.DefaultAgentAdminSettings.AgentAccountSpecialRestriction,
              FullWebAccess: res.DefaultAgentAdminSettings.IsWebAccountAccessFull,
              CanManageSecondChance: res.DefaultAgentAdminSettings.AgentCanManageSecondChance,
              IsConfirmationEmailedToAgent: res.DefaultAgentProfileGroupBag.IsConfirmationEmailedToAgent,
              AllowNotifications: res.DefaultAgentProfileGroupBag.AllowNotifications
            };
            this.patchProfilePreferenceFormForCreateAgent(_defaultAgentProfile);
            this.profilePreferenceForm.markAsPristine();
            this.profilePreferenceForm.markAsUntouched();
          });
      }
      this.profilePreferenceForm.controls.accam.disable();

    } else {
      this.businessTypes = this.activatedRoute.snapshot.data.businessTypes;
      this.locations = this.activatedRoute.snapshot.data.locations;
      this.roles = this.activatedRoute.snapshot.data.roles;
      this.accam = this.userOptions.AgentProfile.Accam;
      if (this.adminRoute === this.userProfileRoute.AdminViewAgent) {
        this.currentAgencyId = this.appStateService.getAgencyInformation().AgencyId;
      } else {
        this.currentAgencyId = this.appStateService.getCredentials().AgencyID;
      }

      if (this.userOptions) {
        this.profilePreferenceForm.patchValue({
          accam: this.userOptions.AgentProfile.Accam,
          agentEmail: this.userOptions.AgentProfile.AgentEmail.Value,
          firstName: this.userOptions.AgentProfile.AgentFirstName.Value,
          lastName: this.userOptions.AgentProfile.AgentLastName.Value,
          agentCode: this.userOptions.AgentProfile.AgentCode.Value,
          agentOtherRole: this.userOptions.AgentProfile.AgentOtherRoleText.Value,
          workLocation: this.userOptions.AgentProfile.WorkLocationText.Value,
          businessType: this.userOptions.AgentProfile.BusinessTypeText.Value,
          isConfirmationEmailedToAgent: this.userOptions.AgentProfile.IsConfirmationEmailedToAgent.Value,
          allowNotifications: this.userOptions.AgentProfile.AllowNotifications.Value
        });
        if (this.adminRoute === this.userProfileRoute.AdminViewAgent && !this.isActive) {
          this.profilePreferenceForm.disable();
        } else {
          if (!this.userOptions.AgentProfile.AgentEmail.IsEditable) {
            this.profilePreferenceForm.controls.agentEmail.disable();
          }
          // If allowAgentsToChangeCodeOnWeb IS NOT checked & the route IS useroptions then disable agentCode
          if (!this.allowAgentsToChangeCodeOnWeb && this.adminRoute === this.userProfileRoute.AgentUserProfile) {
            this.profilePreferenceForm.controls.agentCode.disable();
          }
        }

      }
      this.profilePreferenceForm.markAsPristine();
      this.profilePreferenceForm.markAsUntouched();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  /**
   * Submits the user options profile and preferences form
   * This form will be submitted to either update an agent profile for existing users or to
   * submit a new user for the administration route
   * @returns {void}
   */
  submit(): void {
    if (this.profilePreferenceForm.valid && this.profilePreferenceForm.dirty) {
      if (this.adminRoute === UserProfileRoute.AgentUserProfile) {
        const updatedProfile = this.getUpdatedUserProfilePreferences(this.profilePreferenceForm);
        this.userService.saveAgentProfileAndPreferences(updatedProfile, this.userCredentials)
        .subscribe((profile) => {
          this.validationMessages = profile.ValidationMessages;
          this.profileSaved = this.validationMessages.length === 0 ? true : false;
          if (this.profileSaved) {
            const userOptions = this.appStateService.getUserOptions();
            this.userOptions.AgentProfile = profile;
            userOptions.AgentProfile = profile;
            this.appStateService.setUserOptions(userOptions);
            this.profilePreferenceForm.markAsPristine();
          }
        });
      } else if (this.adminRoute === UserProfileRoute.AdminViewAgent) {
        const updatedProfile = this.createAdminAgentRequest(this.profilePreferenceForm);
        this.adminUserService.updateAgentProfileAndPreferences(updatedProfile)
        .subscribe((profile) => {
          this.validationMessages = profile.ValidationMessages;
          this.profileSaved = this.validationMessages.length === 0 ? true : false;
          if (this.profileSaved) {
            this.profilePreferenceForm.markAsPristine();
          }
        });
      } else {
        const createAgentProfileRequest = this.createAgentProfileRequestObject(this.profilePreferenceForm);
        this.adminUserService.createAdminAgent(createAgentProfileRequest)
        .subscribe((adminProfileResponse) => {
          this.validationMessages = adminProfileResponse.ErrorMessages;
          this.profileSaved = this.validationMessages.length === 0 && adminProfileResponse.WasAccountCreated ? true : false;
          if (this.profileSaved) {
            const agencyId = this.agencyGroup && this.agencyGroup.AgencyId ?  this.agencyGroup.AgencyId : this.appStateService.getCredentials().AgencyID;
            this.router.navigate([`/admin/agencies/${agencyId}/agent-management`]);
          }
        });
      }
    }
  }
/**
 * this button will show when agent account created in client and does not have access to AMO
 * admin will click this button to enable agnet to have access to AMO, agentMax services will genrated an emal and send it to agent
 * to complete form
 * @returns {void}
 */
  public enableOnlineUse(): void {
    if (this.adminRoute === UserProfileRoute.AdminViewAgent && (!this.isWebEnabled || this.pendingAgent)) {
      const possibleResponses: string[] = [
        `Error sending email`,
        `Thank you! Account already exists.`,
        `Thank you! Please click on the link in email that send to agent to validate your account.`,
        `Thank you! Email already sent`
      ];
      this.adminUserService.getAgenciesForAdministration().subscribe((res) => {
        const emailaddress = this.profilePreferenceForm.controls.agentEmail.value;
        const agentAccountId = this.activatedRoute.snapshot.params.agentId;
        const agency = res.Agencies.find((ag) => ag.ACCAM === this.profilePreferenceForm.controls.accam.value);
        if (agency && agency.AgencyID && emailaddress && agentAccountId) {
          this.userService.activateWebAccountByAdmin(emailaddress, agentAccountId, agency.AgencyID.toString())
          .subscribe((response) => {
            if (response.ErrorMessages && response.ErrorMessages.length > 0) {
              this.validationMessages  = response.ErrorMessages;
            } else if (response.Status === 0) {
              this.validationMessages = [{Description: possibleResponses[response.Status], Code: ''}];
            } else {
              this.webEnableMsg = possibleResponses[response.Status] ? possibleResponses[response.Status] : 'Thank you! request has been processed ';
            }
          });
        }
      });
    }
  }
  public resetPasswordByAdmin(): void {
    const emailaddress = this.profilePreferenceForm.controls.agentEmail.value;
    const agentAccountId = this.activatedRoute.snapshot.params.agentId;
    if (emailaddress && agentAccountId && this.isWebEnabled && !this.pendingAgent && this.adminRoute === UserProfileRoute.AdminViewAgent) {
      const initialState = {
        text: `<p>Click 'OK' to send Reset Password email to ${emailaddress}.</p>`,
        dialogIndicator: 'reset-password-admin',
        confirmButtonName: 'OK',
        cancelButtonName: 'Cancel'
      };
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .subscribe((ac: boolean) => {
          if (ac) {
            this.userService.resetPasswordByAdmin(emailaddress, agentAccountId).subscribe((response) => {
              if (response.ErrorMessages && response.ErrorMessages.length > 0) {
                this.validationMessages = this.activatedRoute.snapshot.data.resetPasswordByAdmin.ErrorMessages;
              } else if (response.EmailStatus === ForgotPasswordStatus.EmailLimitExceeded) {
                this.webEnableMsg = 'Maximum number of Emails allowed to be sent has been reached, please try later.';
              } else {
                this.webEnableMsg = `An email has been sent to ${emailaddress} to reset Password.`;
              }
            });
          }
        });
    }
  }
  public onClosedAlert(): void {
    this.webEnableMsg = '';
  }
  /**
   * Converts the front end form into the admin back end request object
   * @param {FormGroup} form - form from the front end
   * @returns {AdminAgentProfile} - admin request object for back end
   */
  private createAdminAgentRequest(form: FormGroup): AdminAgentProfile {
    const adminAgentRequest: AdminAgentProfile = {
      AgencyId: this.agencyGroup ? this.agencyGroup.AgencyId : this.currentAgencyId,
      AgentAccountId: this.activatedRoute.snapshot.paramMap.get('agentId') ? this.activatedRoute.snapshot.paramMap.get('agentId') : 0,
      AgentCode: form.controls.agentCode.value,
      AgentEmail: form.controls.agentEmail.value,
      AgentFirstName: form.controls.agentFirstName ? form.controls.agentFirstName.value : form.controls.firstName.value,
      AgentLastName: form.controls.agentLastName ? form.controls.agentLastName.value : form.controls.lastName.value,
      AgentRoleText: {
        ErrorMessages: [],
        IsEditable: true,
        IsValid: true,
        Value: form.controls.agentOtherRole.value },
      AgentBusinessText: {
        ErrorMessages: [],
        IsEditable: true,
        IsValid: true,
        Value: form.controls.businessType.value },
      AgentWorkLocationText: {
        ErrorMessages: [],
        IsEditable: true,
        IsValid: true,
        Value: form.controls.workLocation.value },
      IsConfirmationEmailedToAgent: form.controls.isConfirmationEmailedToAgent.value,
      AllowNotifications: form.controls.allowNotifications.value
    };
    return adminAgentRequest;
  }

  /**
   * Get updated user profile preferences to send to server
   * @param {FormGroup} formValues
   * @returns {AgentProfile}
   */
  private getUpdatedUserProfilePreferences(form: FormGroup): AgentProfile {
    const updatedUserProfile = this.userOptions.AgentProfile;
    updatedUserProfile.AgentEmail.Value = form.controls.agentEmail.value;
    updatedUserProfile.AgentFirstName.Value = form.controls.firstName.value;
    updatedUserProfile.AgentLastName.Value = form.controls.lastName.value;
    updatedUserProfile.AgentCode.Value = form.controls.agentCode.value;
    updatedUserProfile.AgentOtherRoleText.Value = form.controls.agentOtherRole.value;
    updatedUserProfile.WorkLocationText.Value = form.controls.workLocation.value;
    updatedUserProfile.BusinessTypeText.Value = form.controls.businessType.value;
    updatedUserProfile.IsConfirmationEmailedToAgent.Value = form.controls.isConfirmationEmailedToAgent.value;
    updatedUserProfile.AllowNotifications.Value = form.controls.allowNotifications.value;
    return updatedUserProfile;
  }

  /**
   * Creates the request object needed to create a profile through the administration route.
   * @param form user options form that will be used for the request object
   */
  private createAgentProfileRequestObject(form: FormGroup): CreateAdminAgentRequest {
    const createAdminAgentRequest: CreateAdminAgentRequest = {
      ErrorMessages: [],
      Message: '',
      TransactionNumber: null,
      TransactionStatus: null,
      IsValid: true,
      AgencyId: this.agencyGroup.AgencyId,
      FirstName: form.controls.firstName.value,
      LastName: form.controls.lastName.value,
      EmailAddress: form.controls.agentEmail.value,
      AgentCode: form.controls.agentCode.value,
      AgentRoleText: {
        ErrorMessages: [],
        IsEditable: true,
        IsValid: true,
        Value: form.controls.agentOtherRole.value
      },
      AgentWorkLocationText:  {
        ErrorMessages: [],
        IsEditable: true,
        IsValid: true,
        Value: form.controls.workLocation.value
      },
      AgentBusinessText: {
        ErrorMessages: [],
        IsEditable: true,
        IsValid: true,
        Value: form.controls.businessType.value
      },
      AgentSellingRoleText: {
        ErrorMessages: [],
        IsEditable: true,
        IsValid: true,
        Value: form.controls.sellingRole.value
      },
      AgentReportingRightText: {
        ErrorMessages: [],
        IsEditable: true,
        IsValid: true,
        Value: form.controls.reportingRights.value
      },
      AgentAdministrativeRoleText: {
        ErrorMessages: [],
        IsEditable: true,
        IsValid: true,
        Value: form.controls.administrativeRole.value
      },
      AgentCanManageGroup: form.controls.allowGroupTravel.value,
      AgentCanManageSecondChance: form.controls.allowSecondChanceByMaxMail.value,
      IsWebAccountAccessFull: form.controls.fullWebAccess.value,
      IsConfirmationEmailedToAgent: form.controls.isConfirmationEmailedToAgent.value,
      AllowNotifications: form.controls.allowNotifications.value
    };
    return createAdminAgentRequest;
  }

  /**
   *  it takes value and it return first key in obj that it's value matches value provided
   * @param value {number}
   * @param obj {KeyValue}
   * @returns {string}
   */
  private getKeyByValue(value: number, obj: KeyValue[]): string {
    for (const op of obj) {
      if (op.Value === value.toString()) {
        return op.Key;
      }
    }
  }

  private patchProfilePreferenceFormForCreateAgent(defaultAgentProfile: DefaultAgentProfile): void {
    this.profilePreferenceForm.patchValue({
      accam: this.agencyGroup.Accam,
      agentOtherRole: 'Agent', // default role to agent
      workLocation: 'Unknown', // default to unknown
      businessType: 'Unknown', // default to unknown
      sellingRole: this.getKeyByValue(defaultAgentProfile.SellingRole, this.sellingRoles),
      reportingRights: this.getKeyByValue(defaultAgentProfile.AllowedReports, this.reportingRightsValues), // default to Agency Level
      allowSecondChanceByMaxMail: defaultAgentProfile.CanManageSecondChance,
      allowGroupTravel: defaultAgentProfile.CanManageGroup,
      fullWebAccess: defaultAgentProfile.FullWebAccess,
      isConfirmationEmailedToAgent: defaultAgentProfile.IsConfirmationEmailedToAgent,
      allowNotifications: defaultAgentProfile.AllowNotifications,
      administrativeRole: this.getKeyByValue(defaultAgentProfile.AdminRole, this.administrativeRoles),
      userLockedOut: !!defaultAgentProfile.LockedOut  // set userlocked out to false if locked out value equal to 0, locedout value 0 equal 0, 1 equal true
    });
  }
}
