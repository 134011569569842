// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Serivces
import { AppStateService } from '../services/app-state.service';
import { UserService } from 'src/app/api/services/user.service';

// Models
import { UserOptions } from 'src/app/models/interfaces';

// Third Party
import { Observable, of } from 'rxjs';

@Injectable()
export class UserOptionsResolver implements Resolve<Observable<UserOptions>> {
  constructor(
    private appStateService: AppStateService,
    private userService: UserService
  ) { }
  resolve(): Observable<UserOptions> {
    return this.appStateService.getUserOptions() ? of(this.appStateService.getUserOptions()) : this.userService.getUserOptions(this.appStateService.getCredentials());
  }
}
