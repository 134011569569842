// Angular Core
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { QuoteService } from 'src/app/api/services/quote.service';
import { LoadingService } from '../api/services/loading.service';

// Models
import { State, AgencyProducts, AddOnProgramProductInformation, ProgramProduct, Booking, BookingTypes } from '@allianz/agent-max-core-lib';

// Third Party
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {
  states: State[];
  agencyProducts: AgencyProducts;
  isOpen: boolean = true;
  initalProductsLoaded: boolean = false;
  productSubscriptions: Subscription[] = [];

  constructor(
    private appStateService: AppStateService,
    private router: Router,
    private quoteService: QuoteService,
    private loadingService: LoadingService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.states = this.appStateService.getAgentInformation().States;
    this.agencyProducts = this.route.snapshot.data.agencyProducts;
    this.appStateService.setCurrentBookingQuote(this.agencyProducts.Quote);
  }

  /**
   * Recalculate Agency product quote
   * @param {AgencyProducts} $event
   * @returns {void}
   */
  recalculateAgencyProducts($event: AgencyProducts): void {
    this.agencyProducts = $event;
  }

  /**
   * Routes to either the productcompare or gdsproductcompare based on current route
   * @returns {void}
   */
  getProductCompareLink(): void {
    if (this.agencyProducts.ProgramProducts.length !== 0) {
      this.router.navigate([`${this.router.url}compare`]);
    }
  }

  /**
   * On addon select
   * @param {AddOnProgramProductInformation} addOn
   * @param {Array<AddOnProgramProductInformation>} allAddOns
   * @returns {void}
   */
  onAddOnSelect(addOn: AddOnProgramProductInformation, allAddOns: AddOnProgramProductInformation[]): void {
    if (!addOn.IsSelected && !addOn.ProgramProductInformation.Product.IsRequiredAddOnProduct) {
      addOn.IsSelected = false;
      return;
    }

    if (addOn.ProgramProductInformation.Product.IsSwitchToAddOnProduct) {
      allAddOns.forEach((item) => {
        if (item.ProgramProductInformation.Product.IsSwitchToAddOnProduct) {
          item.IsSelected = false;
        }
      });
      addOn.IsSelected = true;
    } else if (!addOn.ProgramProductInformation.Product.IsSwitchToAddOnProduct && addOn.IsSelected) {
      addOn.IsSelected = true;
    } else {
      addOn.IsSelected = false;
    }

    if (addOn.ProgramProductInformation.Product.IsRequiredAddOnProduct) {
      addOn.IsSelected = true;
    }
  }

  /**
   * Get add on total
   * @param {ProgramProduct} programProduct
   * @return {number}
   */
  getAddOnTotal(programProduct: ProgramProduct): number {
    let totalPrice = programProduct.Price;
    programProduct.AddOnProgramProducts.forEach((addOn) => {
      if (addOn.IsSelected) {
        totalPrice += addOn.ProgramProductInformation.Price;
      }
    });
    return totalPrice;
  }

  /**
   * On product select route to booking info
   * @param {ProgramProduct} programProduct
   * @returns {void}
   */
  onProductSelect(programProduct: ProgramProduct): void {
    const booking: Booking = this.appStateService.getCurrentBookingQuote();
    booking.ProductInformation.ProgramProducts = [];
    booking.ProductInformation.ProgramProducts.push(programProduct);
    programProduct.AddOnProgramProducts.forEach((addon) => {
      if (addon.IsSelected) {
        booking.ProductInformation.ProgramProducts.push(addon.ProgramProductInformation);
      }
    });
    this.appStateService.setCurrentBookingId(null);
    this.loadingService.open();
    this.productSubscriptions.push(
      this.quoteService.getQuoteForSelectedProducts(booking, this.appStateService.getCredentials())
        .subscribe((response) => {
          programProduct.ValidationMessages = [];
          programProduct.ValidationMessages = response.ErrorMessages;
          if (response.IsValid) {
            this.appStateService.setCurrentBookingQuote(response);
            this.appStateService.setBookingInfoViewType(BookingTypes.Quote);
            this.appStateService.setBookingInfoSourceType(BookingTypes.NewBooking);
            this.router.navigate(['bookinginfo']);
          } else {
            programProduct.IsValid = false;
          }
        },
        (error) => { },
        () => this.loadingService.close())
    );
  }

  /**
   * Accordion is open event handler
   * @param {boolean} $event
   * @param {number} $index
   */
  isOpenChange($event: boolean, $index: number): void {
    // avoids scrolling to products on page init
    if (!this.initalProductsLoaded) {
      this.initalProductsLoaded = true;
      return;
    }
    // navigates to the top of the header of the open accordion
    if ($event) {
      // delay to let the accordion animation finish before scrolling
      setTimeout(() => {
        document.getElementById(`product-accordion-${$index}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 350);
    }
  }

  ngOnDestroy(): void {
    this.productSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
