// Angular Core
import { Component, EventEmitter, OnInit, DoCheck, Output } from '@angular/core';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// Services
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

// Models
import { AgencyGroup } from 'src/app/models/interfaces';
import { MainNavTabs, AdminNavTabs } from 'src/app/models/enums';

// 3rd Party Imports
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

// Core library
import { ErrorMessage, KeyValue } from '@allianz/agent-max-core-lib';

// Components
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'app-agent-settings',
  templateUrl: './agent-settings.component.html',
  styleUrls: ['./agent-settings.component.scss']
})
export class AgentSettingsComponent implements OnInit, DoCheck {
  agencyId: string;
  agencyGroups: AgencyGroup[] = Array<AgencyGroup>();
  groups: number[] = [1, 2];
  isLoaded: boolean = false;
  agencyArray: FormArray = this.formBuilder.array([]);
  agentSettingsGroupForm: FormGroup;
  subscriptionList: Subscription[] = [];
  agentSettingsSaved: boolean = false;
  isDesktop: boolean;
  validationMessages: ErrorMessage[] = [];
  bsModalRef: BsModalRef;
  sharedDefaultAgentSettingsId: number = null;
  agencyID: number = null;
  locationListener: any = null;

  @Output('nextActiveTab') nextActiveTab: EventEmitter<KeyValue> = new EventEmitter<KeyValue>();

  private readonly onDestroy: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private adminuserService: AdminUserService,
    private appStateService: AppStateService,
    private bsModalService: BsModalService,
    private location: Location,
    private utilityService: UtilityService
  ) {
    this.agentSettingsGroupForm = this.formBuilder.group({
      agencyArray: this.agencyArray
    });

    // this.locationListener = this.location
    // .subscribe((event: PopStateEvent): void => {
    //   if (this.agentSettingsGroupForm.dirty && event.type === 'popstate') {
    //     this.browserBackButtonClick();
    //   }
    // });
  }

  ngOnInit(): void {
    this.getAgencyGroupsForAgency();
    this.utilityService.getSize().subscribe((isDesktop: boolean) => {
      this.isDesktop = isDesktop;
    });
  }

  ngDoCheck(): void {
    this.agencyArray.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((val) => {
        if (this.agencyArray.dirty) {
          this.utilityService.setTriggerAdminSaveAlert(this, true, MainNavTabs.AdminComponent, AdminNavTabs.AgentSettings);
        }
      });
  }

  /**
   * What happens when browser back button is clicked
   * @returns {void}
   */
  browserBackButtonClick(): void {
    const initialState = {
      text: `<p>Changes to this page have been made. Would you like to Apply the changes?</p>`,
      dialogIndicator: 'admin-save-group-settings'
    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
    this.bsModalRef.content.modalResponse
      .subscribe((ac: boolean) => {
        if (ac) {
          this.updateAgentSettingsGroupsForAgency();
        } else {
          return;
        }
      });
  }

  /**
   * gets a single agency's info, but from a diffrent, more adminy endpoint
   * @returns {void}
   */
  getAgencyGroupsForAgency(): void {
    this.subscriptionList.push(
      this.adminuserService.getAgencyGroupsForAgency()
      .subscribe((response) => {
        this.agencyId = response.AgencyId;
        this.agencyGroups = response.AgencyGroupList;
        this.appStateService.setAgencyGroups(this.agencyGroups);
        this.isLoaded = true;
        const maxGroupId = Math.max(...this.agencyGroups.map((agencyGroup) => agencyGroup.SharedDefaultAgentSettingsId));
        if (maxGroupId > 2) {
          this.groups = Array.from(Array(maxGroupId).keys()).map((group) => group + 1);
        }
        for (const agencyGroup of this.agencyGroups) {
          this.agencyArray.push(
            this.formBuilder.group({
              groupChoice: [agencyGroup.SharedDefaultAgentSettingsId]
            })
          );
        }
      })
    );
  }

  /**
   * Adds a new group column for more groups.  Maxes out at 4 (index of 0 so must be less than 3)
   * @returns {void}
   */
  addGroup(): void {
    const maxGroups = Math.max(...this.groups);
    if (maxGroups < 4) {
      this.groups.push(Math.max(...this.groups) + 1);
    }
  }

  /**
   * Gets the name for the button.  Button name will either be
   * 'Agent Settings' or 'Group {x} Settings'.
   * @param {number} index - index of the row that the button is being grabbed for
   * @returns {string} - name of button
   */
  getButtonName(index: number): string {
    let buttonName = '';
    const groupValue: number = this.agencyArray.controls[index].value.groupChoice;
    if (groupValue === 0) {
      buttonName = 'Agent Settings';
    } else {
      buttonName =  `Group ${groupValue} Settings`;
    }
    return buttonName;
  }

  /**
   * Routes to the appropriate settings based on the shared default agent settings id
   * @param {number} index - index of the row that the button is being grabbed for
   * @returns {void}
   */
  routeToSettings(index: number): void {
    this.sharedDefaultAgentSettingsId = this.agencyGroups[index].SharedDefaultAgentSettingsId;
    this.agencyID = this.agencyGroups[index].AgencyId;
    this.navigateAgentSettings(this.sharedDefaultAgentSettingsId, this.agencyID);
  }

  /**
   * Routes to either group settings or default settings depending on whether or not a group is chosen
   * @param {number} sharedDefaultAgentSettingsId
   * @param {number} agencyId - base agency id used by details page
   * @returns {void}
   */
  navigateAgentGroupSettings(sharedDefaultAgentSettingsId: number, agencyId: number): void {
    if (sharedDefaultAgentSettingsId !== 0) {
      this.router.navigate(['admin/agentSettings', agencyId, sharedDefaultAgentSettingsId], {state: {agencies: this.activatedRoute.snapshot.data.adminAgencies.Agencies}});
    } else {
      this.router.navigate(['admin/agentSettings', agencyId], {state: {agencies: this.activatedRoute.snapshot.data.adminAgencies.Agencies}});
    }
  }

  /**
   * Routes to default agent settings page, passing in agency ID if the agency doesn't belong to a group.
   * Otherwise it will pass in the agencyID and sharedDefaultAgentSettingsId to the admin default agent settings page
   * @param {number} sharedDefaultAgentSettingsId - shared default agent settings id being passed in
   * @param {number} agencyId - base agency id used by details page
   * @returns {void}
   */
  navigateAgentSettings(sharedDefaultAgentSettingsId: number, agencyId: number): void {
    if (!this.agentSettingsGroupForm.pristine) {
      const initialState = {
        text: `<p>Changes to groupings on this page have been made. Would you like to Apply the changes?</p>`,
        dialogIndicator: 'admin-save-group-settings'
      };
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .subscribe((ac: boolean) => {
          if (ac) {
            this.updateAgentSettingsGroupsForAgency();
          } else {
            // if click NO navigate to group settings - default behavior when clicking groupSettings button
            this.navigateAgentGroupSettings(sharedDefaultAgentSettingsId, agencyId);
          }
        });
    } else {
      this.navigateAgentGroupSettings(sharedDefaultAgentSettingsId, agencyId);
    }
  }

  /**
   * Updates the agency groups to have the proper shared default agent settings id
   * @returns {void}
   */
  updateAgentSettingsGroupsForAgency(nextTab?: KeyValue, nextMainTab?: KeyValue, amoGoBack?: boolean): void {
    this.subscriptionList.push(this.adminuserService.updateAgentSettingsGroupsForAgency(this.agencyGroups)
      .subscribe((response) => {
        this.validationMessages = response.ErrorMessages;
        if (response.ErrorMessages.length === 0) {
          this.agentSettingsSaved = this.validationMessages.length === 0 ? true : false;
          this.agentSettingsGroupForm.markAsPristine();
          if (nextTab) {
            this.nextActiveTab.emit(nextTab);
          } else if (nextMainTab) {
            this.router.navigate([nextMainTab.Key]);
          } else if (amoGoBack) {
            this.router.navigate(['home']);
          } else if (this.sharedDefaultAgentSettingsId !== null && this.agencyID !==   null) {
            this.navigateAgentGroupSettings(this.sharedDefaultAgentSettingsId,  this.agencyID);
          } else {
            this.agencyGroups = response.AgencyGroupList;
            this.isLoaded = true;
            this.appStateService.setAgencyGroups(this.agencyGroups);
            for (let index = 0; index < this.agencyGroups.length; index++) {
              this.agencyArray.controls[index].patchValue({groupChoice: this. agencyGroups[index].SharedDefaultAgentSettingsId});
            }
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    // this.utilityService.setTriggerAdminSaveAlert('admin', false);
    this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Handle a change on the radio button form controls
   * @param {number} index - index of the row that the button is being grabbed for
   * @param {number} group - Group number of current index
   * @returns {void}
   */
  handleChange(index: number, group: number): void {
    if (!group) {
      this.agencyGroups[index].SharedDefaultAgentSettingsId = 0;
    } else {
      this.agencyGroups[index].SharedDefaultAgentSettingsId = group;
    }
  }
}
