// Angular Core
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

// Services
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';

// Models
import { CreateALinkSettingsResponse } from '@allianz/agent-max-core-lib';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class AdminGetCreateALinkSettingsResolver implements Resolve<Observable<CreateALinkSettingsResponse>> {
  constructor(
    private adminUserService: AdminUserService,
    private appStateService: AppStateService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<CreateALinkSettingsResponse> {
    return this.adminUserService.getDefaultCreateALinkSettings(route.parent.params.agencyId, this.appStateService.getCredentials());
  }
}
