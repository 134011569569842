// Angular Core
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// Models
import { Security } from 'src/app/models/interfaces';
import { InputField, ErrorMessage, KeyValue } from '@allianz/agent-max-core-lib';

// Services
import { UtilityService } from 'src/app/shared/services/utility.service';
import { UserService } from 'src/app/api/services/user.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { LoadingService } from 'src/app/api/services/loading.service';

// Third Party
import { Subject } from 'rxjs';

@Component({
  selector: 'app-security-form',
  templateUrl: './security-form.component.html',
  styleUrls: ['./security-form.component.scss']
})
export class SecurityFormComponent implements OnInit, AfterViewInit {
  @Input('userOptions') userOptions: Security;
  @Input('isActive') isActive: boolean = true;
  securityValidationMessages: ErrorMessage[] = [];
  securityForm: FormGroup;
  oldPassword: FormControl = new FormControl('');
  newPassword: FormControl = new FormControl('');
  confirmPassword: FormControl = new FormControl('', [this.utilityService.pwValidator(this.newPassword)]);
  reminderQuestion: FormControl = new FormControl('');
  reminderAnswer: FormControl = new FormControl('');
  passwordStrengthSubject: Subject<any> = new Subject();
  reminderQuestions: KeyValue[];
  securitySaved: boolean = false;

  constructor(
    private userService: UserService,
    private utilityService: UtilityService,
    private appStateService: AppStateService,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    this.securityForm = new FormGroup({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword,
      reminderQuestion: this.reminderQuestion,
      reminderAnswer: this.reminderAnswer
    });
    if (!this.isActive) {
      this.securityForm.disable();
    }
  }

  /**
   * On init send an empty string to initialize the PasswordStrengthComponent
   * @returns {void}
   */
  ngAfterViewInit(): void {
    this.passwordStrengthSubject.next('');
  }

  ngOnInit(): void {
    this.reminderQuestions = this.route.snapshot.data.reminderQuestions;
    // this.securityForm.patchValue({
    //   reminderQuestion: this.userOptions.ReminderQuestion.Value,
    //   reminderAnswer: this.userOptions.ReminderAnswer.Value
    // });
  }

  /**
   * When the new password changes, update the PasswordStrengthComponent accordingly
   * @returns {void}
   */
  updateNewPw(): void {
    this.passwordStrengthSubject.next(this.newPassword.value);
  }

  /**
   * Check if the confirm password is dirty and valid
   * @returns {boolean}
   */
  isSecurityChanged(): boolean {
    if (this.newPassword && this.confirmPassword) {
      return this.confirmPassword.valid && this.confirmPassword.dirty;
    }
  }

  /**
   * Update the security info with the changed information
   * @returns {Security}
   */
  getUpdatedSecurity(): Security {
    const updatedSecurity = this.userOptions;

    updatedSecurity.OldPassword = new InputField<string>();
    updatedSecurity.OldPassword.Value = this.oldPassword.value;
    updatedSecurity.Password.Value = this.newPassword.value;
    updatedSecurity.ReminderAnswer.Value = this.reminderAnswer.value;
    updatedSecurity.ReminderQuestion.Value = this.reminderQuestion.value;
    return updatedSecurity;
  }

  /**
   * Update the userOptions Security informaiton with AgentMax service call
   * @returns {void}
   */
  submit(): void {
    if (this.securityForm.valid && this.securityForm.dirty) {
      const updatedMySecurity = this.getUpdatedSecurity();
      this.loadingService.open();
      this.userService.saveSecurity(updatedMySecurity, this.appStateService.getCredentials())
        .subscribe((result) => {
          this.securityValidationMessages = result.ValidationMessages.concat(result.OperationErrorMessages);
          this.securitySaved = (this.securityValidationMessages.length === 0) ? true : false;
          if (this.securitySaved) {
            const userOptions = this.appStateService.getUserOptions();
            userOptions.AgentProfile.Security = result;
            this.appStateService.setUserOptions(userOptions);
          }
          this.securityForm.markAsPristine();
        },
        (error) => { },
        () => this.loadingService.close());
    }
  }

}
