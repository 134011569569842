// Angular Core
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CurrencyPipe, APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { OverlayModule, Overlay } from '@angular/cdk/overlay';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { QueuesComponent } from './queues/queues.component';
import { PendingComponent } from './queues/pending-tab/pending.component';
import { QuoteTabComponent } from './queues/quote-tab/quote-tab.component';
import { ConfirmedComponent } from './queues/confirmed-tab/confirmed-tab.component';
import { UserOptionsComponent } from './user-options/user-options.component';
import { InterfaceSettingsAgencyFormComponent } from './admin/tabs/interface-settings/interface-settings-agency-form/interface-settings-agency-form.component';
import { SecurityFormComponent } from './user-options/security-form/security-form.component';
import { ProductComponent } from './product/product.component';
import { AdminComponent } from './admin/admin-wrapper/admin.component';
import { CreateALinkComponent } from './admin/tabs/agencies/admin-detail/tabs/quotemax/quotemax.component';
import { BecomeAPartnerComponent } from './become-a-partner/become-a-partner.component';
import { ProductCompareComponent } from './product-compare/product-compare.component';
import { PrintOrEmailComponent } from './print-or-email/print-or-email.component';
import { BookingInfoComponent } from './booking-info/booking-info.component';
import { HelpComponent } from './help/help.component';
import { PurchaseConfirmationComponent } from './purchase-confirmation/purchase-confirmation.component';
import { ReportsComponent } from './reports/reports.component';
import { BecomeMoreComponent } from './become-more/become-more.component';
import { AgencySettingsGeneralComponent } from './admin/tabs/agency-settings/agency-settings-general/agency-settings-general.component';
import { AgencySettingsGroupComponent } from './admin/tabs/agency-settings/agency-settings-group/agency-settings-group.component';
import { AgencySettingsGroupDetailsComponent } from './admin/tabs/agency-settings/agency-settings-group/agency-settings-group-details/agency-settings-group-details.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { MaxmailSettingsComponent } from './maxmail-settings/maxmail-settings.component';
import { ResourceCenterComponent } from './resource-center/resource-center.component';
import { SwitchAgencyComponent } from './switch-agency/switch-agency.component';
import { CreateNewAccountComponent } from './create-new-account/create-new-account.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ValidateSecurityComponent } from './validate-security/validate-security.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { GroupTabComponent } from './queues/group-tab/group-tab.component';
import { ModifySmallGroupComponent } from './modify-small-group/modify-small-group.component';
import { GroupPoliciesComponent } from './modify-small-group/group-policies/group-policies.component';
import { GroupDetailsComponent } from './modify-small-group/group-details/group-details.component';
import { ProgramProductListComponent } from './shared/components/program-product-list/program-product-list.component';
import { IndividualPolicyComponent } from './modify-small-group/group-policies/individual-policy/individual-policy.component';
import { GroupAgentPaymentComponent } from './modify-small-group/group-agent-payment/group-agent-payment.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationTabComponent } from './notifications/notification-tab/notification-tab.component';
import { AdminUserProfileComponent } from './admin/admin-user-profile/admin-user-profile/admin-user-profile.component';
import { AdminDetailComponent } from './admin/tabs/agencies/admin-detail/admin-detail.component';
import { AdminAgencyInfoComponent } from './admin/tabs/agencies/admin-detail/tabs/agency-info/admin-agency-info.component';
import { AdminAgentManagementComponent } from './admin/tabs/agencies/admin-detail/tabs/agent-management/admin-agent-management.component';
import { CreateAgentComponent } from './admin/tabs/agent-settings/create-agent/create-agent.component';
import { InterfaceSettingsDetailsComponent } from './admin/tabs/interface-settings/interface-settings-details/interface-settings-details.component';
import { ProductDetailComponent } from './admin/tabs/product-list/product-detail/product-detail.component';
import { AgentDefaultSettingsComponent } from './admin/tabs/agent-settings/agent-default-settings/agent-default-settings/agent-default-settings.component';
import { AgenciesComponent } from './admin/tabs/agencies/agencies.component';
import { ProductListComponent } from './admin/tabs/product-list/product-list.component';
import { InterfaceSettingsComponent } from './admin/tabs/interface-settings/interface-settings.component';
import { AgencySettingsComponent } from './admin/tabs/agency-settings/agency-settings.component';
import { AgentSettingsComponent } from './admin/tabs/agent-settings/agent-settings.component';
import { MaxmailComponent } from './admin/tabs/maxmail/maxmail.component';
import { AdminAgentReadonlyRecordComponent } from './admin/tabs/agencies/admin-detail/tabs/agent-management/admin-agent-readonly-record/admin-agent-readonly-record.component';
// Guards
import { CanActivateGuard } from './shared/guards/can-activate-home.guard';
import { CanActivateWithRedirectGuard } from './shared/guards/can-activate-redirect.guard';

// Resolvers
import { NotificationsResolver } from './shared/resolvers/notification.resolver';

// Services
import { AgentmaxService } from './api/services/agentmax.service';
import { AppStateService } from './shared/services/app-state.service';
import { LoadingService } from './api/services/loading.service';
import { GdsService } from './api/services/gds.service';

// Resolvers
import { UserOptionsResolver } from './shared/resolvers/user-options.resolver';
import { SuppliersResolver } from './shared/resolvers/suppliers.resolver';
import { DestinationsResolver } from './shared/resolvers/destinations.resolver';
import { AgencyProductsResolver } from './shared/resolvers/agency-products.resolver';
import { GetQuoteByBookingIdResolver } from './shared/resolvers/get-quote-by-booking-id.resolver';
import { GetGroupPoliciesByGroupIdResolver } from './shared/resolvers/get-group-policies-by-group-id.resolver';
import { GetAgentInformationByUserResolver } from './shared/resolvers/get-agent-information-by-user.resolver';
import { GetQuoteForAgencyProductsResolver } from './shared/resolvers/get-quote-for-agency-products.resolver';
import { GetQuoteForProductComparisonResolver } from './shared/resolvers/get-quote-for-product-comparison.resolver';
import { GetMaxmailSecondChanceSettingsByBookingIdResolver } from './shared/resolvers/get-maxmail-settings-by-booking-id.resolver';
import { GetProgramProductsForMaxmailResolver } from './shared/resolvers/get-program-products-for-maxmail.resolver';
import { GetAgenciesForAdministrationResolver } from './shared/resolvers/get-agencies-for-administration.resolver';
import { GetSalesReportDefaultsResolver } from './shared/resolvers/get-sales-report-defaults.resolver';
import { GetResourceBlocksResolver } from './shared/resolvers/get-agency-resource-blocks.resolver';
import { GetUserOptionsResolver } from './shared/resolvers/get-user-options.resolver';
import { GetBusinessTypesResolver } from './shared/resolvers/get-business-types.resolver';
import { GetWorkLocationsResolver } from './shared/resolvers/get-work-locations.resolver';
import { GetProfileRolesResolver } from './shared/resolvers/get-profile-roles.resolver';
import { GetReminderQuestionsResolver } from './shared/resolvers/get-reminder-questions.resolver';
import { GetRolesByemailTokenResolver } from './shared/resolvers/get-roles-by-email-token.resolver';
import { GetBusinessTypesByemailTokenResolver } from './shared/resolvers/get-business-types-by-email-token.resolver';
import { GetWorkLocationsByemailTokenResolver } from './shared/resolvers/get-work-locations-by-email-token.resolver';
import { GetReminderQuestionsByemailTokenResolver } from './shared/resolvers/get-reminder-questions-by-email-token.resolver';
import { GetSuppliersResolver } from './shared/resolvers/get-suppliers.resolver';
import { GetStatesResolver } from './shared/resolvers/get-states.resolver';
import { GetNewBookingResolver } from './shared/resolvers/get-new-booking.resolver';
import { GetAllowManageGroupStatusByUserByUserResolver } from './shared/resolvers/get-can-manage-groups-by-user.resolver';
import { GetSmallGroupQuoteByBookingIdResolver } from './shared/resolvers/get-small-group-quote-by-booking-id.resolver';
import { ActivateWebAccountByAdminResolver } from './shared/resolvers/activate-web-account-by-admin.resolver';
import { CreateWebAccountResolver } from './shared/resolvers/create-web-account.resolver';
import { ResetPasswordByAdminResolver } from './shared/resolvers/reset-password-by-admin.resolver';
import { AdminGetAgencyFromAgencyIdResolver } from './shared/resolvers/admin-get-agencies-from-agency-id.resolver';
import { AdminGetAgencySettingsResolver } from './shared/resolvers/admin-get-agency-settings.resolver';
import { AdminGetAgencyAddressAndLogoResolver } from './shared/resolvers/admin-get-agency-address-and-log.resolver';
import { AdminGetGdsInterfaceSettingsResolver } from './shared/resolvers/admin-get-gds-interface-settings.resolver';
import { AdminAgentProfileManagementsResolver } from './shared/resolvers/admin-agent-profile-management.resolver';
import { AdminGetCreateALinkSettingsResolver } from './shared/resolvers/admin-get-create-a-link-settings.resolver';
import { AdminGetUserOptionsResolver } from './shared/resolvers/admin-get-user-options.resolver';
import { GetAddressOptionsSourceTypeResolver } from './shared/resolvers/get-address-options-source-type.resolver';
import { GetSCByMaxMailOptionsSourceTypeResolver } from './shared/resolvers/get-maxMail-options-source-type.resolver';
import { UserGetBusinessTypesResolver } from './shared/resolvers/user-get-business-types.resolver';
import { AdminGetReportingRightsResolver } from './shared/resolvers/admin-get-reporting-rights.resolver';
import { AdminGetAdministrativeRolesResolver } from './shared/resolvers/admin-get-administrative-roles.resolver';
import { AdminGetSellingRolesResolver } from './shared/resolvers/admin-get-selling-roles.resolver';
import { UserGetWorkLocationsResolver } from './shared/resolvers/user-get-work-locations.resolver';
import { UserGetProfileRolesResolver } from './shared/resolvers/user-get-profile-roles.resolver';
import { GetInterfaceOptionsSourceTypeResolver } from './shared/resolvers/get-interface-options-source-type.resolver';
import { AdminGetAgencyGroupsForAgencyResolver } from './shared/resolvers/admin-get-agency-groups-for-agency.resolver';

// Third Party
import { BsDatepickerConfig, AccordionConfig } from 'ngx-bootstrap';
import { LoadingServiceInterceptor } from './interceptors/http-loading-service.interceptor';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SessionTimeOutComponent } from './session-time-out/session-time-out.component';
import { UserIdleModule } from 'angular-user-idle';
import {NgxPaginationModule} from 'ngx-pagination';
import { BrochureComponent } from './brochure/brochure.component';

import { CryptoService } from './api/services/crypto-service.service';


/**
 * Get date picker config for bootstrap date picker
 * @returns {BsDatepickerConfig} - the date picker config
 */
export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    containerClass: 'theme-default',
    dateInputFormat: 'MM/DD/YYYY',
    showWeekNumbers: false
  });
}

/**
 * Get accordion config for bootstrap accordion
 * @returns {AccordionConfig} - the accordion config
 */
export function getAccordionConfig(): AccordionConfig {
  return Object.assign(new AccordionConfig(), { isAnimated: true });
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    QueuesComponent,
    AdminUserProfileComponent,
    AdminDetailComponent,
    PendingComponent,
    QuoteTabComponent,
    ConfirmedComponent,
    UserOptionsComponent,
    InterfaceSettingsAgencyFormComponent,
    SecurityFormComponent,
    ProductComponent,
    BookingInfoComponent,
    AdminComponent,
    CreateALinkComponent,
    AdminAgencyInfoComponent,
    AdminAgentManagementComponent,
    InterfaceSettingsDetailsComponent,
    ProductDetailComponent,
    AgentDefaultSettingsComponent,
    CreateAgentComponent,
    BecomeAPartnerComponent,
    HelpComponent,
    ProductCompareComponent,
    PrintOrEmailComponent,
    PurchaseConfirmationComponent,
    ReportsComponent,
    AgencySettingsGeneralComponent,
    AgencySettingsGroupComponent,
    AgencySettingsGroupDetailsComponent,
    AdminAgentReadonlyRecordComponent,
    AgenciesComponent,
    SwitchAgencyComponent,
    MaxmailSettingsComponent,
    BecomeMoreComponent,
    GlossaryComponent,
    ResourceCenterComponent,
    ValidateSecurityComponent,
    UserProfileComponent,
    CreateNewAccountComponent,
    ForgotPasswordComponent,
    GroupPoliciesComponent,
    GroupTabComponent,
    ModifySmallGroupComponent,
    GroupDetailsComponent,
    ProgramProductListComponent,
    ProductListComponent,
    InterfaceSettingsComponent,
    GroupAgentPaymentComponent,
    AgencySettingsComponent,
    AgentSettingsComponent,
    MaxmailComponent,
    IndividualPolicyComponent,
    NotificationsComponent,
    NotificationTabComponent,
    SessionTimeOutComponent,
    BrochureComponent
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    OverlayModule,
    TypeaheadModule.forRoot(),
    UserIdleModule.forRoot({idle: 1800, timeout: 2, ping: 5}),
    NgxPaginationModule
  ],
  entryComponents: [],
  providers: [
    CanActivateGuard,
    CanActivateWithRedirectGuard,
    { provide: APP_BASE_HREF, useValue: '/agentmaxweb/agentportal/index.html#' },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingServiceInterceptor, multi: true },
    HttpClientModule,
    AgentmaxService,
    AppStateService,
    CurrencyPipe,
    LoadingService,
    GdsService,
    Overlay,
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
    { provide: AccordionConfig, useFactory: getAccordionConfig },
    UserOptionsResolver,
    SuppliersResolver,
    DestinationsResolver,
    AgencyProductsResolver,
    GetQuoteByBookingIdResolver,
    GetGroupPoliciesByGroupIdResolver,
    NotificationsResolver,
    GetAgentInformationByUserResolver,
    GetQuoteForAgencyProductsResolver,
    GetQuoteForProductComparisonResolver,
    GetMaxmailSecondChanceSettingsByBookingIdResolver,
    GetProgramProductsForMaxmailResolver,
    GetAgenciesForAdministrationResolver,
    GetSalesReportDefaultsResolver,
    GetResourceBlocksResolver,
    GetUserOptionsResolver,
    GetBusinessTypesResolver,
    GetWorkLocationsResolver,
    GetProfileRolesResolver,
    GetReminderQuestionsResolver,
    GetRolesByemailTokenResolver,
    GetBusinessTypesByemailTokenResolver,
    GetWorkLocationsByemailTokenResolver,
    GetReminderQuestionsByemailTokenResolver,
    GetSuppliersResolver,
    GetStatesResolver,
    GetNewBookingResolver,
    GetAllowManageGroupStatusByUserByUserResolver,
    GetSmallGroupQuoteByBookingIdResolver,
    ActivateWebAccountByAdminResolver,
    CreateWebAccountResolver,
    ResetPasswordByAdminResolver,
    AdminGetAgencyFromAgencyIdResolver,
    AdminGetAgencySettingsResolver,
    AdminGetAgencyAddressAndLogoResolver,
    AdminGetGdsInterfaceSettingsResolver,
    AdminAgentProfileManagementsResolver,
    AdminGetCreateALinkSettingsResolver,
    AdminGetUserOptionsResolver,
    UserGetBusinessTypesResolver,
    UserGetProfileRolesResolver,
    UserGetWorkLocationsResolver,
    AdminGetSellingRolesResolver,
    AdminGetAdministrativeRolesResolver,
    AdminGetReportingRightsResolver,
    GetAddressOptionsSourceTypeResolver,
    GetSCByMaxMailOptionsSourceTypeResolver,
    GetInterfaceOptionsSourceTypeResolver,
    AdminGetAgencyGroupsForAgencyResolver,
    CryptoService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
