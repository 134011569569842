// Angular Core
import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// Services
import { AppStateService } from '../../services/app-state.service';
import { QuoteService } from 'src/app/api/services/quote.service';
import { LoadingService } from 'src/app/api/services/loading.service';

// Third Party
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';

// Core Lib
import { Booking } from '@allianz/agent-max-core-lib';

@Component({
  selector: 'app-delete-policy-modal',
  templateUrl: './delete-policy-modal.component.html',
  styleUrls: ['./delete-policy-modal.component.scss']
})
export class DeletePolicyModalComponent implements OnInit {
  safeHTML: SafeHtml;
  @Input() text: string;
  @Input() dialogIndicator: string;
  @Input() booking: Booking;
  public modalResponse: Subject<boolean> = new Subject();

  constructor(
    public bsModalRef: BsModalRef,
    private sanitizer: DomSanitizer,
    private quoteService: QuoteService,
    private appStateService: AppStateService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.safeHTML = this.sanitizer.bypassSecurityTrustHtml(this.text);
  }

   /**
    * Close the policy conifrmation dialog modal
    * @returns {void}
    */
  closeThisDialog(): void {
    this.modalResponse.next(false);
    this.bsModalRef.hide();
  }

  /**
   * Deletes the policy if the policy is in a deleteable state
   * @returns {void}
   */
  deletePolicy(): void {
    this.loadingService.open();
    this.quoteService.deleteQuote(this.booking.BookingID.toString(), this.appStateService.getCredentials())
      .subscribe((response) => {
        this.modalResponse.next(response);
        this.bsModalRef.hide();
      },
      (error) => this.loadingService.open(),
      () => this.loadingService.open());
  }
}
