// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Models
import { Booking } from '@allianz/agent-max-core-lib';

// Serivces
import { QuoteService } from 'src/app/api/services/quote.service';
import { AppStateService } from '../services/app-state.service';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class GetNewBookingResolver implements Resolve <Observable<Booking>> {
  constructor(
    private quoteService: QuoteService,
    private appStateService: AppStateService
  ) { }
  resolve(): Observable<Booking> {
    return this.quoteService.generateNewWebBooking(this.appStateService.getCredentials());
  }
}
