"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var quote_product_information_class_1 = require("./quote-product-information.class");
var trip_information_class_1 = require("./trip-information.class");
var travel_information_class_1 = require("./travel-information.class");
var additional_information_class_1 = require("./additional-information.class");
var flight_itinerary_information_class_1 = require("./flight-itinerary-information.class");
var gds_information_class_1 = require("./gds-information.class");
var hotel_information_class_1 = require("./hotel-information.class");
var rental_car_information_class_1 = require("./rental-car-information.class");
var Booking = /** @class */ (function () {
    function Booking() {
        this.ErrorMessages = new Array();
        this.Message = undefined;
        this.TransactionNumber = undefined;
        this.TransactionStatus = undefined;
        this.QuoteCode = undefined;
        this.BookingID = 0;
        this.IsQuoteValid = false;
        this.BookingGAS = 0;
        this.PolicyArchiveLocation = undefined;
        this.AnalyticsInfo = undefined;
        this.HasValidationErrors = true;
        this.BookingSource = 0;
        this.BookingStatus = 0;
        this.AdditionalInformation = new additional_information_class_1.AdditionalInformation();
        this.BookingAgentInformation = undefined;
        this.IsValid = true;
        this.GDSInformation = new gds_information_class_1.GDSInformation();
        this.HotelInformation = new hotel_information_class_1.HotelInformation();
        this.OrderInformation = undefined;
        this.TripInformation = new trip_information_class_1.TripInformation();
        this.TravelInformation = new travel_information_class_1.TravelInformation();
        this.FlightItineraryInformation = new flight_itinerary_information_class_1.FlightItineraryInformation();
        this.RentalCarInformation = new rental_car_information_class_1.RentalCarInformation();
        this.ProductInformation = new quote_product_information_class_1.QuoteProductInformation();
        this.SecondChanceInformation = undefined;
        this.MarketingInformation = null;
        this.CoSource = undefined;
        this.Source = 0;
        this.FrequentFlyer = false;
        this.WidgetId = 0;
    }
    return Booking;
}());
exports.Booking = Booking;
