// Angular Core
import { Component, OnInit, ViewChild, DoCheck } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// Models
import { Booking, BookingTypes, BookingAgentInformation, ProductPayload } from '@allianz/agent-max-core-lib';
import { ConfirmationViewType, PrintOrEmailDocumentType, PrintOrEmailTypes, PrintOrEmailReportType, PrintOrEmailOfferType } from 'src/app/models/enums';
import { PrintOrEmailOptions, AgencySettings } from '../models/interfaces';

// Components
import { AdditionalInformationComponent } from '../shared/components/additional-information/additional-information.component';
import { TripInformationComponent } from '../shared/components/trip-information/trip-information.component';
import { SupplierListComponent } from '../shared/components/supplier-list/supplier-list.component';
import { TravelerInformationComponent } from '../shared/components/traveler-information/traveler-information.component';
import { FlightInformationComponent } from '../shared/components/flight-information/flight-information.component';
import { CheckoutComponent } from '../shared/components/checkout/checkout.component';

// Third Party
import { Subscription } from 'rxjs';

// Services
import { AppStateService } from '../shared/services/app-state.service';
import { QuoteService } from '../api/services/quote.service';
import { PolicyService } from '../api/services/policy.service';
import { UtilityService } from '../shared/services/utility.service';

@Component({
  selector: 'app-booking-info',
  templateUrl: './booking-info.component.html',
  styleUrls: ['./booking-info.component.scss']
})
export class BookingInfoComponent implements OnInit, DoCheck {

  billingAddressSub: Subscription;
  booking: Booking = new Booking();
  isModify: boolean = true;
  quoteSaved: boolean = false;
  sourceType: string;
  agentInfo: BookingAgentInformation;
  bookingInfoForm: FormGroup;
  isSCEnabled: FormControl = new FormControl(false);
  bookingInfoType: BookingTypes;
  fromQueues: boolean;
  pageHeading: string = '';
  priceBeforeModify: number;
  quoteChanged: boolean = false;
  agencySettings: AgencySettings;

  @ViewChild(TripInformationComponent, { static: true })
  public tripInformationComponent: TripInformationComponent;

  @ViewChild(SupplierListComponent, { static: true })
  public supplierListComponent: SupplierListComponent;

  @ViewChild(AdditionalInformationComponent, { static: true })
  public additionalInformationComponent: AdditionalInformationComponent;

  @ViewChild(TravelerInformationComponent, { static: true })
  public travelerInformationComponent: TravelerInformationComponent;

  @ViewChild(FlightInformationComponent, { static: false })
  public flightInformationComponent: FlightInformationComponent;

  @ViewChild(CheckoutComponent, { static: false })
  public checkoutComponent: CheckoutComponent;

  constructor(
    private quoteService: QuoteService,
    private policyService: PolicyService,
    private appStateService: AppStateService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private utilityService: UtilityService
  ) {
    this.bookingInfoForm = this.formBuilder.group({
      isSCEnabled: this.isSCEnabled,
    });
  }

  ngOnInit(): void {
    this.agentInfo = this.appStateService.getAgentInformation();
    this.booking = this.route.snapshot.data.booking;
    this.agencySettings = this.route.snapshot.data.agencySettings;
    this.appStateService.setCurrentBookingQuote(this.booking);
    this.bookingInfoType = this.appStateService.getBookingInfoViewType();
    this.sourceType = this.appStateService.getBookingInfoSourceType();
    this.pageHeading = this.getPageHeadingText();
    this.bookingInfoForm.patchValue({ isSCEnabled: this.booking.SecondChanceInformation.IsSCEnabled.Value });
    this.priceBeforeModify = this.booking.OrderInformation.Price;

    this.scrollToPageHeader();
  }

    /**
   * Scroll the current page to the top by focusing on the element page-header of the master page.
   * @returns {boolean}
   */
  scrollToPageHeader(): void {
    let top = document.getElementById('page-header');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  /**
   * Hides a block of HTML based on condition
   * @param {string} type - type of block to hide
   * @returns {boolean}
   */
  hideBlock(type: string): boolean {
    if (type === 'buttons') {
      switch (this.bookingInfoType) {
        case BookingTypes.Policy:
          return false;
        case BookingTypes.Pending:
          return false;
        case BookingTypes.EditFlights:
          return false;
        default:
          return true;
      }
    }
    return this.bookingInfoType === BookingTypes.Pending;
  }

  /**
   *  this checks if any of these forms have been interacted with, and if so enables the checkout form
   * @returns {void}
   */
  ngDoCheck(): void {
    if (this.tripInformationComponent.TripInformationForm.dirty || this.tripInformationComponent.supplierListComponent.SupplierListForm.dirty || this.travelerInformationComponent.travelerInformationForm.dirty || (this.flightInformationComponent && this.flightInformationComponent.flightInformationForm.dirty) || this.additionalInformationComponent.form.dirty) {
      if (this.isModify) {
        this.quoteChanged = this.isQuoteChanged();
        this.checkoutComponent.checkoutForm.markAsDirty();
        this.utilityService.disableCreditCardFields(this.booking, this.priceBeforeModify, this.checkoutComponent);
      } else {
        this.checkoutComponent.checkoutForm.enable();
      }
    }
  }

  /**
   * clicking the back button
   * @returns {void}
   */
  onCloseBooking(): void {
    if (this.booking.BookingGroupID !== 0 && this.booking.BookingID !== this.booking.BookingGroupID) {
      this.router.navigate([`small-group/modifyGroup/${this.booking.BookingGroupID}/policies`]);
    } else {
      this.router.navigate(['queues']);
    }
  }

  /**
   * gets the page heading for the booking info
   * @returns {string | null}
   */
  getPageHeadingText(): string | null {
    const isPolicyView = this.appStateService.getBookingInfoViewType() === BookingTypes.Policy;
    const isPendingPolicyView = this.appStateService.getBookingInfoViewType() === BookingTypes.Pending;
    const isEditFlightsPolicyView = this.appStateService.getBookingInfoViewType() === BookingTypes.EditFlights;
    this.fromQueues = this.sourceType === 'NewBooking' ? false : true;
    if (isPendingPolicyView && !this.booking.OrderInformation.IsPurchaseConfirmed) {
      return 'Retry Pending';
    } else if (isPolicyView && this.booking.OrderInformation.IsPurchaseConfirmed) {
      return 'Modify Policy';
    } else if (isEditFlightsPolicyView && this.booking.OrderInformation.IsPurchaseConfirmed) {
      return 'Edit Flight Information';
    } else if (!isPolicyView || !isPendingPolicyView || !isEditFlightsPolicyView) {
      this.isModify = false;
      return `Quote ${this.booking.BookingID}`;
    }
    return;
  }

  /**
   * Modifies the booking by calling the policy service
   * @returns {void}
   */
  modifyPurchase(): void {
    this.updateBooking();
    const payload: ProductPayload = {
      BillingInformation: this.booking.OrderInformation.BillingInformation,
      Booking: this.booking,
      ErrorMessages: []
    };

    this.policyService.modifyPurchase(this.booking.BookingID, this.booking.OrderInformation.ConfirmationDetails.Policies[0].Key, payload, this.appStateService.getCredentials())
      .subscribe((result) => {
        if (result.IsPurchaseSuccessful
            && (result.Booking.OrderInformation.IsPurchaseConfirmed
            ||  result.Booking.BookingStatus === 15)) {  //PNR Pending - Modified
          this.appStateService.setCurrentBookingQuote(result.Booking);
          this.appStateService.setCurrentBookingId(null);
          this.appStateService.setConfirmationViewType(ConfirmationViewType.NewConfirmation);
          this.sendPurchaseToGA(result);
          this.router.navigate(['purchaseconfirmation'], {state: {newOrModifiedBooking: true}});
        }
        this.booking = result.Booking;
        this.tripInformationComponent.TripInformation.ValidationMessages = result.Booking.TripInformation.ValidationMessages;
        this.travelerInformationComponent.TravelInformation.ValidationMessages = result.Booking.TravelInformation.ValidationMessages;
        this.flightInformationComponent.booking.FlightItineraryInformation.ValidationMessages = result.Booking.FlightItineraryInformation.ValidationMessages;
        this.checkoutComponent.billingInfoValidation = result.BillingInformation.ValidationMessages;
        this.checkoutComponent.purchaseErrors = result.Booking.ErrorMessages.concat(result.ErrorMessages);
      });
  }

  /**
   * routes the user back to the product page.
   * @returns {void}
   */
  onEditProduct(): void {
    this.updateBooking();
    this.appStateService.setCurrentBookingQuote(this.booking);
    this.router.navigate(['product']);
  }

  /**
   * used in the modify workflow, updates the bookings price based on a change in user data.
   * @returns {void}
   */
  reCalculatePrice(): void {
    this.updateBooking();
    this.priceBeforeModify = this.booking.OrderInformation.Price;
    this.quoteService.reCalculatePrice(this.booking, this.appStateService.getCredentials())
      .subscribe((result) => {
        this.booking = result;
        if (this.tripInformationComponent.TripInformationForm.dirty) {
          this.tripInformationComponent.TripInformationForm.markAsPristine();
        }
        if (this.tripInformationComponent.supplierListComponent.SupplierListForm.dirty) {
          this.tripInformationComponent.supplierListComponent.SupplierListForm.markAsPristine();
        }
        if (this.flightInformationComponent && this.flightInformationComponent.flightInformationForm.dirty) {
          this.flightInformationComponent.flightInformationForm.markAsPristine();
        }
        this.checkoutComponent.checkoutForm.enable();
        this.utilityService.disableCreditCardFields(this.booking, this.priceBeforeModify, this.checkoutComponent);
        this.quoteChanged = this.isQuoteChanged();
        this.updateBooking();
      });
  }

  /**
   * Retry booking from pending queues
   * @returns {void}
   */
  retryPendingBooking(): void {
    this.booking = this.utilityService.updateBillingInfo(this.booking, this.checkoutComponent);
    const payload: ProductPayload = {
      Booking: this.booking,
      BillingInformation: this.booking.OrderInformation.BillingInformation,
      ErrorMessages: []
    };
    this.quoteService.retryPendingBooking(payload, this.appStateService.getCredentials())
      .subscribe((response) => {
        if (response.IsPurchaseSuccessful) {
          this.appStateService.setCurrentBookingQuote(response.Booking);
          this.appStateService.setCurrentBookingId(null);
          if (response.Booking.OrderInformation.IsPurchaseConfirmed) {
            this.appStateService.setConfirmationViewType(ConfirmationViewType.NewConfirmation);
          } else {
            this.appStateService.setConfirmationViewType(ConfirmationViewType.Cancel);
          }
          this.router.navigate(['purchaseconfirmation'], {state: {newOrModifiedBooking: true}});
        } else {
          this.booking = response.Booking;
          this.checkoutComponent.billingInfoValidation = response.BillingInformation.ValidationMessages;
          this.checkoutComponent.purchaseErrors = response.Booking.ErrorMessages.concat(response.ErrorMessages);
        }
      });
  }

  sendPurchaseToGA(result: any) : void {
     if(result != null && result.Booking != null && result.Booking.BookingAgentInformation != null) {
      const dataLayer = (< any > window).dataLayer || [];
      var transactionProducts = [];
      var uniqueNumber = result.Booking.BookingID;
      if(result.Booking.OrderInformation != null && result.Booking.OrderInformation.ConfirmationDetails!=null && result.Booking.OrderInformation.ConfirmationDetails.Policies!=null){
        uniqueNumber = result.Booking.OrderInformation.ConfirmationDetails.Policies[0].Key;
      }
      for (var i = 0; i < result.Booking.ProductInformation.ProgramProducts.length; i++) {
        if(result.Booking.ProductInformation.ProgramProducts[i].Product != null)
        {
          transactionProducts.push({
            'id': uniqueNumber,
            'sku': result.Booking.ProductInformation.ProgramProducts[i].Product.ProductId.toString(),
            'name': result.Booking.ProductInformation.ProgramProducts[i].Product.ProductName,
            'price': result.Booking.ProductInformation.ProgramProducts[i].Price,
            'quantity': 1
          });
        }
      }
      dataLayer.push({
        'transactionId': uniqueNumber,
        'transactionAffiliation': result.Booking.BookingAgentInformation.Accam,
        'transactionTotal': result.Booking.OrderInformation.Price,
        'transactionProducts': transactionProducts,
        'event': 'transactionComplete'
      });
    }
  }

  /**
   * submits the purchase based on the formdata calls the
   * quoteservice to purchase, and if any errors are found updates the form
   * @returns {void}
   */
  bookingPurchase(): void {
    this.updateBooking();
    const payload: ProductPayload = {
      BillingInformation: this.booking.OrderInformation.BillingInformation,
      Booking: this.booking,
      ErrorMessages: []
    };
    this.quoteService.saveAndPurchase(payload, this.appStateService.getCredentials())
      .subscribe((result) => {
        if (result.IsPurchaseSuccessful && result.Booking.OrderInformation.IsPurchaseConfirmed) {
          this.appStateService.setCurrentBookingQuote(result.Booking);
          this.appStateService.setCurrentBookingId(null);
          this.appStateService.setConfirmationViewType(ConfirmationViewType.NewConfirmation);
          this.sendPurchaseToGA(result);
          this.router.navigate(['purchaseconfirmation'], {state: {newOrModifiedBooking: true}});
        }
        this.booking = result.Booking;
        if (this.travelerInformationComponent.travelerArray.value.length !== this.booking.TravelInformation.OtherTravelers.length) {
          this.travelerInformationComponent.TravelInformation.OtherTravelers = this.booking.TravelInformation.OtherTravelers;
          this.travelerInformationComponent.initTravelerArray();
        }
        this.tripInformationComponent.TripInformation.ValidationMessages = result.Booking.TripInformation.ValidationMessages;
        this.travelerInformationComponent.TravelInformation.ValidationMessages = result.Booking.TravelInformation.ValidationMessages;
        if (this.flightInformationComponent) {
          this.flightInformationComponent.booking.FlightItineraryInformation.ValidationMessages = result.Booking.FlightItineraryInformation.ValidationMessages;
        }
        this.checkoutComponent.billingInfoValidation = result.BillingInformation.ValidationMessages;
        this.checkoutComponent.purchaseErrors = result.ErrorMessages;
        if (this.tripInformationComponent.TripInformationForm.dirty) {
          this.tripInformationComponent.TripInformationForm.markAsPristine();
        }
        if (this.tripInformationComponent.supplierListComponent.SupplierListForm.dirty) {
          this.tripInformationComponent.supplierListComponent.SupplierListForm.markAsPristine();
        }
        if (this.flightInformationComponent && this.flightInformationComponent.flightInformationForm.dirty) {
          this.flightInformationComponent.flightInformationForm.markAsPristine();
        }
      });
  }

  /**
   * saves the quote
   * @returns {void}
   */
  saveQuote(): void {
    this.updateBooking();
    this.quoteService.saveQuote(this.booking, this.appStateService.getCredentials())
      .subscribe((result) => {
        this.quoteSaved = result.HasValidationErrors === false && result.IsValid;
        this.booking = result;
        this.appStateService.setCurrentBookingQuote(result);
        this.tripInformationComponent.TripInformation.ValidationMessages = result.TripInformation.ValidationMessages;
        this.travelerInformationComponent.TravelInformation.ValidationMessages = result.TravelInformation.ValidationMessages;
        if (this.flightInformationComponent) {
          this.flightInformationComponent.booking.FlightItineraryInformation.ValidationMessages = result.FlightItineraryInformation.ValidationMessages;
        }
        this.booking.ErrorMessages = result.ErrorMessages.concat(result.SecondChanceInformation.ValidationMessages);
        if (this.tripInformationComponent.TripInformationForm.dirty) {
          this.tripInformationComponent.TripInformationForm.markAsPristine();
        }
        if (this.tripInformationComponent.supplierListComponent.SupplierListForm.dirty) {
          this.tripInformationComponent.supplierListComponent.SupplierListForm.markAsPristine();
        }
        if (this.flightInformationComponent && this.flightInformationComponent.flightInformationForm.dirty) {
          this.flightInformationComponent.flightInformationForm.markAsPristine();
        }
      });
  }

  /**
   * saves the quote, then forwards to the currently not existant email page
   * @returns {void}
   */
  saveQuoteForEmail(): void {
    this.updateBooking();
    this.quoteService.saveQuoteForEmail(this.booking, this.appStateService.getCredentials())
      .subscribe((result) => {
        if (result.HasValidationErrors === false && result.IsValid) {
          const options: PrintOrEmailOptions = {
            HeaderName: 'Print Or Email Quote',
            DocumentType: PrintOrEmailDocumentType.BookingDocument,
            ReportType: PrintOrEmailReportType.WebQuoteByAgent,
            BookingId: result.BookingID,
            OfferType: PrintOrEmailOfferType.FirstChance,
            ShowViewButton: true,
            EmailInput: {
              FromName: `${this.agentInfo.FirstName} ${this.agentInfo.LastName}`,
              FromEmailAddress: this.agentInfo.EmailAddress,
              ToName: `${result.TravelInformation.PrimaryTraveler.Info.FirstName.Value} ${result.TravelInformation.PrimaryTraveler.Info.LastName.Value}`,
              ToEmailAddress: result.TravelInformation.PrimaryTraveler.EmailAddress.Value,
              SecondaryEmailAddress: result.SecondaryEmail
            }
          };
          this.appStateService.setPrintOrEmailReportName(PrintOrEmailTypes.Quote);
          this.appStateService.setPrintOrEmailData(result, options);
          this.router.navigate(['printoremail']);
        }
        this.booking = result;
        this.appStateService.setCurrentBookingQuote(result);
        this.tripInformationComponent.TripInformation.ValidationMessages = result.TripInformation.ValidationMessages;
        this.travelerInformationComponent.TravelInformation.ValidationMessages = result.TravelInformation.ValidationMessages;
        if (this.flightInformationComponent) {
          this.flightInformationComponent.booking.FlightItineraryInformation.ValidationMessages = result.FlightItineraryInformation.ValidationMessages;
        }
        this.booking.ErrorMessages = result.ErrorMessages.concat(result.SecondChanceInformation.ValidationMessages);
        if (this.tripInformationComponent.TripInformationForm.dirty) {
          this.tripInformationComponent.TripInformationForm.markAsPristine();
        }
        if (this.tripInformationComponent.supplierListComponent.SupplierListForm.dirty) {
          this.tripInformationComponent.supplierListComponent.SupplierListForm.markAsPristine();
        }
        if (this.flightInformationComponent && this.flightInformationComponent.flightInformationForm.dirty) {
          this.flightInformationComponent.flightInformationForm.markAsPristine();
        }
      });
  }

  /**
   * checks the trip and flight info to see if they have changed if so add recalc button
   * @returns {boolean}
   */
  isQuoteChanged(): boolean {
    if (this.tripInformationComponent.TripInformationForm.dirty || this.tripInformationComponent.supplierListComponent.SupplierListForm.dirty || (this.flightInformationComponent && this.flightInformationComponent.flightInformationForm.dirty)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * updates the booking object based on all the various sub components
   * @returns {void}
   */
  updateBooking(): void {
    this.booking.SecondChanceInformation.IsSCEnabled.Value = this.bookingInfoForm.controls.isSCEnabled.value;
    this.booking = this.utilityService.updateAdditionalInformation(this.booking, this.additionalInformationComponent);
    this.booking = this.utilityService.updateTripInfo(this.booking, this.tripInformationComponent, this.tripInformationComponent.supplierListComponent);
    this.booking = this.utilityService.updateTravelerInfo(this.booking, this.travelerInformationComponent);
    this.booking = this.utilityService.updateBillingInfo(this.booking, this.checkoutComponent);
    this.booking = this.utilityService.updateFlightInfo(this.booking, this.flightInformationComponent);
    this.appStateService.setCurrentBookingQuote(this.booking);
  }

}
