export class PayGroupPoliciesBannerData {
  ShowGroupPolicyPurchaseBanner: boolean;
  NumberOfGroupPoliciesSubmitted: number;
  GroupPolicyName: string;
  TotalPurchasePrice: number;

  constructor() {
    this.ShowGroupPolicyPurchaseBanner = false;
    this.NumberOfGroupPoliciesSubmitted = 0;
    this.GroupPolicyName = '';
    this.TotalPurchasePrice = 0;
  }
}
