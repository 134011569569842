// Angular Core
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserProfileRoute } from 'src/app/models/enums';

// Interfaces
import { AdminUserOptions } from 'src/app/models/interfaces';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';

@Component({
  selector: 'app-admin-user-profile',
  templateUrl: './admin-user-profile.component.html',
  styleUrls: ['./admin-user-profile.component.scss']
})
export class AdminUserProfileComponent implements OnInit {
  userOptions: AdminUserOptions;
  userProfileRoute: typeof UserProfileRoute = UserProfileRoute;
  constructor(
    private activatedRoute: ActivatedRoute,
    private appStateService: AppStateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userOptions = this.activatedRoute.snapshot.data.userOptions;
  }

  /**
   * Reroutes to the agent management tab
   * @returns {void}
   */
  goToAdmin(): void {
    const currentAgencyId = this.appStateService.getAgencyInformation().AgencyId;

    this.router.navigate([`admin/agencies/${currentAgencyId}/agent-management`]);
  }
}
