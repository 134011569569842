// Angular Core
import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

// Models
import { SupplierInfo, AgencyProducts, State, ProgramProduct, Booking, ErrorMessage } from '@allianz/agent-max-core-lib';

// Components
import { QuoteFormComponent } from 'src/app/shared/components/quote-form/quote-form.component';
import { AdditionalInformationComponent } from 'src/app/shared/components/additional-information/additional-information.component';
import { ProgramProductListComponent } from 'src/app/shared/components/program-product-list/program-product-list.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { QuoteService } from 'src/app/api/services/quote.service';
import { LoadingService } from 'src/app/api/services/loading.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

// Third Party
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss']
})
export class GroupDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(QuoteFormComponent, { static: true })
  public quoteFormComponent: QuoteFormComponent;

  @ViewChild(AdditionalInformationComponent, { static: true })
  public additionalInformationComponent: AdditionalInformationComponent;

  @ViewChild(ProgramProductListComponent, { static: true })
  public programProductListComponent: ProgramProductListComponent;

  @Output() activeTabEventEmmitter: EventEmitter<ProgramProduct> = new EventEmitter<ProgramProduct>();
  @Output() disableTabsEventEmmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Input('goToQueues') goToQueues: boolean;
  destinations: State[];
  suppliers: SupplierInfo[];
  agencyProducts: AgencyProducts;
  datePipe: DatePipe = new DatePipe('en-US');
  currentSmallGroupProduct: ProgramProduct = null;
  booking: Booking;
  bsModalRef: BsModalRef;
  policies: Booking[] = [];
  isActiveTabToggleCalled: boolean = false;
  groupDetailsSubcriptions: Subscription[] = [];
  isFromSaveGroup = false;
  constructor(
    private appStateService: AppStateService,
    private router: Router,
    private route: ActivatedRoute,
    private quoteService: QuoteService,
    private bsModalService: BsModalService,
    private utilityService: UtilityService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.isFromSaveGroup = false;
    this.isActiveTabToggleCalled = false;
    if (this.route.snapshot.data.policies.Bookings) {
      this.policies = this.programProductListComponent.policies = this.route.snapshot.data.policies.Bookings;
    }
    this.appStateService.setCurrentBookingQuote(this.route.snapshot.data.booking);
    this.suppliers = this.route.snapshot.data.suppliers;
    this.destinations = this.route.snapshot.data.destinations;
    this.agencyProducts = this.route.snapshot.data.agencyProducts || null;
    this.booking = this.route.snapshot.data.booking;
    if (this.agencyProducts === null || this.agencyProducts.ProgramProducts.length === 0) {
      this.quoteFormComponent.GroupName.setErrors(null);
      this.quoteFormComponent.Suppliers.setErrors(null);
      const agentCode = this.booking.AdditionalInformation.AgentID.Value;
      const tripId = this.booking.AdditionalInformation.TripID.Value;
      this.booking = this.updateGroupPolicy();
      // update group policy for some reason, it used the values of additional component form which at this point is null or empty.
      // i am setting the value again here
      this.booking.AdditionalInformation.AgentID.Value = agentCode;
      this.booking.AdditionalInformation.TripID.Value = tripId;
      this.loadingService.open();
      this.groupDetailsSubcriptions.push(this.quoteService.getQuoteForAgencyProducts(this.booking, this.appStateService.getCredentials()).subscribe((agencyProducts) => {
          this.agencyProducts = agencyProducts;
          this.currentSmallGroupProduct = this.agencyProducts.Quote.ProductInformation.ProgramProducts.length > 0 ? this.agencyProducts.Quote.ProductInformation.ProgramProducts[0] : null;
        },
        (error) => this.loadingService.close(),
        () => this.loadingService.close())
      );
    } else {
      this.quoteFormComponent.GroupName.setErrors(null);
      this.quoteFormComponent.Suppliers.setErrors(null);
      this.currentSmallGroupProduct = this.agencyProducts.Quote.ProductInformation.ProgramProducts.length > 0 ? this.agencyProducts.Quote.ProductInformation.ProgramProducts[0] : null;
    }

    this.groupDetailsSubcriptions.push(
      this.quoteFormComponent.GetQuoteForm.valueChanges.subscribe((val) => {
        if (val.GroupName !== '') {
          this.quoteFormComponent.GroupName.setErrors(null);
          this.quoteFormComponent.groupNameInValid = false;
        }
        if (!this.quoteFormComponent.GetQuoteForm.pristine) {
          this.disableTabsEventEmmitter.emit(val);
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.quoteFormComponent.supplierListComponent.SupplierArray().controls.forEach((control) => {
      this.groupDetailsSubcriptions.push(
        control.valueChanges.subscribe((val) => {
          if (!this.quoteFormComponent.supplierListComponent.SupplierArray().pristine) {
            this.disableTabsEventEmmitter.emit(val);
          }
        })
      );
    });

    this.groupDetailsSubcriptions.push(
      this.quoteFormComponent.supplierListComponent.SupplierListForm.valueChanges.subscribe((val) => {
        this.disableTabsEventEmmitter.emit(val);
      })
    );
  }

  ngOnDestroy(): void {
    this.quoteFormComponent.groupNameInValid = false;
    if (this.goToQueues && this.isActiveTabToggleCalled) {
      this.activeTabToggle();
    } else {
      if (
        (this.quoteFormComponent.GetQuoteForm.dirty || this.additionalInformationComponent.form.dirty) && (this.quoteFormComponent.GetQuoteForm.controls.GroupName.value !== '' || this.supplierValidCheck())
      ) {
        this.toggleSaveGroupModal();
      }
    }

    this.groupDetailsSubcriptions.forEach((res) => res.unsubscribe());
  }

  /**
   * Checks if product is selected
   * @returns {boolean}
   */
  isProductSelected(): boolean {
    return this.currentSmallGroupProduct !== null;
  }

  /**
   * Checks if Supplier list has a value
   * @returns {boolean}
   */
  supplierValidCheck(): boolean {
    for (let control of this.quoteFormComponent.supplierListComponent.SupplierArray().controls) {
      control;
    };
    return false;
  }

  /**
   * If condition met red outline will be displayed around GroupName and/or Supplier inputs elements
   * @param {ErrorMessage[]} errorMessages
   * @returns {void}
   */
  displayGroupNameSuppliersErrors(errorMessages: ErrorMessage[]): void {
    for (const error of errorMessages) {
      if (error.Code === '1066') {
        this.quoteFormComponent.groupNameInValid = true;
        this.quoteFormComponent.GroupName.setErrors({incorrect: true});
      }
      if (error.Code === '1067') {
        this.quoteFormComponent.Suppliers.setErrors({incorrect: true});
      }
    }
  }

  /**
   * Toggle the save group modal to ask the user whether or not to save changes
   * @returns {void}
   */
  toggleSaveGroupModal(): void {
    this.quoteFormComponent.validationMessages.length = 0;
    const initialState = {
      text: 'Changes have been made to this group. Would you like to save changes?',
      dialogIndicator: 'small-group-details'
    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.groupDetailsSubcriptions.push(this.bsModalRef.content.modalResponse
      .subscribe((modalResponse) => {
        if (modalResponse) {
          this.isActiveTabToggleCalled = true;
          this.saveGroup();
        } else {
          // if we aren't saving mark as pristine to avoid extra checks
          this.quoteFormComponent.GetQuoteForm.markAsPristine();
          this.additionalInformationComponent.form.markAsPristine();
          if (this.goToQueues) {
            this.router.navigate(['queues']);
          } else {
            this.router.navigate([`small-group/modifyGroup/${this.route.snapshot.params.groupid}/policies`]).finally(() => this.activeTabEventEmmitter.emit(this.route.snapshot.data.activeTab));
          }
        }
      })
    );
  }

  saveGroup() : void {
    let isInvalid = false;
    this.quoteFormComponent.validationMessages = [];

    if(this.quoteFormComponent.GetQuoteForm.controls.GroupName.value === ''){
      this.quoteFormComponent.groupNameInValid = true;
      this.quoteFormComponent.GroupName.setErrors({incorrect: true});
      isInvalid = true;
      this.quoteFormComponent.validationMessages.push({Code:"1066", Description:"Group Name is required"});
    }
    if(this.quoteFormComponent.GetQuoteForm.controls.GroupName.value.includes('|')){
      this.quoteFormComponent.groupNameInValid = true;
      this.quoteFormComponent.GroupName.setErrors({incorrect: true});
      isInvalid = true;
      //Code 0 was added because it's just a frontend validation and does not go to backend for validations
      this.quoteFormComponent.validationMessages.push({Code:"0", Description:"Invalid character use; please correct/update to Save the record."});
    }
    if(this.quoteFormComponent.supplierListComponent.SupplierArray().controls[0].value.supplier === 'null' || this.quoteFormComponent.supplierListComponent.SupplierArray().controls[0].value.supplier[0] === null) {
      this.quoteFormComponent.validationMessages.push({Code:"108999", Description:"Supplier Name is required"});
      isInvalid = true;
    }
    if(this.booking.ProductInformation.ProgramProducts.length <=0) {
      isInvalid = true;
      this.quoteFormComponent.validationMessages.push({Code:"1089992", Description:"Product is required"});
    }

    if(isInvalid) {
      alert('You must correct all errors before you can save the group. See alerts in the upper right corner.');
    }
    else {
      this.isFromSaveGroup = true;
      this.activeTabToggle();
    }
  }
  /**
   * Toggle the save group modal to ask the user whether or not to save changes when either GroupName or Suppliers is blank
   * @returns {void}
   */
  toggleSaveQuoteFormErrorModal(): void {
    this.quoteFormComponent.validationMessages.length = 0;
    const initialState = {
      text: 'This group has not been saved, do you want to save this data before leaving this screen?',
      dialogIndicator: 'small-group-details'
    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.groupDetailsSubcriptions.push(this.bsModalRef.content.modalResponse
      .subscribe((modalResponse) => {
        if (modalResponse) {
          this.isActiveTabToggleCalled = true;
          this.activeTabToggle();
        } else {
          this.quoteFormComponent.GetQuoteForm.markAsPristine();
          this.bsModalRef.hide();
          this.router.navigate(['queues']);
        }
      })
    );
  }

  /**
   * Toggles the active tab via event emitter from the program product list
   * and saves current changes of the group policy booking
   * @returns {void}
   */
  activeTabToggle(): void {
    const groupPolicy = this.updateGroupPolicy();
    if (this.quoteFormComponent.GetQuoteForm.controls.GroupName.value === '' || !this.quoteFormComponent.supplierListComponent.SupplierArray().controls !== null || this.policies.length === 0) {
      this.saveGroupQuote(groupPolicy);
    } else {
      if ((this.quoteFormComponent.GetQuoteForm.dirty || this.additionalInformationComponent.form.dirty) && (this.quoteFormComponent.GetQuoteForm.controls.GroupName.value !== '' && this.quoteFormComponent.Suppliers.value[0].supplier !== null)) {
        if (groupPolicy) {
          this.saveGroupQuote(groupPolicy);
        }
      }
    }
  }

  /**
   * Updates local booking object
   * @returns {Booking}
   */
  private updateGroupPolicy(): Booking {
    // Setting up new group quote request
    this.booking.BookingID = this.route.snapshot.params.groupid || 0;
    this.booking.IsGroupBooking = true;
    // group status enum needed
    this.booking.BookingStatus = 21;

    // Quote form group policy details
    this.booking.GroupName.Value = this.quoteFormComponent.GroupName.value;
    this.booking.TripInformation.CurrencyType.Value = 'USD';
    this.booking.TripInformation.Destination.Value = this.quoteFormComponent.Destinations.value;
    this.booking.TripInformation.FormattedDepartureDate.Value = this.datePipe.transform(this.quoteFormComponent.DepartureDate.value, 'MM/dd/yyyy');
    this.booking.TripInformation.FormattedReturnDate.Value = this.datePipe.transform(this.quoteFormComponent.ReturnDate.value, 'MM/dd/yyyy');
    this.booking.TripInformation.FormattedInitialDepositDate.Value = this.datePipe.transform(this.quoteFormComponent.InitialDepositDate.value, 'MM/dd/yyyy');

    // if user changes value of any supplier in list
    if (this.quoteFormComponent.supplierListComponent) {
        this.booking.TripInformation.SupplierList.Values = this.utilityService.updateSupplierList(this.booking.TripInformation.SupplierList.Values, this.quoteFormComponent.supplierListComponent);
    }

    // Additional Information controls
    this.booking.AdditionalInformation.TripID.Value = this.additionalInformationComponent.TripID.value;
    this.booking.AdditionalInformation.AgentID.Value = this.additionalInformationComponent.AgentID.value;

    // Product to add to group policy
    // only ever one product at the group level
    if (this.programProductListComponent.selectedProduct) {
      // set it to empty so it doesnt keep adding the same product to the list over and over
      this.booking.ProductInformation.ProgramProducts = [];
      this.booking.ProductInformation.ProgramProducts.push(this.programProductListComponent.selectedProduct);
    }
    return this.booking;
  }

  /**
   * Save group quote
   * @param {GroupQuoteRequest} groupPolicy
   * @returns {void}
   */
  private saveGroupQuote(groupPolicy: Booking): void {

    if(this.quoteFormComponent.GetQuoteForm.controls.GroupName.value === '' || (this.quoteFormComponent.supplierListComponent.SupplierArray().controls[0].value.supplier === 'null' || this.quoteFormComponent.supplierListComponent.SupplierArray().controls[0].value.supplier[0] === null)){
      //button product is selected, but not save yet.
      return;
    }
    groupPolicy.IsGroupBooking = true;

    if(!this.isFromSaveGroup){
      console.log('is not possible save from button product!!!!!!!')
      return;
    }
    console.log('save ok!')

    this.groupDetailsSubcriptions.push(
      this.quoteService.saveGroupQuote(groupPolicy, this.appStateService.getCredentials())
        .subscribe(
          (response) => {
            if (response.ErrorMessages.length === 0) {
              this.quoteFormComponent.GetQuoteForm.markAsPristine();
              this.additionalInformationComponent.form.markAsPristine();
              this.appStateService.setCurrentBookingQuote(response);
              const groupId = this.route.snapshot.params.groupid || response.BookingID;
              this.appStateService.setCurrentBookingId(null);
              if (this.goToQueues) {
               this.router.navigate(['queues']);
              }
               else {
                this.router.navigate([`small-group/modifyGroup/${groupId}/policies`]).finally(() => this.activeTabEventEmmitter.emit(this.route.snapshot.data.activeTab));
              }
            } else {
              this.displayGroupNameSuppliersErrors(response.ErrorMessages);
              this.quoteFormComponent.validationMessages = response.ErrorMessages;
            }
          })
      );
    }
}
