// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Models
import { ReportSettings } from 'src/app/models/interfaces';

// Serivces
import { AppStateService } from '../services/app-state.service';
import { ReportService } from 'src/app/api/services/report.service';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class GetSalesReportDefaultsResolver implements Resolve<Observable<ReportSettings>> {
  constructor(
    private appStateService: AppStateService,
    private reportService: ReportService
  ) { }
  resolve(): Observable<ReportSettings> {
    return this.reportService.getSalesReportsDefaults(this.appStateService.getCredentials());
  }
}
