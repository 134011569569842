"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_field_class_1 = require("./input-field.class");
var AlertCommunicationInfo = /** @class */ (function () {
    function AlertCommunicationInfo() {
        this.CommunicationMethod = 'Unknown';
        this.SendTextAlert = new input_field_class_1.InputField();
        this.SendEmailAlert = new input_field_class_1.InputField();
        this.OptOutFromAlerts = new input_field_class_1.InputField();
        this.EmailAddress = new input_field_class_1.InputField();
        this.CellPhone = new input_field_class_1.InputField();
    }
    return AlertCommunicationInfo;
}());
exports.AlertCommunicationInfo = AlertCommunicationInfo;
