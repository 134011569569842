// Angular Core
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

// Services
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AppStateService } from '../services/app-state.service';

// Models
import { AgencySettings } from 'src/app/models/interfaces';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class AdminGetAgencySettingsResolver implements Resolve<Observable<AgencySettings>> {
  constructor(
    private adminUserService: AdminUserService,
    private appStateService: AppStateService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<AgencySettings> {
    const agencyId = route.params.agencyId ? route.params.agencyId : this.appStateService.getCredentials().AgencyID;

    const agencySettings = this.adminUserService.getAgencySettings(agencyId, this.appStateService.getCredentials());

    return this.adminUserService.getAgencySettings(agencyId, this.appStateService.getCredentials());
  }
}
