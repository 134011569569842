// Angular Core
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

// RxJS Subscriptions
import { Subscription } from 'rxjs';

// Models
import { IsOpen } from '../../../../models/enums';
import { AgencySettings, AddressAndLogo, AgencyGroup } from 'src/app/models/interfaces';

// Services
import { UtilityService } from '../../../../shared/services/utility.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-admin-detail',
  templateUrl: './admin-detail.component.html',
  styleUrls: ['./admin-detail.component.scss']
})
export class AdminDetailComponent implements OnInit {
  agency: AgencyGroup;
  agencySettings: AgencySettings;
  agencyAddressAndLog: AddressAndLogo;
  showCALTab: boolean = false;
  isOpenType: typeof IsOpen = IsOpen;
  isOpenKeys: string[] = Object.keys(IsOpen);
  activeArray: {} = {};
  isDesktop: boolean;
  adminSubscriptions: Subscription[] = [];
  agencyId: number;
  createAgent: boolean = false;
  tabName: string = 'Agency Info';
  @Output() backEvent: EventEmitter<void> = new EventEmitter();

  constructor(
    private utilityService: UtilityService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appStateService: AppStateService
  ) {
    this.createAgent = false;
    this.agencyId = parseInt(this.activatedRoute.snapshot.params.agencyId, 10) || 0;
    this.agency = this.activatedRoute.snapshot.data.Agencies.AgencyGroupList.find((a) => a.AgencyId === this.agencyId);
    this.agencySettings = this.activatedRoute.snapshot.data.AgencySettings;
    this.agencyAddressAndLog = this.activatedRoute.snapshot.data.AgencyAddressAndLog;
    this.showCALTab = this.activatedRoute.snapshot.data.AgencySettings.AllowCreateALink;
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => this.routeChanged(e));
  }

  /**
   *  it shows component correspond to route
   * @param e NavigationEnd
   */
  routeChanged(e: NavigationEnd): void {
    this.isOpenKeys.forEach((obj) => {
      this.activeArray[obj] = false;
    });
    if (e.url.indexOf('create-agent') !== -1 ) {
      this.determineRoute(IsOpen.createAgentIsOpen);
      this.activeArray[IsOpen.createAgentIsOpen] = true;
    } else if (e.url.indexOf('agent-management') !== -1) {
      this.determineRoute(IsOpen.agentManagementIsOpen);
      this.activeArray[IsOpen.agentManagementIsOpen] = true;
    } else if (e.url.indexOf('quotemax') !== -1) {
      this.determineRoute(IsOpen.quoteMaxIsOpen);
      this.activeArray[IsOpen.quoteMaxIsOpen] = true;
    } else {
      this.determineRoute(IsOpen.generalIsOpen);
      this.activeArray[IsOpen.generalIsOpen] = true;
    }
  }

  ngOnInit(): void {
    this.adminSubscriptions.push(
      this.utilityService.getSize().subscribe((isDesktop: boolean) => {
        this.isDesktop = isDesktop;
        this.checksAllTabs();
      })
    );
    this.isOpenKeys.forEach((obj) => {
      this.activeArray[obj] = false;
    });
    this.activeArray[this.activatedRoute.snapshot.firstChild.data.tab] = true;
    this.appStateService.setAgencySettings(this.agencySettings, this.agencyId);
    this.appStateService.setAgencyAddressAndLogo(this.agencyAddressAndLog, this.agencyId);
  }

  /**
   * sets agencyIsSelected to false, so it displays the agency list
   * @returns {void}
   */
  showAvailableAgencies(): void {
    this.backEvent.emit();
  }

  ngOnDestroy(): void {
    this.adminSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Toggles the create agent flag to set the agent management
   * to either a list of agents or a create agent form
   * @returns {void}
   */
  createAgentToggle(): void {
    this.createAgent = !this.createAgent;
    this.isOpenKeys.forEach((obj) => {
      this.activeArray[obj] = false;
    });
    this.activeArray[this.isOpenType.createAgentIsOpen] = true;
  }

  /**
   * if all tabs are deactive, make the first tab active
   * @returns {void}
   */
  checksAllTabs(): void {
    if (Object.values(this.activeArray).every((element) => element === false)) {
      this.activeArray[this.activatedRoute.snapshot.firstChild.data.tab] = true;
      if (!this.isDesktop) {
        this.onAccordionOpenChange(this.activatedRoute.snapshot.firstChild.data.tab, true);
      }
    }
  }

  /**
   * Called on tab deselection change
   * @param {IsOpen} activeVar - tab to be deactive
   * @returns {void}
   */
  onTabDeselectionChange(activeVar: IsOpen): void {
    this.activeArray[activeVar] = false;
    this.checksAllTabs();
  }

  /**
   * Called on accordion change
   * @param {IsOpen} openVar - accordion to be manipulated
   * @param {boolean} event - boolean to decide to open or close accordion
   * @returns {void}
   */
  onAccordionOpenChange(openVar: IsOpen, event: boolean): void {
    const segment = this.determineRoute(openVar);
    this.router.navigate([`/admin/agencies/${this.agencyId}/${segment}`]).finally(() => {
      this.activeArray[openVar] = event;
      // scroll to top of accordion
      // timeout to ensure that the accordion is open before scrolling
      if (event) {
        setTimeout(() => {
          document.getElementById(`admin-accordion-${openVar}`).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }, 450);
      }
    });
  }

  /**
   * Called on tab selection change
   * @param {IsOpen} activeVar - tab to be active
   * @returns {void}
   */
  onTabSelectionChange(activeVar: IsOpen): void {
    let actuallyActive = activeVar;
    if (actuallyActive === IsOpen.agentManagementIsOpen) {
      if (this.activatedRoute.firstChild && this.activatedRoute.firstChild.snapshot.data.tab === IsOpen.createAgentIsOpen) {
        actuallyActive = IsOpen.createAgentIsOpen;
      }
    }
    const segment = this.determineRoute(actuallyActive);
    this.router.navigate([`/admin/agencies/${this.agencyId}/${segment}`]);
  }
/**
 * route back to agement management page if current page is create new agent,
 * otherwise it routes back to admin page
 */
  backButton(): void {
    if (this.activeArray[IsOpen.createAgentIsOpen]) {
      this.router.navigate([`/admin/agencies/${this.agencyId}/agent-management`]);
    } else {
      this.router.navigate([`/admin`]);
    }
  }
  /**
   * determine which route is open for mobile compatability
   * @param activeVar which tab is open
   */
  private determineRoute(activeVar: IsOpen): string {
    let segment = '';
    if (activeVar !== IsOpen.agentManagementIsOpen) {
      this.createAgent = false;
    }
    if (activeVar === IsOpen.generalIsOpen) {
      this.tabName = 'Agency Info';
      segment = 'agency-info';
    } else if (activeVar === IsOpen.interfaceIsOpen) {
      segment = 'interface-settings';
    } else if (activeVar === IsOpen.quoteMaxIsOpen) {
      segment = 'quotemax';
      this.tabName = 'QuoteMax';
    } else if (activeVar === IsOpen.agentManagementIsOpen) {

        segment = 'agent-management';
        this.tabName = 'Agent Management';
    } else if (activeVar === IsOpen.createAgentIsOpen) {
        segment = 'create-agent';
        this.tabName = 'Create New Agent Account';
      }
    return segment;
  }
}
