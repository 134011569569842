// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Serivces
import { AdminUserService } from 'src/app/api/services/adminUser.service';

// Third Party
import { Observable } from 'rxjs';
import { KeyValue } from '@allianz/agent-max-core-lib';

@Injectable()
export class GetInterfaceOptionsSourceTypeResolver implements Resolve<Observable<KeyValue[]>> {
  constructor(
    private adminUserService: AdminUserService
  ) { }
  resolve(): Observable<KeyValue[]> {
    return this.adminUserService.getInterfaceOptions();
  }
}
