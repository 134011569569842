// Angular Core
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// Services
import { AppStateService } from '../shared/services/app-state.service';
import { ReportService } from '../api/services/report.service';
import { LoadingService } from '../api/services/loading.service';

// Components
import { ModalComponent } from '../shared/components/modal/modal.component';

// Models
import { UserInformation, Feedback, PrintOrEmailOptions } from '../models/interfaces';
import { BookingAgentInformation } from '@allianz/agent-max-core-lib';
import { HelpData } from '../models/classes';
import { environment } from 'src/environments/environment';
import { PrintOrEmailTypes, PrintOrEmailDocumentType, PrintOrEmailReportType } from '../models/enums';

// Third Party
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, OnDestroy {
  bsModalRef: BsModalRef;
  agentHelpPhoneNumber: string;
  feedbackForm: FormGroup;
  feedback: FormControl = new FormControl('', [Validators.required, Validators.maxLength(1000)]);
  inputName: FormControl = new FormControl('', [Validators.required, Validators.maxLength(75)]);
  inputEmail: FormControl = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]);
  inputSubject: FormControl = new FormControl('', [Validators.required]);
  helpData: HelpData = new HelpData();
  envName: string;
  creds: UserInformation;
  helpSubscription: Subscription;
  Subject: any = ['Sales', 'Agency Support', 'Website Support', 'Other']
  submitted = false;

  constructor(
    private appStateService: AppStateService,
    private formBuilder: FormBuilder,
    private router: Router,
    private reportService: ReportService,
    private bsModalService: BsModalService,
    private loadingService: LoadingService
  ) {
    this.feedbackForm = this.formBuilder.group({
      feedback: this.feedback,
      inputName: this.inputName,
      inputEmail: this.inputEmail,
      inputSubject: this.inputSubject
    });
  }

  ngOnInit(): void {
    const agentInformation: BookingAgentInformation = this.appStateService.getAgentInformation();
    this.creds = this.appStateService.getCredentials();
    this.agentHelpPhoneNumber = agentInformation.AgencyHelpPhoneNumber || environment.DefaultHelpPhoneNumber;
    this.createHelpData(agentInformation);
    this.environment(agentInformation);
  }

  // convenience getter for easy access to form fields
  get f() { return this.feedbackForm.controls; }

  /**
   * When you click the email link on the help page
   * @returns {void}
   */
  onEmail(): void {
    const agentInfo = this.appStateService.getAgentInformation();
    const htmlString = document.getElementById('aboutTable').innerHTML;
    const regexString = htmlString.replace(/(\r\n|\n|\r)/gm, '');
    this.helpData.HtmlTable = regexString;
    const options: PrintOrEmailOptions = {
      HeaderName: 'Send Help Email',
      DocumentType: PrintOrEmailDocumentType.GenericDocument,
      ReportType: PrintOrEmailReportType.HelpRequest,
      ShowViewButton: true,
      IsHelpEmail: true,
      ShowFromName: true,
      ShowFromEmail: true,
      IFrameUrl: this.helpData.HtmlTable,
      EmailInput: {
        FromName: `${agentInfo.FirstName} ${agentInfo.LastName}`,
        FromEmailAddress: agentInfo.EmailAddress,
        ToName: 'Agent Services',
        ToEmailAddress: environment.DefaultEmailAddress
      }
    };
    this.appStateService.setPrintOrEmailReportName(PrintOrEmailTypes.Help);
    this.appStateService.setPrintOrEmailData('', options);
    this.router.navigate(['printoremail']);
  }

/**
   * Submit feedback form
   * @returns {void}
   */
 submitFeedback(): void {

  // stop here if form is invalid
  if (this.feedbackForm.invalid) {
      return;
  }

  const feedback: Feedback = { FeedbackName: this.feedbackForm.value.inputName,
  FeedbackEmail: this.feedbackForm.value.inputEmail,
  FeedbackSubject: this.feedbackForm.value.inputSubject,
  FeedbackComment: this.feedbackForm.value.feedback };
  this.loadingService.open();
  this.helpSubscription = this.reportService.sendUserFeeback(feedback, this.creds)
    .subscribe((response) => {
      if (response.ErrorMessages.length === 0) {
        const initialState = { text: 'Feedback submitted' };
        this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
        this.bsModalRef.content.closeBtnName = 'Close';
        this.feedbackForm.patchValue({ feedback: '' });
        this.feedbackForm.markAsPristine();
        this.submitted = true;
      }
    },
    (error) => { },
    () => this.loadingService.close());
}

  ngOnDestroy(): void {
    if (this.helpSubscription) {
      this.helpSubscription.unsubscribe();
    }
  }

  /**
   * generates the help data object to use in the html from the various sources
   * @param {BookingAgentInformation} agentInformation
   * @returns {void}
   */
  private createHelpData(agentInformation: BookingAgentInformation): void {
    const browserInfo: { name: string, version: string } = this.getBrowser();

    this.helpData.About.BrowserName = browserInfo.name;
    this.helpData.About.BrowserVersion = browserInfo.version;
    this.helpData.About.AgentAccountID = this.creds.AgentAccountID;
    this.helpData.About.ACCAM = agentInformation.Accam;
    this.helpData.About.AgentName = `${agentInformation.FirstName} ${agentInformation.LastName}`;
    this.helpData.About.AgentEmail = agentInformation.EmailAddress;
    this.helpData.About.TimeStamp = new Date();
    this.helpData.About.OperatingSystem = this.detectOS();
  }

  /**
   * pulls the environment from the booking agent agency short name
   * @param {BookingAgentInformation} agentInformation
   * @returns {void}
   */
  private environment(agentInformation: BookingAgentInformation): void {
    const shortname: string = agentInformation.AgencyShortName;
    const shortNameArr: string[] = shortname.split(' ');
    this.envName = shortNameArr[2];
  }

  /**
   * Detects the browers information
   * @returns {name: string, version: string}
   */
  private getBrowser(): { name: string, version: string } {
    const userAgent = navigator.userAgent;
    let matchExpression: RegExpMatchArray | any[]  = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    let tem: RegExpExecArray | any[];

    if (/trident/i.test(matchExpression[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      this.helpData.About.BrowserName = 'IE';
      this.helpData.About.BrowserVersion = tem[1] || '';
      return { name: 'IE', version: (tem[1] || '') };
    }
    if (matchExpression[1] === 'Chrome') {
      tem = userAgent.match(/\bOPR\/(\d+)/);
      if (tem !== null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    matchExpression = matchExpression[2] ? [matchExpression[1], matchExpression[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = userAgent.match(/version\/(\d+)/i);
    if (tem != null) {
      matchExpression.splice(1, 1, tem[1]);
    }
    if (matchExpression !== null || typeof (matchExpression) !== 'undefined') {
      if (matchExpression['name'] === 'IE') {
        matchExpression['name'] = 'Internet Explorer';
      }
      return { name: matchExpression[0], version: matchExpression[1] };
    } else {
      return { name: 'Unknown Browser', version: '' };
    }
  }

  /**
   * Detects what OS your browsing the site on
   * @returns {string}
   */
  private detectOS(): string {
    const OS_NAME: string = window.navigator.appVersion;
    const OS: { Windows: RegExp, MacOSX: RegExp, Unix: RegExp, Linux: RegExp, Solaris: RegExp } = {
      Windows: /Win/i,
      MacOSX: /Mac/i,
      Unix: /X11/i,
      Linux: /Linux/i,
      Solaris: /SunOS/i
    };
    for (const key in OS) {
      if (OS[key].test(OS_NAME)) {
        return key;
      } else {
        return 'Unknown Operating System';
      }
    }
  }

}
