// Angular Core
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { QuoteService } from 'src/app/api/services/quote.service';
import { AppStateService } from './app-state.service';
import { LoadingService } from 'src/app/api/services/loading.service';

// Models
import { BookingTypes } from '@allianz/agent-max-core-lib';
import { QueueTypes } from 'src/app/models/enums';

// Third party
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedBusinessLogic {
  constructor(
    private appStateService: AppStateService,
    private quoteService: QuoteService,
    private loadingService: LoadingService,
    private router: Router
  ) { }

  /**
   * gets the info needed to copy a quote
   * routes the user to the product page
   * @param {string} bookingID
   * @param {QueueTypes} queueType
   * @returns {void}
   */
  copyToNewQuote(bookingID: string, queueType: QueueTypes): Subscription {
    this.loadingService.open();
    return this.quoteService.copyToNewQuote(bookingID, this.appStateService.getCredentials())
      .subscribe((result) => {
        this.appStateService.setCurrentBookingQuote(result);
        this.appStateService.setBookingInfoViewType(BookingTypes.Quote);
        this.appStateService.setBookingInfoSourceType(BookingTypes.CopiedBooking);
        this.appStateService.setQueueType(queueType);
        this.router.navigate(['product']);
      },
      (error) => { },
      () => this.loadingService.close());

  }

}
