// Angular Core
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Models
import { GDSInterfaceSettings, InterfaceSettings, UserInformation } from 'src/app/models/interfaces';
import { Booking, BookingTypes, BookingAgentInformation, TripInformation, TravelInformation } from '@allianz/agent-max-core-lib';
import { QueueTypes } from '../models/enums';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { UtilityService } from '../shared/services/utility.service';
import { QuoteService } from '../api/services/quote.service';
import { GdsService } from 'src/app/api/services/gds.service';
import { AdminUserService } from '../api/services/adminUser.service';

 // GUID
 import { Guid } from 'guid-typescript';
import { GDSInformation } from '@allianz/agent-max-core-lib/models/classes/gds-information.class';
import { DatePipe } from '@angular/common';

//SweetAlert
import Swal from 'sweetalert2'

//Timer
import { timer } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  AgentInformation: BookingAgentInformation;
  canManageGroups: boolean = false;
  userInfo: UserInformation;
  isLoaded: boolean = false;
  notificationCount: number;
  isDesktop: boolean;
  gdsFailure: boolean;
  isGdsLogin: boolean;
  bookingid: string;
  booking: Booking = new Booking();
  isSuperAdminAccessRequest: Boolean = false;
  interfaceSettings: InterfaceSettings;
  isApolloEnabled: boolean = false;
  gdsBooking: Booking = new Booking();
  datePipe: DatePipe = new DatePipe('en-US');

  constructor(
    private appStateService: AppStateService,
    private utilityService: UtilityService,
    private router: Router,
    private quoteService: QuoteService,
    private gdsService: GdsService,
    private route: ActivatedRoute,
    private adminuserService: AdminUserService
  ) {
    this.appStateService.notificationCountObservable$.subscribe((val) => {
      this.notificationCount = val || 0;
    });
  }

  ngOnInit(): void {
    this.utilityService.getSize().subscribe((isDesktop: boolean) => {
      this.isDesktop = isDesktop;
    });
    this.userInfo = this.appStateService.getCredentials();
    this.AgentInformation = this.route.snapshot.data.agentInformation;
    this.isSuperAdminAccessRequest = this.appStateService.IsSuperAdmin();
    this.isGdsLogin = this.appStateService.getGUID() !== null;
    //this.isApolloEnabled = this.appStateService.getApolloSettings();;
    //this.isGdsLogin = true;
    this.appStateService.setAgentInformation(this.AgentInformation);
    this.canManageGroups = this.route.snapshot.data.canManageGroups;
    this.appStateService.setCanManageGroups(this.canManageGroups);

    if ((this.appStateService.getLatestGDSBookingId() || this.route.snapshot.queryParams.BookingID) && this.isGdsLogin) {
      this.booking = this.route.snapshot.data.booking;
      this.appStateService.setCurrentBookingQuote(this.booking);
      this.router.navigate(['/product']);
    }

    this.adminuserService.getGDSInterfaceSettings(this.AgentInformation.AgencyId, this.appStateService.getCredentials())
        .subscribe((response) => {
          if (response.ErrorMessages.length === 0) {
            this.interfaceSettings = response; 
            if(response.IsValid && response.GDSInterfaceSettings.length > 0){
              this.appStateService.setCurrentArrayInterfaceSettings(response.GDSInterfaceSettings);
            }
            response.GDSInterfaceSettings.forEach( (element) => {
              if(element.DataSource.startsWith('Apollo') && element.IsEnabled === true) {
                this.isApolloEnabled = true;
                this.appStateService.setApolloSettings(this.isApolloEnabled);
              }
              else {
                this.appStateService.setApolloSettings(this.isApolloEnabled);
              }
          });                       
          } 
        })        
  }  

  ngOnDestroy(): void {
    this.gdsFailure = false;
    this.isGdsLogin = false;
    this.isApolloEnabled = false;
  }

  /**
   * Get the current notification count
   * @returns {number | string}
   */
  getNotificationCount(): number | string {
    return this.notificationCount < 99 ? this.notificationCount : '99+';
  }

  /**
   * Gets the latest quote saved by the logged in GDS user
   * @param {UserInformation} userInfo
   * @returns {void}
   */
  getLatestSavedGdsQuote(userInfo: UserInformation): void {
    const latestGdsBookingId = this.appStateService.getLatestGDSBookingId();
    this.gdsService.getLatestSavedGdsQuote(userInfo,latestGdsBookingId)
      .subscribe((response) => {
        if (response.BookingID.toString() === latestGdsBookingId) {
          this.openSavedQuote(response.BookingID.toString());
        } else if (response.BookingID === 0 || response.ErrorMessages.length > 0) {
          this.gdsFailure = true;
        } else {
          return;
        }
      });
  }

  /**
   * opens a previously saved quote
   * @param {string} BookingId
   * @returns {void}
   */
  openSavedQuote(BookingId: string): void {
    this.appStateService.setBookingInfoViewType(BookingTypes.Quote);
    this.appStateService.setBookingInfoSourceType(BookingTypes.ExistingBooking);
    this.appStateService.setCurrentBookingId(BookingId);
    this.router.navigate(['product']);
  }

  /**
   * Order brochures navigate offsite
   * @returns {void}
   */
  onOrderBrochures(): void {
    this.router.navigate(['brochure']);
  }

  /**
   * Sets the queue type and navigates to the queues route
   * @param {string} queueType
   * @returns {void}
   */
  onManagePolicy(queueType: string): void {
    this.appStateService.setQueueType(QueueTypes[queueType]);
    this.router.navigate(['queues']);
  }

  /**
   * Sets the queue type and navigates to the queues route
   * @returns {void}
   */
  onManageGroups(): void {
    this.appStateService.setQueueType(QueueTypes.Groups);
    this.router.navigate(['queues']);
  }

  hideGDSErrorAfterTimeOut(){
    const source = timer(5000);  
    const subscribe = source.subscribe(val => 
      {
        this.gdsFailure = false 
      });  
  }
 

  /**
   * Create PNR
   * @returns {void}
   */
   onCreatePNR(): void {
    
    this.appStateService.setGUID(Guid.create().toString());

    Swal.fire({
      title: 'Please, type your PNR',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Search',
      showLoaderOnConfirm: true,
      preConfirm: (pnrCode) => {

        var regex = new RegExp("(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{6})$");

        if(!regex.test(pnrCode)){
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'PNR must be alphanumeric and no more than 6 characters.'
          })
          this.gdsFailure = true;
          this.hideGDSErrorAfterTimeOut();
        }
        else {
          //once get the PNR from Travelport API, set up the Booking to be saved
          this.gdsService.createPNR(pnrCode,this.appStateService.getCredentials())
              .subscribe((response) => {
                if (response.ErrorMessages.length === 0) {
                  this.appStateService.setCurrentBookingQuote(response);
                  Swal.fire({
                    icon: "success",
                    title: 'Success',
                    showConfirmButton: true,
                    text: 'PNR imported successfully from Apollo/Galileo.'
                  }).then((result) => {
                    if(result.value){
                      this.router.navigateByUrl('/product');
                    }                    
                  });                
                } else {
                  this.gdsFailure = true;
                  this.hideGDSErrorAfterTimeOut();
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.ErrorMessages[0].Description
                  })
                }
              },
              (error) => {
                this.gdsFailure = true;
                this.hideGDSErrorAfterTimeOut();
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'System Error, please contact system admin.'
                })
          })
        }
      }
    })
  }
 
}
