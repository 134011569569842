import { TripInformation, AdditionalInformation, QuoteProductInformation } from '@allianz/agent-max-core-lib';
import { WebObject } from './web-object.class';

export class GroupQuoteRequest extends WebObject {
    GroupName: string;
    BookingID: number;
    GroupCreatorEmailAddress: string;
    TripInformation: TripInformation;
    ProductInformation: QuoteProductInformation;
    AdditionalInformation: AdditionalInformation;
    constructor() {
        super();
        this.GroupName = '';
        this.BookingID = 0;
        this.GroupCreatorEmailAddress = '';
        this.TripInformation = new TripInformation();
        this.AdditionalInformation = new AdditionalInformation();
        this.ProductInformation = new QuoteProductInformation();
    }
}
