// Angular core
import { Component, OnInit } from '@angular/core';

// Models
import { NotificationSaleType } from '../models/enums';

// Service
import { AppStateService } from '../shared/services/app-state.service';
import { NotificationService } from '../api/services/notification.service';

// Third Party
import { delay } from 'rxjs/internal/operators/delay';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  maxmailIndicator: boolean;
  quotemaxIndicator: boolean;
  preexistingIndicaotr: boolean;

  constructor(
    private appStateService: AppStateService,
    private notificationService: NotificationService
  ) {
    this.notificationService.getNotifications()
      .subscribe((response) => {
        if (response.ErrorMessages.length === 0 && response.Messages) {
          this.appStateService.triggerMaxmailNotificationStatus(response.Messages.filter((msg) => msg.SaleType === NotificationSaleType.MaxMail && msg.MessageConsumed === false).length > 0);
          this.appStateService.triggerQuoteMaxNotificationStatus(response.Messages.filter((msg) => msg.SaleType === NotificationSaleType.QuoteMax && msg.MessageConsumed === false).length > 0);
          this.appStateService.triggerPreexpiringNotificationStatus(response.Messages.filter((msg) => msg.SaleType === NotificationSaleType.PreExExpiring && msg.MessageConsumed === false).length > 0);
        }
      });
  }

  ngOnInit(): void {
    this.appStateService.maxmailNotificationObservable$.pipe(delay(0)).subscribe((val) => {
      this.maxmailIndicator = val;
    });
    this.appStateService.quotemaxNotificationObservable$.pipe(delay(0)).subscribe((val) => {
      this.quotemaxIndicator = val;
    });
    this.appStateService.preexpiringNotificationObservable$.pipe(delay(0)).subscribe((val) => {
      this.preexistingIndicaotr = val;
    });
  }

  /**
   * Check if the tab has the icon
   * @param {boolean} indicator
   * @returns {string}
   */
  hasIcon(indicator: boolean): string {
    return indicator ? 'has-icon' : '';
  }

}
