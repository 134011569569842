// Angular Core
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

// Services
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';

// Models
import { InterfaceSettings } from 'src/app/models/interfaces';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class AdminGetGdsInterfaceSettingsResolver implements Resolve<Observable<InterfaceSettings>> {
  constructor(
    private adminUserService: AdminUserService,
    private appStateService: AppStateService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<InterfaceSettings> {
    return this.adminUserService.getGDSInterfaceSettings(route.params.agencyId, this.appStateService.getCredentials());
  }
}
