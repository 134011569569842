export enum SortTypes {
    ASC = 'ASC',
    DESC = 'DESC',
    SortablePurchaseDate = 'SortablePurchaseDate',
    SortableSavedDate = 'SortableSavedDate',
    SortableDepartureDate = 'SortableDepartureDate',
    ConfirmationNumber = 'ConfirmationNumber',
    FormattedCreateDate = 'CreateDate',
    Newest = 'Newest',
    Oldest = 'Oldest',
    Alpha = 'A - Z',
    ReverseAlpha = 'Z - A',
    PurchaseDate = 'PurchaseDate',
    SavedDate = 'SavedDate',
    DepartureDate = 'DepartureDate'
}
