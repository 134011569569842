// Angular Core
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

// MODELS
import { UserOptions, AgencySettings } from 'src/app/models/interfaces';
import { ErrorMessage } from '@allianz/agent-max-core-lib';

// SERVICES
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';

@Component({
  selector: 'app-agency-settings-group-details',
  templateUrl: './agency-settings-group-details.component.html',
  styleUrls: ['./agency-settings-group-details.component.scss']
})
export class AgencySettingsGroupDetailsComponent implements OnInit {
  @Input('AgencySettings') agencySettings: AgencySettings;
  @Input('GroupNumber') groupNumber: number;

  agencySettingsSaved: boolean;
  agencySettingsValidationMessages: ErrorMessage[] = [];
  adminGeneralFormGroup: FormGroup;
  userOptions: UserOptions;
  isValidFormSubmitted: boolean = false;
  enableRecommendationEngine: FormControl = new FormControl();
  enableAgentTips: FormControl = new FormControl();
  allowSecondChanceOffers: FormControl = new FormControl();
  allowManageGroup: FormControl = new FormControl();
  allowCreditSales: FormControl = new FormControl();
  allowCashSales: FormControl = new FormControl();
  suppressCommissionOnReport: FormControl = new FormControl();
  allowAgentsToChangeCodeOnWeb: FormControl = new FormControl();
  allowBookingAgentCodeOverride: FormControl = new FormControl();
  allowCreateALink: FormControl = new FormControl();
  allowNotifications: FormControl = new FormControl();
  checkPrinterWaiver: FormControl = new FormControl();
  agencyQueueMaxDays: FormControl = new FormControl('', [Validators.max(2000), Validators.min(0), Validators.minLength(0), Validators.maxLength(4)]);
  insuranceQuestionText: FormControl = new FormControl();
  enableAdminApproval: FormControl = new FormControl();

  constructor(
    private formBuilder: FormBuilder,
    private adminuserService: AdminUserService,
    private appStateService: AppStateService
  ) {
    this.adminGeneralFormGroup = this.formBuilder.group({
      agencySettings: this.agencySettings,
      enableRecommendationEngine: this.enableRecommendationEngine,
      allowCreateALink: this.allowCreateALink,
      enableAgentTips: this.enableAgentTips,
      allowSecondChanceOffers: this.allowSecondChanceOffers,
      allowManageGroup: this.allowManageGroup,
      allowCreditSales: this.allowCreditSales,
      allowCashSales: this.allowCashSales,
      suppressCommissionOnReport: this.suppressCommissionOnReport,
      allowAgentsToChangeCodeOnWeb: this.allowAgentsToChangeCodeOnWeb,
      allowBookingAgentCodeOverride: this.allowBookingAgentCodeOverride,
      allowNotifications: this.allowNotifications,
      checkPrinterWaiver: this.checkPrinterWaiver,
      agencyQueueMaxDays: this.agencyQueueMaxDays,
      insuranceQuestionText: this.insuranceQuestionText,
      enableAdminApproval: this.enableAdminApproval
    });
  }

  ngOnInit(): void {
    this.adminGeneralFormGroup.patchValue({
      enableRecommendationEngine: this.agencySettings.EnableRecommendationEngine,
      allowCreateALink: this.agencySettings.AllowCreateALink,
      enableAgentTips: this.agencySettings.EnableAgentTips,
      allowSecondChanceOffers: this.agencySettings.AllowSecondChanceOffers,
      allowManageGroup: this.agencySettings.AllowManageGroup,
      allowCreditSales: this.agencySettings.AllowCreditSalesIfCW,
      allowCashSales: this.agencySettings.AllowCashSalesIfCW,
      suppressCommissionOnReport: this.agencySettings.AreCommissionsSuppressed,
      allowAgentsToChangeCodeOnWeb: this.agencySettings.AllowAgentsToChangeCodeOnWeb,
      allowBookingAgentCodeOverride: this.agencySettings.AllowBookingAgentCodeOverride,
      allowNotifications: this.agencySettings.AllowNotifications,
      checkPrinterWaiver: this.agencySettings.IsPrintWaiverCheckedByDefault,
      agencyQueueMaxDays: this.agencySettings.AgencyQueueMaxDays,
      insuranceQuestionText: this.agencySettings.InsurePromptQuestionText,
      enableAdminApproval: this.agencySettings.EnableAdminApproval
    });
  }

  /**
   * gets the agency settings for group, then updates them for the valid groups
   * @returns {void}
   */
  saveAgencySettings(): void {
    this.adminuserService.getAgencyGroupsForAgency().subscribe((agencyGroupsResponse) => {
      agencyGroupsResponse.AgencyGroupList.forEach((agency) => {
        if (agency.SharedAgencySettingsId === this.groupNumber) {
          this.agencySettings.AgencyID = agency.AgencyId;
          this.agencySettings.EnableRecommendationEngine = this.enableRecommendationEngine.value;
          this.agencySettings.AllowCreateALink = this.allowCreateALink.value;
          this.agencySettings.EnableAgentTips = this.enableAgentTips.value;
          this.agencySettings.AllowSecondChanceOffers = this.allowSecondChanceOffers.value;
          this.agencySettings.AllowManageGroup = this.allowManageGroup.value;
          this.agencySettings.AllowCreditSalesIfCW = this.allowCreditSales.value;
          this.agencySettings.AllowCashSalesIfCW = this.allowCashSales.value;
          this.agencySettings.AreCommissionsSuppressed = this.suppressCommissionOnReport.value;
          this.agencySettings.AllowAgentsToChangeCodeOnWeb = this.allowAgentsToChangeCodeOnWeb.value;
          this.agencySettings.AllowBookingAgentCodeOverride = this.allowBookingAgentCodeOverride.value;
          this.agencySettings.AllowNotifications = this.allowNotifications.value;
          this.agencySettings.IsPrintWaiverCheckedByDefault = this.checkPrinterWaiver.value;
          this.agencyQueueMaxDays.value === null ? this.agencySettings.AgencyQueueMaxDays = 0 : this.agencySettings.AgencyQueueMaxDays = this.agencyQueueMaxDays.value;
          this.agencySettings.InsurePromptQuestionText = this.insuranceQuestionText.value;
          this.agencySettings.EnableAdminApproval = this.enableAdminApproval.value;
          this.adminuserService.saveAgencySettings(this.agencySettings, this.appStateService.getCredentials()).subscribe((response) => {
            if (!response.ErrorMessages.length) {
              this.isValidFormSubmitted = true;
              this.agencySettings = response;
              this.agencySettingsSaved = true;
              this.adminGeneralFormGroup.markAsPristine();
            } else {
              this.agencySettingsSaved = false;
              this.agencySettingsValidationMessages = response.ErrorMessages;
            }
          });
        }
      });
    });
  }

}
