"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_field_class_1 = require("./input-field.class");
var HotelInformation = /** @class */ (function () {
    function HotelInformation() {
        this.NumberOfRooms = new input_field_class_1.InputField();
    }
    return HotelInformation;
}());
exports.HotelInformation = HotelInformation;
