"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_field_class_1 = require("./input-field.class");
var FlightInformation = /** @class */ (function () {
    function FlightInformation() {
        this.FormattedFlightDate = new input_field_class_1.InputField();
        this.FlightNumber = new input_field_class_1.InputField();
        this.AirlineFSCode = new input_field_class_1.InputField();
        this.AirlineName = new input_field_class_1.InputField();
        this.AirportFSCode = new input_field_class_1.InputField();
        this.AirportMatchString = new input_field_class_1.InputField();
        this.AirportName = new input_field_class_1.InputField();
    }
    return FlightInformation;
}());
exports.FlightInformation = FlightInformation;
