// Angular Core
import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// Component
import { UploadRosterModalComponent } from '../../shared/components/upload-roster-modal/upload-roster-modal.component';
import { DeletePolicyModalComponent } from '../../shared/components/delete-policy-modal/delete-policy-modal.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

// Models
import { Booking, ConfirmationDetails, BookingTypes, ErrorMessage, FileTypes, BookingAgentInformation } from '@allianz/agent-max-core-lib';
import { BookingStatus, PaymentType, SelectAllPaymentType, PrintOrEmailDocumentType } from 'src/app/models/enums';
import { PrintOrEmailReportType, PrintOrEmailTypes, QueueTypes, AlertType, BookingStatusDropdownOptions, ConfirmationViewType, PrintOrEmailOfferType } from 'src/app/models/enums';
import { PaySelectedGroupPoliciesData, PayGroupPoliciesBannerData } from 'src/app/models/classes';
import { PrintOrEmailOptions, PolicyAlertbanner } from '../../models/interfaces';

// Services
import { AppStateService } from '../../shared/services/app-state.service';
import { QuoteService } from 'src/app/api/services/quote.service';
import { LoadingService } from 'src/app/api/services/loading.service';
import { PolicyService } from 'src/app/api/services/policy.service';

// Third Party
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { GroupPoliciesOptions } from 'src/app/models/constants';
import { finalize } from 'rxjs/operators';
import { ReportService } from 'src/app/api/services/report.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-group-policies',
  templateUrl: './group-policies.component.html',
  styleUrls: ['./group-policies.component.scss']
})
export class GroupPoliciesComponent implements OnInit {
  subscriptions: Subscription[] = [];
  groupPoliciesForm: FormGroup;
  agentInformation: BookingAgentInformation;
  agentId: string;
  policySelectCheckboxes: FormArray;
  groupID: string;
  policies: Booking[] = [];
  groupPolicy: Booking;
  bsModalRef: BsModalRef;
  bookingStatus: typeof BookingStatus = BookingStatus;
  paymentType: typeof PaymentType = PaymentType;
  selectAllPaymemtType: typeof SelectAllPaymentType = SelectAllPaymentType;
  paySelectedGroupPoliciesData: PaySelectedGroupPoliciesData;
  showGroupPolicyPurchaseBanner: boolean = false;
  isDesktop: boolean;
  totalPurchasePrice: number;
  numberOfGroupPoliciesSubmitted: number;
  groupPurchaseBannerType: AlertType;
  payGroupPoliciesBannerData: PayGroupPoliciesBannerData;
  policyAlertBanner: PolicyAlertbanner;
  queueType: FormControl = new FormControl(QueueTypes.Pending);
  isAllowedCashWithHeld: boolean = true;
  @Input() modifyGroupFormGroup: FormGroup;
  @Input() sortDesc: FormControl = new FormControl(true);
  @Input() sortBy: FormControl = new FormControl('SortableDepartureDate');
  @Input() visibilityType: FormControl = new FormControl('Primary');
  confirmationMenuErrors: ErrorMessage[] = [];

  constructor(
    private appStateService: AppStateService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private bsModalService: BsModalService,
    private formBuilder: FormBuilder,
    private quoteService: QuoteService,
    private policyService: PolicyService,
    private reportService: ReportService,
    private utilityService: UtilityService,
  ) {
    this.groupPoliciesForm = this.formBuilder.group({
      selectAgencyCreditCard: new FormControl({value: false, disabled: true}),
      selectCashWithheld: new FormControl({value: false, disabled: true}),
      policySelectCheckboxes: this.formBuilder.array([]),
      visibilityType: this.visibilityType,
      sortBy: this.sortBy,
      sortDesc: this.sortDesc,
      modifyGroupFormGroup: this.modifyGroupFormGroup
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.utilityService.getSize().subscribe((isDesktop: boolean) => {
        this.isDesktop = isDesktop;
      })
    );
    this.agentInformation = this.appStateService.getAgentInformation();
    this.agentId = this.agentInformation.AgentCode;
    this.payGroupPoliciesBannerData = this.appStateService.getPayGroupPoliciesBannerData();
    if (this.payGroupPoliciesBannerData !== null) {
      this.showGroupPolicyPurchaseBanner = this.payGroupPoliciesBannerData.ShowGroupPolicyPurchaseBanner;
      this.totalPurchasePrice = this.payGroupPoliciesBannerData.TotalPurchasePrice;
      this.numberOfGroupPoliciesSubmitted = this.payGroupPoliciesBannerData.NumberOfGroupPoliciesSubmitted;
    }
    this.groupPolicy = this.route.snapshot.data.groupPolicy;
    this.appStateService.setCurrentBookingQuote(this.groupPolicy);
    this.groupID = this.route.snapshot.params.groupid;

    this.policies = this.route.snapshot.data.policies.Bookings;
    this.policies.some((policy) => this.enableSelectAllACCFrmCntrl(policy)) ? this.selectAgencyCreditCardFormControl.enable() : this.selectAgencyCreditCardFormControl.disable();
    this.policies.some((policy) => this.enableSelectAllCWFrmCntrl(policy)) ? this.selectCashWithheldFormControl.enable() : this.selectCashWithheldFormControl.disable();
    this.insertPolicyCheckboxes();
    this.sortPolicies();
    this.checkCashWithHeldAvailability(this.policies);
    if (this.appStateService.groupPurchaseBannerType) {
      this.appStateService.groupPurchaseBannerType.subscribe((state) => this.groupPurchaseBannerType = state);
    }
  }
  checkCashWithHeldAvailability(policies: Booking[]): void {
    this.isAllowedCashWithHeld = false;
    if (policies && policies.length) {
      this.isAllowedCashWithHeld = policies.some((p) => p.OrderInformation && p.OrderInformation.IsCashWithHeldEnabled);
    }
  }

  /**
   * Returns selectAgencyCreditCard checkbox as a FormControl
   * @returns {FormControl}
   */
  get selectAgencyCreditCardFormControl(): FormControl {
    return this.groupPoliciesForm.get('selectAgencyCreditCard') as FormControl;
  }

  /**
   * Returns selectCashWithheld checkbox as a FormControl
   * @returns {FormControl}
   */
  get selectCashWithheldFormControl(): FormControl {
    return this.groupPoliciesForm.get('selectCashWithheld') as FormControl;
  }

  /**
   * Returns policy select checkboxes as a FormArray
   * @returns {FormArray}
   */
  get policySelectCheckboxesFormArray(): FormArray {
    return this.groupPoliciesForm.get('policySelectCheckboxes') as FormArray;
  }

  /**
   * Adds policy checkbox form controls to policySelectCheckboxes FormArray
   * @returns {void}
   */
  insertPolicyCheckboxes(): void {
    this.policySelectCheckboxesFormArray.clear();
    for (const policy of this.policies) {
      // Prevents "Parent" group bookings from being included in group policies list
      if (policy.BookingGroupID !== 0) {
        this.policySelectCheckboxesFormArray.push(this.policyCheckbox(policy));
      }
    }
    this.groupPoliciesForm.patchValue(this.policySelectCheckboxesFormArray);
  }

  /**
   * Checks if visibilityType is All
   * @returns {boolean}
   */
  isShowAllInsured(): boolean {
    const isVisibilityTypeAll: boolean = this.modifyGroupFormGroup.value.visibilityType === 'All';
    const hasOtherTravelers = this.groupPoliciesForm.controls.policySelectCheckboxes.value.find((obj) => {
      return obj.policy.TravelInformation.OtherTravelers.length !== 0;
    });
    let isOtherTravelers: boolean = false;

    if (hasOtherTravelers) {
      isOtherTravelers = true;
    }

    return isVisibilityTypeAll && isOtherTravelers;
  }

  /**
   * sorts policies using the function 'dynamicSort' defined immediately below this
   * @returns {void}
   */
  sortPolicies(): void {
    let policies = [];

    policies = this.policySelectCheckboxesFormArray.value;
    this.policySelectCheckboxesFormArray.patchValue(policies.sort(this.dynamicSort(this.sortBy.value, this.sortDesc.value)));
    this.groupPoliciesForm.patchValue(this.policySelectCheckboxesFormArray);
  }

  /**
   * sorting method for arrays. Calling it like:
   * myArray.sort(this.dynamicSort(myProperty, true));
   * (good candidate for utils file when we have one)
   * @param {string} property - name of the property in the aray to sort by
   * @param {boolean} sortDesc - whether you want it to sort by descending or not
   * @returns {Array<string>} - returns the sorted array
   */
  dynamicSort(property: string, sortDesc: boolean): (a: object, b: object) => number {
    if (property[0] === '-') {
      property = property.substr(1);
    }
    return function sort(a: object, b: object): number {
      const propA = a[property];
      const propB = b[property];
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;

      if (sortDesc) {
        return result * -1;
      } else {
        return result;
      }
    };
  }

  /**
   * Is true if there exists an Agency Credit Card Policy in group policies table that is purchaseable
   * @param {Booking} policy
   * @returns {boolean}
   */
  enableSelectAllACCFrmCntrl(policy: Booking): boolean {
    return ((policy.BookingStatus === 20 || policy.BookingStatus === 3) && policy.OrderInformation.PaymentMethod === 2);
  }

  /**
   * Is true if there exists a CashWithheld Policy in group policies table that is purchaseable
   * @param {Booking} policy
   * @returns {boolean}
   */
  enableSelectAllCWFrmCntrl(policy: Booking): boolean {
    return ((policy.BookingStatus === 20 || policy.BookingStatus === 3) && policy.OrderInformation.PaymentMethod === 1);
  }

  /**
   * Is true if the Policy is submitted or pending
   * @param {Booking} policy
   * @returns {boolean}
   */
  isGroupPolicySubmittedOrPending(policy: Booking): boolean {
    return ((policy.BookingStatus === 25 || policy.BookingStatus === 22) && this.payGroupPoliciesBannerData.ShowGroupPolicyPurchaseBanner === true);
  }

  /**
   * Disables or enables GroupPolicyForm depending on if Booking Status is Group Purchase Pending
   * @returns {void}
   */
  disableOrEnableGroupPoliciesForm(): void {
    if (this.groupPolicy.BookingStatus === 22) {
      this.groupPoliciesForm.disable();
      this.policySelectCheckboxesFormArray.controls.forEach((control) => {
        control.disable();
      });
    } else {
      this.policySelectCheckboxesFormArray.controls.forEach((control) => {
        control.enable();
      });
    }
  }

  /**
   * Builds policy checkbox form control
   * @param {FormGroup} formGroup
   * @returns {boolean}
   */
  renderSelectedFormControl(formGroup: FormGroup): boolean {
    let isCashWithHeldEnable = true;
    if (this.policies && this.policies.length) {
      for (const p of this.policies) {
        if (formGroup.controls.bookingId.value && p.BookingID && formGroup.controls.bookingId.value === p.BookingID) {
            isCashWithHeldEnable = p.OrderInformation && p.OrderInformation.IsCashWithHeldEnabled;
            break;
        }
      }
    }

    const isAgencyCreditCard = formGroup.controls.paymentType.value === this.paymentType.AgencyCreditCard;
    const isCashWithheld = formGroup.controls.paymentType.value === this.paymentType.CashWithheld && isCashWithHeldEnable;
    const isReadyForPurchase = formGroup.controls.bookingStatus.value === this.bookingStatus.GroupQuoteReadyForPurchase;
    const isPurchaseRejected = formGroup.controls.bookingStatus.value === this.bookingStatus.PurchaseRejected;

    return (isAgencyCreditCard || isCashWithheld) && (isReadyForPurchase || isPurchaseRejected);
  }

  /**
   * Return Primary Traveler Name - Last Name First, First Name Last
   * @param {Booking} booking
   * @returns {string}
   */
  primaryTravelerName(booking: Booking): string {
    return booking.TravelInformation.PrimaryTraveler !== null ? `${booking.TravelInformation.PrimaryTraveler.Info.LastName.Value}, ${booking.TravelInformation.PrimaryTraveler.Info.FirstName.Value}` : '';
  }

  /**
   * Builds policy checkbox form control
   * @param {Booking} booking
   * @returns {FormGroup}
   */
  policyCheckbox(booking: Booking): FormGroup {
    return this.formBuilder.group({
      policy: booking,
      bookingId: booking.BookingID,
      bookingStatus: this.getBookingStatusText(booking.BookingStatus),
      paymentType: this.getPaymentTypeText(booking.OrderInformation.PaymentMethod),
      selected: false,
      SortableDepartureDate: booking.TripInformation.FormattedDepartureDate.Value,
      ConfirmationNumber: this.getPolicyConfirmationNumber(booking.OrderInformation.ConfirmationDetails),
      AgentCode: booking.BookingAgentInformation.AgentCode,
      BookingAgentInformation: booking.BookingAgentInformation,
      PrimaryInsured: this.primaryTravelerName(booking)
    });
  }

  /**
   * Selects policy to be paid on individual checkbox selection
   * @param {boolean} selected
   * @param {string} paymentType
   * @returns {void}
   */
  policySelectedToPay(selected: boolean, paymentType: string): void {
    if (selected === false && paymentType === 'Agency Credit Card' && this.selectAgencyCreditCardFormControl.value === true) {
      this.selectAgencyCreditCardFormControl.setValue(false);
    }

    if (selected === false && paymentType === 'Cash Withheld' && this.selectCashWithheldFormControl.value === true) {
      this.selectCashWithheldFormControl.setValue(false);
    }
  }

  /**
   * If applicable form control is checked then check all applicable policies in policies table
   * @param {boolean} selected
   * @returns {void}
   */
  selectAllAgencyCreditCard(selected: boolean): void {
    this.toggleCheckAllPaymentTypes(selected, 'Agency Credit Card');
  }

  /**
   * If applicable form control is checked then check all applicable policies in policies table
   * @param {boolean} selected
   * @returns {void}
   */
  selectAllCashWithheld(selected: boolean): void {
    this.toggleCheckAllPaymentTypes(selected, 'Cash Withheld');
  }

  /**
   * Checks all applicable policies in policies table
   * @returns {void}
   */
  toggleCheckAllPaymentTypes(selected: boolean, paymentType: string): void {
    for (const control of this.policySelectCheckboxesFormArray.controls) {
      const checkBox = control.get('selected') as FormControl;
      if (control.value.paymentType === paymentType) {
        checkBox.setValue(selected);
      }
    }
  }

  /**
   * If a policy is selected for payment then the pay now button is enabled
   * @returns {boolean}
   */
  setPolicyNotSelected(): boolean {
    const selected = [] as boolean[];

    for (const control of this.policySelectCheckboxesFormArray.controls) {
      selected.push(control.value.selected);
    }

    if (selected.includes(true)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Collects selected policies and goes to agent payment
   * @returns {void}
   */
  paySelectedPolicies(): void {
    const readyAgencyCCPolicies: Booking[] = [];
    const readyCashWithheldPolicies: Booking[] = [];

    this.paySelectedGroupPoliciesData = new PaySelectedGroupPoliciesData();
    this.paySelectedGroupPoliciesData.SelectAllPaymentType = this.getSelectAllPaymentType();

    for (const control of this.policySelectCheckboxesFormArray.controls) {
      const selected = control.get('selected') as FormControl;
      const policy = control.get('policy') as FormControl;

      if (control.value.paymentType === PaymentType.AgencyCreditCard && selected.value === true && (control.value.bookingStatus === BookingStatus.GroupQuoteReadyForPurchase || control.value.bookingStatus === BookingStatus.PurchaseRejected)) {
        readyAgencyCCPolicies.push(policy.value);
      }
      if (control.value.paymentType === PaymentType.CashWithheld && selected.value === true && (control.value.bookingStatus === BookingStatus.GroupQuoteReadyForPurchase || control.value.bookingStatus === BookingStatus.PurchaseRejected)) {
        readyCashWithheldPolicies.push(policy.value);
      }
    }

    this.paySelectedGroupPoliciesData.ReadyAgencyCCPolicies = readyAgencyCCPolicies;
    this.paySelectedGroupPoliciesData.ReadyCashWithheldPolicies = readyCashWithheldPolicies;
    this.appStateService.setPaySelectedGroupPoliciesData(this.paySelectedGroupPoliciesData);
    this.router.navigate([`small-group/modifyGroup/${this.groupID}/agent-payment/`]);
  }

  /**
   * Triggers the upload roster modal for bulk policy upload
   * @returns {void}
   */
  showUploadRosterModal(): void {
    const initialState = {
      text: `Select File to upload policies to group: ${this.groupPolicy.GroupName.Value}`,
      dialogIndicator: 'Policy'
    };
    this.bsModalRef = this.bsModalService.show(UploadRosterModalComponent, { initialState });
    this.bsModalRef.content.modalPoliciesResponse
      .subscribe((response) => {
        if (response) {
          this.policyAlertBanner = {
            AlertType: AlertType.Success,
            Type: 'upload'
          };
          this.fetchPoliciesForGroup();
        }
      });
  }

  /**
   * Triggers the upload roster modal for bulk policy upload
   * @param {Booking} bookingToDelete - booking reference that will be deleted by back end
   * @returns {void}
   */
  showDeletePolicyConfirmationModal(bookingToDelete: Booking): void {
    const initialState = {
      text: `Are you sure you want to delete this policy?: ${this.groupPolicy.GroupName.Value}`,
      dialogIndicator: 'Policy',
      booking: bookingToDelete,
    };
    this.bsModalRef = this.bsModalService.show(DeletePolicyModalComponent, { initialState });
    this.bsModalRef.content.modalResponse.subscribe((response) => {
      if (response) {
        this.policyAlertBanner = {
          AlertType: AlertType.Success,
          Type: 'delete'
        };
        this.fetchPoliciesForGroup();
      }
    });
  }

  /**
   * Triggers the upload roster modal for bulk policy upload
   * @param {Booking} bookingToDelete - booking reference that will be deleted by back end
   * @returns {void}
   */
  showDialogCancelConfirm(booking: Booking): void {
    const initialState = {
      text: `Are you sure you want to Cancel this policy?`,
      dialogIndicator: 'Policy'
    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
    this.bsModalRef.content.modalResponse.subscribe((response) => {
      if (response) {
        this.loadingService.open();
        this.policyService.cancelPurchase(booking.BookingID, booking.OrderInformation.ConfirmationDetails.Policies[0].Key, this.appStateService.getCredentials())
          .subscribe((cancelResponse) => {
            this.loadingService.close();
            this.policyAlertBanner = {
              Policy: cancelResponse.Booking,
              ValidationMessages: cancelResponse.ErrorMessages,
              AlertType: cancelResponse.IsCancelSuccessful ? AlertType.Success : AlertType.Danger,
              Type: 'cancel'
            };
            if (cancelResponse.IsCancelSuccessful) {
              this.fetchPoliciesForGroup();
            }
          },
            (error) => this.loadingService.close(),
            () => this.loadingService.close());
      }
    });
  }

  /**
   * gets most recent policies for group
   * @returns {void}
   */
  fetchPoliciesForGroup(): void {
    this.loadingService.open();
    this.quoteService.GetPoliciesForGroup(this.groupID, this.appStateService.getCredentials())
      .subscribe((policyResponse) => {
        this.loadingService.close();
        this.policies = policyResponse.Bookings;
        this.policySelectCheckboxesFormArray.clear();
        this.insertPolicyCheckboxes();
        this.sortPolicies();
        this.policies.some((policy) => this.enableSelectAllACCFrmCntrl(policy)) ? this.selectAgencyCreditCardFormControl.enable() : this.selectAgencyCreditCardFormControl.disable();
        this.policies.some((policy) => this.enableSelectAllCWFrmCntrl(policy)) ? this.selectCashWithheldFormControl.enable() : this.selectCashWithheldFormControl.disable();
        this.checkCashWithHeldAvailability(policyResponse.Bookings);
      },
        (error) => this.loadingService.close(),
        () => this.loadingService.close());
  }

  /**
   * View current individual traveler policy routing
   * same as modify for the moment
   * @param {Booking} booking
   * @returns {void}
   */
  viewPolicy(booking: Booking): void {
    this.appStateService.setCurrentBookingId(booking.BookingID);
    const url = booking.BookingStatus === 4 || booking.BookingStatus === 5 || booking.BookingStatus === 13 ? `purchaseconfirmation` : `small-group/modifyGroup/${this.groupID}/policies/modify-individual-policy/${booking.BookingID}`;
    this.router.navigate([url]);
  }

  /**
   * Modify current individual traveler policy routing
   * @param {Booking} booking
   * @returns {void}
   */
  openPolicy(booking: Booking): void {
    this.appStateService.setCurrentBookingId(booking.BookingID);
    this.router.navigate([`small-group/modifyGroup/${this.groupID}/policies/modify-individual-policy/${booking.BookingID}`]);
  }

  /**
   * Goes to create individual traveler policy route
   * also save current group booking to prefill the info in the next route
   * @returns {void}
   */
  addIndividualPolicy(): void {
    this.appStateService.setCurrentBookingId(null);
    this.appStateService.setCurrentBookingQuote(this.groupPolicy);
    this.router.navigate([`small-group/modifyGroup/${this.groupID}/policies/add-new-policy/`]);
  }

  /**
   *  Check if the policy can be deleted to show the prompt
   *  @param {booking} policy policy status check to see if policy can be deleted
   *  @returns {boolean}
   */
  isValidForDeletion(policy: Booking): boolean {
    return policy.BookingStatus === 1 || policy.BookingStatus === 3 || policy.BookingStatus === 19
      || policy.BookingStatus === 20 || policy.BookingStatus === 21;

  }

  /**
   * Get the booking status as plain text
   * @param {number} bookingStatusNumber
   * @returns {string} - booking status as plain text
   */
  getBookingStatusText(bookingStatusNumber: number): string {
    return this.bookingStatus[Object.keys(this.bookingStatus)[bookingStatusNumber]];
  }

  /**
   * Get the payment type as plain text
   * @param {number} bookingPaymentNumber
   * @returns {string} - payment type as plain text
   */
  getPaymentTypeText(bookingPaymentNumber: number): string {
    return this.paymentType[Object.keys(this.paymentType)[bookingPaymentNumber]];
  }

  /**
   * Sets the selectAllPaymentType as plain text
   * @returns {SelectAllPaymentType} - payment type as plain text
   */
  getSelectAllPaymentType(): SelectAllPaymentType {
    if (this.selectAgencyCreditCardFormControl.value === false && this.selectCashWithheldFormControl.value === true) {
      return this.selectAllPaymemtType.CashWitheld;
    }
    if (this.selectAgencyCreditCardFormControl.value === true && this.selectCashWithheldFormControl.value === false) {
      return this.selectAllPaymemtType.AgencyCreditCard;
    }
    if (this.selectAgencyCreditCardFormControl.value === true && this.selectCashWithheldFormControl.value === true) {
      return this.selectAllPaymemtType.Both;
    }
    if (this.selectAgencyCreditCardFormControl.value === false && this.selectCashWithheldFormControl.value === false) {
      return this.selectAllPaymemtType.Neither;
    }
  }

  /**
   * Get small group policy confirmation number
   * @param {ConfirmationDetails} confirmationDetails - policy confirmation details
   * @returns {string} - confirmation number for small group policy
   */
  getPolicyConfirmationNumber(confirmationDetails: ConfirmationDetails | null): string {
    return confirmationDetails && confirmationDetails.Policies.length > 0 ? confirmationDetails.Policies[0].Key : '';
  }

  /**
   * Get small group policy confirmation number
   * @param {ConfirmationDetails | null} confirmationDetails - policy confirmation details
   * @returns {string} - confirmation number for small group policy
   */
  getPurchaseConfirmationDate(confirmationDetails: ConfirmationDetails | null): string {
    return confirmationDetails ? confirmationDetails.FormattedPurchaseDate : '';
  }

  /**
   * When selecting the Print/Email option from the dropdown route to page with appropriate options
   * @param {Booking} booking
   * @returns {void}
   */
  onPrintOrEmailPolicyDocuments(booking: Booking): void {
    const agentInfo = this.appStateService.getAgentInformation();
    const options: PrintOrEmailOptions = {
      HeaderName: 'Email Purchase Receipt / Policy Confirmation',
      DocumentType: PrintOrEmailDocumentType.BookingDocument,
      ReportType: PrintOrEmailReportType.TravelInsuranceReceipt,
      BookingId: booking.BookingID,
      ShowResendFulfillment: false,
      ShowViewButton: true,
      ShowFromName: true,
      IsGroupEmail: false,
      ShowFromEmail: true,
      EmailInput: {
        FromEmailAddress: agentInfo.EmailAddress,
        FromName: `${agentInfo.FirstName} ${agentInfo.LastName}`,
        ToName: `${booking.TravelInformation.PrimaryTraveler.Info.FirstName.Value} ${booking.TravelInformation.PrimaryTraveler.Info.LastName.Value}`,
        ToEmailAddress: booking.TravelInformation.PrimaryTraveler.EmailAddress.Value,
      }
    };
    if (booking.OrderInformation.ConfirmationDetails != null && booking.OrderInformation.ConfirmationDetails.Policies.length > 0) {
      options.PolicyNumber = booking.OrderInformation.ConfirmationDetails.Policies[0].Key,
        options.ShowResendFulfillment = true;
      options.IsGroupEmail = true;
    } else {
      options.HeaderName = 'Print Or Email Quote';
      options.DocumentType = PrintOrEmailDocumentType.BookingDocument;
      options.ReportType = PrintOrEmailReportType.WebQuoteByAgent;
    }
    this.appStateService.setPrintOrEmailReportName(PrintOrEmailTypes.PurchaseReceiptAndConfirmation);
    this.appStateService.setPrintOrEmailData(booking, options);
    this.router.navigate(['printoremail']);
  }

  /**
   * Exits the small group and navigates back to group queues page
   * @returns {void}
   */
  exitSmallGroup(): void {
    this.appStateService.setCurrentBookingId(null);
    this.appStateService.setCurrentBookingQuote(null);
    this.appStateService.setQueueType(QueueTypes.Groups);
    this.router.navigate(['queues']);
  }

  /**
   * can upload roster if there aren't any policies associated with the group
   * @returns {boolean}
   */
  canUploadRoster(): boolean {
    return (this.groupPoliciesForm.controls.policySelectCheckboxes as FormArray).controls.length === 0;
  }

  /**
   * get availabe dropdow options to each booking status
   * @returns {BookingStatusDropdownOptions[]}
   */
  getBookingAvailabeDropdownOptions(booking: Booking): BookingStatusDropdownOptions[] {

    if (booking.BookingStatus === 19) { //  incomplete record
      return GroupPoliciesOptions.IncompleteStatus;
    } else if (booking.BookingStatus === 20) { // ready for purchase status
      return GroupPoliciesOptions.ReadyForPurchase;
    } else if (booking.BookingStatus === 3) { // purchase rejected
      return GroupPoliciesOptions.PurchaseRejected;
    } else if (booking.BookingStatus === 4) { // purchase confirmed
      return GroupPoliciesOptions.Confirmed;
    } else if (booking.BookingStatus === 5) { // cancel
      return GroupPoliciesOptions.Cancelled;
    } else {
      return new Array<BookingStatusDropdownOptions>();
    }
  }
  /**
   * On cancel policy
   * @param {Booking} booking
   * @returns {void}
   */
  onCancelPolicy(booking: Booking): void {
    this.loadingService.open();
    this.policyService.getBookingWithCurrentPolicyData(BookingTypes.Cancel, this.convertBookingID(booking.BookingID), this.getPolicyConfirmationNumber(booking.OrderInformation.ConfirmationDetails),
      this.appStateService.getCredentials()).pipe(finalize(() => this.loadingService.close()))
      .subscribe((result) => {
        if (result.ErrorMessages == null || result.ErrorMessages.length === 0) {
          if (result.ShowMessageBeforeCancel) {
            window.alert(result.ShowMessageBeforeCancel);
          }
          this.appStateService.setCurrentBookingId(null);
          this.appStateService.setCurrentBookingQuote(result.Booking);
          this.appStateService.setConfirmationViewType(ConfirmationViewType.Cancel);
          this.router.navigate(['purchaseconfirmation']);
        } else {
          this.confirmationMenuErrors = result.ErrorMessages;
        }
      },
        (error) => { },
        () => { });
  }
  /**
   *  calls the policyService to get a policy based on the booking id
   * this will route to /bookinginfo
   * @param {Queue} queueItem
   * @returns {void}
   */
  onModifyPolicy(booking: Booking): void {
    this.loadingService.open();
    this.policyService.getBookingWithCurrentPolicyData(BookingTypes.Modify, this.convertBookingID(booking.BookingID),
      this.getPolicyConfirmationNumber(booking.OrderInformation.ConfirmationDetails), this.appStateService.getCredentials())
      .pipe(finalize(() => this.loadingService.close()))
      .subscribe((result) => {
        if (result.ErrorMessages.length < 1) {
          this.appStateService.setQueueType(QueueTypes.Confirmed);
          if (result.CanEditFlights) {
            this.appStateService.setBookingInfoViewType(BookingTypes.EditFlights);
            this.appStateService.setBookingInfoSourceType(BookingTypes.EditFlights);
          } else {
            this.appStateService.setBookingInfoViewType(BookingTypes.Policy);
            this.appStateService.setBookingInfoSourceType(BookingTypes.Modify);
          }
          this.appStateService.setCurrentBookingId(null);
          this.appStateService.setCurrentBookingQuote(result.Booking);
          this.router.navigate(['bookinginfo']);
        } else {
          this.confirmationMenuErrors = result.ErrorMessages;
        }
      },
        () => { });
  }
  /**
   *  calls the quoteService to get a booking based on the booking id
   * this will route to
   * @param {string} bookingID
   * @returns {void}
   */
  onViewPolicy(bookingID: string): void {
    this.appStateService.setCurrentBookingId(bookingID);
    this.appStateService.setConfirmationViewType(ConfirmationViewType.OpenConfirmation);
    this.appStateService.setBookingInfoViewType(BookingTypes.Quote);
    this.router.navigate(['purchaseconfirmation']);
  }
  /**
   * Navigates to print email route after
   * setting up the booking and report name
   * @returns {void}
   */
  onPrintPolicy(bookingID: string): void {
    const bookingParam = new Booking();
    bookingParam.BookingID = bookingID;
    this.loadingService.open();
    this.reportService.viewOrEmailBookingDocument('View', PrintOrEmailReportType.TravelInsuranceReceipt, bookingParam, {}, this.appStateService.getCredentials())
      .pipe(finalize(() => this.loadingService.close()))
      .subscribe((response) => {
        if (response.IsValid) {
          this.utilityService.openFile(response.FileContent, FileTypes.PDF);
        } else {
          this.confirmationMenuErrors = response.ErrorMessages;
        }
      },
        () => { }
      );
  }

  /**
   * sends user to print/email said quote
   * @param {Queue} queueItem
   * @returns {void}
   */
  onPrintOrEmailQuote(booking: Booking): void {
    const agentInfo: BookingAgentInformation = this.appStateService.getAgentInformation();
    const options: PrintOrEmailOptions = {
      HeaderName: 'Print Or Email Quote',
      DocumentType: PrintOrEmailDocumentType.BookingDocument,
      ReportType: PrintOrEmailReportType.WebQuoteByAgent,
      BookingId: booking.BookingID,
      OfferType: PrintOrEmailOfferType.FirstChance,
      ShowViewButton: true,
      EmailInput: {
        FromName: `${agentInfo.FirstName} ${agentInfo.LastName}`,
        FromEmailAddress: agentInfo.EmailAddress,
        ToName: '',
        ToEmailAddress: '',
        SecondaryEmailAddress: ''
      }
    };
    this.appStateService.setPrintOrEmailReportName(PrintOrEmailTypes.Quote);
    this.appStateService.setPrintOrEmailData(booking, options);
    this.appStateService.setQueueType(QueueTypes.Quotes);
    this.router.navigate(['printoremail']);
  }
  /*
   * Sets up the print email options and routes to the print email page
   * @param {Booking} booking
   * @returns {void}
   */
  onEmailConfirmedPolicy(booking: Booking): void {
    const agentInfo = this.appStateService.getAgentInformation();
    const options: PrintOrEmailOptions = {
      HeaderName: 'Email Purchase Receipt / Policy Confirmation',
      DocumentType: PrintOrEmailDocumentType.BookingDocument,
      ReportType: PrintOrEmailReportType.TravelInsuranceReceipt,
      BookingId: booking.BookingID,
      PolicyNumber: this.getPolicyConfirmationNumber(booking.OrderInformation.ConfirmationDetails),//booking.PolicyNumber,
      ShowResendFulfillment: true,
      ShowViewButton: false,
      EmailInput: {
        FromEmailAddress: agentInfo.EmailAddress,
        FromName: `${agentInfo.FirstName} ${agentInfo.LastName}`,
        ToName: booking.PrimaryInsuredName,
        ToEmailAddress: '',
        SecondaryEmailAddress: ''
      }
    };
    this.appStateService.setPrintOrEmailReportName(PrintOrEmailTypes.PurchaseReceiptAndConfirmation);
    this.appStateService.setPrintOrEmailData(booking, options);
    this.appStateService.setQueueType(QueueTypes.Confirmed);
    this.router.navigate(['printoremail']);
  }

  /**
   * convert bookingid to string if it number
   * @param bookingID
   * @returns {string}
   */
  convertBookingID(bookingID?: string | number): string {
    if (typeof bookingID === 'number') {
      return bookingID.toString();
    }
    return bookingID;
  }
  /**
   * get availabe dropdown options to each booking status
   * @returns {Void}
   */
  performRightActionOnBooking(action: BookingStatusDropdownOptions, booking: Booking): void {
    if (action === BookingStatusDropdownOptions.Open) {
      this.openPolicy(booking);
    } else if (action === BookingStatusDropdownOptions.Delete) {
      if (this.isValidForDeletion(booking)) {
        this.showDeletePolicyConfirmationModal(booking);
      }
    } else if (action === BookingStatusDropdownOptions.Cancel) {
        this.onCancelPolicy(booking);
    } else if (action === BookingStatusDropdownOptions.Modify) {
        this.onModifyPolicy(booking);
    } else if (action === BookingStatusDropdownOptions.View) {
      const bookingId = this.convertBookingID(booking.BookingID);
      if (bookingId) {
        this.onViewPolicy(bookingId);
      }
    } else if (action === BookingStatusDropdownOptions.Print) {
      const bookingId = this.convertBookingID(booking.BookingID);
      if (bookingId) {
        this.onPrintPolicy(bookingId);
      }
    } else if (action === BookingStatusDropdownOptions.PrintEmail) {
      this.onPrintOrEmailQuote(booking);
    } else if (action === BookingStatusDropdownOptions.Email) {
      this.onEmailConfirmedPolicy(booking);
    }
  }
}
