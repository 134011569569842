// Angular Core
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// Models
import { SupplierInfo, InputFieldCollection } from '@allianz/agent-max-core-lib';

// Third Party
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss']
})
export class SupplierListComponent implements OnInit, OnDestroy {
  displayedSupplierList: SupplierInfo[] = [];
  SupplierListForm: FormGroup;
  suppliers: SupplierInfo[];
  isSmallGroup: boolean = false;
  isModifySmallGroup: boolean = false;
  isSmallGroupDetails: boolean = false;
  isSmallGroupAddNew: boolean = false;
  isBookingInfo: boolean = false;
  isModifyIndividual: boolean = false;
  isColMd10: boolean = false;
  supplierListSubscriptions: Subscription[] = [];

  @Input('SupplierList') SupplierList: InputFieldCollection< SupplierInfo >;
  @Input('parent') Parent: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.isSmallGroupRoute();
    this.isSmallGroupDetailsRoute();
    this.isSmallGroupAddNewRoute();
    this.isBookingInfoRoute();
    this.isModifyIndividualRoute();

    this.SupplierListForm =  this.formBuilder.group({
      SupplierArray: this.formBuilder.array([])
    });
  }

  SupplierArray(): FormArray {
    return this.SupplierListForm.get('SupplierArray') as FormArray;
  }

  ngOnInit(): void {
    this.suppliers = this.route.snapshot.data.suppliers;
    this.isColMd10 = (this.isSmallGroupAddNewRoute() || this.isBookingInfoRoute() || this.isModifyIndividualRoute()) && this.Parent === 'tripInfo';
    this.initSupplierArray();

    if (this.SupplierArray().length !== 0) {
      this.SupplierArray()
      .valueChanges
      .pipe(
        debounceTime(500)
      );

      // this.SupplierArray().controls.forEach((control) => {
      //   this.supplierListSubscriptions.push(
      //     control.valueChanges.subscribe((val) => {
      //     })
      //   );
      // })
    }
  }

  ngOnDestroy(): void {
    // this.supplierListSubscriptions.forEach((res) => res.unsubscribe());
  }

  /**
   * Selects supplier when dropdown arrow is clicked
   * @param {number} index
   * @param {Event} $event
   * @returns {void}
   */
  changeSupplier(index: number, $event: Event): void {
    this.SupplierArray().controls[index].patchValue({ supplier: $event.target['value'] });
  }

  /**
   * Suppliers typeahead input handler
   * @param {number} index
   * @param {Event} $event
   * @returns {void}
   */
  supplierOnInput(index: number, $event: Event): void {
    let searchString = null;
    searchString = $event.target['value'] !== '' ? $event.target['value'].toLowerCase() : '';

    this.filterSuppliers(searchString);
  }

  /**
   * Suppliers typeahead select handler
   * @param {number} index
   * @param {Event} $event
   * @returns {void}
   */
  supplierOnSelect($event?: Event): void {
    const currentSupplier = this.suppliers.filter((supp) => {
      let searchString = $event['value'] !== '' ? $event['value'][0].toLowerCase() + $event['value'].substring(1) : '';
      let supplierWithSpace = [];

      if (searchString.includes(' ')) {
        supplierWithSpace = searchString.split(' ');
        // for each string after space make first character lowercase
        for (let i = 0, x = supplierWithSpace.length; i < x; i++) {
          supplierWithSpace[i] = supplierWithSpace[i] !== '' ? supplierWithSpace[i][0].toLowerCase() + supplierWithSpace[i].substring(1) : '';
        }
        searchString = supplierWithSpace.join(' ');
      }

      if (supp.Description.startsWith(searchString)) {
        return searchString === supp.Description.toLowerCase().substring(0, searchString.length);
      }
    });
  }

  /**
   * Suppliers typeahead no results handler
   * @param {number} index
   * @param {any} $event
   * @returns {void}
   */
  typeaheadNoResults(index: number, $event: any): void {
    if ($event) {
      this.SupplierArray()['controls'][index].patchValue({ supplier: null });
    }
  }
  
  /**
   * Suppliers typeahead blur handler
   * @param {number} index
   * @param {Event} $event
   * @returns {void}
   */
  typeaheadOnBlur(index: number, $event: any): void {
    if ($event) {
      this.SupplierArray().controls[index].patchValue({ supplier: $event.value });
      this.SupplierArray().controls[index].setErrors(null);
    } else {
      this.SupplierArray()['controls'][index].patchValue({ supplier: null });
    }
  }

  /**
   * Filters main supplierList and populates displayedSupplierList with suppliers that begin with user entered text
   * @param {string} searchString
   * @returns {void}
   */
  filterSuppliers(searchString: string): void {
    this.displayedSupplierList = this.suppliers.filter((supp) => supp.Description.toLowerCase().includes(searchString));
  }

  /**
   * Add supplier to the form object
   * @returns {void}
   */
  addSupplier(): void {
    const newGroup = this.formBuilder.group({
      groupIndex: this.SupplierArray().length,
      supplier: new FormControl([null], Validators.required)
    });

    // this.supplierListSubscriptions.push(
    //   newGroup.valueChanges.subscribe((val) => {
    //   })
    // )

    this.SupplierArray().push(newGroup);
  }

  /**
   * Remove specific supplier from form object
   * @param {number} index
   * @returns {void}
   */
  removeSupplier(index: number): void {
    this.SupplierArray().removeAt(index);
  }

  /**
   * Init suppliers array for the form object
   * @returns {void}
   */
  private initSupplierArray(): void {
    if (typeof this.SupplierList === 'undefined') {
      this.addSupplier();
      return;
    }
    if (typeof this.SupplierList !== 'undefined') {
      for (const supp of this.SupplierList.Values) {
        if (supp.Code !== '') {
          const listOfMatches = this.suppliers.filter((item) => {
            return (item.Code).includes(supp.Code);
          });
          
          if (listOfMatches.length !== 0) {
            const newGroup = this.formBuilder.group({
              groupIndex: this.SupplierArray().length,
              supplier: new FormControl(listOfMatches[0].Description, Validators.required)
            });
  
            this.SupplierArray().push(newGroup);
          }
        }
      }
      if (this.SupplierArray().length === 0) {
        this.addSupplier();
      }
    }
  }

  /**
   * Checks to see if route is small group
   * @returns {boolean}
   */
   private isSmallGroupRoute(): boolean {
    this.isModifySmallGroup = this.router.url.includes('modifyGroup');
    return this.isSmallGroup = this.router.url.includes('/small-group');
  }

  /**
   * Checks to see if route is small group
   * @returns {boolean}
   */
  private isSmallGroupDetailsRoute(): boolean {
    return this.isSmallGroupDetails = this.router.url.includes('/details');
  }

  /**
   * Checks to see if route is small group
   * @returns {boolean}
   */
  private isSmallGroupAddNewRoute(): boolean {
    return this.isSmallGroupAddNew = this.router.url.includes('/add-new-policy');
  }

  /**
   * Checks to see if route is small group
   * @returns {boolean}
   */
  private isBookingInfoRoute(): boolean {
    return this.isSmallGroupAddNew = this.router.url.includes('/bookinginfo');
  }

  /**
   * Checks to see if route is small group
   * @returns {boolean}
   */
   private isModifyIndividualRoute(): boolean {
    return this.isModifyIndividual = this.router.url.includes('/modify-individual-policy');
  }
}
