// Angular Core
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

// Serivces
import { AppStateService } from '../services/app-state.service';
import { QuoteService } from 'src/app/api/services/quote.service';

// Models
import { GetGroupPoliciesBody } from 'src/app/models/classes';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class GetGroupPoliciesByGroupIdResolver implements Resolve<Observable<GetGroupPoliciesBody>> {
  constructor(
    private quoteService: QuoteService,
    private appStateService: AppStateService
  ) { }
  resolve(route: ActivatedRouteSnapshot): Observable<GetGroupPoliciesBody> {
    return this.quoteService.GetPoliciesForGroup(route.parent.params.groupid, this.appStateService.getCredentials());
  }
}
