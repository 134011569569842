// Angular Core
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

// Services
import { NotificationService } from 'src/app/api/services/notification.service';

// Models
import { NotificationSaleType } from 'src/app/models/enums';
import { NotificationResponse } from 'src/app/models/interfaces';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class NotificationsResolver implements Resolve<any> {
  constructor(private notificationService: NotificationService) { }

  /**
   * Loads data when resolving the route
   * @param {ActivatedRouteSnapshot} route - current route being loaded
   * @returns {Observable<Notificationresponse>}
   */
  resolve(route: ActivatedRouteSnapshot): Observable<NotificationResponse> {
    let saleType: NotificationSaleType;
    switch (route.routeConfig.path) {
      case 'maxmail':
        saleType = NotificationSaleType.MaxMail;
        break;
      case 'quotemax':
        saleType = NotificationSaleType.QuoteMax;
        break;
      case 'pre-ex-expiring':
        saleType = NotificationSaleType.PreExExpiring;
        break;
      default:
        saleType = NotificationSaleType.Default;
        break;
    }
    return this.notificationService.getNotificationsBySaleType(saleType);
  }
}
