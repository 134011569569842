// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Serivces
import { UserService } from 'src/app/api/services/user.service';
import { AppStateService } from '../services/app-state.service';

// Third Party
import { Observable, of } from 'rxjs';

@Injectable()
export class GetAllowManageGroupStatusByUserByUserResolver implements Resolve<Observable<boolean>> {
  constructor(
    private appStateService: AppStateService,
    private userService: UserService
  ) { }
  resolve(): Observable<boolean> {
    return this.appStateService.getCanManageGroups() ? of(this.appStateService.getCanManageGroups()) : this.userService.getAllowManageGroupStatusByUser(this.appStateService.getCredentials());
  }
}
