export class UserCredentials {
    Password: string;
    UserName: string;
    WebLoginType?: string;
    RememberMe: boolean;
    constructor() {
        this.Password = '';
        this.UserName = '';
        this.WebLoginType = '';
        this.RememberMe = false;
    }
}
