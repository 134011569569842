// Angular Core
import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

// Services
import { UserService } from '../api/services/user.service';
import { LoadingService } from '../api/services/loading.service';

// Models
import { ErrorMessage } from '@allianz/agent-max-core-lib';

// Third Party
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-new-account',
  templateUrl: './create-new-account.component.html',
  styleUrls: ['./create-new-account.component.scss']
})
export class CreateNewAccountComponent implements OnDestroy {
  createAccountFormGroup: FormGroup;
  email: FormControl = new FormControl('');
  accam: FormControl = new FormControl('');
  newAccountSubscriptions: Subscription[] = [];

  agencyLongName: string;
  isValidACCAM: boolean = false;
  validationMessages: ErrorMessage[] = [];
  isFormSubmissionComplete: boolean = false;
  agencyId: number;
  agencyIdMasked: string;
  status: number;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private loadingService: LoadingService
  ) {
    this.createAccountFormGroup = this.formBuilder.group({
      Email: this.email,
      Accam: this.accam
    });
  }

  ngOnDestroy(): void {
    this.newAccountSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * calls the createwebaccount api endpoint and handles the response
   * @return {void}
   */
  createNewAccount(): void {
    this.validationMessages = [];
    this.loadingService.open();
    this.newAccountSubscriptions.push(
      //this.userService.createWebAccount(this.createAccountFormGroup.value.Email, this.agencyId)
      this.userService.createWebAccount(this.createAccountFormGroup.value.Email, this.agencyIdMasked)
        .subscribe((response) => {
          if (response.IsValid && response.StatusCode !== 0) {
            this.isFormSubmissionComplete = true;
            this.status = response.StatusCode;
          } else {
            this.validationMessages = response.ErrorMessages;
          }
        },
        (error) => { },
        () => this.loadingService.close())
    );
  }

  /**
   * calls the verifyAgencyByACCAM api endpoint and handles the response
   * @return {void}
   */
  onVerifyACCAM(): void {
    this.validationMessages = [];
    this.loadingService.open();
    this.newAccountSubscriptions.push(
      this.userService.verifyAgencyByACCAM(this.createAccountFormGroup.value.Accam)
        .subscribe((response) => {
          if (response.IsValid) {
            this.isValidACCAM = true;            
            //this.agencyId = response.AgencyID;            
            this.agencyIdMasked = response.Agt;
            this.agencyLongName = response.LongName;
          } else {
            this.validationMessages = response.ErrorMessages;
          }
        },
        (error) => { },
        () => this.loadingService.close())
    );
  }

}
