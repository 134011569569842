// Angular Core
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

// RxJS Subscriptions
import { Subscription } from 'rxjs';

// Models
import { GDSInterfaceSettings } from 'src/app/models/interfaces';
import { InterfaceSettings, Agency } from 'src/app/models/interfaces';
import { ErrorMessage } from '@allianz/agent-max-core-lib';

// Services
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { GdsService } from 'src/app/api/services/gds.service';
import { AlertType } from 'src/app/models/enums';

@Component({
  selector: 'app-interface-settings-details',
  templateUrl: './interface-settings-details.component.html',
  styleUrls: ['./interface-settings-details.component.scss']
})
export class InterfaceSettingsDetailsComponent implements OnInit, OnDestroy {
  agency: Agency;
  agencyId: number;
  validationMessages: ErrorMessage[] = [];
  interfaceSettingsForm: FormGroup;
  selectedInterface: GDSInterfaceSettings;
  isEditingGDSSettings: boolean = false;
  isMobileView: boolean = false;
  adminSubscriptions: Subscription[] = [];
  interfaceSettings: InterfaceSettings;
  isGroup: boolean;
  groupId: number;
  interfaceManagementName: string = '';
  isLoaded: boolean = false;
  formUpdated: boolean = false;
  alertType: typeof AlertType = AlertType;
  constructor(
    private appStateService: AppStateService,
    private adminuserService: AdminUserService,
    private gdsService: GdsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.interfaceSettingsForm = new FormGroup({});
  }

  ngOnInit(): void {
    const agencyId = this.activatedRoute.snapshot.params.agencyId;
    this.isGroup = this.activatedRoute.snapshot.data.isGroup;
    if (!agencyId) {
      this.router.navigate(['admin']);
    }
    this.agencyId = parseInt(agencyId, 10);
    this.getAgencyFromAgencyId(this.agencyId);
    this.getAgencyInterfaces(this.agencyId);
    if (this.isGroup) {
      const groupId = this.activatedRoute.snapshot.params.groupId;
      this.groupId = parseInt(groupId, 10);
      this.interfaceManagementName = `Group ${this.groupId}`;
    }
  }

  /**
   * Navigates back to admin interface tab
   * @returns {void}
   */
  backButton(): void {
    const extras: NavigationExtras = {
      queryParams: {
        tab: 'interface'
      }
    };
    this.router.navigate([`/admin/`], extras);
  }

  /**
   * Grabs agency settings from admin user service and sets the response for the html to read
   * @param {number} agencyId agency id of admin user
   * @returns {void}
   */
  getAgencyInterfaces(agencyId: number): void {
    this.adminSubscriptions.push(
      this.adminuserService.getGDSInterfaceSettings(agencyId, this.appStateService.getCredentials())
        .subscribe((response) => {
          if (response.ErrorMessages.length === 0) {
            this.interfaceSettings = response;
            this.isLoaded = true;
          } else {
            this.router.navigate(['/admin']);
          }
        })
    );
  }

  /**
   * Route to settings form
   * @param {GDSInterfaceSettings} interfaceSettings
   * @returns {void}
   */
  routeToSettingsForm(interfaceSettings: GDSInterfaceSettings): void {
    if (interfaceSettings.IsEnabled) {
      this.appStateService.setCurrentInterfaceSettings(interfaceSettings);
      if (this.isGroup) {
        this.router.navigate(['admin/interfacesettingsform/', this.agencyId, this.groupId]);
      } else {
        this.router.navigate(['admin/interfacesettingsform/', this.agencyId]);
      }
    }
  }

  /**
   * Gets the list of agencies
   * @param {number} agencyId - agency id of admin user
   * @returns {void}
   */
  getAgencyFromAgencyId(agencyId: number): void {
    this.adminSubscriptions.push(
      this.adminuserService.getAgenciesForAdministration()
        .subscribe((result) => {
          const agency = result.Agencies.find( (a) => a.AgencyID === agencyId);
          if (!agency) {
            this.router.navigate(['/admin']);
          } else {
            this.agency = agency;
            if (!this.isGroup) {
              this.interfaceManagementName = `${this.agency.LongName} (${this.agency.ACCAM})`;
            }
          }
        })
    );
  }

  /**
   * toggles the GDS interface and saves to database.  Groups are handled on the back end through the same endpoint
   * so there is no need to use multiple calls depending on the route here.
   * @param {number} interfaceIndex - which interface index is beign updated (sabre, apollo, etc.)
   * @returns {void}
   */
  toggleInterface(interfaceIndex: number): void {
    if (this.formUpdated) {
      this.onClosedAlert();
    }
    this.interfaceSettings.GDSInterfaceSettings[interfaceIndex].IsEnabled = !this.interfaceSettings.GDSInterfaceSettings[interfaceIndex].IsEnabled;
    this.adminuserService.saveGDSInterfaceSettings(this.interfaceSettings, this.appStateService.getCredentials()).subscribe((res) => {
      this.formUpdated = true;
      if (res.ErrorMessages && res.ErrorMessages.length > 0) {
        this.interfaceSettings.GDSInterfaceSettings[interfaceIndex].IsEnabled = !this.interfaceSettings.GDSInterfaceSettings[interfaceIndex].IsEnabled;
        this.validationMessages = res.ErrorMessages;
      } else {
        this.interfaceSettings = res;
        this.updateAppletSettings();
      }
    }, (err) => {
      this.interfaceSettings.GDSInterfaceSettings[interfaceIndex].IsEnabled = !this.interfaceSettings.GDSInterfaceSettings[interfaceIndex].IsEnabled;
    });
  }

  public onClosedAlert(): void {
    this.formUpdated = false;
    this.validationMessages = [];
  }
  public trackByDataSource(index: number, gdsInterfaceSettings: GDSInterfaceSettings): string {
    return gdsInterfaceSettings.DataSource;
  }
  ngOnDestroy(): void {
    this.adminSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Updates applet settings to reflect the form changes
   * @returns {void}
   */
  private updateAppletSettings(): void {
    if (this.appStateService.getGUID()) {
      this.interfaceSettings.GDSInterfaceSettings.forEach( (element) => {
        if(element.DataSource.startsWith('Apollo') && element.IsEnabled === true) {
          this.appStateService.setApolloSettings(true);
        }
        else{
          this.appStateService.setApolloSettings(false);
        }
    });
      this.gdsService.registerGDSLogin(this.appStateService.getCredentials(), this.appStateService.getGUID())
        .subscribe((response) => {
          if (response.ErrorMessages.length > 0) {
            this.validationMessages = response.ErrorMessages;
          }
        });
    }
  }
}
