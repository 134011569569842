// Angular Core
import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Models
import { Booking, BookingTypes, IncludedBenefitEnum, ProductBenefitInfo, ProgramProduct, ProductInformation } from '@allianz/agent-max-core-lib';
import { PrintOrEmailTypes, PrintOrEmailDocumentType } from '../models/enums';
import { PrintOrEmailOptions } from '../models/interfaces';

// Services
import { QuoteService } from '../api/services/quote.service';
import { AppStateService } from '../shared/services/app-state.service';
import { LoadingService } from '../api/services/loading.service';

// Components
import { ModalComponent } from '../shared/components/modal/modal.component';

// Third Party
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-compare',
  templateUrl: './product-compare.component.html',
  styleUrls: ['./product-compare.component.scss']
})
export class ProductCompareComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('productCompCarousel', { static: true })
  productCompCarousel: NguCarousel<{ text: string }>;
  public carouselConfig: NguCarouselConfig;
  bsModalRef: BsModalRef;

  quote: Booking;
  hideTable: boolean = false;
  globalBenefitList: ProductBenefitInfo[];
  programProducts: ProgramProduct[];
  includedBenefitEnum: typeof IncludedBenefitEnum = IncludedBenefitEnum;
  carouselProducts: ProductInformation[];
  productCompareSubscriptions: Subscription[] = [];

  constructor(
    private appStateService: AppStateService,
    private quoteService: QuoteService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private modalService: BsModalService,
    private loadingService: LoadingService,
    private route: ActivatedRoute
  ) {
    // Setting up the Carousel
    this.carouselConfig = {
      grid: { xs: 2, sm: 3, md: 4, lg: 5, all: 0 },
      slide: 2,
      speed: 400,
      interval: {
        timing: 4000,
        initialDelay: 0
      },
      point: {
        visible: true
      },
      load: 2,
      touch: true,
      loop: false
    };
  }

  /**
   * Have to detect changes to prevent onChange errors
   * @returns {void}
   */
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.quote = this.appStateService.getCurrentBookingQuote();
    this.globalBenefitList = this.route.snapshot.data.agencyProducts.GlobalBenefitList;
    this.programProducts = this.route.snapshot.data.agencyProducts.ProgramProducts;
    this.initBenefitArray();
  }

  ngOnDestroy(): void {
    this.productCompareSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Initializes the benefit array for the carousel
   * @returns {void}
   */
  initBenefitArray(): void {
    this.carouselProducts = this.programProducts.reduce((accumulator, curr) => {
      const product = curr.Product;
      const prodBenefitArr = [];

      for (const benefit of this.globalBenefitList) {
        const matchedBenefit = product.ProductBenefitList.find(
          (el) => el.BenefitText === benefit.BenefitText
        );
        // If the benefit is defined, it is either 1. a value or 2. 'included'
        // Otherwise, we will set the value to 'Not included'
        if (typeof matchedBenefit !== 'undefined') {
          prodBenefitArr.push({
            BenefitValue: matchedBenefit.BenefitValue,
            BenefitCode: matchedBenefit.BenefitCode
          });
          // Add Not Included to benefits that aren't available to the current product.
        } else {
          prodBenefitArr.push({
            BenefitValue: this.includedBenefitEnum.notIncluded,
            BenefitCode: benefit.BenefitCode
          });
        }
      }

      curr.Product['ProductBenefits'] = prodBenefitArr;

      accumulator = accumulator.concat(curr);
      return accumulator;
    }, []);
  }

  /**
   * Executes functionality when a product is selected and handles errors if necessary
   * @param {ProgramProduct} programProduct
   * @returns {void}
   */
  onProductCompareSelect(programProduct: ProgramProduct): void {
    this.quote.ProductInformation.ProgramProducts = [];
    this.quote.ProductInformation.ProgramProducts.push(programProduct);
    this.appStateService.setCurrentBookingId(null);
    this.loadingService.open();
    this.productCompareSubscriptions.push(
      this.quoteService.getQuoteForSelectedProducts(this.quote, this.appStateService.getCredentials())
        .subscribe((response) => {
          if (response.IsValid) {
            this.appStateService.setCurrentBookingQuote(response);
            this.appStateService.setBookingInfoViewType(BookingTypes.Quote);
            this.appStateService.setBookingInfoSourceType(BookingTypes.NewBooking);
            this.router.navigate(['bookinginfo']);
          } else {
            programProduct.ValidationMessages = response.ErrorMessages;
            let msgText = '';
            programProduct.ValidationMessages.forEach((msg) => msgText += `${msg.Description}, `);
            // remove whitespace
            msgText = msgText.trim();
            // removes last comma if one
            msgText = msgText.endsWith(',') ? msgText.slice(0, -1) : msgText;
            this.bsModalRef = this.modalService.show(ModalComponent);
            this.bsModalRef.content.text = msgText;
          }
        },
        (error) => { },
        () => this.loadingService.close())
    );
  }

  /**
   * Return a list of the products offered by the quote done.
   * @returns {number[]}
   */
  getProductsOffered(): number[]{
    if(this.programProducts !== null)
      return this.programProducts.map(x => x.ProgramProductId);
    else
      return null;
  }

  /**
   * Logic to route to the email page to email the product compare quote
   * @returns {void}
   */
  emailProductCompare(): void {
    const options: PrintOrEmailOptions = {
      HeaderName: 'Email Product Comparison',
      DocumentType: PrintOrEmailDocumentType.CompareProductDocument,
      ReportType: null,
      BookingId: this.quote.BookingID,
      IsProdCompare: true,
      ShowViewButton: false,
      EmailInput: {
        ToName: '',
        ToEmailAddress: '',
        SecondaryEmailAddress: ''
      },
      ProductsOffered: this.getProductsOffered()
    };
    this.appStateService.setPrintOrEmailReportName(PrintOrEmailTypes.ProductComparison);
    this.appStateService.setPrintOrEmailData(this.quote, options);
    this.router.navigate(['printoremail']);
  }

  /**
   * Logic to route back to the product page
   * @returns {void}
   */
  onCloseProductCompare(): void {
    this.router.navigate(['product']);
  }

}
