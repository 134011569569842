// Angular Core
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Models
import { UserCredentials } from 'src/app/models/classes';
import {
  UserInformation,
  UserOptions,
  AgentProfile,
  Agencies,
  MyAgenciesResponse,
  AddressAndLogo,
  Security,
  AgenciesForAdministration,
  ForgotPasswordPayload,
  ValidateSecurityModel,
  CreateAccount,
  CreateWebAccountResponse,
  ResetPasswordByAdminResponse,
  InterfaceSettings,
  LogoutResponse
} from 'src/app/models/interfaces';
import { BookingAgentInformation, KeyValue, MaxMail2ndChanceSettings } from '@allianz/agent-max-core-lib';
import { environment } from 'src/environments/environment';

// Services
import { UtilityService } from 'src/app/shared/services/utility.service';

// Third party
import { Observable } from 'rxjs';
import { refCount } from 'rxjs/internal/operators';
import { publishLast } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userServiceEndpoint: string;

  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService
  ) {
    this.userServiceEndpoint = this.utilityService.setTheDomainForAPICalls(environment.UserService);
  }

  /**
   * Login
   * @param {UserCredentials} userCredentials - User Credentials object
   * @return {Observable<UserInformation>} - User information from server
   */
  public login(userCredentials: UserCredentials): Observable<UserInformation> {
    return this.httpClient.put<UserInformation>(`${this.userServiceEndpoint}/Login`, userCredentials).pipe(publishLast(), refCount());
  }

  /**
   * creates a web account and sends an email to the supplied email address
   * @param {string} emailAddress -
   * @param {number} agencyId -
   * @return {Observable<CreateAccount>} -
   */
  public createWebAccount(emailAddress: string, agencyId: string): Observable<CreateAccount> {
    return this.httpClient.get<CreateAccount>(`${this.userServiceEndpoint}/CreateWebAccount/${emailAddress}/${agencyId}`);
  }

  /**
   * returns whether the supplied accam is valid,
   * along with information about said accam
   * @param {string} accam
   * @return {Observable<CreateAccount>}
   */
  public verifyAgencyByACCAM(accam: string): Observable<Agencies> {
    return this.httpClient.get<Agencies>(`${this.userServiceEndpoint}/VerifyAgencyByACCAM/${accam}`);
  }

  /**
   * Get Agent Information by user
   * @param {UserInformation} userInfo - The user information object
   * @returns {<Observable{BookingAgentInformation}>}
   */
  public getAgentInformationByUser(userInfo: UserInformation): Observable<BookingAgentInformation> {
    return this.httpClient.get<BookingAgentInformation>(`${this.userServiceEndpoint}/GetAgentInformation`, { headers: this.utilityService.getServiceHeaders(userInfo) }).pipe(publishLast(), refCount());
  }

  /**
   * Get whether the currently logged in user has the ability to manage groups
   * @param {UserInformation} userInfo - The user information object
   * @returns {<Observable{boolean}>}
   */
  public getAllowManageGroupStatusByUser(userInfo: UserInformation): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.userServiceEndpoint}/getAllowManageGroupStatus`, { headers: this.utilityService.getServiceHeaders(userInfo) }).pipe(publishLast(), refCount());
  }

  /**
   * Get Agent Information by user
   * @param {UserInformation} userInfo - The user information object
   * @returns {<Observable{UserOptions}>}
   */
  public getUserOptions(userInfo: UserInformation): Observable<UserOptions> {
    return this.httpClient.get<UserOptions>(`${this.userServiceEndpoint}/GetUserOptions`, { headers: this.utilityService.getServiceHeaders(userInfo) }).pipe(publishLast(), refCount());
  }

  /**
   * Saves the agent profile and preferences information
   * @param {AgentProfile} agentProfile
   * @param {UserInformation} userCredentials
   * @returns {Observable<AgentProfile>}
   */
  public saveAgentProfileAndPreferences(agentProfile: AgentProfile, userCredentials: UserInformation): Observable<AgentProfile> {
    return this.httpClient.put<AgentProfile>(`${this.userServiceEndpoint}/SaveAgentProfile`, agentProfile, { headers: this.utilityService.getServiceHeaders(userCredentials) }).pipe(publishLast(), refCount());
  }

  /**
   * Get one agency based on the accam inputted by the user
   * @param {string} accam
   * @param {UserInformation} userInfo
   * @returns {Observable<Agency>}
   */
  public getAgencyAccountForAdd(accam: string, userInfo: UserInformation): Observable<Agencies> {
    return this.httpClient.get<Agencies>(`${this.userServiceEndpoint}/GetAgencyAccountForAdd/${accam}`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

   /**
    * gets the information needed for the forgot password page
    * @param {string} tokenID
    * @returns {Observable<ValidateSecurityModel>}
    */
  public getForgotPasswordInfo(tokenID: string): Observable<ValidateSecurityModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'emailToken': `Basic${tokenID}`
    });
    return this.httpClient.get<ValidateSecurityModel>(`${this.userServiceEndpoint}/ValidateEmail`, { headers });
  }

  /**
   * checks to see if the provided security answer is correct
   * @param {string} tokenID
   * @returns {Observable<any>}
   */
  public validateSecurity(tokenID: string, agentProfile: AgentProfile): Observable<ValidateSecurityModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'emailToken': `Basic${tokenID}`,
    });
    return this.httpClient.put<ValidateSecurityModel>(`${this.userServiceEndpoint}/ValidateSecurity`, agentProfile, { headers });
  }

  /**
   * Gets profile business types as key value pairs
   * @param {UserInformation} userCredentials
   * @returns {Observable<KeyValue>}
   */
  public getBusinessTypes(userCredentials: UserInformation): Observable<KeyValue> {
    return this.httpClient.get<KeyValue>(`${this.userServiceEndpoint}/GetBusinessTypes`, { headers: this.utilityService.getServiceHeaders(userCredentials) }).pipe(publishLast(), refCount());
  }

  /**
   * Save myAgencies array
   * @param {Array<Agency>} myAgencies
   * @param {UserInformation} userInfo
   * @returns {Observable<MyAgenciesResponse>}
   */
  public saveAgencyAccounts(myAgencies: Agencies[], userInfo: UserInformation): Observable<MyAgenciesResponse> {
    return this.httpClient.put<MyAgenciesResponse>(`${this.userServiceEndpoint}/SaveAgencyAccounts`, myAgencies, { headers: this.utilityService.getServiceHeaders(userInfo) }).pipe(publishLast(), refCount());
  }

  /**
   * Gets profile work locations as key value pairs
   * @param {UserInformation} userCredentials
   * @returns {Observable<KeyValue>}
   */
  public getWorkLocations(userCredentials: UserInformation): Observable<KeyValue> {
    return this.httpClient.get<KeyValue>(`${this.userServiceEndpoint}/GetWorkLocations`, { headers: this.utilityService.getServiceHeaders(userCredentials) });
  }

  /**
   * Gets profile roles available as key value pairs
   * @param {UserInformation} userCredentials
   * @returns {Observable<KeyValue>}
   */
  public getProfileRoles(userCredentials: UserInformation): Observable<KeyValue> {
    return this.httpClient.get<KeyValue>(`${this.userServiceEndpoint}/GetRoles`, { headers: this.utilityService.getServiceHeaders(userCredentials) });
  }

  /**
   * Get second chance products for maxmail
   * @param {UserInformation} userInfo
   * @returns {Observable<KeyValue>}
   */
  public getProgramProductsForMaxMail(userInfo: UserInformation): Observable<KeyValue[]> {
    return this.httpClient.get<KeyValue[]>(`${this.userServiceEndpoint}/GetProgramProductsForMaxMail`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * Saves the maxmail second chance settings in the user options
   * @param {MaxMail2ndChanceSettings} maxMailSecondChanceSettings
   * @param {UserInformation} userInfo
   * @returns {Observable<MaxMail2ndChanceSettings>}
   */
  public saveMaxMailSettings(maxMailSecondChanceSettings: MaxMail2ndChanceSettings, userInfo: UserInformation): Observable<MaxMail2ndChanceSettings> {
    return this.httpClient.put<MaxMail2ndChanceSettings>(`${this.userServiceEndpoint}/SaveMaxMailSettings`, maxMailSecondChanceSettings, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * Get agency Address
   * @param {UserInformation} userInfo
   * @returns {Observable<AddressAndLogo>}
   */
  public getAgencyAddress(userInfo: UserInformation): Observable<AddressAndLogo> {
    return this.httpClient.get<AddressAndLogo>(`${this.userServiceEndpoint}/GetAgencyAddress`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * Get agency logo
   * @param {UserInformation} userInfo
   * @returns {Observable<any>}
   */
  public getAgencyLogo(userInfo: UserInformation): Observable<AddressAndLogo> {
    return this.httpClient.get<AddressAndLogo>(`${this.userServiceEndpoint}/GetAgencyLogo`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * Saves the address and logo for the user options
   * @param {UserInformation} userInfo
   * @param {addressAndLogo} addressAndLogo
   * @returns {Observable<AddressAndLogo>}
   */
  public saveAddressAndLogo(addressAndLogo: AddressAndLogo, userInfo: UserInformation): Observable<AddressAndLogo> {
    return this.httpClient.put<AddressAndLogo>(`${this.userServiceEndpoint}/SaveAddressAndLogo`, addressAndLogo, { headers: this.utilityService.getServiceHeaders(userInfo) }).pipe(publishLast(), refCount());
  }

  /**
   * Gets the reminder questions options for the security tab for change password
   * @param {UserInformation} userInfo
   * @returns {Observable<KeyValue[]>}
   */
  public getReminderQuestions(userInfo: UserInformation): Observable<KeyValue[]> {
    return this.httpClient.get<KeyValue[]>(`${this.userServiceEndpoint}/GetReminderQuestions`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * Updates the Security tab on the user options page
   * @param {Security} security
   * @param {UserInformation} userInfo
   * @returns {Observable<Security>}
   */
  public saveSecurity(security: Security, userInfo: UserInformation): Observable<Security> {
    return this.httpClient.put<Security>(`${this.userServiceEndpoint}/SaveSecurity`, security, { headers: this.utilityService.getServiceHeaders(userInfo) }).pipe(publishLast(), refCount());
  }

  /**
   * Gets the list of agencies for the switch agency dropdown by user
   * @param {UserInformation} userInfo
   * @returns {Observable<AgenciesForAdministration>}
   */
  public getAgenciesToSwitch(userInfo: UserInformation): Observable<AgenciesForAdministration> {
    return this.httpClient.get<AgenciesForAdministration>(`${this.userServiceEndpoint}/GetAgenciesToSwitch`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }
  /**
   * Gets the list of branches for the switch branch dropdown by user
   * @param {UserInformation} userInfo
   * @param {number} agencyId
   * @returns {Observable<AgenciesForAdministration>}
   */
  public getBranchesToSwitch(agencyId: number, userInfo: UserInformation): Observable<AgenciesForAdministration> {
    return this.httpClient.get<AgenciesForAdministration>(`${this.userServiceEndpoint}/GetBranchesToSwitch/${agencyId}`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }
  /**
   * switches the suppleid users current agency
   * @param {UserInformation} userInfo
   * @param {number} agencyId
   * @returns {Observable<UserInformation>}
   */
  public switchAgency(agencyId: number, userInfo: UserInformation): Observable<UserInformation> {
    return this.httpClient.get<UserInformation>(`${this.userServiceEndpoint}/SwitchAgency/${agencyId}`, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }

  /**
   * sends a forgot password email to the supplied email address
   * @param {string} emailAddress
   * @returns {Observable<ForgotPasswordPayload>}
   */
  public forgotPassword(emailAddress: string): Observable<ForgotPasswordPayload> {
    return this.httpClient.get<ForgotPasswordPayload>(`${this.userServiceEndpoint}/ForgotPassword/${emailAddress}`);
  }

  /**
   * switches the suppleid users current agency
   * @param {string} tokenID
   * @returns {Observable<KeyValue[]>}
   */
  public getRoles(tokenID: string): Observable<KeyValue[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'emailToken': `Basic${tokenID}`
    });
    return this.httpClient.get<KeyValue[]>(`${this.userServiceEndpoint}/GetRoles`, { headers });
  }

  /**
   * Gets profile business types as key value pairs
   * @param {string} tokenID
   * @returns {Observable<KeyValue>}
   */
  public getBusinessTypesByToken(tokenID: string): Observable<KeyValue[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'emailToken': `Basic${tokenID}`
    });
    return this.httpClient.get<KeyValue[]>(`${this.userServiceEndpoint}/GetBusinessTypes`, { headers });
  }

  /**
   * Gets the reminder questions options for the user profile
   * @param {string} tokenID
   * @returns {Observable<KeyValue[]>}
   */
  public getReminderQuestionsByToken(tokenID: string): Observable<KeyValue[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'emailToken': `Basic${tokenID}`
    });
    return this.httpClient.get<KeyValue[]>(`${this.userServiceEndpoint}/GetReminderQuestions`, { headers });
  }

  /**
   * Gets profile work locations as key value pairs
   * @param {string} tokenID
   * @returns {Observable<KeyValue>}
   */
  public getWorkLocationsByToken(tokenID: string): Observable<KeyValue[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'emailToken': `Basic${tokenID}`
    });
    return this.httpClient.get<KeyValue[]>(`${this.userServiceEndpoint}/GetWorkLocations`, { headers });
  }

  /**
   * Updates the users profile using a token from the url
   * @param {string} tokenID
   * @param {AgentProfile} agentProfile
   * @returns {Observable<AgentProfile>}
   */
  public savePendingAgentProfile(tokenID: string, agentProfile: AgentProfile): Observable<AgentProfile> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'emailToken': `Basic${tokenID}`
    });
    return this.httpClient.put<AgentProfile>(`${this.userServiceEndpoint}/SavePendingAgentProfile`, agentProfile, { headers });
  }

  /**
   * Activate web account by admin (client activated)
   * @param {string} emailAddress
   * @param {string} agentAccountId
   * @param {string} agencyId
   * @returns {Observable<CreateWebAccountResolver>}
   */
  public activateWebAccountByAdmin(emailAddress: string, agentAccountId: string, agencyId: string): Observable<CreateWebAccountResponse> {
    return this.httpClient.get<CreateWebAccountResponse>(`${this.userServiceEndpoint}/ActivateWebAccountByAdmin/${emailAddress}/${agentAccountId}/${agencyId}`);
  }

  /**
   * Reset password by admin for particular user (client activated)
   * @param {string} emailAddress
   * @param {string} agentAccountId
   * @returns {Observable<ResetPasswordByAdminResponse}
   */
  public resetPasswordByAdmin(emailAddress: string, agentAccountId: string): Observable<ResetPasswordByAdminResponse> {
    return this.httpClient.get<ResetPasswordByAdminResponse>(`${this.userServiceEndpoint}/ResetPasswordByAdmin/${emailAddress}/${agentAccountId}`);
  }

  /**
   * Saves information for the interface settings tab
   * @param {UserInformation} userInfo - User Credentials object
   * @return {Observable<InterfaceSettings>} - Interface Settings Model
   */
  public saveInterfaceSettings(interfaceSettings: InterfaceSettings, userInfo: UserInformation): Observable<InterfaceSettings> {
    return this.httpClient.put<InterfaceSettings>(`${this.userServiceEndpoint}/SaveInterfaceSettings`, interfaceSettings, { headers: this.utilityService.getServiceHeaders(userInfo) }).pipe(publishLast(), refCount());
  }

  /**
   * Logs out of the server and revokes the auth token
   * @param {UserInformation} userInfo - User Credentials object
   * @return {LogoutResponse}
   */
  public logout(userInfo: UserInformation): Observable<LogoutResponse> {
    return this.httpClient.put<LogoutResponse>(`${this.userServiceEndpoint}/Logout`, null, { headers: this.utilityService.getServiceHeaders(userInfo) });
  }
}
