"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_field_class_1 = require("./input-field.class");
var QuoteProductInformation = /** @class */ (function () {
    function QuoteProductInformation() {
        this.IsProductValid = true;
        this.ValidationMessages = [];
        this.BusinessUnit = new input_field_class_1.InputField();
        this.Path = new input_field_class_1.InputField();
        this.Language = new input_field_class_1.InputField();
        this.ProgramProducts = new Array();
    }
    return QuoteProductInformation;
}());
exports.QuoteProductInformation = QuoteProductInformation;
