// Angular Core
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
// Models
import { AgentAdminSettings, AgentAdminSettingsResponse, AdminDefaultAgentProfile, AgencyGroupsForAgency, AgencyGroup } from 'src/app/models/interfaces';
// Core library
import { ErrorMessage, KeyValue } from '@allianz/agent-max-core-lib';
// RxJS Imports
import { Subscription } from 'rxjs';
// Services
import { AdminUserService } from 'src/app/api/services/adminUser.service';

@Component({
  selector: 'app-agent-default-settings',
  templateUrl: './agent-default-settings.component.html',
  styleUrls: ['./agent-default-settings.component.scss']
})
export class AgentDefaultSettingsComponent implements OnInit, OnDestroy {
  validationMessages: ErrorMessage[] = [];
  defaultAdminAgentSettingsFormGroup: FormGroup;
  headerText: string;
  agency: AgencyGroup;
  agencies: AgencyGroupsForAgency;
  agentAdminDefaultSettingsReponse: AgentAdminSettings;
  defaultAgentProfileGroupBag: AdminDefaultAgentProfile;
  agencyId: number;
  groupId: number;
  subscriptionList: Subscription[] = [];
  addressOptions: KeyValue[];
  administrativeRoles: KeyValue[];
  maxMailOptionsType: KeyValue[];
  reportingRights: KeyValue[];
  sellingRoles: KeyValue[];
  interfaceOptionsType: KeyValue[];
  updateSuccessful: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private adminUserService: AdminUserService,
    private activatedRoute: ActivatedRoute
  ) {

  }

  /**
   * Initializes the front end with Agent default settings. Sets the header text based on if
   * an agency is apart of a group for agent default settings.
   * @returns {void}
   */
  ngOnInit(): void {
    this.addressOptions = this.activatedRoute.snapshot.data.addressOptions;
    this.administrativeRoles = this.activatedRoute.snapshot.data.administrativeRoles;
    this.maxMailOptionsType = this.activatedRoute.snapshot.data.maxMailOptions;
    this.reportingRights = this.activatedRoute.snapshot.data.reportingRights;
    this.sellingRoles = this.activatedRoute.snapshot.data.sellingRoles;
    this.interfaceOptionsType = this.activatedRoute.snapshot.data.interfaceOptions;
    this.constructAdminDefaultAgentSettings();
    this.adminUserService.getAgencyGroupsForAgency()
      .subscribe((response) => {
        this.agencies = response;
        this.initializeForm();
      });
  }

  /**
   * Updates the agent default settings for either the group or agency.
   * If the agency is apart of a group the back end service will update the settings
   * for other agencies within the same group. Otherwise if the agency isn't in a group
   * only the default agent settings for that agency are updated.
   * @returns {void}
   */
  public submitAgentDefaultSettingsUpdates(): void {
    const defaultAgentAdminSettings: AgentAdminSettings = this.createDefaultAgentAdminSettingsRequest(this.defaultAdminAgentSettingsFormGroup);
    const defaultAgentProfileGroupBag: AdminDefaultAgentProfile = this.createDefaultAgentProfileGroupBag(this.defaultAdminAgentSettingsFormGroup);
    const request: AgentAdminSettingsResponse = {
      DefaultAgentAdminSettings: defaultAgentAdminSettings,
      IsValid: true,
      Message: '',
      TransactionNumber: null,
      TransactionStatus: null,
      DefaultAgentProfileGroupBag: defaultAgentProfileGroupBag
    };

    this.adminUserService.updateDefaultAgentAdminSettingsGridBag(request)
      .subscribe((response) => {
        this.validationMessages = response.ErrorMessages;
        this.updateSuccessful = this.validationMessages.length === 0 ? true : false;
        this.updateDefaultAdminAgentSettingsForm();
        this.defaultAdminAgentSettingsFormGroup.markAsPristine();
      });

  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
  }
  /**
   * determines whether the submit button should be enabled or not
   * @returns {boolean}
   */
  disableSubmit(): boolean {
    return this.defaultAdminAgentSettingsFormGroup.pristine || this.defaultAdminAgentSettingsFormGroup.invalid;
  }
  /**
   * Creates a new default agent admin settings request from the form
   * @returns {AgentAdminSettings}
   */
  private createDefaultAgentAdminSettingsRequest(form: FormGroup): AgentAdminSettings {
    this.agentAdminDefaultSettingsReponse.LoginSwitchToAccam = form.value.sellingACCAM;
    this.agentAdminDefaultSettingsReponse.AgentSellingRole = form.value.agentSellingRole;
    this.agentAdminDefaultSettingsReponse.AgentReportingRight = form.value.agentReportingRights;
    this.agentAdminDefaultSettingsReponse.AgentAdministrativeRole = form.value.agentAdministrativeRole;
    this.agentAdminDefaultSettingsReponse.InterfaceOptionsSource = form.value.interfaceOptions;
    this.agentAdminDefaultSettingsReponse.AddressOptionsSource = form.value.addressOptions;
    this.agentAdminDefaultSettingsReponse.MaxMailOptionsSource = form.value.maxMailOptions;
    this.agentAdminDefaultSettingsReponse.AgentCanManageSecondChance = form.value.allowSecondChance;
    this.agentAdminDefaultSettingsReponse.AgentCanManageGroup = form.value.allowGroupTravel;
    this.agentAdminDefaultSettingsReponse.IsWebAccountAccessFull = form.value.fullWebAccess;
    this.agentAdminDefaultSettingsReponse.AgentAccountSpecialRestriction = this.updateUserLockedOut(form.value.userLockedOut);
    return this.agentAdminDefaultSettingsReponse;
  }

  /**
   * Creates a new AdminDefaultAgentProfile request from the form
   * @returns {AdminDefaultAgentProfile}
   */
  private createDefaultAgentProfileGroupBag(form: FormGroup): AdminDefaultAgentProfile {
    this.defaultAgentProfileGroupBag.AllowNotifications = form.value.allowNotifications;
    this.defaultAgentProfileGroupBag.IsConfirmationEmailedToAgent = form.value.isConfirmationEmailedToAgent;
    return this.defaultAgentProfileGroupBag;
  }

  /**
   * update default Admin Agent Settings Form
   */
  private updateDefaultAdminAgentSettingsForm(): void {
    this.defaultAdminAgentSettingsFormGroup.patchValue({
      sellingACCAM: this.agentAdminDefaultSettingsReponse.LoginSwitchToAccam,
      agentSellingRole: this.agentAdminDefaultSettingsReponse.AgentSellingRole,
      agentReportingRights: this.agentAdminDefaultSettingsReponse.AgentReportingRight,
      agentAdministrativeRole: this.agentAdminDefaultSettingsReponse.AgentAdministrativeRole,
      interfaceOptions: this.agentAdminDefaultSettingsReponse.InterfaceOptionsSource,
      addressOptions: this.agentAdminDefaultSettingsReponse.AddressOptionsSource,
      maxMailOptions: this.agentAdminDefaultSettingsReponse.MaxMailOptionsSource,
      allowSecondChance: this.agentAdminDefaultSettingsReponse.AgentCanManageSecondChance,
      allowGroupTravel: this.agentAdminDefaultSettingsReponse.AgentCanManageGroup,
      fullWebAccess: this.agentAdminDefaultSettingsReponse.IsWebAccountAccessFull,
      userLockedOut: this.getUserLockedOut(this.agentAdminDefaultSettingsReponse.AgentAccountSpecialRestriction),
      isConfirmationEmailedToAgent: this.defaultAgentProfileGroupBag.IsConfirmationEmailedToAgent,
      allowNotifications: this.defaultAgentProfileGroupBag.AllowNotifications
    });
  }
  /**
   * build Admin Default Agent Settings
   */
  private constructAdminDefaultAgentSettings(): void {
    this.defaultAdminAgentSettingsFormGroup = this.formBuilder.group({
      sellingACCAM: new FormControl(),
      agentSellingRole: new FormControl(),
      agentReportingRights: new FormControl(),
      agentAdministrativeRole: new FormControl(),
      interfaceOptions: new FormControl(),
      addressOptions: new FormControl(),
      maxMailOptions: new FormControl(),
      allowSecondChance: new FormControl(),
      allowGroupTravel: new FormControl(),
      fullWebAccess: new FormControl(false),
      userLockedOut: new FormControl(false),
      allowNotifications: new FormControl(true),
      isConfirmationEmailedToAgent: new FormControl()
    });
  }
  /**
   * get all data needed to initialize form
   */
  private initializeForm(): void {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.agencyId = parseInt(paramMap.get('agencyId'), 10);
      this.groupId = parseInt(paramMap.get('groupId'), 10);
      this.agency = this.agencies.AgencyGroupList.find((agency) => agency.AgencyId === this.agencyId);
      if (isNaN(this.groupId)) {
        this.groupId = 0;
      }
      if (this.groupId !== 0) {
        this.headerText = `Group ${this.groupId}`;
      } else {
        this.headerText = `${this.agency.LongBranchName} (${this.agency.Accam})`;
      }
    });

    this.adminUserService.getDefaultAgentAdminSettingsGridBag(this.agencyId)
      .subscribe((res) => {
        this.agentAdminDefaultSettingsReponse = res.DefaultAgentAdminSettings;
        this.defaultAgentProfileGroupBag = res.DefaultAgentProfileGroupBag;
        this.updateDefaultAdminAgentSettingsForm();
      });
  }
  /**
   * return 2 if user lockedout 0 otherwise
   * @param value
   * @returns {number}
   */
  private updateUserLockedOut(value: any): number {
    return value ? 2 : 0;
  }

  /**
   *  return false if lockedout equal to 0, true otherwise
   * @param value
   */
  private getUserLockedOut(value: number): boolean {
    return !!value;
  }
}
