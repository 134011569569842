// Angular Core
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';

// Services
import { AppStateService } from '../../services/app-state.service';
import { UtilityService } from '../../services/utility.service';
import { NotificationService } from 'src/app/api/services/notification.service';
import { UserService } from 'src/app/api/services/user.service';

// Models
import { environment } from 'src/environments/environment';
import { PayGroupPoliciesBannerData } from 'src/app/models/classes';
import { PrintOrEmailOptions } from 'src/app/models/interfaces';
import { PrintOrEmailDocumentType, PrintOrEmailReportType, PrintOrEmailTypes, AlertType, MainNavTabs, AdminNavTabs } from 'src/app/models/enums';
import { Booking } from '@allianz/agent-max-core-lib';

// Third Party
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  isProduction: boolean = environment.Production;
  agencyHelpPhoneNumberParsed: string = environment.DefaultHelpPhoneNumber;
  isMobile: boolean;
  isDesktop: boolean;
  mobileMenuOpen: boolean = false;
  isAdmin: boolean = true;
  hasReportingRight: boolean = true;
  isWaiver: boolean;
  fullWebAccess: boolean;
  displayBanner: boolean = true;
  allowNotifications: boolean;
  notificationCount: number;
  headerSubscriptions: Subscription[] = [];
  numberOfGroupPoliciesSubmitted: number;
  totalPurchasePrice: number;
  groupPolicyName: string;
  groupPurchaseBannerType: AlertType;
  payGroupPoliciesBannerData: PayGroupPoliciesBannerData;
  isAdminFormDirty: boolean = false;
  isActive: MainNavTabs = null;
  activeAdminTab: AdminNavTabs = null;
  component: object = null;

  private readonly onDestroy: Subject<void> = new Subject<void>();

  constructor(
    private appStateService: AppStateService,
    private utilityService: UtilityService,
    private notificationService: NotificationService,
    private userService: UserService,
    private router: Router,
    private currencyPipe: CurrencyPipe
  ) {
    if (this.appStateService.getCredentials()) {
      this.notificationService.getNotificationCount().subscribe((response) => {
        this.notificationCount = response.Count || 0;
        this.appStateService.setAllowNotifications(response.AllowNotifications);
        this.appStateService.setNotificationCountStatus(this.notificationCount);
      });
    }

    this.utilityService.getTriggerAdminSaveAlert()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((trigger) => {
        if (trigger.component && trigger.component.constructor.name === 'AdminComponent') {
          this.component = trigger.component;
        }
        if (trigger.activeMainTab !== this.isActive) {
          this.isActive = trigger.activeMainTab;
        }
        if (trigger.activeAdminTab !== this.activeAdminTab) {
          this.activeAdminTab = trigger.activeAdminTab;
        }
        if (trigger.isAdminFormDirty) {
          this.isAdminFormDirty = true;
        } else {
          this.isAdminFormDirty = false;
        }
      });
  }

  ngOnInit(): void {
    this.headerSubscriptions.push(this.appStateService.isAdminObservable$.subscribe((val) => {
      this.isAdmin = val;
    }));
    this.headerSubscriptions.push(this.appStateService.fullAccessObservable$.subscribe((val) => {
      this.fullWebAccess = val;
    }));
    this.headerSubscriptions.push(this.appStateService.displayBannerObservable$.subscribe((val) => {
      this.displayBanner = val;
    }));
    this.headerSubscriptions.push(
      this.appStateService.notificationCountObservable$.subscribe((val) => {
        this.notificationCount = val || 0;
      })
    );
    this.hasReportingRight = this.appStateService.getReportingRight();
    this.utilityService.getSize().subscribe((isDesktop: boolean) => this.isDesktop = isDesktop);
    const printData = this.appStateService.getPrintOrEmailData();
    if (printData && printData.hasOwnProperty('options')) {
      if (printData.options.IsWaiver) {
        this.appStateService.triggerWaiverRouteStatus(printData.options.IsWaiver);
      }
    }
    this.appStateService.waiverState.subscribe((state) => this.isWaiver = state);
    this.appStateService.groupPurchaseBannerType.subscribe((state) => this.groupPurchaseBannerType = state);
    this.appStateService.getAllowNotifications().subscribe((s) => this.allowNotifications = s);
  }

  /**
   * Called when main nav tab is clicked
   * @param {any} event - Event object for tab click
   * @returns {void}
   */
  mainTabClick(event: any): void {
    if (this.isDesktop && this.isAdminFormDirty) {
      if (MainNavTabs[event.target.name]) {
        this.utilityService.setTriggerAdminSaveAlert(this, this.isAdminFormDirty, this.isActive, this.activeAdminTab, MainNavTabs[event.target.name], false);
      }
    } else {
      return;
    }
  }

  /**
   * Returns banner content based on groupPurchaseBannerType
   * @param {GroupPurchaseBannerType} groupPurchaseBannerType
   * @returns {string}
   */
  getGroupPurchaseBannerContent(groupPurchaseBannerType: AlertType): string {
    this.payGroupPoliciesBannerData = this.appStateService.getPayGroupPoliciesBannerData();
    let policy = 'Policy';
    let toBe = 'has';
    if (this.payGroupPoliciesBannerData) {
      if (this.payGroupPoliciesBannerData.NumberOfGroupPoliciesSubmitted > 1) {
        policy = 'Policies';
        toBe =  'have';
      }
      const alertPrimary = `${this.payGroupPoliciesBannerData.NumberOfGroupPoliciesSubmitted} ${policy} ${toBe} been processed successfully totaling ${this.currencyPipe.transform(this.payGroupPoliciesBannerData.TotalPurchasePrice, 'USD')}.`;
      const alertDanger = `${this.payGroupPoliciesBannerData.NumberOfGroupPoliciesSubmitted} ${policy} totaling ${this.currencyPipe.transform(this.payGroupPoliciesBannerData.TotalPurchasePrice, 'USD')} ${toBe} failed during processing; please retry your purchase attempt.`;
      return groupPurchaseBannerType === AlertType.Info ? alertPrimary : alertDanger;
    }
    return '';
  }

  /**
   * Get the current notification count
   * @returns {number | string}
   */
  getNotificationCount(): number | string {
    return this.notificationCount < 999 ? this.notificationCount : '999+';
  }

  /**
   * for routes that exist that dont show the main nav
   * @returns {boolean}
   */
  canShowNavigation(): boolean {
    return this.router.url !== '/become-a-partner';
  }

  /**
   * Checks weather the user is logged out or not
   * @returns {boolean}
   */
  get isLoggedOut(): boolean {
    return !this.appStateService.getCredentials();
  }

  /**
   * Logout and redirect to login page
   * @returns {void} - Void
   */
  logout(event: object): void {
    if (this.isActive === MainNavTabs.AdminComponent) {
      this.mainTabClick(event);
    } else {
      const userInfo = this.appStateService.getCredentials();
      this.userService.logout(userInfo).subscribe((response) => {
        if (response.Success) {
          this.appStateService.clearAppState();
          this.router.navigate(['login']);
        }
        else {
          console.error(response);
        }
      });
    }
  }

  /**
   * Only update the mobileMenuOpen variable if on mobile
   * @returns {void}
   */
  updateMobileMenu(): void {
    if (!this.isDesktop) {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    }
  }

  /**
   * Waiver print or email
   * @returns {void}
   */
  waiverPrintOrEmail(event: object): void {
    const agentInformation = this.appStateService.getAgentInformation();
    const booking = new Booking();
    booking.BookingID = 0;
    const options: PrintOrEmailOptions = {
      HeaderName: 'Print Or Email Waiver',
      DocumentType: PrintOrEmailDocumentType.AgencyDocument,
      ReportType: PrintOrEmailReportType.TravelInsuranceWaiver,
      BookingId: booking.BookingID,
      IsWaiver: this.isWaiver = true,
      ShowViewButton: true,
      EmailInput: {
        FromEmailAddress: agentInformation.EmailAddress,
        FromName: `${agentInformation.FirstName} ${agentInformation.LastName}`
      }
    };
    this.appStateService.setPrintOrEmailReportName(PrintOrEmailTypes.Waiver);
    this.appStateService.setPrintOrEmailData(booking, options);

    this.mainTabClick(event);
    this.router.navigate(['printoremail']);
  }

  onAlertClosed(): void {
    this.groupPurchaseBannerType = null;
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.headerSubscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
