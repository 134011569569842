// Angular Core
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgencySettings, Agency } from 'src/app/models/interfaces';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ErrorMessage } from '@allianz/agent-max-core-lib';

@Component({
  selector: 'app-agency-settings-general',
  templateUrl: './agency-settings-general.component.html',
  styleUrls: ['./agency-settings-general.component.scss']
})
export class AgencySettingsGeneralComponent implements OnInit {
  agency: Agency;
  agencySettings: AgencySettings;
  agencySettingsSaved: boolean;
  agencySettingsValidationMessages: ErrorMessage[] = [];
  adminAgencySettingsForm: FormGroup;
  isValidFormSubmitted: boolean = false;
  constructor(
    private appStateService: AppStateService,
    private adminuserService: AdminUserService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder) {
      this.constructForm();
     }

  ngOnInit(): void {
    this.agencySettings = this.activatedRoute.snapshot.data.AgencySettings;
    this.updateForm(this.agencySettings);
  }

  /**
   * gets the agency settings, then saves them
   * @returns {void}
   */
  saveAgencySettings(): void {
    this.agencySettings.EnableRecommendationEngine = this.adminAgencySettingsForm.value.enableRecommendationEngine,
    this.agencySettings.AllowCreateALink = this.adminAgencySettingsForm.value.allowCreateALink,
    this.agencySettings.EnableAgentTips = this.adminAgencySettingsForm.value.enableAgentTips,
    this.agencySettings.AllowSecondChanceOffers = this.adminAgencySettingsForm.value.allowSecondChanceOffers,
    this.agencySettings.AllowManageGroup = this.adminAgencySettingsForm.value.allowManageGroup,
    this.agencySettings.AllowCreditSalesIfCW = this.adminAgencySettingsForm.value.allowCreditSales,
    this.agencySettings.AllowCashSalesIfCW = this.adminAgencySettingsForm.value.allowCashSales,
    this.agencySettings.AreCommissionsSuppressed = this.adminAgencySettingsForm.value.suppressCommissionOnReport,
    this.agencySettings.AllowAgentsToChangeCodeOnWeb = this.adminAgencySettingsForm.value.allowAgentsToChangeCodeOnWeb,
    this.agencySettings.AllowBookingAgentCodeOverride = this.adminAgencySettingsForm.value.allowBookingAgentCodeOverride,
    this.agencySettings.AllowNotifications = this.adminAgencySettingsForm.value.allowNotifications,
    this.agencySettings.IsPrintWaiverCheckedByDefault = this.adminAgencySettingsForm.value.checkPrinterWaiver,
    this.adminAgencySettingsForm.value.agencyQueueMaxDays === null ? this.agencySettings.AgencyQueueMaxDays = 0 : this.agencySettings.AgencyQueueMaxDays = this.adminAgencySettingsForm.value.agencyQueueMaxDays,
    this.agencySettings.InsurePromptQuestionText = this.adminAgencySettingsForm.value.insuranceQuestionText,
    this.agencySettings.EnableAdminApproval = this.adminAgencySettingsForm.value.enableAdminApproval,
    this.agencySettings.AgencyID = this.activatedRoute.snapshot.params.agencyId,
    this.agencySettings.AllowAgentsToCreateWebAccounts = this.adminAgencySettingsForm.value.allowAgentsToCreateWebAccounts,
    this.adminuserService.saveAgencySettings(this.agencySettings, this.appStateService.getCredentials()).subscribe((response) => {
      if (!response.ErrorMessages.length) {
        this.isValidFormSubmitted = true;
        this.agencySettings = response;
        this.updateForm(this.agencySettings);
        this.agencySettingsSaved = true;
        this.adminAgencySettingsForm.markAsPristine();
      } else {
        this.agencySettingsSaved = false;
        this.agencySettingsValidationMessages = response.ErrorMessages;
      }
    });
  }
/**
 * build admin agency settings form
 * @returns {void}
 */
  private constructForm(): void {
    this.adminAgencySettingsForm = this.formBuilder.group({
      enableRecommendationEngine: new FormControl(),
      allowCreateALink: new FormControl(),
      enableAgentTips: new FormControl(),
      allowSecondChanceOffers: new FormControl(),
      allowManageGroup: new FormControl(),
      allowCreditSales: new FormControl(),
      allowCashSales: new FormControl(),
      suppressCommissionOnReport: new FormControl(),
      allowAgentsToChangeCodeOnWeb: new FormControl(),
      allowBookingAgentCodeOverride: new FormControl(),
      allowNotifications: new FormControl(),
      checkPrinterWaiver: new FormControl(),
      agencyQueueMaxDays: new FormControl('', [Validators.max(2000), Validators.min(0), Validators.minLength(0), Validators.maxLength(4)]),
      insuranceQuestionText: new FormControl(),
      enableAdminApproval: new FormControl(),
      allowAgentsToCreateWebAccounts: new FormControl()
    });
  }
/**
 * update admin agency settings form
 * @returns {void}
 */
  private updateForm(agencySettings: AgencySettings): void {
    this.adminAgencySettingsForm.patchValue(
      {
        enableRecommendationEngine: agencySettings.EnableRecommendationEngine,
        allowCreateALink: agencySettings.AllowCreateALink,
        enableAgentTips: agencySettings.EnableAgentTips,
        allowSecondChanceOffers: agencySettings.AllowSecondChanceOffers,
        allowManageGroup: agencySettings.AllowManageGroup,
        allowCreditSales: agencySettings.AllowCreditSalesIfCW,
        allowCashSales: agencySettings.AllowCashSalesIfCW,
        suppressCommissionOnReport: agencySettings.AreCommissionsSuppressed,
        allowAgentsToChangeCodeOnWeb: agencySettings.AllowAgentsToChangeCodeOnWeb,
        allowBookingAgentCodeOverride: agencySettings.AllowBookingAgentCodeOverride,
        allowNotifications: agencySettings.AllowNotifications,
        checkPrinterWaiver: agencySettings.IsPrintWaiverCheckedByDefault,
        agencyQueueMaxDays: agencySettings.AgencyQueueMaxDays,
        insuranceQuestionText: agencySettings.InsurePromptQuestionText,
        enableAdminApproval: agencySettings.EnableAdminApproval,
        allowAgentsToCreateWebAccounts: agencySettings.AllowAgentsToCreateWebAccounts
    });
  }
}
