// Angular Core
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

// Models
import { Booking, State, BillingInformation, ErrorMessage, ProductInformation } from '@allianz/agent-max-core-lib';
import { PaymentType, SelectAllPaymentType, AlertType } from 'src/app/models/enums';
import { PaySelectedGroupPoliciesData, PayGroupPoliciesBannerData } from 'src/app/models/classes';
import { GroupProductPayload } from 'src/app/models/interfaces';
import { environment } from 'src/environments/environment';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { UniversalConfigService } from 'src/app/api/services/universal-config.service';
import { QuoteService } from 'src/app/api/services/quote.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { LoadingService } from 'src/app/api/services/loading.service';

// Third Party
import { Observable } from 'rxjs';
import { concatMap, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-group-agent-payment',
  templateUrl: './group-agent-payment.component.html',
  styleUrls: ['./group-agent-payment.component.scss']
})
export class GroupAgentPaymentComponent implements OnInit, OnDestroy {
  submitForPurchaseForm: FormGroup;
  billingInfoValidation: ErrorMessage[] = [];
  purchaseErrors: ErrorMessage[] = [];
  states: Observable<State[]>;
  payAllReadyAgencyCC: boolean;
  payAllReadyCashWithheld: boolean;
  groupID: string | number;
  groupPolicy: Booking;
  policies: Booking[] = [];
  agencyCreditCardPolicies: Booking[] = [];
  cashWithheldPolicies: Booking[] = [];
  policiesToSubmitForPurchase: Booking[] = [];
  paymentType: typeof PaymentType = PaymentType;
  agencyCreditCardPrices: number[] = [];
  agencyCreditCardTotal: number = 0;
  cashWithheldPrices: number[] = [];
  cashWithheldTotal: number = 0;
  selectAllPaymentType: typeof SelectAllPaymentType = SelectAllPaymentType;
  paySelectedGroupPoliciesData: PaySelectedGroupPoliciesData;
  productName: string = '';
  productInformation: ProductInformation;
  showPurAckError: boolean = false;
  totalAmount: number = 0;
  // TODO: Put in the utility service
  public phoneMask: Array<string | RegExp> = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  private _months: Array<{ value: number; text: string; }> = [
    { value: 1, text: `January` },
    { value: 2, text: `February` },
    { value: 3, text: `March` },
    { value: 4, text: `April` },
    { value: 5, text: `May` },
    { value: 6, text: `June` },
    { value: 7, text: `July` },
    { value: 8, text: `August` },
    { value: 9, text: `September` },
    { value: 10, text: `October` },
    { value: 11, text: `November` },
    { value: 12, text: `December` }
  ];
  private _years: Array<{ value: number; text: string; }>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appStateService: AppStateService,
    private formBuilder: FormBuilder,
    private universalConfigService: UniversalConfigService,
    private quoteService: QuoteService,
    private utilityService: UtilityService,
    private loadingService: LoadingService
  ) {
    this.paySelectedGroupPoliciesData = this.appStateService.getPaySelectedGroupPoliciesData();
    if (this.paySelectedGroupPoliciesData != null) {
      this.agencyCreditCardPolicies = this.paySelectedGroupPoliciesData.ReadyAgencyCCPolicies;
      this.cashWithheldPolicies = this.paySelectedGroupPoliciesData.ReadyCashWithheldPolicies;
      this.payAllReadyAgencyCC = this.paySelectedGroupPoliciesData.SelectAllPaymentType === this.selectAllPaymentType.AgencyCreditCard || this.paySelectedGroupPoliciesData.SelectAllPaymentType === this.selectAllPaymentType.Both ? true : false;
      this.payAllReadyCashWithheld = this.paySelectedGroupPoliciesData.SelectAllPaymentType === this.selectAllPaymentType.CashWitheld || this.paySelectedGroupPoliciesData.SelectAllPaymentType === this.selectAllPaymentType.Both ? true : false;
    }
    this.submitForPurchaseForm = this.formBuilder.group({
      nameOnCard: new FormControl(null, {updateOn: 'blur', validators: Validators.required}),
      cardNumber: new FormControl(null, {updateOn: 'blur', validators: [Validators.required, Validators.maxLength(20)]}),
      expMonth: new FormControl(null, {updateOn: 'blur', validators: Validators.required}),
      expYear: new FormControl(null, {updateOn: 'blur', validators: Validators.required}),
      addressLine1: new FormControl(null, {updateOn: 'blur', validators: Validators.required}),
      addressLine2: new FormControl(),
      city: new FormControl(null, {updateOn: 'blur', validators: Validators.required}),
      stateOfResidence: new FormControl(null, {updateOn: 'blur', validators: Validators.required}),
      zipCode: new FormControl(null, {updateOn: 'blur', validators: Validators.required}),
      phone: new FormControl(null, {updateOn: 'blur', validators: Validators.required}),
      readCertificate: new FormControl(false),
      readPurchaseAgreement: new FormControl(false)
    });
  }

  ngOnInit(): void {
    this.groupPolicy = this.route.snapshot.data.groupPolicy;
    this.groupID = this.groupPolicy.BookingID;
    this.productName = this.groupPolicy.ProductInformation.ProgramProducts[0].Product.ProductName;

    if (this.cashWithheldPolicies.length > 0) {
      this.cashWithheldTotal = this.getCashWithheldTotal(this.cashWithheldPolicies);
    }
    if (this.agencyCreditCardPolicies.length > 0) {
      this.initializeExpirationYear();
      this.states = this.universalConfigService.getStates();
      this.agencyCreditCardTotal = this.getAgencyCreditCardTotal(this.agencyCreditCardPolicies);
    }
    this.totalAmount = this.agencyCreditCardTotal + this.cashWithheldTotal;
    this.productInformation = this.getProductInformation();
  }

  ngOnDestroy(): void {
    this.appStateService.setPaySelectedGroupPoliciesData(null);
    this.submitForPurchaseForm.reset();
  }

  /**
   * Get the AgencyCreditCard total price
   * @param {Booking[]} policies
   * @returns {number}
   */
  getAgencyCreditCardTotal(policies: Booking[]): number {
    this.agencyCreditCardPrices = new Array();
    for (const policy of policies) {
      this.agencyCreditCardPrices.push(policy.ProductInformation.ProgramProducts[0].Price);
    }
    return this.totalPrice(this.agencyCreditCardPrices);
  }

  /**
   * Get the CashWithheld total price
   * @param {Booking[]} policies
   * @returns {number}
   */
  getCashWithheldTotal(policies: Booking[]): number {
    this.cashWithheldPrices = new Array();
    for (const policy of policies) {
      this.cashWithheldPrices.push(policy.ProductInformation.ProgramProducts[0].Price);
    }
    return this.totalPrice(this.cashWithheldPrices);
  }

  /**
   * Get the payment type as plain text
   * @param {number} bookingPaymentNumber
   * @returns {string} - payment type as plain text
   */
  getPaymentTypeText(bookingPaymentNumber: number): string {
    return this.paymentType[Object.keys(this.paymentType)[bookingPaymentNumber]];
  }

  /**
   * augments total price param
   * @param {number[]} prices
   * @returns {number} total
   */
  totalPrice(prices: number[]): number {
    return prices.reduce((a, b) => a + b, 0);
  }

  /**
   * update Group Agency Credit Card Billing information from sub component form
   * @returns {BillingInformation}
   */
  updateGroupAgencyCCBillingInfo(): BillingInformation {
    const billingInformation = new BillingInformation();
    billingInformation.CardholderAddress1.Value = this.isEmptyAssignNull(this.submitForPurchaseForm.controls.addressLine1.value);
    billingInformation.CardholderAddress2.Value = this.isEmptyAssignNull(this.submitForPurchaseForm.controls.addressLine2.value);
    billingInformation.CardholderCity.Value = this.isEmptyAssignNull(this.submitForPurchaseForm.controls.city.value);
    billingInformation.CardholderState.Value = this.isEmptyAssignNull(this.submitForPurchaseForm.controls.stateOfResidence.value);
    billingInformation.CardholderZipCode.Value = this.isEmptyAssignNull(this.submitForPurchaseForm.controls.zipCode.value);
    billingInformation.CardholderFullName.Value = this.isEmptyAssignNull(this.submitForPurchaseForm.controls.nameOnCard.value);
    billingInformation.CardholderPhoneNumber.Value = this.isEmptyAssignNull(this.submitForPurchaseForm.controls.phone.value);
    billingInformation.IsPayMethodCashWithheld.Value = false;
    billingInformation.FulfillmentPrimaryEmail.Value = this.isEmptyAssignNull('');
    billingInformation.FulfillmentSecondaryEmail.Value = this.isEmptyAssignNull('');
    billingInformation.IsFulfillmentByMail.Value = false;
    billingInformation.CardExpirationMonth.Value = this.submitForPurchaseForm.controls.expMonth.value || 0;
    billingInformation.CardExpirationYear.Value = this.submitForPurchaseForm.controls.expYear.value || 0;
    billingInformation.CardNumber.Value = this.isEmptyAssignNull(this.submitForPurchaseForm.controls.cardNumber.value);
    if (this.paySelectedGroupPoliciesData.SelectAllPaymentType === SelectAllPaymentType.AgencyCreditCard) {
      billingInformation.CardTypeCodeString.Value = this.utilityService.getCreditCardCodeString(this.submitForPurchaseForm.controls.cardNumber.value);
    } else {
      billingInformation.CardTypeCodeString.Value = null;
    }
    billingInformation.CardholderCountryCode.Value = 'USA';

    return billingInformation;
  }

  /**
   * If true Submit Policies for purchase button is disabled
   * @returns {boolean}
   */
  disableSubmitPoliciesForPurchaseBttn(): boolean {
    let disableButton = false;

    if (this.agencyCreditCardPolicies.length === 0 && this.cashWithheldPolicies.length === 0) {
      disableButton = true;
    }

    if (this.agencyCreditCardPolicies.length > 0 && (this.submitForPurchaseForm.pristine || this.submitForPurchaseForm.invalid)) {
      disableButton = true;
    }

    return disableButton;
  }

  /**
   * Get the CashWithheld total price
   * @param {Booking[]} bookings
   * @returns {boolean}
   */
  isGroupPurchasePending(bookings: Booking[]): boolean {
    return bookings.some((booking) => booking.BookingStatus === 22);
  }

  /**
   * Get the CashWithheld total price
   * @param {Booking[]} bookings
   * @param {number[]} childBookingIds
   * @returns {boolean}
   */
  isGroupPurchaseNotConfirmed(bookings: Booking[], childBookingIds: number[]): boolean {
    let isGroupPurchaseNotConfirmed: boolean = true;

    for (const childBookingId of childBookingIds) {
      bookings.forEach((booking) => {
        if (booking.BookingID === childBookingId) {
          booking.BookingStatus !== 4 ? isGroupPurchaseNotConfirmed = true : isGroupPurchaseNotConfirmed = false;
        }
      });
    }

    return isGroupPurchaseNotConfirmed;
  }

  /**
   * Builds billingInformation object based on submitForPurchaseForm inputs and populates policiesToSubmitForPurchase array with all policies selected for purchase
   * @returns {void}
   */
  submitPoliciesForPurchase(): void {
    if (this.submitForPurchaseForm.controls.readCertificate.value && this.submitForPurchaseForm.controls.readPurchaseAgreement.value) {
      this.showPurAckError = false;
      this.loadingService.open();
      this.policiesToSubmitForPurchase = this.agencyCreditCardPolicies.concat(this.cashWithheldPolicies);
      const payGroupPoliciesBannerData: PayGroupPoliciesBannerData = {
        ShowGroupPolicyPurchaseBanner: false,
        GroupPolicyName: this.groupPolicy.GroupName.Value,
        TotalPurchasePrice: this.agencyCreditCardTotal + this.cashWithheldTotal,
        NumberOfGroupPoliciesSubmitted: this.policiesToSubmitForPurchase.length
      };
      const groupProductPayload: GroupProductPayload = {
        Booking: this.groupPolicy,
        BillingInformation: this.updateGroupAgencyCCBillingInfo(),
        GroupId: this.groupID,
        ChildBookingIds: null,
        Bookings: null
      };

      groupProductPayload.ChildBookingIds = this.policiesToSubmitForPurchase.map((child) => child.BookingID);
      groupProductPayload.Bookings = this.policiesToSubmitForPurchase;
      this.quoteService.submitGroupRecordsForPurchase(groupProductPayload, this.appStateService.getCredentials()).pipe(
        concatMap((GroupProductRes) => {

          this.appStateService.setPayGroupPoliciesBannerData(payGroupPoliciesBannerData);
          if ((GroupProductRes.ErrorMessages && GroupProductRes.ErrorMessages.length > 0) ||
            (!GroupProductRes.PaymentRecordIds || GroupProductRes.PaymentRecordIds.length === 0)) {
            throw new Error(GroupProductRes.ErrorMessages[0].Description);
          }
          groupProductPayload.PaymentRecordIds = GroupProductRes.PaymentRecordIds;
          return this.quoteService.submitGroupRecordIds(groupProductPayload, this.appStateService.getCredentials());
        }),
        finalize(() => {
          this.loadingService.close();
          this.router.navigate([`small-group/modifyGroup/${this.groupID}/policies/`]);
        })
      ).subscribe((response) => {
        if (response.IsPurchaseSuccessful === true) {
          payGroupPoliciesBannerData.ShowGroupPolicyPurchaseBanner = true;
          this.appStateService.setPayGroupPoliciesBannerData(payGroupPoliciesBannerData);
          this.appStateService.triggerGroupPurchaseBannerType(AlertType.Info);
        } else {
          payGroupPoliciesBannerData.ShowGroupPolicyPurchaseBanner = true;
          this.appStateService.setPayGroupPoliciesBannerData(payGroupPoliciesBannerData);
          this.appStateService.triggerGroupPurchaseBannerType(AlertType.Danger);
        }
      }, (err) => {
        payGroupPoliciesBannerData.ShowGroupPolicyPurchaseBanner = true;
        this.appStateService.setPayGroupPoliciesBannerData(payGroupPoliciesBannerData);
        this.appStateService.triggerGroupPurchaseBannerType(AlertType.Danger);
      }, () => {});
      // TODO Add error checking to check if selected product is indicated as unavailable when individual policy is opened from "Policies" tab
    } else {
      this.showPurAckError = true;
    }
  }

  /**
   * Gets the months
   * @returns {Array<{value: number, text: string}>}
   */
  get months(): Array<{ value: number; text: string; }> {
    return this._months;
  }

  /**
   * Gets the years
   * @returns {Array<{value: number, text: string}>}
   */
  get years(): Array<{ value: number; text: string; }> {
    return this._years;
  }

  /**
   * Sets the years
   * @returns {Array<{value: number, text: string}>}
   */
  set years(val: Array<{ value: number; text: string; }>) {
    this._years = val;
  }

  /**
   *  opens the purchase agreement
   * @returns {void}
   */
  openPurchaseAgreement(): void {
    window.open('https://content.allianzpartnerservices.com/purchase-agreement/');
  }

  /**
   *  opens a link to ati
   * @returns {void}
   */
  openPlanDetails(): void {

    if (this.productInformation !== null) {
      let url = `${environment.ProductTC}&productid=${this.productInformation.PSProductId}&state`;
      if (this.groupPolicy.TravelInformation.PrimaryTraveler.Address.State.Value !== '') {
        url += `=${this.groupPolicy.TravelInformation.PrimaryTraveler.Address.State.Value}`;
      }
      window.open(url);
    }
  }

  /**
   * gets product information for group
   * @returns {ProductInformation}
   */
  getProductInformation(): ProductInformation {
    if (this.agencyCreditCardPolicies && this.agencyCreditCardPolicies.length > 0) {
      if (this.agencyCreditCardPolicies[0].ProductInformation && this.agencyCreditCardPolicies[0].ProductInformation.ProgramProducts.length > 0) {
        return this.agencyCreditCardPolicies[0].ProductInformation.ProgramProducts[0].Product;
      }
    }
    if (this.cashWithheldPolicies && this.cashWithheldPolicies.length > 0) {
      if (this.cashWithheldPolicies[0].ProductInformation && this.cashWithheldPolicies[0].ProductInformation.ProgramProducts.length > 0) {
        return this.cashWithheldPolicies[0].ProductInformation.ProgramProducts[0].Product;
      }
    }
    return null;
  }
  /**
   * gets the values for the expiration year dropdown
   * @returns {void}
   */
  private initializeExpirationYear(): void {
    const today = new Date();
    const todaysYear = today.getFullYear();
    const years: Array<{ value: number; text: string; }> = [];

    for (let i = 0; i < 20; i++) {
      years.push({ value: todaysYear + i, text: (todaysYear + i).toString() });
    }

    this._years = years;
  }

  /**
   * turns empty input to null for the service layer to ingest
   * @param {string | number} item
   * @returns {any | null}
   */
  private isEmptyAssignNull(item: string | number | null): any | null {
    return item === '' ? null : item;
  }
}
