// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Serivces
import { UniversalConfigService } from 'src/app/api/services/universal-config.service';

// Third Party
import { Observable } from 'rxjs';
import { State } from '@allianz/agent-max-core-lib';

@Injectable()
export class DestinationsResolver implements Resolve<Observable<State[]>> {
  constructor(
    private universalConfigService: UniversalConfigService
  ) { }
  resolve(): Observable<State[]> {
    return this.universalConfigService.getDestinations();
  }
}
