"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var alert_communication_info_class_1 = require("./alert-communication-info.class");
var FlightItineraryInformation = /** @class */ (function () {
    function FlightItineraryInformation() {
        this.IsVisible = null;
        this.ValidationMessages = Array();
        this.AlertCommunicationInfo = new alert_communication_info_class_1.AlertCommunicationInfo();
        this.Flights = new Array();
    }
    return FlightItineraryInformation;
}());
exports.FlightItineraryInformation = FlightItineraryInformation;
