// Angular Core
import { Component, OnInit, DoCheck, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

// Services
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

// Models
import { AgencyGroup } from 'src/app/models/interfaces';
import { MainNavTabs, AdminNavTabs } from 'src/app/models/enums';

// 3rd Party Imports
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

// Core library
import { ErrorMessage, KeyValue } from '@allianz/agent-max-core-lib';

// Components
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'app-maxmail',
  templateUrl: './maxmail.component.html',
  styleUrls: ['./maxmail.component.scss']
})
export class MaxmailComponent implements OnInit, DoCheck, OnDestroy {
  agencyId: string;
  agencyGroups: AgencyGroup[] = Array<AgencyGroup>();
  groups: number[] = [1, 2];
  isLoaded: boolean = false;
  agencyArray: FormArray = this.formBuilder.array([]);
  maxMailSettingsGroupForm: FormGroup;
  subscriptionList: Subscription[] = [];
  maxMailSettingsSaved: boolean = false;
  isDesktop: boolean;
  validationMessages: ErrorMessage[] = [];
  bsModalRef: BsModalRef;
  sharedMaxMailSettingsId: number = null;
  agencyID: number = null;
  locationListener: any = null;

  @Output('nextActiveTab') nextActiveTab: EventEmitter<KeyValue> = new EventEmitter<KeyValue>();

  private readonly onDestroy: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private adminuserService: AdminUserService,
    private appStateService: AppStateService,
    private bsModalService: BsModalService,
    private location: Location,
    private utilityService: UtilityService
  ) {
    this.maxMailSettingsGroupForm = this.formBuilder.group({
      agencyArray: this.agencyArray
    });

    // this.locationListener = this.location
    // .subscribe((event: PopStateEvent): void => {
    //   if (this.maxMailSettingsGroupForm.dirty && event.type === 'popstate') {
    //     this.browserBackButtonClick();
    //   }
    // });
  }

  ngOnInit(): void {
    this.getAgencyGroupsForAgency();
    this.utilityService.getSize().subscribe((isDesktop: boolean) => {
      this.isDesktop = isDesktop;
    });
  }

  ngDoCheck(): void {
    this.agencyArray.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((val) => {
        if (this.agencyArray.dirty) {
          this.utilityService.setTriggerAdminSaveAlert(this, true, MainNavTabs.AdminComponent, AdminNavTabs.MaxMail);
        }
      });
  }

  /**
   * What happens when browser back button is clicked
   * @returns {void}
   */
  browserBackButtonClick(): void {
    const initialState = {
      text: `<p>Changes to this page have been made. Would you like to Apply the changes?</p>`,
      dialogIndicator: 'admin-save-group-settings'
    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
    this.bsModalRef.content.modalResponse
      .subscribe((ac: boolean) => {
        if (ac) {
          this.updateMaxmailSettingsGroupsForAgency();
        } else {
          return;
        }
      });
  }

  /**
   * gets a single agency's info, but from a diffrent, more adminy endpoint
   * @returns {void}
   */
  getAgencyGroupsForAgency(): void {
    this.subscriptionList.push(
      this.adminuserService.getAgencyGroupsForAgency()
      .subscribe((response) => {
        this.agencyId = response.AgencyId;
        this.agencyGroups = response.AgencyGroupList;
        this.appStateService.setAgencyGroups(this.agencyGroups);
        this.isLoaded = true;
        const maxGroupId = Math.max(...this.agencyGroups.map((agencyGroup) => agencyGroup.SharedAgencyMaxMailSettingsId));
        if (maxGroupId > 2) {
          this.groups = Array.from(Array(maxGroupId).keys()).map((group) => group + 1);
        }
        for (const agencyGroup of this.agencyGroups) {
          this.agencyArray.push(
            this.formBuilder.group({
              groupChoice: [agencyGroup.SharedAgencyMaxMailSettingsId]
            })
          );
        }
      })
    );
  }

  /**
   * Adds a new group column for more groups.  Maxes out at 4 (index of 0 so must be less than 3)
   * @returns {void}
   */
  addGroup(): void {
    const maxGroups = Math.max(...this.groups);
    if (maxGroups < 4) {
      this.groups.push(Math.max(...this.groups) + 1);
    }
  }

  /**
   * Gets the name for the button.  Button name will either be
   * 'Agency 2nd Chance' or 'Group {x} 2nd Chance'.
   * @param {number} index - index of the row that the button is being grabbed for
   * @returns {string} - name of button
   */
  getButtonName(index: number): string {
    const groupValue: number = this.agencyArray.controls[index].value.groupChoice;
    if (groupValue === 0) {
      return 'Agency 2nd Chance';
    }
    return  `Group ${groupValue} 2nd Chance`;
  }

  /**
   * Routes to the appropriate settings based on the shared maxmail settings id
   * @param {number} index - index of the row that the button is being grabbed for
   * @returns {void}
   */
  routeToSettings(index: number): void {
    this.sharedMaxMailSettingsId = this.agencyGroups[index].SharedAgencyMaxMailSettingsId;
    this.agencyID = this.agencyGroups[index].AgencyId;
    this.navigateAgentSettings(this.sharedMaxMailSettingsId, this.agencyID);
  }

  /**
   * Routes to either group settings or default settings depending on whether or not a group is chosen
   * @param {number} sharedAgencyMaxMailSettingsId
   * @param {number} agencyId - base agency id used by details page
   * @returns {void}
   */
  navigateMaxmailGroupSettings(sharedAgencyMaxMailSettingsId: number, agencyId: number): void {
    if (sharedAgencyMaxMailSettingsId !== 0) {
      this.router.navigate(['admin/maxmailSettings', agencyId, sharedAgencyMaxMailSettingsId]);
    } else {
      this.router.navigate(['admin/maxmailSettings', agencyId]);
    }
  }

  /**
   * Routes to maxmail settings page, passing in agency ID if the agency doesn't belong to a group.
   * Otherwise it will pass in the agencyID and sharedAgencyMaxMailSettingsId to the maxmail settings page
   * @param {number} sharedAgencyMaxMailSettingsId - shared maxmail settings id being passed in
   * @param {number} agencyId - base agency id used by details page
   * @returns {void}
   */
  navigateAgentSettings(sharedAgencyMaxMailSettingsId: number, agencyId: number): void {
    if (!this.maxMailSettingsGroupForm.pristine) {
      const initialState = {
        text: `<p>Changes to groupings on this page have been made. Would you like to Apply the changes?</p>`,
        dialogIndicator: 'admin-save-group-settings'
      };
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .subscribe((ac: boolean) => {
          if (ac) {
            this.updateMaxmailSettingsGroupsForAgency();
          } else {
            // if click NO navigate to group settings - default behavior when clicking groupSettings button
            this.navigateMaxmailGroupSettings(sharedAgencyMaxMailSettingsId, agencyId);
          }
        });
    } else {
      this.navigateMaxmailGroupSettings(sharedAgencyMaxMailSettingsId, agencyId);
    }
  }

  /**
   * Updates the agency groups to have the proper shared maxmail settings id
   * @returns {void}
   */
  updateMaxmailSettingsGroupsForAgency(nextTab?: KeyValue, nextMainTab?: KeyValue, amoGoBack?: boolean): void {
    this.subscriptionList.push(this.adminuserService.updateMaxmailSettingsGroupsForAgency(this.agencyGroups)
      .subscribe((response) => {
        this.validationMessages = response.ErrorMessages;
        this.maxMailSettingsSaved = this.validationMessages.length === 0 ? true : false;
        if (response.ErrorMessages.length === 0) {
          this.utilityService.setTriggerAdminSaveAlert(this, false);
          this.maxMailSettingsGroupForm.markAsPristine();
          if (nextTab) {
            this.nextActiveTab.emit(nextTab);
          } else if (nextMainTab) {
            this.router.navigate([nextMainTab.Key]);
          } else if (amoGoBack) {
            this.router.navigate(['home']);
          } else if (this.sharedMaxMailSettingsId !== null && this.agencyID !== null) {
            this.navigateMaxmailGroupSettings(this.sharedMaxMailSettingsId, this. agencyID);
          } else {
            this.agencyGroups = response.AgencyGroupList;
            this.isLoaded = true;
            this.appStateService.setAgencyGroups(this.agencyGroups);
            for (let index = 0; index < this.agencyGroups.length; index++) {
              this.agencyArray.controls[index].patchValue({groupChoice: this. agencyGroups[index].SharedAgencyMaxMailSettingsId});
            }
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Handle a change on the radio button form controls
   * @param {number} index - index of the row that the button is being grabbed for
   * @param {number} group - Group number of current index
   * @returns {void}
   */
  handleChange(index: number, group: number): void {
    if (!group) {
      this.agencyGroups[index].SharedAgencyMaxMailSettingsId = 0;
    } else {
      this.agencyGroups[index].SharedAgencyMaxMailSettingsId = group;
    }
  }
}
