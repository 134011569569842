// Angular Core
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { LoadingService } from 'src/app/api/services/loading.service';

// Models
import { AgencySettings, PrintOrEmailOptions } from 'src/app/models/interfaces';
import { ErrorMessage, DefaultCreateALinkSettings, KeyValue, Booking } from '@allianz/agent-max-core-lib';
import { PrintOrEmailTypes, PrintOrEmailDocumentType, PrintOrEmailReportType } from 'src/app/models/enums';
import { environment } from 'src/environments/environment';

// Third Party
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-a-link',
  templateUrl: './quotemax.component.html',
  styleUrls: ['./quotemax.component.scss'],
})
export class CreateALinkComponent implements OnInit, OnDestroy {
  @Input('AgencySettings') AgencySettings: AgencySettings;

  IFrameHeight: number;
  IFrameWidth: number;
  IFrameUrl: string;
  emailUrl: string;

  // boolean flags
  showCode: boolean;
  isWeb: boolean = true;
  copied: boolean;
  createALinkValidationMessages: ErrorMessage[] = [];
  settings: DefaultCreateALinkSettings;
  agents: KeyValue[] = [];
  products: KeyValue[] = [];

  createALinkForm: FormGroup;
  productId: FormControl = new FormControl(-1);
  isMultiProductView: FormControl = new FormControl(false);
  versionChosen: FormControl = new FormControl('webVersion');
  pictureTypeValue: FormControl = new FormControl('0');
  layoutTypeValue: FormControl = new FormControl('0');
  assignedAgtAcctID: FormControl = new FormControl('');
  quotemaxSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private adminUserServe: AdminUserService,
    private appStateService: AppStateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService
  ) {
  }

  ngOnInit(): void {
    this.settings = this.activatedRoute.firstChild.snapshot.data.CALSettings.DefaultCreateALinkSettings;
    this.agents = this.activatedRoute.firstChild.snapshot.data.CALSettings.AgentCodes;
    this.products = this.activatedRoute.firstChild.snapshot.data.CALSettings.ProgramProducts;
    this.createALinkForm = this.formBuilder.group({
      productId: this.productId,
      isMultiProductView: this.isMultiProductView,
      versionChosen: this.versionChosen,
      pictureTypeValue: this.pictureTypeValue,
      layoutTypeValue: this.layoutTypeValue,
      assignedAgtAcctID: this.assignedAgtAcctID,
    });
  }

  /**
   * A focus event handler to prevent focusing an element it attached to
   * @param {FocusEvent} $event
   * @returns {void}
   */
  onFocusTextArea($event: FocusEvent): void {
    $event.preventDefault();
    // Try to remove the focus from this element.
    const currentTarget: any | null = $event.currentTarget;
    if (currentTarget !== null && this.isFunction(currentTarget.blur)) {
      currentTarget.blur();
    }
    // Try to set focus back to the previous element
    const relatedTarget: any | null = $event.relatedTarget;
    if (relatedTarget !== null && this.isFunction(relatedTarget.focus)) {
      relatedTarget.focus();
    }
  }

  /**
   * Setting the event function
   * @param {any} eventFunction
   * @returns {() => {}}
   */
  isFunction(eventFunction: any): eventFunction is () => {} {
    return eventFunction instanceof Function;
  }

  /**
   * updates the reuest object based on form data.
   * @returns {void}
   */
  UpdateSettings(): void {
    this.settings.AssignedAgtAcctID.Value = this.assignedAgtAcctID.value;
    this.settings.ProductId = this.productId.value;
    this.settings.IsMultiProductView = this.isMultiProductView.value;
    this.settings.PictureTypeValue = this.pictureTypeValue.value;
    this.settings.LayoutTypeValue = this.layoutTypeValue.value;
  }

  /**
   * creates the quotemax link based on the form data.
   * @param {boolean} isEmailOption
   * @returns {void}
   */
  saveCreateALinkSettings(isEmailOption: boolean): void {
    this.UpdateSettings();

    // if it's email this is 1, otherwise its two.
    // it's the format the service wants it to be in. I'm sorry.
    this.settings.CreateALinkTypeValue = isEmailOption ? 1 : 0;

    this.loadingService.open();
    this.adminUserServe.saveCreateALinkSettings(this.settings, this.appStateService.getCredentials())
      .subscribe((response) => {
        if (!response.ValidationMessages.length) {
          this.settings = response;
          this.showCode = true;
          const quoteCode = response.QuoteToolCode;
          const widgetID = response.WidgetID;
          const accam = response.ACCAM;
          this.setWidgetDimensions(response.LayoutTypeValue);

          if (isEmailOption) {
            this.IFrameUrl = `${environment.StoreFrontURL}#/home/?emaillinkcode=${quoteCode}`;
          } else {
            this.IFrameUrl = `<iframe frameborder='0' width='${this.IFrameWidth}' height='${this.IFrameHeight}' src='${environment.QuoteToolURL}?widgetid=${widgetID}&accam=${accam}&code=${quoteCode}'></iframe>`;
          }
          this.createALinkForm.markAsPristine();
        }
        this.createALinkValidationMessages = response.ValidationMessages;
      },
      (error) => { },
      () => this.loadingService.close());
  }

  /**
   * accepts either a 1 or a 0, ( that's the format the service returns.)
   * sets the iframes dimensions
   * @param {number} layout
   * @returns {void}
   */
  setWidgetDimensions(layout: number): void {
    if (layout === 0) {
      this.IFrameWidth = 300;
      this.IFrameHeight = 675;
    } else {
      this.IFrameWidth = 600;
      this.IFrameHeight = 390;
    }
  }

  /**
   * updates content flags based on user input
   * @returns {void}
   */
  onChangeVersion(): void {
    this.IFrameUrl = '';
    this.showCode = false;
    // TODO possibly convert to an enum
    if (this.versionChosen.value === 'webVersion') {
      this.isWeb = true;
    }
    if (this.versionChosen.value === 'emailVersion') {
      this.isWeb = false;
    }
  }

  /**
   * does some fun javascript and saves the specifeid elements text to the clipboard
   * @returns {void}
   */
  copyToClipBoard(): void {
    // Create new element to copy from since we are blocking focus
    const newElement = document.createElement('TEXTAREA') as HTMLTextAreaElement;
    // set the new elements body to the textarea content
    newElement.value = this.IFrameUrl;
    // append to body
    document.body.appendChild(newElement);
    // grab the info
    newElement.select();
    // actually copy the content
    document.execCommand('copy');
    // remove the new element
    document.body.removeChild(newElement);

    this.copied = true;
    setTimeout(() => { this.copied = false; }, 3000);
  }

  ngOnDestroy(): void {
    if (this.quotemaxSubscription) {
      this.quotemaxSubscription.unsubscribe();
    }
  }

  /**
   * Sets up the options for the print email form
   * @returns {void}
   */
  emailQuoteToolCode(): void {
    const agentInfo = this.appStateService.getAgentInformation();
    const options: PrintOrEmailOptions = {
      HeaderName: 'Send QuoteMax Code',
      DocumentType: PrintOrEmailDocumentType.GenericDocument,
      ReportType: PrintOrEmailReportType.ClientGeneratedHtmlDocument,
      IsQuoteToolEmail: true,
      IFrameUrl: this.IFrameUrl,
      EmailInput: {
        FromEmailAddress: agentInfo.EmailAddress,
        FromName: `${agentInfo.FirstName} ${agentInfo.LastName}`
      }
    };

    this.appStateService.setPrintOrEmailReportName(PrintOrEmailTypes.QuoteTool);
    this.appStateService.setPrintOrEmailData(new Booking(), options);
    this.router.navigate(['printoremail']);
  }
}
