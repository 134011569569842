// Angular Core
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

// Services
import { AdminUserService } from 'src/app/api/services/adminUser.service';

// Models
import { AgentAdminSettingGridbagsWithPending } from 'src/app/models/interfaces';
import { AgentAdminSettingsGridbagRecordType } from 'src/app/models/enums/agent-admin-settings-gridbag-record-type.enum';

// Third Party
import { Observable } from 'rxjs';
import { AppStateService } from '../services/app-state.service';
import { AgentAdminSearchRequest } from '../../models/interfaces/agent-admin-search-request.interface';

@Injectable()
export class AdminAgentProfileManagementsResolver  implements Resolve<Observable<AgentAdminSettingGridbagsWithPending>> {
  constructor(private adminUserService: AdminUserService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<AgentAdminSettingGridbagsWithPending> {
    const agentAdminSearchRequest: AgentAdminSearchRequest = {
      AgencyId: route.parent.params.agencyId,
      RecordType: AgentAdminSettingsGridbagRecordType.ActiveOnly,
      AgentName: '',
      AgentId: '',
      AgentEmail: '',
      Index: 1,
      Count: 50
    };
    if (route.children.length) {
      return;
    } else {
      return this.adminUserService.getAgentAdminSettingsGridBagsForAgency(agentAdminSearchRequest);
    }
  }
}
