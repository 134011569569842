// Angular Core
import { Injectable } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

// Components
import { LoadingIndicatorComponent } from 'src/app/shared/components/loading-indicator/loading-indicator.component';

@Injectable()
export class LoadingService {

  private _overlayRef: OverlayRef;
  private _countOpenLoadingSpinner: number = 0;
  constructor(private overlay: Overlay) { }

  /**
   * Trigger open the loading indicator
   * @returns {void}
   */
  public open(): void {
    if (typeof this._overlayRef !== 'undefined' && this._overlayRef.hasAttached()) {
      this._countOpenLoadingSpinner ++;
      return;
    }
    this._countOpenLoadingSpinner = 0;
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig();
    overlayConfig.hasBackdrop = true;
    overlayConfig.scrollStrategy = this.overlay.scrollStrategies.block();
    overlayConfig.positionStrategy = positionStrategy;

    const portal = new ComponentPortal(LoadingIndicatorComponent);
    this._overlayRef = this.overlay.create(overlayConfig);
    this._overlayRef.attach(portal);
  }

  /**
   * Trigger close the loading indicator
   * @returns {void}
   */
  public close(): void {

    if (typeof this._overlayRef !== 'undefined') {
      if (this._countOpenLoadingSpinner < 1) {
        this._overlayRef.dispose();
      } else {
        this._countOpenLoadingSpinner --;
      }
    }
  }

}
