// Angular Core
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Modules
import { SharedModule } from './shared/shared.module';

// Components
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { QueuesComponent } from './queues/queues.component';
import { UserOptionsComponent } from './user-options/user-options.component';
import { ProductComponent } from './product/product.component';
import { AdminComponent } from './admin/admin-wrapper/admin.component';
import { AgencySettingsGroupComponent } from './admin/tabs/agency-settings/agency-settings-group/agency-settings-group.component';
import { AgencySettingsGeneralComponent } from './admin/tabs/agency-settings/agency-settings-general/agency-settings-general.component';
import { ProductCompareComponent } from './product-compare/product-compare.component';
import { PrintOrEmailComponent } from './print-or-email/print-or-email.component';
import { BecomeAPartnerComponent } from './become-a-partner/become-a-partner.component';
import { BookingInfoComponent } from './booking-info/booking-info.component';
import { PurchaseConfirmationComponent } from './purchase-confirmation/purchase-confirmation.component';
import { HelpComponent } from './help/help.component';
import { SwitchAgencyComponent } from './switch-agency/switch-agency.component';
import { MaxmailSettingsComponent } from './maxmail-settings/maxmail-settings.component';
import { BecomeMoreComponent } from './become-more/become-more.component';
import { AdminDetailComponent } from './admin/tabs/agencies/admin-detail/admin-detail.component';
import { ReportsComponent } from './reports/reports.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { ResourceCenterComponent } from './resource-center/resource-center.component';
import { CreateNewAccountComponent } from './create-new-account/create-new-account.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ValidateSecurityComponent } from './validate-security/validate-security.component';
import { ModifySmallGroupComponent } from './modify-small-group/modify-small-group.component';
import { IndividualPolicyComponent } from './modify-small-group/group-policies/individual-policy/individual-policy.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationTabComponent } from './notifications/notification-tab/notification-tab.component';
import { GenericClientValidationComponent } from './shared/components/generic-client-validation/generic-client-validation.component';
import { AdminUserProfileComponent } from './admin/admin-user-profile/admin-user-profile/admin-user-profile.component';
import { AdminAgencyInfoComponent } from './admin/tabs/agencies/admin-detail/tabs/agency-info/admin-agency-info.component';
import { CreateALinkComponent } from './admin/tabs/agencies/admin-detail/tabs/quotemax/quotemax.component';
import { AdminAgentManagementComponent } from './admin/tabs/agencies/admin-detail/tabs/agent-management/admin-agent-management.component';
import { SecondChancesFormComponent } from './shared/components/second-chances-form/second-chances-form.component';
import { CreateAgentComponent } from './admin/tabs/agent-settings/create-agent/create-agent.component';
import { InterfaceSettingsDetailsComponent } from './admin/tabs/interface-settings/interface-settings-details/interface-settings-details.component';
import { InterfaceSettingsAgencyFormComponent } from './admin/tabs/interface-settings/interface-settings-agency-form/interface-settings-agency-form.component';
import { ProductDetailComponent } from './admin/tabs/product-list/product-detail/product-detail.component';
import { AgentDefaultSettingsComponent } from './admin/tabs/agent-settings/agent-default-settings/agent-default-settings/agent-default-settings.component';

// Models
import { IsOpen, UserProfileRoute } from './models/enums';

// Guards
import { CanActivateGuard } from './shared/guards';
import { AdminGuard } from './shared/guards/admin.guard';
import { CanActivateWithRedirectGuard } from './shared/guards/can-activate-redirect.guard';

// Resolvers
import { AdminGetUserOptionsResolver } from './shared/resolvers/admin-get-user-options.resolver';
import { AdminAgentProfileManagementsResolver } from './shared/resolvers/admin-agent-profile-management.resolver';
import { AdminGetAgencyFromAgencyIdResolver } from './shared/resolvers/admin-get-agencies-from-agency-id.resolver';
import { AdminGetAgencySettingsResolver } from './shared/resolvers/admin-get-agency-settings.resolver';
import { AdminGetCreateALinkSettingsResolver } from './shared/resolvers/admin-get-create-a-link-settings.resolver';
import { NotificationsResolver } from './shared/resolvers/notification.resolver';
import { UserOptionsResolver } from './shared/resolvers/user-options.resolver';
import { DestinationsResolver } from './shared/resolvers/destinations.resolver';
import { AgencyProductsResolver } from './shared/resolvers/agency-products.resolver';
import { GetQuoteByBookingIdResolver } from './shared/resolvers/get-quote-by-booking-id.resolver';
import { GetGroupPoliciesByGroupIdResolver } from './shared/resolvers/get-group-policies-by-group-id.resolver';
import { GetAgentInformationByUserResolver } from './shared/resolvers/get-agent-information-by-user.resolver';
import { GetQuoteForAgencyProductsResolver } from './shared/resolvers/get-quote-for-agency-products.resolver';
import { GetQuoteForProductComparisonResolver } from './shared/resolvers/get-quote-for-product-comparison.resolver';
import { GetMaxmailSecondChanceSettingsByBookingIdResolver } from './shared/resolvers/get-maxmail-settings-by-booking-id.resolver';
import { GetProgramProductsForMaxmailResolver } from './shared/resolvers/get-program-products-for-maxmail.resolver';
import { GetAgenciesForAdministrationResolver } from './shared/resolvers/get-agencies-for-administration.resolver';
import { GetSalesReportDefaultsResolver } from './shared/resolvers/get-sales-report-defaults.resolver';
import { GetResourceBlocksResolver } from './shared/resolvers/get-agency-resource-blocks.resolver';
import { GetUserOptionsResolver } from './shared/resolvers/get-user-options.resolver';
import { GetBusinessTypesResolver } from './shared/resolvers/get-business-types.resolver';
import { GetWorkLocationsResolver } from './shared/resolvers/get-work-locations.resolver';
import { GetProfileRolesResolver } from './shared/resolvers/get-profile-roles.resolver';
import { GetReminderQuestionsResolver } from './shared/resolvers/get-reminder-questions.resolver';
import { GetRolesByemailTokenResolver } from './shared/resolvers/get-roles-by-email-token.resolver';
import { GetBusinessTypesByemailTokenResolver } from './shared/resolvers/get-business-types-by-email-token.resolver';
import { GetWorkLocationsByemailTokenResolver } from './shared/resolvers/get-work-locations-by-email-token.resolver';
import { GetReminderQuestionsByemailTokenResolver } from './shared/resolvers/get-reminder-questions-by-email-token.resolver';
import { GetSuppliersResolver } from './shared/resolvers/get-suppliers.resolver';
import { GetStatesResolver } from './shared/resolvers/get-states.resolver';
import { AdminGetAgencyAddressAndLogoResolver } from './shared/resolvers/admin-get-agency-address-and-log.resolver';
import { GetNewBookingResolver } from './shared/resolvers/get-new-booking.resolver';
import { GetAllowManageGroupStatusByUserByUserResolver } from './shared/resolvers/get-can-manage-groups-by-user.resolver';
import { GetSmallGroupQuoteByBookingIdResolver } from './shared/resolvers/get-small-group-quote-by-booking-id.resolver';
import { ActivateWebAccountByAdminResolver } from './shared/resolvers/activate-web-account-by-admin.resolver';
import { CreateWebAccountResolver } from './shared/resolvers/create-web-account.resolver';
import { ResetPasswordByAdminResolver } from './shared/resolvers/reset-password-by-admin.resolver';
import { UserGetProfileRolesResolver } from './shared/resolvers/user-get-profile-roles.resolver';
import { UserGetWorkLocationsResolver } from './shared/resolvers/user-get-work-locations.resolver';
import { AdminGetSellingRolesResolver } from './shared/resolvers/admin-get-selling-roles.resolver';
import { AdminGetAdministrativeRolesResolver } from './shared/resolvers/admin-get-administrative-roles.resolver';
import { AdminGetReportingRightsResolver } from './shared/resolvers/admin-get-reporting-rights.resolver';
import { UserGetBusinessTypesResolver } from './shared/resolvers/user-get-business-types.resolver';
import { GetAddressOptionsSourceTypeResolver } from './shared/resolvers/get-address-options-source-type.resolver';
import { GetSCByMaxMailOptionsSourceTypeResolver } from './shared/resolvers/get-maxMail-options-source-type.resolver';
import { GetInterfaceOptionsSourceTypeResolver } from './shared/resolvers/get-interface-options-source-type.resolver';
import { AdminGetAgencyGroupsForAgencyResolver } from './shared/resolvers/admin-get-agency-groups-for-agency.resolver';
// Models
import { NotificationDescription } from './models/constants';
import { SessionTimeOutComponent } from './session-time-out/session-time-out.component';
import { BrochureComponent } from './brochure/brochure.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [CanActivateGuard],
    resolve: {
      agentInformation: GetAgentInformationByUserResolver,
      canManageGroups: GetAllowManageGroupStatusByUserByUserResolver,
      booking: GetQuoteByBookingIdResolver
    }
  },
  {
    path: 'useroptions',
    component: UserOptionsComponent,
    canActivate: [CanActivateGuard],
    data: { adminRoute: false },
    resolve: {
      userOptions: GetUserOptionsResolver,
      businessTypes: GetBusinessTypesResolver,
      locations: GetWorkLocationsResolver,
      roles: GetProfileRolesResolver,
      maxmailProgramProducts: GetProgramProductsForMaxmailResolver,
      reminderQuestions: GetReminderQuestionsResolver,
      agencySettings: AdminGetAgencySettingsResolver
    }
  },
  {
    path: 'purchaseconfirmation',
    component: PurchaseConfirmationComponent,
    canActivate: [CanActivateGuard],
    resolve: {
      booking: GetQuoteByBookingIdResolver
    }
  },
  {
    path: 'bookinginfo',
    component: BookingInfoComponent,
    canActivate: [CanActivateGuard],
    resolve: {
      booking: GetQuoteByBookingIdResolver,
      suppliers: GetSuppliersResolver,
      destinations: GetStatesResolver,
      agencySettings: AdminGetAgencySettingsResolver
    }
  },
  {
    path: 'product',
    component: ProductComponent,
    canActivate: [CanActivateGuard],
    resolve: {
      agencyProducts: GetQuoteForAgencyProductsResolver
    }
  },
  {
    path: 'productcompare',
    component: ProductCompareComponent,
    canActivate: [CanActivateGuard],
    resolve: {
      agencyProducts: GetQuoteForProductComparisonResolver
    }
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [CanActivateGuard, AdminGuard],
    resolve: {
      adminAgencies: GetAgenciesForAdministrationResolver
    }
  },
  {
    path: 'maxmailsettings',
    component: MaxmailSettingsComponent,
    canActivate: [CanActivateGuard],
    resolve: {
      maxmailSecondChanceSettings: GetMaxmailSecondChanceSettingsByBookingIdResolver,
      maxmailProgramProducts: GetProgramProductsForMaxmailResolver
    }
  },
  {
    path: 'resourcecenter',
    component: ResourceCenterComponent,
    canActivate: [CanActivateGuard],
    resolve: {
      resourceBlocks: GetResourceBlocksResolver
    }
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [CanActivateGuard],
    resolve: {
      reportSettings: GetSalesReportDefaultsResolver
    }
  },
  {
    path: 'userprofile',
    component: UserProfileComponent,
    resolve: {
      roles: GetRolesByemailTokenResolver,
      businessTypes: GetBusinessTypesByemailTokenResolver,
      workLocations: GetWorkLocationsByemailTokenResolver,
      reminderQuestions: GetReminderQuestionsByemailTokenResolver
    }
  },
  {
    path: 'admin/:agentId',
    component: AdminUserProfileComponent,
    canActivate: [CanActivateGuard],
    data: { adminAgentRoute: true },
    resolve: {
      userOptions: AdminGetUserOptionsResolver,
      businessTypes: GetBusinessTypesResolver,
      locations: GetWorkLocationsResolver,
      roles: GetProfileRolesResolver,
      maxmailProgramProducts: GetProgramProductsForMaxmailResolver,
      reminderQuestions: GetReminderQuestionsResolver,
      agencySettings: AdminGetAgencySettingsResolver
    }
  },
  {
    path: 'admin/agencies/:agencyId',
    component: AdminDetailComponent,
    canActivate: [CanActivateGuard, AdminGuard],
    data: {
      adminAgentRoute: true
    },
    resolve: {
      Agencies: AdminGetAgencyFromAgencyIdResolver,
      AgencySettings: AdminGetAgencySettingsResolver,
      AgencyAddressAndLog: AdminGetAgencyAddressAndLogoResolver
    },
    children: [
      { path: '', redirectTo: 'agency-info', pathMatch: 'prefix' },
      {
        path: 'agency-info',
        component: AdminAgencyInfoComponent,
        data: { tab: IsOpen.generalIsOpen }
      },
      {
        path: 'quotemax',
        component: CreateALinkComponent,
        data: { tab: IsOpen.quoteMaxIsOpen },
        resolve: {
          CALSettings: AdminGetCreateALinkSettingsResolver
        }
      },
      {
        path: 'agent-management',
        component: AdminAgentManagementComponent,
        data: { tab: IsOpen.agentManagementIsOpen, createAgent: false },
        resolve: {
          AdminAgencySettings: AdminAgentProfileManagementsResolver,
          sellingRoles: AdminGetSellingRolesResolver,
          administrativeRoles: AdminGetAdministrativeRolesResolver,
          reportingRights: AdminGetReportingRightsResolver,
          addressOptions: GetAddressOptionsSourceTypeResolver,
          maxMailOptions: GetSCByMaxMailOptionsSourceTypeResolver,
          interfaceOptions: GetInterfaceOptionsSourceTypeResolver
        }
      },
      {
        path: 'create-agent',
        data: { tab: IsOpen.createAgentIsOpen, createAgent: UserProfileRoute.AdminCreateAgent },
        component: CreateAgentComponent,
        canActivate: [CanActivateGuard],
        resolve: {
          agencyGroups : AdminGetAgencyGroupsForAgencyResolver,
          businessTypes: UserGetBusinessTypesResolver,
          profileRoles: UserGetProfileRolesResolver,
          locations: UserGetWorkLocationsResolver,
          sellingRoles: AdminGetSellingRolesResolver,
          administrativeRoles: AdminGetAdministrativeRolesResolver,
          reportingRights: AdminGetReportingRightsResolver
        }
      }
    ]
  },
  {
    path: 'admin/agencygroups/:agencyId/:groupId', component: AgencySettingsGroupComponent, canActivate: [CanActivateGuard, AdminGuard]
  },
  {
    path: 'admin/agencySettings/:agencyId', component: AgencySettingsGeneralComponent,
    canActivate: [CanActivateGuard, AdminGuard],
    resolve: {
      AgencySettings: AdminGetAgencySettingsResolver
    }
  },
  { path: 'admin/interfacegroups/:agencyId/:groupId', component: InterfaceSettingsDetailsComponent, data: { isGroup: true }, canActivate: [CanActivateGuard, AdminGuard] },
  { path: 'admin/interfacesettingsform/:agencyId/:groupId', component: InterfaceSettingsAgencyFormComponent, data: { isGroup: true }, canActivate: [CanActivateGuard, AdminGuard] },
  { path: 'admin/interfaces/:agencyId', component: InterfaceSettingsDetailsComponent, data: { isGroup: false }, canActivate: [CanActivateGuard, AdminGuard] },
  { path: 'admin/interfacesettingsform/:agencyId', component: InterfaceSettingsAgencyFormComponent, data: { isGroup: false }, canActivate: [CanActivateGuard, AdminGuard] },
  { path: 'admin/product/:agencyId', component: ProductDetailComponent, canActivate: [CanActivateGuard, AdminGuard] },
  { path: 'admin/product/:agencyId/:groupId', component: ProductDetailComponent, canActivate: [CanActivateGuard, AdminGuard] },
  {
    path: 'admin/agentSettings/:agencyId',
    component: AgentDefaultSettingsComponent,
    canActivate: [CanActivateGuard, AdminGuard],
    resolve: {
      sellingRoles: AdminGetSellingRolesResolver,
      administrativeRoles: AdminGetAdministrativeRolesResolver,
      reportingRights: AdminGetReportingRightsResolver,
      addressOptions: GetAddressOptionsSourceTypeResolver,
      maxMailOptions: GetSCByMaxMailOptionsSourceTypeResolver,
      interfaceOptions: GetInterfaceOptionsSourceTypeResolver
    }
  },
  {
    path: 'admin/agentSettings/:agencyId/:groupId',
    component: AgentDefaultSettingsComponent,
    canActivate: [CanActivateGuard, AdminGuard],
    resolve: {
      sellingRoles: AdminGetSellingRolesResolver,
      administrativeRoles: AdminGetAdministrativeRolesResolver,
      reportingRights: AdminGetReportingRightsResolver,
      addressOptions: GetAddressOptionsSourceTypeResolver,
      maxMailOptions: GetSCByMaxMailOptionsSourceTypeResolver,
      interfaceOptions: GetInterfaceOptionsSourceTypeResolver
    }
  },
  {
    path: 'admin/maxmailSettings/:agencyId',
    data: { adminAgenciesRoute: true },
    component: SecondChancesFormComponent,
    canActivate: [CanActivateGuard, AdminGuard],
    resolve: {
      adminAgencies: GetAgenciesForAdministrationResolver
    }
  },
  {
    path: 'admin/maxmailSettings/:agencyId/:groupId',
    data: { adminAgenciesRoute: true },
    component: SecondChancesFormComponent,
    canActivate: [CanActivateGuard, AdminGuard],
    resolve: {
      adminAgencies: GetAgenciesForAdministrationResolver
    }
  },
  {
    path: 'small-group',
    canActivate: [CanActivateGuard],
    children: [
      {
        path: 'create-group',
        data: { pageHeader: 'Add New Group' },
        children: [
          {
            path: '',
            component: ModifySmallGroupComponent,
            pathMatch: 'full'
          },
          {
            path: 'details',
            component: ModifySmallGroupComponent,
            data: { activeTab: { Key: 'details', Value: 'Details' } },
            resolve: {
              userOptions: UserOptionsResolver,
              destinations: DestinationsResolver,
              suppliers: GetSuppliersResolver,
              booking: GetNewBookingResolver,
              policies: GetGroupPoliciesByGroupIdResolver
            }
          }
        ]
      },
      {
        path: 'modifyGroup/:groupid',
        data: { pageHeader: 'Modify Group' },
        children: [
          {
            path: '',
            component: ModifySmallGroupComponent,
            pathMatch: 'full'
          },
          {
            path: 'details',
            component: ModifySmallGroupComponent,
            data: { activeTab: { Key: 'details', Value: 'Details' } },
            resolve: {
              userOptions: UserOptionsResolver,
              destinations: DestinationsResolver,
              suppliers: GetSuppliersResolver,
              agencyProducts: AgencyProductsResolver,
              booking: GetSmallGroupQuoteByBookingIdResolver,
              policies: GetGroupPoliciesByGroupIdResolver
            }
          },
          {
            path: 'policies',
            data: { activeTab: { Key: 'policies', Value: 'Policies' } },
            children: [
              {
                path: '',
                component: ModifySmallGroupComponent,
                pathMatch: 'full',
                resolve: {
                  agentInformation: GetAgentInformationByUserResolver,
                  groupPolicy: GetSmallGroupQuoteByBookingIdResolver,
                  policies: GetGroupPoliciesByGroupIdResolver
                }
              },
              {
                path: 'modify-individual-policy/:bookingId',
                component: IndividualPolicyComponent,
                resolve: {
                  booking: GetSmallGroupQuoteByBookingIdResolver,
                  destinations: DestinationsResolver,
                  suppliers: GetSuppliersResolver,
                  agencyProducts: AgencyProductsResolver
                }
              },
              {
                path: 'add-new-policy',
                component: IndividualPolicyComponent,
                resolve: {
                  destinations: DestinationsResolver,
                  suppliers: GetSuppliersResolver,
                  booking: GetNewBookingResolver,
                  agencyProducts: AgencyProductsResolver
                }
              }
            ]
          },
          {
            path: 'agent-payment',
            component: ModifySmallGroupComponent,
            data: { activeTab: { Key: 'agent-payment', Value: 'Agent Payment' } },
            resolve: {
              groupPolicy: GetSmallGroupQuoteByBookingIdResolver,
              policies: GetGroupPoliciesByGroupIdResolver
            }
          }
        ]
      }
    ]
  },
  { path: 'login', component: LoginComponent, canActivate: [CanActivateGuard] },
  {
    path: 'queues', component: QueuesComponent,
    canActivate: [CanActivateGuard],
    resolve: {
      AgencySettings: AdminGetAgencySettingsResolver
    }
  },
  { path: 'printoremail', component: PrintOrEmailComponent, canActivate: [CanActivateGuard] },
  { path: 'help', component: HelpComponent, canActivate: [CanActivateGuard] },
  { path: 'switchagency', component: SwitchAgencyComponent, canActivate: [CanActivateGuard] },
  { path: 'become-a-partner', component: BecomeAPartnerComponent },
  { path: 'glossary', component: GlossaryComponent, canActivate: [CanActivateGuard] },
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [CanActivateGuard],
    children: [
      {
        path: 'quotemax', component: NotificationTabComponent,
        resolve: { notifications: NotificationsResolver },
        data: { description: NotificationDescription.QuoteMax }
      },
      {
        path: 'maxmail', component: NotificationTabComponent,
        resolve: { notifications: NotificationsResolver },
        data: { description: NotificationDescription.MaxMail }
      },
      {
        path: 'pre-ex-expiring', component: NotificationTabComponent,
        resolve: { notifications: NotificationsResolver },
        data: { description: NotificationDescription.PreExExpiring }
      }
    ]
  },
  { path: 'becomemore', component: BecomeMoreComponent },
  { path: 'createaccount', component: CreateNewAccountComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'validateemail/:pendingemailtoken', component: ValidateSecurityComponent },
  { path: 'validatesecurity', component: ValidateSecurityComponent },
  { path: 'validateemailadmin/:pendingemailtoken',
    component: ValidateSecurityComponent,
    canActivate: [CanActivateWithRedirectGuard],
    resolve: {
      agentInformation: GetAgentInformationByUserResolver,
      canManageGroups: GetAllowManageGroupStatusByUserByUserResolver,
    }
  },
  {
    path: 'amofromclient/:emailaddress/:agencyid',
    component: GenericClientValidationComponent,
    resolve: {
      createWebAccount: CreateWebAccountResolver
    }
  },
  {
    path: 'resetpasswordbyadmin/:emailaddress/:agentaccountid',
    component: GenericClientValidationComponent,
    resolve: {
      resetPasswordByAdmin: ResetPasswordByAdminResolver
    }
  },
  {
    path: 'activatewebaccountbyadmin/:emailaddress/:agentaccountid/:agencyid',
    component: GenericClientValidationComponent,
    resolve: {
      activateWebAccountByAdmin: ActivateWebAccountByAdminResolver
    }
  },
  {
    path: 'brochure',
    component: BrochureComponent,
    canActivate: [CanActivateGuard],
    resolve: {
      agentInformation: GetAgentInformationByUserResolver
    }
  },
  { path: 'session-time-out', component: SessionTimeOutComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }), SharedModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
