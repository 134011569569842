"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_field_class_1 = require("./input-field.class");
var RentalCarInformation = /** @class */ (function () {
    function RentalCarInformation() {
        this.FormattedEndDate = new input_field_class_1.InputField();
        this.FormattedStartDate = new input_field_class_1.InputField();
        this.NumberOf24HrPeriods = new input_field_class_1.InputField();
    }
    return RentalCarInformation;
}());
exports.RentalCarInformation = RentalCarInformation;
