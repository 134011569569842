// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Models
import { Booking } from '@allianz/agent-max-core-lib';

// Serivces
import { AppStateService } from '../services/app-state.service';
import { QuoteService } from 'src/app/api/services/quote.service';

// Third Party
import { Observable, of } from 'rxjs';

@Injectable()
export class GetSmallGroupQuoteByBookingIdResolver implements Resolve<Observable<Booking>> {
  constructor(
    private appStateService: AppStateService,
    private quoteService: QuoteService
  ) { }
  resolve(): Observable<Booking> {
    return this.appStateService.getCurrentBookingId() ? this.quoteService.getSmallGroupQuoteByID(this.appStateService.getCurrentBookingId(), this.appStateService.getCredentials()) : of(this.appStateService.getCurrentBookingQuote());
  }
}
