// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Models
import { State } from '@allianz/agent-max-core-lib';

// Serivces
import { UniversalConfigService } from 'src/app/api/services/universal-config.service';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class GetStatesResolver implements Resolve < Observable < State[] >> {
  constructor(private universalConfigServcie: UniversalConfigService) { }
  resolve(): Observable<State[]> {
    return this.universalConfigServcie.getDestinations();
  }
}
