// Angular Core
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

// Models
import { SimpleBooking } from 'src/app/models/classes';
import { Booking, TripInformation, TravelerInformation, State, ErrorMessage, AgencyProducts, AgeType } from '@allianz/agent-max-core-lib';
import { UserInformation } from 'src/app/models/interfaces';

// Constants
import { environment } from 'src/environments/environment';

// Services
import { UtilityService } from 'src/app/shared/services/utility.service';
import { QuoteService } from 'src/app/api/services/quote.service';
import { AppStateService } from '../../services/app-state.service';
import { LoadingService } from 'src/app/api/services/loading.service';

// Components
import { SupplierListComponent } from '../supplier-list/supplier-list.component';

// Third Party
import { Subscription } from 'rxjs';
import { finalize, } from 'rxjs/operators';

@Component({
  selector: 'app-quote-form',
  templateUrl: './quote-form.component.html',
  styleUrls: ['./quote-form.component.scss']
})
export class QuoteFormComponent implements OnInit, OnDestroy {
  // TODO: trip-information.component.ts and quote-form.component.ts need to be combined into a single shared component
  @ViewChild('supplierListComponent', { static: false })
  public supplierListComponent: SupplierListComponent;
  // @Output() passSupplierListToGroupDetails: EventEmitter<SupplierListComponent> = new EventEmitter<SupplierListComponent>();

  public dobMask: Array<string | RegExp> = this.utilityService.dobMask;
  public ageMask: Array<string | RegExp> = this.utilityService.ageMask;
  regexPattern: string = '(\\d+)(,\\d{3})*(\\.\\d{1,2})?$';
  validationMessages: ErrorMessage[] = [];
  GetQuoteForm: FormGroup;
  GroupName: FormControl = new FormControl('');
  Destinations: FormControl = new FormControl('');
  Suppliers: FormArray = this.formBuilder.array([]);
  StateOfResidence: FormControl = new FormControl('');
  DepartureDate: FormControl = new FormControl('', [this.utilityService.dateValidator()]);
  ReturnDate: FormControl = new FormControl('', [this.utilityService.dateValidator()]);
  InitialDepositDate: FormControl = new FormControl('', [this.utilityService.dateValidator()]);
  TotalTripCost: FormControl = new FormControl('', [Validators.pattern(this.regexPattern)]);
  Ages: FormArray = this.formBuilder.array([this.createInput(AgeType.Int)], [Validators.minLength(1), Validators.maxLength(8)]);
  DateOfBirths: FormArray = this.formBuilder.array([this.createInput(AgeType.DOB)], [Validators.minLength(1), Validators.maxLength(8)]);
  AgeDOB: FormControl = new FormControl(AgeType.Int, [Validators.required]);
  TravelerAges: FormControl = new FormControl();
  showMoreLess: boolean = true;
  isOpen: boolean = true;
  isPopoverOpen: boolean = false;
  responsePayload: Booking;
  isProductPage: boolean = false;
  isSmallGroup: boolean = false;
  isModifySmallGroup: boolean = false;
  datePipe: DatePipe = new DatePipe('en-US');
  @Input('destinations') destinationList: State[];
  @Input('states') states: State[];
  @Input('model') TripInformation: TripInformation;
  departureMinDate: Date = new Date();
  returnMinDate: Date = new Date();
  depositMaxDate: Date = new Date();
  isDesktop: boolean;
  @Output() agencyProducts: EventEmitter<AgencyProducts> = new EventEmitter<AgencyProducts>();
  subscriptions: Subscription[] = [];
  groupNameInValid: boolean = false;
  QuoteForm: string = 'quoteForm';

  constructor(
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private quoteService: QuoteService,
    private appStateService: AppStateService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    this.isProductRoute();
    this.isSmallGroupRoute();
    this.GetQuoteForm = this.formBuilder.group({
      GroupName: this.GroupName,
      Destinations: this.Destinations,
      StateOfResidence: this.StateOfResidence,
      DepartureDate: this.DepartureDate,
      ReturnDate: this.ReturnDate,
      InitialDepositDate: this.InitialDepositDate,
      TotalTripCost: this.TotalTripCost,
      AgeDOB: this.AgeDOB,
      Ages: this.Ages,
      DateOfBirths: this.DateOfBirths,
      TravelerAges: this.TravelerAges
    });
  }

  ngOnInit(): void {
    // get the quote from home page and prefill the quote form
    this.departureMinDate.setDate(this.departureMinDate.getDate() + 1);
    this.returnMinDate.setDate(this.returnMinDate.getDate() + 1);
    this.subscriptions.push(
      this.utilityService.getSize().subscribe((isDesktop: boolean) => this.isDesktop = isDesktop)
    );
    if (this.isProductPage) {
      this.setUpProductRoute();
    } else if (this.isSmallGroup) {
      this.setUpSmallGroup();
    }

    if (this.supplierListComponent) {
      this.subscriptions.push(
        this.supplierListComponent.SupplierListForm.valueChanges.subscribe(() => {
          if (this.supplierListComponent.SupplierListForm.dirty) {
            this.TripInformation.ValidationMessages.length = 0;
          }
        })
      );
    }

    this.subscriptions.push(
      this.GetQuoteForm.valueChanges.subscribe(() => {
        if (this.GetQuoteForm.dirty) {
          this.validationMessages.length = 0;
        }
      })
    );
  }

  /**
   * Destroys the subscription for isDesktop
   * @returns {void}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((res) => res.unsubscribe());
  }

  /**
   * Toggle if the accordion is open
   * @param {boolean} open
   * @returns {void}
   */
  isOpenChange(open: boolean): void {
    this.isOpen = open;
  }

  /**
   * Set the new return min date based on the departure date
   * @param {Date} value
   * @returns {void}
   */
  setNewReturnMinDate(value: Date): void {
    if (value) {
      // setting the day after the departure without hours minutes second miliseconds
      this.returnMinDate = new Date(value.getFullYear(), value.getMonth(), value.getDate() + 1, 0, 0, 0);
      // Only look to check the control if the control has a value
      if (this.ReturnDate.value) {
        // temp return date without hours minutes second miliseconds
        const tempReturnDate = new Date(new Date(this.ReturnDate.value).getFullYear(), new Date(this.ReturnDate.value).getMonth(), new Date(this.ReturnDate.value).getDate() + 1, 0, 0, 0);
        // Reset the return date control if the new min return date conflicts the return date listed
        if (this.returnMinDate.getTime() >= tempReturnDate.getTime()) {
          this.ReturnDate.reset();
        }
      }
    }
  }

  /**
   * Get Quote Form add input field Age/DOB
   * @returns {void}
   */
  triggerAddField(): void {
    const maxInputs: number = 8;
    if (this.AgeDOB.value === AgeType.Int) {
      if (this.Ages.length < maxInputs) {
        this.addInput(AgeType.Int);
      }
    } else if (this.AgeDOB.value === AgeType.DOB) {
      if (this.DateOfBirths.length < maxInputs) {
        this.addInput(AgeType.DOB);
      }
    }
  }

  /**
   * Create form input Age or DOB
   * @param {AgeType} type - Enum to differentiate between Age and DOB
   * @returns {FormGroup} - Returns the form group object to be added to the form
   */
  createInput(type: AgeType, value?: string | number): FormGroup {
    let obj = {};
    if (type === AgeType.Int) {
      obj = { age: value || '' };
    } else if (type === AgeType.DOB) {
      obj = { dateOfBirth: value || '' };
    }
    return this.formBuilder.group(obj);
  }

  /**
   * Adds input field to the form
   * @param {AgeType} type - Enum to differentiate between Age and DOB
   * @returns {void}
   */
  addInput(type: AgeType, value?: string | number): void {
    if (type === AgeType.Int) {
      this.Ages.push(this.createInput(type, value));
    } else if (type === AgeType.DOB) {
      this.DateOfBirths.push(this.createInput(type, value));
    }
  }

  /**
   * Displays the proper age or date of birth in the form when interacting with the popover
   * @returns {string}
   */
  displayAgeDob(): string {
    let arr = [];
    if (this.AgeDOB.value === AgeType.Int) {
      arr = this.Ages.value.filter((formGroup) => formGroup.age !== '').map((value) => value.age);
    } else if (this.AgeDOB.value === AgeType.DOB) {
      arr = this.DateOfBirths.value.filter((formGroup) => formGroup.dateOfBirth !== '').map((value) => value.dateOfBirth);
    }
    return arr.join(', ');
  }

  /**
   * Toggles popover
   * @returns {void}
   */
  modifyPopover(): void {
    this.isPopoverOpen = !this.isPopoverOpen;
  }

  /**
   * Turns off popover
   * @returns {void}
   */
  disablePopover(): void {
    this.isPopoverOpen = false;
  }

  /**
   * Removes input field from the form
   * @param {number} index - The index to remove from the form
   * @param {AgeType} type - Enum to differentiate between Age and DOB
   * @returns {void}
   */
  removeInput(index: number, type: AgeType): void {
    this.isPopoverOpen = true;
    if (type === AgeType.Int) {
      if (this.Ages.length > 1 && index !== 0) {
        this.Ages.removeAt(index);
      }
    } else if (type === AgeType.DOB) {
      if (this.DateOfBirths.length > 1 && index !== 0) {
        this.DateOfBirths.removeAt(index);
      }
    }
  }

  /**
   * Creates the payload for quote form object
   * @param {Booking} responsePayload - if the form has been submitted once and errors are returned
   * @returns {Booking}
   */
  getQuoteFormPayload(responsePayload?: Booking): SimpleBooking | Booking {
    let payload;
    if (!responsePayload) {
      payload = new SimpleBooking();
    } else {
      payload = responsePayload;
    }
    payload.TravelInformation.PrimaryTraveler.Address.State.Value = this.GetQuoteForm.value.StateOfResidence || null;
    let count = 0;
    if (this.GetQuoteForm.value.AgeDOB === AgeType.Int && this.GetQuoteForm.value.Ages.length > 0) {
      if (this.GetQuoteForm.value.Ages[0].age) {
        payload.TravelInformation.PrimaryTraveler.Info.AgeIfNoBirthDate.Value = this.GetQuoteForm.value.Ages[0].age || null;
        payload.TravelInformation.PrimaryTraveler.Info.FormattedDateOfBirth.Value = '';
      }
      const tempAges = this.GetQuoteForm.value.Ages.map((ageObject) => Object.assign({}, ageObject));
      tempAges.shift();
      // For Saved GDS Quote
      if (payload.TravelInformation.OtherTravelers.length > 0 && payload.TravelInformation.OtherTravelers.length === tempAges.length) {
        tempAges.forEach((age) => {
          payload.TravelInformation.OtherTravelers[count].AgeIfNoBirthDate.Value = age.age || null;
          count++;
        });
      } else {
        if(payload.TravelInformation.OtherTravelers.length != 0)
          payload.TravelInformation.OtherTravelers.length = 0;

        tempAges.forEach((age) => {
          const traveler = new TravelerInformation();
          traveler.AgeIfNoBirthDate.Value = age.age || null;
          payload.TravelInformation.OtherTravelers.push(traveler);
        });
      }
    } else if (this.GetQuoteForm.value.AgeDOB === AgeType.DOB && this.GetQuoteForm.value.DateOfBirths.length > 0) {
      if (this.GetQuoteForm.value.DateOfBirths[0].dateOfBirth) {
        payload.TravelInformation.PrimaryTraveler.Info.FormattedDateOfBirth.Value = this.GetQuoteForm.value.DateOfBirths[0].dateOfBirth || null;
        payload.TravelInformation.PrimaryTraveler.Info.AgeIfNoBirthDate.Value = this.utilityService.calculateAge(this.GetQuoteForm.value.DateOfBirths[0].dateOfBirth) || null;
      }
      const tempOriginalBooking = this.appStateService.getCurrentBookingQuote();
      const tempDateOfBirths = this.GetQuoteForm.value.DateOfBirths.map((dateObject) => Object.assign({}, dateObject));
      tempDateOfBirths.shift();
      // For Saved GDS Quote
      if (payload.TravelInformation.OtherTravelers.length > 0 && payload.TravelInformation.OtherTravelers.length === tempDateOfBirths.length) {
        tempDateOfBirths.forEach((dateOfBirth) => {
          payload.TravelInformation.OtherTravelers[count].FormattedDateOfBirth.Value = dateOfBirth.dateOfBirth || null;
          payload.TravelInformation.OtherTravelers[count].AgeIfNoBirthDate.Value = this.utilityService.calculateAge(dateOfBirth.dateOfBirth);
          count++;
        });
      } else {
        if(payload.TravelInformation.OtherTravelers.length != 0)
          payload.TravelInformation.OtherTravelers.length = 0;
        tempDateOfBirths.forEach((dateOfBirth) => {
          let traveler = new TravelerInformation();
          traveler.FormattedDateOfBirth.Value = dateOfBirth.dateOfBirth || null;
          traveler.AgeIfNoBirthDate.Value = this.utilityService.calculateAge(dateOfBirth.dateOfBirth);
          payload.TravelInformation.OtherTravelers.push(traveler);
        });
      }
    }

    payload.TripInformation.FormattedDepartureDate.Value =
      this.datePipe.transform(this.GetQuoteForm.value.DepartureDate, 'MM/dd/yyyy') || null;
    payload.TripInformation.FormattedInitialDepositDate.Value =
      this.datePipe.transform(this.GetQuoteForm.value.InitialDepositDate, 'MM/dd/yyyy') || null;
    payload.TripInformation.FormattedReturnDate.Value = this.datePipe.transform(this.GetQuoteForm.value.ReturnDate, 'MM/dd/yyyy') || null;
    payload.TripInformation.TotalTripCost.Value = this.GetQuoteForm.value.TotalTripCost.toString().replace(',','').replace(',','.') || null;

    return payload;
  }

  /**
   * Checks the quote form date of births and matches to pre existing travelers
   * @param {TravelerInformation[]} otherTravelers
   * @param {TravelerInformation} traveler
   * @returns {TravelerInformation}
   */
  checkCurrentOtherTravelers(otherTravelers: TravelerInformation[], traveler: TravelerInformation): TravelerInformation {
    const index = otherTravelers.findIndex((person) => person.AgeIfNoBirthDate.Value === traveler.AgeIfNoBirthDate.Value);
    if (index !== -1) {
      traveler.FirstName.Value = otherTravelers[index].FirstName.Value;
      traveler.LastName.Value = otherTravelers[index].LastName.Value;
      otherTravelers.splice(index, 1);
    }
    return traveler;
  }

  /**
   * Submits the form to get quote based on user input
   * @returns {void}
   */
  onSubmit(): void {
    const payload = this.getQuoteFormPayload(this.responsePayload);
    const userCreds = this.appStateService.getCredentials();
    if (this.isProductRoute()) {
      this.getQuoteForAgencyProducts(payload, userCreds);
    } else {
      this.validateQuickQuote(payload, userCreds);
    }
  }

  /**
   * Checks if block is disabled
   * @returns {boolean}
   */
  isDisabled(): boolean {
    if (this.isProductPage || this.isSmallGroupRoute) {
      return false;
    } else {
      return this.isDesktop;
    }
  }

  /**
   * Get the accordions header text
   * @returns {string}
   */
  getAccordionHeader(): string {
    if (this.isProductPage) {
      return 'Trip Details';
    } else if (this.isSmallGroup) {
      return 'Group Details';
    } else {
      return 'Get Quote';
    }
  }

  /**
   * Open covered suppliers PDF
   * @returns {string}
   */
  openCoveredSuppliers(): string {
    return environment.SupplierURL;
  }

  /**
   * Bootstraps the form for the product route
   * @returns {void}
   */
  private setUpProductRoute(): void {
    this.isProductPage = true;
    this.responsePayload = this.appStateService.getCurrentBookingQuote();
    let isAgeOrDOB: AgeType;
    if (this.responsePayload.TravelInformation.PrimaryTraveler.Info.FormattedDateOfBirth.Value) {
      isAgeOrDOB = AgeType.DOB;
      // remove the initial empty dob from form init
      this.DateOfBirths.controls.splice(0);
      this.addInput(isAgeOrDOB, this.responsePayload.TravelInformation.PrimaryTraveler.Info.FormattedDateOfBirth.Value);
    } else {
      isAgeOrDOB = AgeType.Int;
      // remove the initial empty age from form init
      this.Ages.controls.splice(0);
      this.addInput(isAgeOrDOB, this.responsePayload.TravelInformation.PrimaryTraveler.Info.AgeIfNoBirthDate.Value);
    }
    // loop the other travelers and add them to the quote form
    this.responsePayload.TravelInformation.OtherTravelers.forEach((traveler) => {
      const value: string | number = isAgeOrDOB === AgeType.DOB ? traveler.FormattedDateOfBirth.Value : traveler.AgeIfNoBirthDate.Value;
      this.addInput(isAgeOrDOB, value);
    });

    // add other values from the booking object to the form
    this.GetQuoteForm.patchValue({
      StateOfResidence: this.responsePayload.TravelInformation.PrimaryTraveler.Address.State.Value,
      DepartureDate: this.responsePayload.TripInformation.FormattedDepartureDate.Value,
      ReturnDate: this.responsePayload.TripInformation.FormattedReturnDate.Value,
      InitialDepositDate: this.responsePayload.TripInformation.FormattedInitialDepositDate.Value,
      TotalTripCost: ((Math.round((this.responsePayload.TripInformation.TotalTripCost.Value)*100)/100).toFixed(2)).toString(),
      AgeDOB: isAgeOrDOB
    });
    this.GetQuoteForm.markAsPristine();
    if (this.route.snapshot.data.agencyProducts.ProgramProducts.length === 0) {
      this.onSubmit();
    }
  }

  /**
   * Checks the route for product
   * @returns {boolean}
   */
  private isProductRoute(): boolean {
    return this.isProductPage = this.router.url === '/product';
  }

  /**
   * Checks to see if route is small group
   * @returns {boolean}
   */
  private isSmallGroupRoute(): boolean {
    this.isModifySmallGroup = this.router.url.indexOf('modifyGroup') !== -1;
    return this.isSmallGroup = this.router.url.indexOf('/small-group') !== -1;
  }

  /**
   * Set up the small group
   * @returns {void}
   */
  private setUpSmallGroup(): void {
    if (this.isModifySmallGroup) {
      this.responsePayload = this.appStateService.getCurrentBookingQuote();
      this.GetQuoteForm.patchValue({
        GroupName: this.responsePayload.GroupName.Value,
        DepartureDate: this.responsePayload.TripInformation.FormattedDepartureDate.Value,
        ReturnDate: this.responsePayload.TripInformation.FormattedReturnDate.Value,
        InitialDepositDate: this.responsePayload.TripInformation.FormattedInitialDepositDate.Value,
        Destinations: this.responsePayload.TripInformation.Destination.Value,
      });
    }
  }

  /**
   * Submits quotes on home page for products
   * @param {SimpleBooking | Booking} payload
   * @param {UserInformation} creds
   * @returns {void}
   */
  private validateQuickQuote(payload: SimpleBooking | Booking, creds: UserInformation): void {
    this.loadingService.open();
    this.subscriptions.push(
      this.quoteService.validateQuickQuote(payload, creds)
        .pipe(finalize(() => this.loadingService.close()))
        .subscribe((booking) => {
          this.validationMessages = [];
          this.responsePayload = booking;
          if (booking.HasValidationErrors === false && booking.IsValid) {
            this.appStateService.setCurrentBookingQuote(booking);
            if (!this.isProductPage) {
              this.router.navigate(['product']);
            }
          } else {
            this.validationMessages = this.validationMessages.concat(booking.TripInformation.ValidationMessages)
              .concat(booking.TravelInformation.ValidationMessages)
              .concat(booking.ErrorMessages);
          }
      })
    );
  }

  /**
   * submits the quote on product page
   * @param {SimpleBooking | Booking} payload
   * @param {UserInformation} creds
   * @returns {void}
   */
  private getQuoteForAgencyProducts(payload: SimpleBooking | Booking, creds: UserInformation): void {
    this.loadingService.open();
    this.subscriptions.push(
      this.quoteService.getQuoteForAgencyProducts(payload, creds)
        .subscribe((agencyProducts) => {
          this.validationMessages = [];
          if (agencyProducts.Quote.HasValidationErrors === false && agencyProducts.Quote.IsValid) {
            this.appStateService.setCurrentBookingQuote(agencyProducts.Quote);
            this.agencyProducts.emit(agencyProducts);
          } else {
            this.validationMessages = this.validationMessages.concat(agencyProducts.Quote.TripInformation.ValidationMessages)
              .concat(agencyProducts.Quote.TravelInformation.ValidationMessages)
              .concat(agencyProducts.Quote.ErrorMessages);
          }
        },
        (error) => this.loadingService.close(),
        () => this.loadingService.close())
    );
  }

  /**
   * Check if the text typed on Group Name has the character pipe '|'.
   * If so, then not allow user to proceed
   * @returns {string}
   */
  checkText() {
    if(this.GetQuoteForm.controls['GroupName'].value.includes('|')) {
      var errorMessage: ErrorMessage = {
        Code: '0',
        Description: "Invalid character use; please correct/update to Save the record.",
      };
      this.validationMessages.push(errorMessage);
    }

  }

}
