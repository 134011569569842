// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Models
import { AgencyProducts } from '@allianz/agent-max-core-lib';

// Serivces
import { AppStateService } from '../services/app-state.service';
import { QuoteService } from 'src/app/api/services/quote.service';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class GetQuoteForAgencyProductsResolver implements Resolve<Observable<AgencyProducts>> {
  constructor(
    private appStateService: AppStateService,
    private quoteService: QuoteService
  ) { }
  resolve(): Observable<AgencyProducts> {
    return this.quoteService.getQuoteForAgencyProducts(this.appStateService.getCurrentBookingQuote(), this.appStateService.getCredentials());
  }
}
