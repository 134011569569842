// Angular Core
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UserIdleService } from 'angular-user-idle';
// Models
import { ErrorMessage } from '@allianz/agent-max-core-lib';
// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { UserService } from 'src/app/api/services/user.service';
import { NotificationService } from '../api/services/notification.service';
import { GdsService } from 'src/app/api/services/gds.service';
import { InterfaceSettings } from '../models/interfaces';
import { AdminUserService } from '../api/services/adminUser.service';
import { Guid } from 'guid-typescript';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  ValidationMessages: ErrorMessage[] = [];
  LoginForm: FormGroup;
  UserName: FormControl = new FormControl('', [Validators.required]);
  Password: FormControl = new FormControl('', [Validators.required]);
  RememberMe: FormControl = new FormControl(false);
  redirectUrl: string;
  guid: string = null;
  bookingid: string = null;
  interfaceSettings: InterfaceSettings;
  public showPassword: boolean;

  constructor(
    private appStateService: AppStateService,
    private userService: UserService,
    private notificationService: NotificationService,
    private gdsService: GdsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private userIdle: UserIdleService,
    private adminuserService: AdminUserService
  ) {
    this.LoginForm = this.formBuilder.group({
      UserName: this.UserName,
      Password: this.Password,
      RememberMe: this.RememberMe
    });
  }

  ngOnInit(): void {
    const rememberMe = this.appStateService.getRememberMeInfo();
    if (rememberMe) {
      this.LoginForm.patchValue({
        UserName: rememberMe,
        RememberMe: true
      });
    }
    if (this.activatedRoute.snapshot.queryParams.GUID) {
      this.appStateService.setGUID(this.activatedRoute.snapshot.queryParams.GUID);
    }
    if (this.activatedRoute.snapshot.queryParams.BookingID) {
      this.appStateService.setLatestGDSBookingId(this.activatedRoute.snapshot.queryParams.BookingID);
    }

    this.redirectUrl = this.activatedRoute.snapshot.queryParams['redirectUrl'] || '';
  }

  /**
   * Login button to validate credentials
   * @returns {void} - returns the Validation message as and Array of strings
   */
  onLogin(): void {
    this.validateLogin();
  }

  /**
   * Create Account
   * @returns {void} - navigates to create account page
   */
  onCreateAccount(): void {
    this.router.navigate(['createaccount']);
  }

  /**
   * Set user settings on successful login
   * @param {UserInformation} userInformation
   * @returns {void}
   */
  setUserSettingsOnSuccessfulLogin(userInformation): void {
    this.appStateService.setCredentials(userInformation);
    this.appStateService.setReportingRight(userInformation.HasReportingRight);
    this.appStateService.setPassportRight(userInformation.HasPassportAccess);
  }

  startWatching() {
    this.userIdle.startWatching();
    //console.log('start!!');
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(() => console.log('check'));
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(()=> {
       if (!this.appStateService.checkTimeStampisValid()) {
        this.userIdle.stopWatching();
        this.userIdle.stopTimer();
        const userInfo = this.appStateService.getCredentials();
        this.userService.logout(userInfo).subscribe((response) => {
          if (response.Success) {
            this.router.navigate(['session-time-out']);
          }
          else {
            console.error(response);
          }
        });
      }
    });
  }

  /**
   * Validate Login
   * @returns {void} - saves the user information into session and passes back the validation message
   */
  validateLogin(): void {
    this.userService.login(this.LoginForm.value)
      .subscribe((loginResponse) => {
        if (loginResponse.ErrorMessages.length === 0) {
          if (this.LoginForm.value.RememberMe) {
            this.appStateService.setRememberMeInfo(this.LoginForm.value.UserName);
          } else {
            this.appStateService.setRememberMeInfo(null);
          }
          this.appStateService.setIsSuperAdmin(loginResponse.IsRequestSuperAdminAccess);
          this.appStateService.setFullAccess(loginResponse.HasFullAccess);
          this.appStateService.setAdminStatus(loginResponse.IsAdmin);
          this.appStateService.setCredentials(loginResponse);
          this.appStateService.setIsConfirmationEmailedToAgent(loginResponse.IsConfirmationEmailedToAgent);
          this.appStateService.setStorageTimeStamp();
          this.notificationService.getNotificationCount().subscribe((response) => {
            this.appStateService.triggerNotificationCountStatus(response.Count);
            this.appStateService.setAllowNotifications(response.AllowNotifications);
          });
          this.adminuserService.getGDSInterfaceSettings(loginResponse.AgencyID, this.appStateService.getCredentials())
            .subscribe((response) => {
              if (response.ErrorMessages.length === 0) {
                this.interfaceSettings = response;
                response.GDSInterfaceSettings.forEach( (element) => {
                  if(element.DataSource.startsWith('Apollo') && element.IsEnabled === true) {
                    this.appStateService.setGUID(Guid.create().toString());
                    this.appStateService.setApolloSettings(true);
                  }
                  else{
                    this.appStateService.setApolloSettings(false);
                  }
              });
              }
            })
          this.appStateService.setReportingRight(loginResponse.HasReportingRight);
          this.appStateService.setPassportRight(loginResponse.HasPassportAccess);
          this.appStateService.setStorageTimeStamp();
          this.startWatching();
          if (this.appStateService.getGUID()) {
            this.gdsService.registerGDSLogin(loginResponse, this.appStateService.getGUID())
            .subscribe((response) => {
              if (response.ErrorMessages.length === 0) {
                this.router.navigate(['home']);
              } else {
                this.ValidationMessages = response.ErrorMessages;
              }
            });
          } else {
            if(this.redirectUrl !== ''){
              this.router.navigateByUrl(this.redirectUrl);
            }
            else
              this.router.navigate(['home']);
          }
        } else {
          this.ValidationMessages = loginResponse.ErrorMessages;
          if (this.ValidationMessages[0].Code == '1069') {
            this.router.navigate(['/forgotpassword'], { queryParams: { maxLoginAttemptsExceeded: '1', emailAddress: this.LoginForm.value.UserName } });
          }
        }
      });
  }
}
