// Angular Core
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

// Models
import { AdminUserOptions } from 'src/app/models/interfaces';

// Serivces
import { AppStateService } from '../services/app-state.service';

// Third Party
import { Observable } from 'rxjs';
import { AdminUserService } from 'src/app/api/services/adminUser.service';

@Injectable()
export class AdminGetUserOptionsResolver implements Resolve<Observable<AdminUserOptions>> {
  constructor(
    private appStateService: AppStateService,
    private adminUserService: AdminUserService
  ) { }
  resolve(route: ActivatedRouteSnapshot): Observable<AdminUserOptions> {
    return this.adminUserService.getUserOptions(route.params.agentId);
  }
}
