// Angular Core
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// RxJS Subscriptions
import { Subscription } from 'rxjs';

// Models
import { AgencySettings } from 'src/app/models/interfaces/agency-settings.interface';
import { AgencyGroup } from 'src/app/models/interfaces/agency-group.interface';

// Services
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';

@Component({
  selector: 'app-agency-settings-group',
  templateUrl: './agency-settings-group.component.html',
  styleUrls: ['./agency-settings-group.component.scss']
})
export class AgencySettingsGroupComponent implements OnInit, OnDestroy {
  isDesktop: boolean;
  isLoaded: boolean = false;
  adminSubscriptions: Subscription[] = [];
  agencySettings: AgencySettings;
  agencyGroup: AgencyGroup;

  constructor(
    private adminuserService: AdminUserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appStateService: AppStateService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe( (paramMap) => {
      const agencyId = paramMap.get('agencyId');
      const groupId = paramMap.get('groupId');
      if (!agencyId) {
        this.router.navigate(['admin']);
      }
      const nAgencyId = Number(agencyId);
      const nGroupId = Number(groupId);
      this.getAgencyGroupSettings(nGroupId);
    });
  }

  /**
   * Get a list of agency groups for the parent agency in admin
   * @param {number} groupId-The group ID used for the groups
   * @returns {void}
   */
  getAgencyGroupSettings(groupId: number): void {
    this.adminuserService.getAgencyGroupsForAgency().subscribe( (agencyGroupsResponse) => {
      this.agencyGroup = agencyGroupsResponse.AgencyGroupList.find( (agencyGroup) => {
        return agencyGroup.SharedAgencySettingsId === groupId;
      });
      if (this.agencyGroup) {
        this.adminuserService.getAgencySettings(this.agencyGroup.AgencyId, this.appStateService.getCredentials())
          .subscribe((agencySettingsResponse) => {
            this.agencySettings = agencySettingsResponse;
            this.isLoaded = true;
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.adminSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
