// Angular Core
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { PolicyService } from 'src/app/api/services/policy.service';
import { SharedBusinessLogic } from 'src/app/shared/services/shared-business-logic.service';
import { ReportService } from 'src/app/api/services/report.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

// Models
import { Queue, PrintOrEmailOptions } from 'src/app/models/interfaces';
import { ErrorMessage, BookingTypes, Booking, FileTypes } from '@allianz/agent-max-core-lib';
import { PrintOrEmailReportType, QueueTypes, ConfirmationViewType, PrintOrEmailDocumentType, PrintOrEmailTypes } from 'src/app/models/enums';

// Third Party
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirmed',
  templateUrl: './confirmed-tab.component.html',
  styleUrls: ['./confirmed-tab.component.scss']
})
export class ConfirmedComponent implements OnInit, OnDestroy {
  Queues: Queue[] = [];
  @Input() confirmationMenuErrors: ErrorMessage[] = [];
  confirmedTab: Subscription[] = [];
  queueType: QueueTypes;

  constructor(
    private policyService: PolicyService,
    private reportService: ReportService,
    private appStateService: AppStateService,
    private utilityService: UtilityService,
    private router: Router,
    private sharedBusinessLogic: SharedBusinessLogic
  ) { }

  ngOnInit(): void {
    this.queueType = this.appStateService.getQueueType();
  }

  /**
   * empties the ConfirmationMenuErrors array
   * @returns {void}
   */
  clearErrors(): void {
    this.confirmationMenuErrors = [];
  }

  /**
   *  calls the quoteService to get a booking based on the booking id
   * this will route to
   * @param {string} bookingID
   * @returns {void}
   */
  onOpenPolicy(bookingID: string): void {
    this.appStateService.setCurrentBookingId(bookingID);
    this.appStateService.setConfirmationViewType(ConfirmationViewType.OpenConfirmation);
    this.appStateService.setBookingInfoViewType(BookingTypes.Quote);
    this.router.navigate(['purchaseconfirmation']);
  }

  /**
   * calls a shared business logic service to copy the given quote to a new one.
   * @param {string} BookingId
   * @returns {void}
   */
  onCopyToNewQuote(bookingID: string): void {
    this.confirmedTab.push(this.sharedBusinessLogic.copyToNewQuote(bookingID, this.appStateService.getQueueType()));
  }

  ngOnDestroy(): void {
    this.confirmedTab.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   *  calls the policyService to get a policy based on the booking id
   * this will route to /bookinginfo
   * @param {Queue} queueItem
   * @returns {void}
   */
  onModifyPolicy(queueItem: Queue): void {
    this.confirmedTab.push(
      this.policyService.getBookingWithCurrentPolicyData(BookingTypes.Modify, queueItem.BookingId, queueItem.ConfirmationNumber, this.appStateService.getCredentials())
        .subscribe((result) => {
          if (result.ErrorMessages.length < 1) {
            this.appStateService.setQueueType(QueueTypes.Confirmed);
            if (result.CanEditFlights) {
              this.appStateService.setBookingInfoViewType(BookingTypes.EditFlights);
              this.appStateService.setBookingInfoSourceType(BookingTypes.EditFlights);
            } else {
              this.appStateService.setBookingInfoViewType(BookingTypes.Policy);
              this.appStateService.setBookingInfoSourceType(BookingTypes.Modify);
            }
            this.appStateService.setCurrentBookingId(null);
            this.appStateService.setCurrentBookingQuote(result.Booking);
            this.router.navigate(['bookinginfo']);
          } else {
            this.confirmationMenuErrors = result.ErrorMessages;
          }
        }));
  }

  /**
   * On email policy from confirmed tab
   * @param {Queue} queueItem
   * @returns {void}
   */
  onEmailPolicy(queueItem: Queue): void {
    const currentBooking = new Booking();
    currentBooking.BookingID = queueItem.BookingId;
    currentBooking.PolicyNumber = queueItem.ConfirmationNumber;
    currentBooking.PrimaryInsuredName = queueItem.PrimaryInsured;
    this.setUpPrintEmail(currentBooking);
  }

  /**
   * Navigates to print email route after
   * setting up the booking and report name
   * @returns {void}
   */
  onPrintPolicy(bookingID: string): void {
    const bookingParam = new Booking();
    bookingParam.BookingID = bookingID;
    this.confirmedTab.push(
      this.reportService.viewOrEmailBookingDocument('View', PrintOrEmailReportType.TravelInsuranceReceipt, bookingParam, {}, this.appStateService.getCredentials())
        .subscribe((response) => {
          if (response.IsValid) {
            this.utilityService.openFile(response.FileContent, FileTypes.PDF);
          } else {
            this.confirmationMenuErrors = response.ErrorMessages;
          }
        }));
  }
  /**
   * On cancel policy
   * @param {Queue} queueItem
   * @returns {void}
   */
  onCancelPolicy(queueItem: Queue): void {
    this.policyService.getBookingWithCurrentPolicyData(BookingTypes.Cancel, queueItem.BookingId, queueItem.ConfirmationNumber, this.appStateService.getCredentials())
      .subscribe((result) => {
        if (result.ErrorMessages == null || result.ErrorMessages.length === 0) {
          if (result.ShowMessageBeforeCancel !== undefined && result.ShowMessageBeforeCancel !== null
            && result.ShowMessageBeforeCancel !== '') {
            window.alert(result.ShowMessageBeforeCancel);
          }
          this.appStateService.setCurrentBookingId(null);
          this.appStateService.setCurrentBookingQuote(result.Booking);
          this.appStateService.setConfirmationViewType(ConfirmationViewType.Cancel);
          this.appStateService.setQueueType(this.queueType);
          this.router.navigate(['purchaseconfirmation']);
        } else {
          this.confirmationMenuErrors = result.ErrorMessages;
        }
      });
  }
  /*
   * Sets up the print email options and routes to the print email page
   * @param {Booking} booking
   * @returns {void}
   */
  private setUpPrintEmail(booking: Booking): void {
    const agentInfo = this.appStateService.getAgentInformation();
    const options: PrintOrEmailOptions = {
      HeaderName: 'Email Purchase Receipt / Policy Confirmation',
      DocumentType: PrintOrEmailDocumentType.BookingDocument,
      ReportType: PrintOrEmailReportType.TravelInsuranceReceipt,
      BookingId: booking.BookingID,
      PolicyNumber: booking.PolicyNumber,
      ShowResendFulfillment: true,
      ShowViewButton: false,
      EmailInput: {
        FromEmailAddress: agentInfo.EmailAddress,
        FromName: `${agentInfo.FirstName} ${agentInfo.LastName}`,
        ToName: booking.PrimaryInsuredName,
        ToEmailAddress: '',
        SecondaryEmailAddress: ''
      }
    };
    this.appStateService.setPrintOrEmailReportName(PrintOrEmailTypes.PurchaseReceiptAndConfirmation);
    this.appStateService.setPrintOrEmailData(booking, options);
    this.appStateService.setQueueType(QueueTypes.Confirmed);
    this.router.navigate(['printoremail']);
  }
}
