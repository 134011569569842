"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ConfirmationDetails = /** @class */ (function () {
    function ConfirmationDetails() {
        this.Policies = new Array();
        this.FormattedPurchaseDate = '';
        this.PaymentType = '';
        this.CardNumber = '';
        this.ReceiptNumber = '';
        this.FulfillmentMethod = '';
        this.FulfillmentPrimaryEmail = '';
        this.FulfillmentSecondaryEmail = '';
        this.Email = '';
        this.DepartureDate = '';
        this.ReturnDate = '';
        this.MailAddress = '';
        this.Price = 0;
    }
    return ConfirmationDetails;
}());
exports.ConfirmationDetails = ConfirmationDetails;
