// Angular Core
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
// Models
import { Agency, AdminProducts, AdminProduct } from 'src/app/models/interfaces';
import { AlertType } from 'src/app/models/enums';
// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AdminUserService } from 'src/app/api/services/adminUser.service';
// Core library
import { ErrorMessage, ProgramProduct } from '@allianz/agent-max-core-lib';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  validationMessages: ErrorMessage[] = [];
  headerText: string;
  agency: Agency;
  agencies: Agency[];
  adminProductResponse: AdminProducts;
  allListedProducts: AdminProduct[] = [];
  adminProductForm: FormGroup;
  selectedProductArray: FormArray = this.formBuilder.array([]);
  availableProductArray: FormArray = this.formBuilder.array([]);
  displayNewProducts: FormControl = new FormControl();
  selectedAvailableItems: FormArray = this.formBuilder.array([]);
  selectedListItems: FormArray = this.formBuilder.array([]);
  agencyId: number;
  isLoaded: boolean = false;
  groupId: number;
  updateSuccessful: boolean = false;
  adminProductFormNotChanged: boolean = null;
  alertType: typeof AlertType = AlertType;
  programProduct: ProgramProduct;
  programProductListId: number[] = [];
  enableUpdateButton = false;
  hideNewProducts = null;

  constructor(
    private appStateService: AppStateService,
    private router: Router,
    private adminUserService: AdminUserService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    if (router.getCurrentNavigation().extras.state) {
      this.agencies = router.getCurrentNavigation().extras.state.agencies;
    }

    this.adminProductForm = this.formBuilder.group({
      selectedProductArray: this.selectedProductArray,
      availableProductArray: this.availableProductArray,
      displayNewProducts: this.displayNewProducts
    });
  }

  ngOnInit(): void {
    if (!this.agencies) {
      this.adminUserService.getAgenciesForAdministration().subscribe((response) => {
        this.agencies = response.Agencies;
      });
    }
    this.activatedRoute.paramMap.subscribe( (paramMap) => {
      this.agencyId = parseInt(paramMap.get('agencyId'), 10);
      this.groupId = parseInt(paramMap.get('groupId'), 10);
      if (isNaN(this.groupId)) {
        this.groupId = 0;
      }
    });
    if (this.agencies) {
      this.agency = this.agencies.find((agency) => agency.AgencyID === this.agencyId);
    }
    this.adminUserService.getProductsForAdmin(this.agencyId, this.appStateService.getCredentials()).subscribe((response) => {
      this.adminProductResponse = response;
      if (this.groupId !== 0) {
        this.headerText = `Group ${this.groupId}`;
      } else {
        this.headerText = `${this.agency.LongName} (${this.agency.ACCAM})`;
      }
      this.initSelectedProductArray();
      this.initAvailableProductArray();
      this.adminProductForm.patchValue({
        displayNewProducts: response.HideNewProducts
      });
      this.isLoaded = true;
    });
    this.adminProductForm.markAsPristine();
    this.adminProductForm.markAsUntouched();    
  }

  /**
   * If user clicks displayNewCheckbox Update Successful message and disable Update button
   * @returns {void}
   */
  displayNewCheckboxClicked($event): void {
    this.adminProductForm.patchValue({
      displayNewProducts: $event.target.checked
    });
    this.adminProductFormNotChanged = false;
    this.enableUpdateButton = true;
  }

  /**
   * Initializes the selected products array and fills it with product information
   * @returns {void}
   */
  initSelectedProductArray(): void {
    for (const item of this.adminProductResponse.AgencySelectedProducts) {
      const selectedProduct = this.formBuilder.group({
        productId: item.PsProductId,
        productName: item.ProgramProductName,
        isNewProduct: item.IsNewProduct,
        itemSelected: false
      });

      if (selectedProduct.value.isNewProduct) {
        selectedProduct.patchValue({
          productName: `*NEW* ${selectedProduct.value.productName}`
        });
      }

      this.selectedProductArray.push(selectedProduct);
      this.allListedProducts.push(item);
    }
  }

  /**
   * Initializes the available products array and fills it with product information
   * @returns {void}
   */
  initAvailableProductArray(): void {
    for (const item of this.adminProductResponse.AgencyRejectedProducts) {
      const availableProduct = this.formBuilder.group({
        productId: item.PsProductId,
        productName: item.ProgramProductName,
        // only needs to be unavailable on rejected products (no product
        // will be unavailable in selected based on backend implementation)
        isUnavailable: item.IsUnavailable,
        isNewProduct: item.IsNewProduct,
        itemSelected: false
      });
      if (availableProduct.value.isUnavailable) {
        availableProduct.patchValue({
          productName: `*UNAVAILABLE* ${availableProduct.value.productName}`
        });
      }

      if (availableProduct.value.isNewProduct) {
        availableProduct.patchValue({
          productName: `*NEW* ${availableProduct.value.productName}`
        });
      }

      if(!availableProduct.value.isUnavailable) {
        this.availableProductArray.push(availableProduct);
        this.allListedProducts.push(item);
      }      
    }
  }

  /**
   * When a user selects an item in either the selected or available products
   * this function adds the selection to the destination array to be displayed to the user
   * or removes it from the destination array
   * @param {FormArray} destination the array to send the selected product(s) to
   * @param {FormGroup} selection the current item selected in either available or selected products
   * @returns {void}
   */
  toggleSelection(destination: FormArray, selection: FormGroup): void {
    this.adminProductForm.markAsDirty();
    this.adminProductFormNotChanged = false;
    selection.value.itemSelected = !selection.value.itemSelected;
    var programProductId = this.allListedProducts.find(x => x.PsProductId === selection.value.productId).ProgramProductId;
    
    if (selection.value.itemSelected && programProductId) {
      destination.push(selection);
      this.programProductListId.push(programProductId);
    } else {
      destination.removeAt(destination.controls.findIndex((selectionToRemove) => selectionToRemove.value.productId === selection.value.productId));
      let index = this.programProductListId.findIndex(d => d === selection.value.productId);
      this.programProductListId.splice(index, 1);  
      this.programProduct = null;    
    }

    if(this.programProductListId.length > 0){
      this.adminUserService.getProgramProductDetails(this.programProductListId, this.appStateService.getCredentials())
      .subscribe((response) => {
        if(response[0].ErrorMessages !== null){
          this.validationMessages = response[0].ErrorMessages;
        }
        this.programProduct = response;
      });
    }
  }

  /**
   * This method moves products from the selected products array to and from the available products array.
   * Based on how the user drag and drops product selection this updates the available and selected products array based on the product selection.
   * @param {CdkDragDrop<FormControl[]>} event An event triggered by dragging and dropping a selected item
   * @returns {void}
   */
  drop(event: CdkDragDrop<FormControl[]>): void {
    this.adminProductForm.markAsDirty();
    this.adminProductFormNotChanged = false;
    this.enableUpdateButton = true;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (event.previousContainer.data[event.previousIndex].value.isNewProduct) {
        event.previousContainer.data[event.previousIndex].patchValue({
          isNewProduct: false,
          productName: event.previousContainer.data[event.previousIndex].value.productName.substring(6)
        });
      }
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  /**
   * Moves a product from the available products array to or from the selected products array
   * when the user clicks one either the < or > directional arrows.
   * @param {FormArray} startArray the array where an item is selected
   * @param {FormArray} destArray the array where the selected item will be sent to based on the directional arrow clicked
   * @returns {void}
   */
  moveProdsWithButtons(startArray: FormArray, destArray: FormArray): void {
    this.enableUpdateButton = true;
    for (let i = startArray.length - 1; i >= 0; i--) {
      if (startArray.controls[i].value.itemSelected) {
        startArray.controls[i].value.itemSelected = false;
        if (startArray.controls[i].value.isNewProduct) {
          startArray.controls[i].value.isNewProduct = false;
          startArray.controls[i].value.productName = startArray.controls[i].value.productName.substring(6);
        }
        if (!startArray.controls[i].value.isUnavailable) {
          destArray.push(startArray.controls[i]);
          startArray.controls.splice(i, 1);
        }
      }
    }
    this.selectedAvailableItems = this.formBuilder.array([]);
    this.selectedListItems = this.formBuilder.array([]);
  }

  /**
   * Updates products for a specific admin agency when the update button is clicked.
   * @returns {void}
   */
  updateProductBag(): void {
    const agencySelectedProducts: AdminProduct[] = [];
    const agencyRejectedProducts: AdminProduct[] = [];
    this.selectedProductArray.controls.forEach((selectedProduct) => {
      const allProduct = this.allListedProducts.find((allProducts) => {
        return selectedProduct.value.productId === allProducts.PsProductId;
      });
      agencySelectedProducts.push(allProduct);
    });

    this.availableProductArray.controls.forEach((availableProduct) => {
      const allProduct = this.allListedProducts.find((allProducts) => {
        return availableProduct.value.productId === allProducts.PsProductId;
      });
      agencyRejectedProducts.push(allProduct);
    });

    const agencyInfo: AdminProducts = {
      AgencyConfigId: this.adminProductResponse.AgencyConfigId,
      AgencyRejectedProducts: agencyRejectedProducts,
      AgencySelectedProducts: agencySelectedProducts,
      HideNewProducts: this.displayNewProducts.value,
      ModifiedUserId: this.adminProductResponse.ModifiedUserId
    };
    this.adminUserService.updateProductsForAdmin(agencyInfo).subscribe((response) => {
      this.validationMessages = response.ErrorMessages;
      this.updateSuccessful = this.validationMessages.length === 0 ? true : false;
      if (this.updateSuccessful) {
        this.adminProductFormNotChanged = true;
        this.enableUpdateButton = false;
      }
    });
  }
}
