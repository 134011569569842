// Angular Core
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Models
import { environment } from 'src/environments/environment';
import { UserInformation, GroupProductPayload, GroupProductResponse, RosterUploadResponse } from 'src/app/models/interfaces';
import { SimpleBooking, GetGroupPoliciesBody, RosterUploadRequest } from 'src/app/models/classes';
import { Booking, AgencyProducts, ProductPayload, ProductResponse, MaxMail2ndChanceSettings } from '@allianz/agent-max-core-lib';

// Services
import { UtilityService } from 'src/app/shared/services/utility.service';

// Third Party
import { Observable } from 'rxjs';
import { publishLast } from 'rxjs/operators';
import { refCount } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  quoteServices: string;

  constructor(
    private utilityService: UtilityService,
    private httpClient: HttpClient
  ) {
    this.quoteServices = this.utilityService.setTheDomainForAPICalls(environment.QuoteServices);
  }

  /**
   * Get Quote by booking id
   * @param {string | number} bookingId
   * @param {UserInformation} credentials
   * @returns {Observable<Booking>}
   */
  public getQuoteById(bookingId: string | number, credentials: UserInformation): Observable<Booking> {
    return this.httpClient.get<Booking>(
      `${this.quoteServices}/GetQuoteByID/${bookingId}`, { headers: this.utilityService.getServiceHeaders(credentials) }
    ).pipe(publishLast(), refCount());
  }

  /**
   * Get Small group Quote by booking id
   * @param {string | number} bookingId
   * @param {UserInformation} credentials
   * @returns {Observable<Booking>}
   */
  public getSmallGroupQuoteByID(bookingId: string | number, credentials: UserInformation): Observable<Booking> {
    return this.httpClient.get<Booking>(
      `${this.quoteServices}/GetSmallGroupQuoteByID/${bookingId}`, { headers: this.utilityService.getServiceHeaders(credentials) }
    ).pipe(publishLast(), refCount());
  }

  /**
   * brings you to the product page for a new quote
   * @param {string | number} bookingId
   * @param {UserInformation} credentials
   * @returns {Observable<Booking>}
   */
  public copyToNewQuote(bookingId: string | number, credentials: UserInformation): Observable<Booking> {
    return this.httpClient.get<Booking>(
      `${this.quoteServices}/CopyToNewQuoteByID/${bookingId}`, { headers: this.utilityService.getServiceHeaders(credentials) }
    );
  }
  /**
   * Get 2nd chance settings by booking id
   * @param {string} bookingId
   * @param {UserInformation} credentials
   * @returns {Observable<MaxMail2ndChanceSettings>}
   */
  public getMaxMailSettingsByBookingId(bookingId: string | number, credentials: UserInformation): Observable<MaxMail2ndChanceSettings> {
    return this.httpClient.get<MaxMail2ndChanceSettings>(
      `${this.quoteServices}/GetMaxMailSettingsByBookingId/${bookingId}`, { headers: this.utilityService.getServiceHeaders(credentials) }
    );
  }

  /**
   * save 2nd chance settings by booking id
   * @param {string} bookingId
   * @param {MaxMail2ndChanceSettings} maxmailSettings
   * @param {UserInformation} credentials
   * @returns {Observable<MaxMail2ndChanceSettings>}
   */
  public saveMaxMailSettingsByBookingId(bookingId: string | number, maxmailSettings: MaxMail2ndChanceSettings, credentials: UserInformation): Observable<MaxMail2ndChanceSettings> {
    return this.httpClient.put<MaxMail2ndChanceSettings>(
      `${this.quoteServices}/SaveMaxMailSettingsByBookingId/${bookingId}`, maxmailSettings, { headers: this.utilityService.getServiceHeaders(credentials) }
    );
  }

  /**
   * Validate quick quote
   * @param {SimpleBooking | Booking} payload
   * @param {UserInformation} credentials
   * @returns {Observable<Booking>}
   */
  public validateQuickQuote(payload: SimpleBooking | Booking, credentials: UserInformation): Observable<Booking> {
    return this.httpClient.put<Booking>(
      `${this.quoteServices}/ValidateQuickQuote`, payload, { headers: this.utilityService.getServiceHeaders(credentials) }
    ).pipe(publishLast(), refCount());
  }

  /**
   * Get Quote for Agency Products
   * @param {SimpleBooking | Booking} payload
   * @param {UserInformation} credentials
   * @returns {Observable<AgencyProducts>}
   */
  public getQuoteForAgencyProducts(payload: SimpleBooking | Booking, credentials: UserInformation): Observable<AgencyProducts> {
    return this.httpClient.put<AgencyProducts>(
      `${this.quoteServices}/GetQuoteForAgencyProducts`, payload, { headers: this.utilityService.getServiceHeaders(credentials) }
    ).pipe(publishLast(), refCount());
  }

  /**
   * Get Quote for Selected Products
   * @param {Booking} payload
   * @param {UserInformation} credentials
   * @returns {Observable<Booking>}
   */
  public getQuoteForSelectedProducts(payload: Booking, credentials: UserInformation): Observable<Booking> {
    return this.httpClient.put<Booking>(
      `${this.quoteServices}/GetQuoteForSelectedProducts`, payload, { headers: this.utilityService.getServiceHeaders(credentials) }
    ).pipe(publishLast(), refCount());
  }

  /**
   * Get Quote for Product Comparison
   * @param {Booking} payload
   * @param {UserInformation} credentials
   * @returns {Observable<AgencyProducts>}
   */
  public getQuoteForProductComparison(payload: Booking, credentials: UserInformation): Observable<AgencyProducts> {
    return this.httpClient.put<AgencyProducts>(
      `${this.quoteServices}/GetQuoteForProductComparison`, payload, { headers: this.utilityService.getServiceHeaders(credentials) }
    ).pipe(publishLast(), refCount());
  }

  /**
   * Save and purchase products
   * @param {GroupProductPayload} groupProductPayload
   * @param {UserInformation} credentials
   * @returns {Observable<GroupProductResponse>}
   */
  public submitGroupRecordsForPurchase(groupProductPayload: GroupProductPayload, credentials: UserInformation): Observable<GroupProductResponse> {
    return this.httpClient.put<GroupProductResponse>(
      `${this.quoteServices}/SubmitGroupRecordsForPurchase`, groupProductPayload, { headers: this.utilityService.getServiceHeaders(credentials) }
    );
  }

  /**
   * Save and purchase products
   * @param {GroupProductPayload} groupProductPayload
   * @param {UserInformation} credentials
   * @returns {Observable<GroupProductResponse>}
   */
  public submitGroupRecordIds(groupProductPayload: GroupProductPayload, credentials: UserInformation): Observable<GroupProductResponse> {
    return this.httpClient.put<GroupProductResponse>(
      `${this.quoteServices}/SubmitGroupPaymentRecordIds`, groupProductPayload, { headers: this.utilityService.getServiceHeaders(credentials) }
    );
  }

  /**
   * Save and purchase products
   * @param {ProductPayload} payload
   * @param {UserInformation} credentials
   * @returns {Observable<ProductResponse>}
   */
  public saveAndPurchase(payload: ProductPayload, credentials: UserInformation): Observable<ProductResponse> {
    return this.httpClient.put<ProductResponse>(
      `${this.quoteServices}/SaveAndPurchase`, payload, { headers: this.utilityService.getServiceHeaders(credentials) }
    );
  }

  /**
   * Saves the given booking to your user account
   * @param {Booking} quote
   * @param {credentials} UserInformation
   * @returns {Observable<Booking>}
   */
  public reCalculatePrice(payload: Booking, credentials: UserInformation): Observable<Booking> {
    return this.httpClient.put<Booking>(
      `${this.quoteServices}/RecalculatePrice`, payload, { headers: this.utilityService.getServiceHeaders(credentials) }
    );
  }
  /**
   * Saves the given booking to your user account
   * @param {Booking} quote
   * @param {credentials} UserInformation
   * @returns {Observable<Booking>}
   */
  public reCalculatePriceForSmallGroup(payload: Booking, credentials: UserInformation): Observable<Booking> {
    return this.httpClient.put<Booking>(
      `${this.quoteServices}/RecalculatePriceForSmallGroup`, payload, { headers: this.utilityService.getServiceHeaders(credentials) }
    );
  }
  /**
   * Save quote
   * @param {Booking} quote
   * @param {UserInformation} credentials
   * @returns {Observable<Booking>}
   */
  public saveQuote(quote: Booking, credentials: UserInformation): Observable<Booking> {
    return this.httpClient.put<Booking>(`${this.quoteServices}/SaveQuote`, quote, { headers: this.utilityService.getServiceHeaders(credentials) });
  }

  /**
   * Get the list of quotes under a group booking
   * @param {string | number} bookingId
   * @param {UserInformation} credentials
   * @returns {Observable<Booking>}
   */
  public GetPoliciesForGroup(bookingId: string | number, credentials: UserInformation): Observable<GetGroupPoliciesBody> {
    return this.httpClient.get<GetGroupPoliciesBody>(
      `${this.quoteServices}/GetPoliciesForGroup/${bookingId}`, { headers: this.utilityService.getServiceHeaders(credentials) }
    ).pipe(publishLast(), refCount());
  }

  /**
   * uploads a roster of quotes to a specified group
   * @param {RosterUploadRequest} payload
   * @param {credentials} UserInformation
   * @returns {Observable<RosterUploadResponse>}
   */
  public rosterUpload(payload: RosterUploadRequest, credentials: UserInformation): Observable<RosterUploadResponse> {
    return this.httpClient.put<RosterUploadResponse>(
      `${this.quoteServices}/Roster/Upload`, payload, { headers: this.utilityService.getServiceHeaders(credentials) }
    );
  }

  /**
   * Generates new web booking for new policies
   * @param {UserInformation} credentials
   * @returns {Booking}
   */
  public generateNewWebBooking(credentials: UserInformation): Observable<Booking> {
    return this.httpClient.get<Booking>(`${this.quoteServices}/GenerateNewWebBooking`, { headers: this.utilityService.getServiceHeaders(credentials) });
  }

  /**
   * Submit individual group policy for purchase
   * @param {Booking} payload
   * @param {UserInformation} credentials
   * @returns {Observable<ProductPayload>}
   */
  public submitGroupPurchase(payload: Booking, credentials: UserInformation): Observable<ProductPayload> {
    return this.httpClient.put<ProductPayload>(`${this.quoteServices}/SubmitGroupPurchase`, payload, { headers: this.utilityService.getServiceHeaders(credentials) });
  }

  /**
   * Save quote
   * @param {GroupQuoteRequest} quote
   * @param {UserInformation} credentials
   * @returns {Observable<Booking>}
   */
  public saveGroupQuote(groupQuoteRequest: Booking, credentials: UserInformation): Observable<Booking> {
    return this.httpClient.put<Booking>(`${this.quoteServices}/SaveGroupQuote`, groupQuoteRequest, { headers: this.utilityService.getServiceHeaders(credentials) });
  }

  /**
   * delete saved quote by id
   * @param {string} bookingId
   * @param {UserInformation} credentials
   * @returns {Observable<boolean>}
   */
  public deleteQuote(bookingId: string, credentials: UserInformation): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.quoteServices}/DeleteQuoteByID/${bookingId}`, { headers: this.utilityService.getServiceHeaders(credentials) });
  }

  /**
   * service call for the save quote for email page
   * @param {Booking} quote
   * @param {credentials} UserInformation
   * @returns {Observable<Booking>}
   */
  public saveQuoteForEmail(quote: Booking, credentials: UserInformation): Observable<Booking> {
    return this.httpClient.put<Booking>(`${this.quoteServices}/SaveQuoteForEmail`, quote, { headers: this.utilityService.getServiceHeaders(credentials) });
  }

  /**
   * service call for retry pending quote
   * @param {ProductPayload} quote
   * @param {credentials} UserInformation
   * @returns {Observable<ProductResponse>}
   */
  public retryPendingBooking(quote: ProductPayload, credentials: UserInformation): Observable<ProductResponse> {
    return this.httpClient.put<ProductResponse>(`${this.quoteServices}/RetryPendingBooking`, quote, { headers: this.utilityService.getServiceHeaders(credentials) });
  }

}
