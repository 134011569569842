// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Services
import { UserService } from 'src/app/api/services/user.service';
import { AppStateService } from '../services/app-state.service';

// Third Party
import { Observable } from 'rxjs';

// Core Lib
import { KeyValue } from '@allianz/agent-max-core-lib';

@Injectable()
export class UserGetBusinessTypesResolver implements Resolve<Observable<KeyValue>> {
  constructor(
      private userService: UserService,
      private appStateService: AppStateService) {}

  resolve(): Observable<KeyValue> {
    return this.userService.getBusinessTypes(this.appStateService.getCredentials());
  }
}
