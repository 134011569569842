// Angular Core
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

// Models
import { GDSInterfaceSettings, InterfaceSettings } from 'src/app/models/interfaces';
import { ErrorMessage } from '@allianz/agent-max-core-lib';

// Services
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { GdsService } from 'src/app/api/services/gds.service';

@Component({
  selector: 'app-interface-settings-agency-form',
  templateUrl: './interface-settings-agency-form.component.html',
  styleUrls: ['./interface-settings-agency-form.component.scss']
})
export class InterfaceSettingsAgencyFormComponent implements OnInit {
  agencyId: number;
  groupId: number;
  isGroup: boolean;
  interfaceSettingsForm: FormGroup;
  gdsInterfaceSettings: GDSInterfaceSettings;
  interfaceSettings: InterfaceSettings;
  errorMessages: ErrorMessage[] = new Array();
  interfaceSettingsSaved: boolean;  
  constructor(
    private appStateService: AppStateService,
    private adminuserService: AdminUserService,
    private gdsService: GdsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.gdsInterfaceSettings = this.appStateService.getCurrentInterfaceSettings();
    this.setUpTheSettingsForm();
  }

  ngOnInit(): void {
    this.isGroup = this.activatedRoute.snapshot.data.isGroup;
    const agencyId = this.activatedRoute.snapshot.params.agencyId;
    this.agencyId = parseInt(agencyId, 10);
    if (this.isGroup) {
      const groupId = this.activatedRoute.snapshot.params.groupId;
      this.groupId =  parseInt(groupId, 10);
    }
    if (!agencyId) {
      this.router.navigate(['admin']);
    }
  }

  /**
   * Set up the form dynamicaly based on what interface setting was selected
   * @returns {void}
   */
  setUpTheSettingsForm(): void {
    //
    // All the logic to create dynamic forms
    // if we ever need to update it. As of
    // now the update button in the js code
    // is commented out thus can never be updated.
    // This is expensive especially if we are not
    // using the form outside of display purposes.
    //
    const groups = {};
    this.gdsInterfaceSettings.Sections.forEach( (group) => {
      group.GroupControls.forEach( (groupControl) => {
        groups[groupControl.Key] = new FormControl(groupControl.Value);
      });
      group.Controls.forEach( (control) => {
        groups[control.Key] = new FormControl(control.Value);
      });
    });
    this.interfaceSettingsForm = new FormGroup(groups);
  }

  /**
   * Set up the form dynamicaly based on what interface setting was selected
   * @param {GDSInterfaceSettings} interfaceSettings
   * @returns {void}
   */
  saveTheSettingsForm(interfaceSettings: GDSInterfaceSettings): void {
    this.updateInterfaceSettings();
    this.generateInterfaceSettingsRequestObject(interfaceSettings);
  }

  /**
   * Route to interface form from GDS settings
   * @returns {void}
   */
  routeToInterfacesForm(): void {
    this.router.navigate(['admin/interfaces/', this.agencyId]);
  }

  /**
   * Generates interface settigns request object for service
   * @param {GDSInterfaceSettings} interfaceSettings
   * @returns {void}
   */
  private generateInterfaceSettingsRequestObject(interfaceSettings: GDSInterfaceSettings): void {
    // TODO: Verify if needed
    this.adminuserService.getGDSInterfaceSettings(Number(this.agencyId), this.appStateService.getCredentials())
      .subscribe((response) => {
        this.interfaceSettings = response;
        this.interfaceSettings.GDSInterfaceSettings.forEach( (gdi) => interfaceSettings.Key === gdi.Key ? interfaceSettings : gdi);
        const interfaceIndex = this.interfaceSettings.GDSInterfaceSettings.indexOf(this.interfaceSettings.GDSInterfaceSettings.find( (is) => is.Key === interfaceSettings.Key));
        this.interfaceSettings.GDSInterfaceSettings[interfaceIndex] = interfaceSettings;
        this.adminuserService.saveGDSInterfaceSettings(this.interfaceSettings, this.appStateService.getCredentials())
        .subscribe( (saveResponse) => {
          this.errorMessages = saveResponse.ErrorMessages;
          if (this.errorMessages.length === 0) {
            this.interfaceSettingsSaved = true;
            this.updateAppletSettings(); 
          }
          this.appStateService.setCurrentInterfaceSettings(saveResponse.GDSInterfaceSettings.find
            ( (saveResponseInterfaceSettings) => saveResponseInterfaceSettings.Key === this.appStateService.getCurrentInterfaceSettings().Key));
        });               
      });
  }

  /**
   * Update GDS interface settings
   * @returns {void}
   */
  private updateInterfaceSettings(): void {
    Object.keys(this.interfaceSettingsForm.controls).forEach( (key) => {
      this.gdsInterfaceSettings.Sections.forEach( (section) => section.GroupControls.forEach( (groupControl) => {
        const tempString = String(this.interfaceSettingsForm.get(key).value);
        const valueString = tempString.charAt(0).toUpperCase() + tempString.slice(1);
        groupControl.Value = groupControl.Key === key ?  valueString : groupControl.Value;
      }));
    });
    Object.keys(this.interfaceSettingsForm.controls).forEach( (key) => {
      this.gdsInterfaceSettings.Sections.forEach( (section) => section.Controls.forEach( (control) => {
        const tempString = String(this.interfaceSettingsForm.get(key).value);
        const valueString = tempString.charAt(0).toUpperCase() + tempString.slice(1);
        control.Value = control.Key === key ?  valueString : control.Value;
      }));
    });
  }

  /**
   * Updates applet settings to reflect the form changes
   * @returns {void}
   */
  private updateAppletSettings(): void {
    if (this.appStateService.getGUID()) {
      this.gdsService.registerGDSLogin(this.appStateService.getCredentials(), this.appStateService.getGUID())
        .subscribe((response) => {
          if (response.ErrorMessages.length > 0) {
            this.errorMessages = response.ErrorMessages;
          }
        });
    }
  }
}
