// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Models
import { AgenciesForAdministration } from 'src/app/models/interfaces';

// Serivces
import { AdminUserService } from 'src/app/api/services/adminUser.service';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class GetAgenciesForAdministrationResolver implements Resolve<Observable<AgenciesForAdministration>> {
  constructor(
    private adminUserService: AdminUserService
  ) { }
  resolve(): Observable<AgenciesForAdministration> {
    return this.adminUserService.getAgenciesForAdministration();
  }
}
