// Angular Core
import { Component, OnInit, OnDestroy, Output, EventEmitter, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { UtilityService } from 'src/app/shared/services/utility.service';
import { AdminUserService } from 'src/app/api/services/adminUser.service';

// Models
import { AgentAdminSettingGridbag, AgentAdminSettingGridbagsWithPending, AgentAdminSettingGridbagWithPending } from 'src/app/models/interfaces';
import { AlertType, AgentAdminSettingsGridbagRecordType } from 'src/app/models/enums';
// Core Library
import { ErrorMessage } from '@allianz/agent-max-core-lib/models/interfaces/error-message.interface';
import { KeyValue } from '@allianz/agent-max-core-lib';
// Third Party
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import * as XLSX from 'xlsx'; 
import * as FileSaver from 'file-saver';
import { Paging } from '../../../../../../models/classes/paging.class';
import { AppStateService } from '../../../../../../shared/services/app-state.service';
import { AgentAdminSearchRequest } from '../../../../../../models/interfaces/agent-admin-search-request.interface';
import { ModalComponent } from '../../../../../../shared/components/modal/modal.component';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-admin-agent-management',
  templateUrl: './admin-agent-management.component.html',
  styleUrls: ['./admin-agent-management.component.scss']
})
export class AdminAgentManagementComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef;
  selectedIndex: number;
  activeRecord: AgentAdminSettingsGridbagRecordType = AgentAdminSettingsGridbagRecordType.ActiveOnly;
  inactiveRecord: AgentAdminSettingsGridbagRecordType = AgentAdminSettingsGridbagRecordType.InactiveOnly;
  recordType: FormControl = new FormControl(this.activeRecord);
  agentManagementFormGroup: FormGroup;
  agentAdminSettingsSubscriptions: Subscription[] = [];
  agentAdminSettingsGridbagsResponse: AgentAdminSettingGridbagsWithPending;
  isDesktop: boolean;
  agencyId: number;
  groups: FormArray = this.formBuilder.array([]);
  defaultAgentAdminSettings: AgentAdminSettingGridbag;
  activeAgentAdminSettings: AgentAdminSettingGridbag[] = new Array<AgentAdminSettingGridbag>();
  inactiveAgentAdminSettings: AgentAdminSettingGridbag[] = new Array<AgentAdminSettingGridbag>();
  validationMessages: ErrorMessage[] = [];
  formUpdated: boolean = false;
  agentLockedOut: number = 2;
  agentUnlocked: number = 0;
  @Output() createAgentToggle: EventEmitter<boolean> = new EventEmitter();
  addressOptions: KeyValue[];
  administrativeRoles: KeyValue[];
  maxMailOptionsType: KeyValue[];
  reportingRights: KeyValue[];
  sellingRoles: KeyValue[];
  interfaceOptionsType: KeyValue[];
  alertType: typeof AlertType = AlertType;
  paging: Paging = new Paging();
  readonly DefaultItemsPerPageSize: number = 50;
  searchName: FormControl = new FormControl('');
  searchId: FormControl = new FormControl('');
  searchEmail: FormControl = new FormControl('');
  /*name of the excel-file */ 
  fileName= 'AgentManagement.xlsx'; 
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private adminUserService: AdminUserService,
    private modalService: BsModalService,
    private appStateService: AppStateService,
  ) {
  }

  ngOnInit(): void {
    this.addressOptions = this.activatedRoute.firstChild.snapshot.data.addressOptions;
    this.administrativeRoles = this.activatedRoute.firstChild.snapshot.data.administrativeRoles;
    this.maxMailOptionsType = this.activatedRoute.firstChild.snapshot.data.maxMailOptions;
    this.reportingRights = this.activatedRoute.firstChild.snapshot.data.reportingRights;
    this.sellingRoles = this.activatedRoute.firstChild.snapshot.data.sellingRoles;
    this.interfaceOptionsType = this.activatedRoute.firstChild.snapshot.data.interfaceOptions;
    this.agencyId = parseInt(this.activatedRoute.snapshot.params.agencyId, 10);
    this.utilityService.getSize().subscribe((isDesktop: boolean) => {
      this.isDesktop = isDesktop;
    });
    this.agentAdminSettingsGridbagsResponse = this.activatedRoute.firstChild.snapshot.data.AdminAgencySettings;
    
    this.agentManagementFormGroup = this.formBuilder.group({
      groups: this.groups,
      recordType: this.recordType,
      searchName: this.searchName,
      searchId: this.searchId,
      searchEmail: this.searchEmail
    });
    this.setupAgentManagementForm();
    this.paging.updateRowsCount(this.agentAdminSettingsGridbagsResponse.Rowscount);

    this.paging.itemsPerPage = this.DefaultItemsPerPageSize;
    this.appStateService.setPageSize(this.DefaultItemsPerPageSize);
  }

  ngOnDestroy(): void {
    this.agentAdminSettingsSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  /**
   * This function sets up the Form by building the reactive form and
   * populating it with a table of agent profiles. Each agent profile row
   * in the table is an array of FormGroups that have FormControls
   * for each agent's profile settings.
   * @returns {void}
   */
  setupAgentManagementForm(): void {
    this.formUpdated = false;
    this.groups = new FormArray([]);
    this.agentManagementFormGroup.controls.groups = this.groups;

    this.agentAdminSettingsGridbagsResponse.AgentAdminSettings.forEach((agent) => {
      const agentForm = this.formBuilder.group({
        agentId: new FormControl(agent.AgentCode),
        isPendingAgent: new FormControl(agent.IsWebAccountPending),
        isWebEnabled: new FormControl(agent.IsWebEnabled),
        agentReportingRights: new FormControl(agent.AgentReportingRight),
        addressOptions: new FormControl(agent.AddressOptionsSource),
        fullWebAccess: new FormControl(agent.IsWebAccountAccessFull),
        sellingACCAM: new FormControl(agent.LoginSwitchToAccam),
        agentAdministrativeRole: new FormControl(agent.AgentAdministrativeRole),
        maxMailOptions: new FormControl(agent.MaxMailOptionsSource),
        lockedOut: new FormControl(agent.AgentAccountSpecialRestriction === this.agentLockedOut),
        agentSellingRole: new FormControl(agent.AgentSellingRole),
        interfaceOptions: new FormControl(agent.InterfaceOptionsSource),
        allowGroupTravel: new FormControl(agent.AgentCanManageGroup),
        agentEmailAddress: new FormControl(agent.AgentEmailAddress)
      });
      this.groups.push(agentForm);
    });
    this.groups.valueChanges.subscribe(() => {
      if (this.formUpdated) {
        this.onClosedAlert();
      }
    });
    this.agentManagementFormGroup.controls.groups.markAsPristine();
    this.agentManagementFormGroup.controls.groups.markAsUntouched();
  }
  /**
   * Adjusts the table based on the recordType FormControl. Displays either all active agents
   * or inactive agents based on the value of the recordType control
   * @param {AgentAdminSettingsGridbagRecordType} recordType - represents showing either active or inactive agents
   * @returns {void}
   */
  handleStatusChange(recordType: AgentAdminSettingsGridbagRecordType): void {
    this.recordType.setValue(recordType);
    const agentAdminSearchRequest: AgentAdminSearchRequest = {
      AgencyId: this.agencyId,
      RecordType: recordType,
      AgentName: this.searchName.value,
      AgentId: this.searchId.value,
      AgentEmail: this.searchEmail.value,
      Index: 1,
      Count: this.paging.itemsPerPage
    };
    this.getAgents(agentAdminSearchRequest);
  }
  /**
   * Routes to create new agent when 'create new agent' is clicked'
   * @returns {void}
   */
  routeToCreateAdminAgent(): void {

    this.router.navigate([`/admin/agencies/${this.agencyId}/create-agent`])
      .catch((e) => console.log('cool ', e)).finally(() => this.createAgentToggle.emit(true));
  }
  /**
   * This gets triggered when the cancel button is clicked.
   * Resets the agent profile form values that have changed back to their initial values from when the page was first loaded.
   * @returns {void}
   */
  resetForm(): void {
    for (const control of this.groups.controls) {
      if (control instanceof FormGroup) {
        if (!control.pristine) {
          const agent = this.agentAdminSettingsGridbagsResponse.AgentAdminSettings.filter((curAgent) => curAgent.AgentEmailAddress === control.value.agentEmailAddress);
          if (agent.length > 0) {
            control.patchValue({
              agentId: agent[0].AgentCode,
              agentReportingRights: agent[0].AgentReportingRight,
              addressOptions: agent[0].AddressOptionsSource,
              fullWebAccess: agent[0].IsWebAccountAccessFull,
              sellingACCAM: agent[0].LoginSwitchToAccam,
              agentAdministrativeRole: agent[0].AgentAdministrativeRole,
              maxMailOptions: agent[0].MaxMailOptionsSource,
              lockedOut: agent[0].AgentAccountSpecialRestriction,
              agentSellingRole: agent[0].AgentSellingRole,
              interfaceOptions: agent[0].InterfaceOptionsSource,
              allowGroupTravel: agent[0].AgentCanManageGroup,
              agentEmailAddress: agent[0].AgentEmailAddress
            });
            control.markAsPristine();
          }
        }
      }
    }
  }

  /**
   * Navigates to the admin agent profile page using the agent account id of the agent
   * @param {number} index - index value for the position of an agent in both the table in the form's formArray and in the
   * initial agentAdminSettingsGridbagsResponse object
   * @returns {void}
   */
  routeToAgentProfile(index: number): void {
    const agentAccountId = this.agentAdminSettingsGridbagsResponse.AgentAdminSettings[index].AgentAccountId;
    if (agentAccountId !== 0) {
      this.router.navigate([`admin/${agentAccountId}`]);
    } else {
      this.router.navigate([`admin/agentSettings/${this.agencyId}`], { state: { agencies: this.activatedRoute.snapshot.data.adminAgencies.Agencies } });
    }
  }

  /**
   * When either deactivate or activate agent is clicked on an agent profile,
   * this function will get the current form values and update those along with
   * setting whether or not the agent is active or not.
   * This functionality is consistent with how activation and deactivation is done in the client today.
   * @param index - index value for the position of an agent in both the table in the form's formArray and in the
   * initial agentAdminSettingsGridbagsResponse object
   * @returns {void}
   */
  agentActivation(index: number): void {

    const isAgentActive = this.recordType.value === this.activeRecord;

    const agentToUpdate: AgentAdminSettingGridbag = {
      AddressOptionsSource: this.groups.controls[index].value.addressOptions,
      AgentAccountId: this.agentAdminSettingsGridbagsResponse.AgentAdminSettings[index].AgentAccountId,
      AgentAccountSpecialRestriction: this.groups.controls[index].value.lockedOut === true ? this.agentLockedOut : this.agentUnlocked,
      AgentAdministrativeRole: this.groups.controls[index].value.agentAdministrativeRole,
      AgentAgencyId: this.agentAdminSettingsGridbagsResponse.AgentAdminSettings[index].AgentAgencyId,
      AgentCanManageGroup: this.groups.controls[index].value.allowGroupTravel,
      AgentCanManageSecondChance: this.agentAdminSettingsGridbagsResponse.AgentAdminSettings[index].AgentCanManageSecondChance,
      AgentCode: this.groups.controls[index].value.agentId,
      AgentEmailAddress: this.groups.controls[index].value.agentEmailAddress,
      AgentReportingRight: this.groups.controls[index].value.agentReportingRights,
      AgentSellingRole: this.groups.controls[index].value.agentSellingRole,
      InterfaceOptionsSource: this.groups.controls[index].value.interfaceOptions,
      IsAccountActive: !isAgentActive,
      IsDefaultAgent: this.agentAdminSettingsGridbagsResponse.AgentAdminSettings[index].IsDefaultAgent,
      IsWebAccountAccessFull: this.groups.controls[index].value.fullWebAccess,
      LoginSwitchToAccam: this.groups.controls[index].value.sellingACCAM,
      MaxMailOptionsSource: this.groups.controls[index].value.maxMailOptions,
      ProductOrderSource: this.agentAdminSettingsGridbagsResponse.AgentAdminSettings[index].ProductOrderSource
    };

    this.groups.controls.splice(index, 1);

    this.agentAdminSettingsSubscriptions.push(this.adminUserService.updateAdminSettingsGridBagForAgent(agentToUpdate).subscribe((response) => {
      this.validationMessages = response.ErrorMessages;
    }));
  }
  /**
   * Handles the update functionality for this form when the Apply button is clicked.
   * Sends either a single agent to the backend or a list of agents based on how many
   * agent profiles values have changed.
   * @returns {void}
   */
  submitForm(): void {
    const agentListToUpdate: AgentAdminSettingGridbag[] = [];
    const agentIndexList: number[] = [];
    for (const control of this.groups.controls) {
      if (control instanceof FormGroup) {
        if (!control.pristine) {
          const agent = this.agentAdminSettingsGridbagsResponse.AgentAdminSettings.filter((curAgent) => curAgent.AgentEmailAddress === control.value.agentEmailAddress);
          if (agent.length > 0) {
            const agentIndex = this.groups.controls.indexOf(control);
            agentIndexList.push(agentIndex);
            const tempAgent: AgentAdminSettingGridbag = {
              AddressOptionsSource: this.groups.controls[agentIndex].value.addressOptions,
              AgentAccountId: this.agentAdminSettingsGridbagsResponse.AgentAdminSettings[agentIndex].AgentAccountId,
              AgentAccountSpecialRestriction: this.groups.controls[agentIndex].value.lockedOut === true ? this.agentLockedOut : this.agentUnlocked,
              AgentAdministrativeRole: this.groups.controls[agentIndex].value.agentAdministrativeRole,
              AgentAgencyId: this.agentAdminSettingsGridbagsResponse.AgentAdminSettings[agentIndex].AgentAgencyId,
              AgentCanManageGroup: this.groups.controls[agentIndex].value.allowGroupTravel,
              AgentCanManageSecondChance: this.agentAdminSettingsGridbagsResponse.AgentAdminSettings[agentIndex].AgentCanManageSecondChance,
              AgentCode: this.groups.controls[agentIndex].value.agentId,
              AgentEmailAddress: this.groups.controls[agentIndex].value.agentEmailAddress,
              AgentReportingRight: this.groups.controls[agentIndex].value.agentReportingRights,
              AgentSellingRole: this.groups.controls[agentIndex].value.agentSellingRole,
              InterfaceOptionsSource: this.groups.controls[agentIndex].value.interfaceOptions,
              IsAccountActive: this.agentAdminSettingsGridbagsResponse.AgentAdminSettings[agentIndex].IsAccountActive,
              IsDefaultAgent: this.agentAdminSettingsGridbagsResponse.AgentAdminSettings[agentIndex].IsDefaultAgent,
              IsWebAccountAccessFull: this.groups.controls[agentIndex].value.fullWebAccess,
              LoginSwitchToAccam: this.groups.controls[agentIndex].value.sellingACCAM,
              MaxMailOptionsSource: this.groups.controls[agentIndex].value.maxMailOptions,
              ProductOrderSource: this.agentAdminSettingsGridbagsResponse.AgentAdminSettings[agentIndex].ProductOrderSource
            };
            agentListToUpdate.push(tempAgent);
          }
        }
      }
      control.markAsPristine();
    }
    if (agentListToUpdate.length > 0) {
      this.validationMessages = [];
      this.agentAdminSettingsSubscriptions.push(
        this.adminUserService.updateAdminSettingsGridBagForAgentList(agentListToUpdate)
          .subscribe((response) => {
            this.formUpdated = true;
            this.validationMessages = response.ErrorMessages;
            if (this.validationMessages.length === 0) {
              this.updateAgentAdminSettingsGridbagsResponse(agentIndexList, agentListToUpdate);
              this.groups.markAsPristine();
            }
          }));
    }
  }

  /**
   * open modal when user click deactivation button
   * @param {TemplateRef<any>} template
   * @param {number} index
   * @param {AgentAdminSettingsGridbagRecordType} recordType
   * @returns {void}
   */
  public openModal(template: TemplateRef<any>, index: number, recordType: AgentAdminSettingsGridbagRecordType): void {
    if (recordType === this.activeRecord) {
      this.selectedIndex = index;
      const config: ModalOptions = {
        class: 'modal-dialog-centered'
      };
      this.modalRef = this.modalService.show(template, config);
    }
  }
  /**
   * deactivate agent when user confirm deactivation, and hide modal
   * @returns {void}
   */
  public confirmDeactivation(): void {
    this.agentActivation(this.selectedIndex);
    this.modalRef.hide();
  }
  /**
   * on dismissing success/ fail alert message
   * @returns {void}
   */
  public onClosedAlert(): void {
    this.formUpdated = false;
    this.validationMessages = [];
  }
  public trackByEmail(index: number, agentSettings: AbstractControl): string {
    return agentSettings.value.agentEmailAddress;
  }

  private updateAgentAdminSettingsGridbagsResponse(agentIndexList: number[], agentListToUpdate: AgentAdminSettingGridbag[]): void {
    agentIndexList.forEach((el, i) => {
      const agent: AgentAdminSettingGridbagWithPending = this.agentAdminSettingsGridbagsResponse.AgentAdminSettings[el];
      agent.AgentCode = agentListToUpdate[i].AgentCode;
      agent.AgentReportingRight = agentListToUpdate[i].AgentReportingRight;
      agent.AddressOptionsSource = agentListToUpdate[i].AddressOptionsSource;
      agent.IsWebAccountAccessFull = agentListToUpdate[i].IsWebAccountAccessFull;
      agent.LoginSwitchToAccam = agentListToUpdate[i].LoginSwitchToAccam;
      agent.AgentAdministrativeRole = agentListToUpdate[i].AgentAdministrativeRole;
      agent.MaxMailOptionsSource = agentListToUpdate[i].MaxMailOptionsSource;
      agent.AgentAccountSpecialRestriction = agentListToUpdate[i].AgentAccountSpecialRestriction;
      agent.AgentSellingRole = agentListToUpdate[i].AgentSellingRole;
      agent.InterfaceOptionsSource = agentListToUpdate[i].InterfaceOptionsSource;
      agent.AgentCanManageGroup = agentListToUpdate[i].AgentCanManageGroup;
      agent.AgentEmailAddress = agentListToUpdate[i].AgentEmailAddress;
    });
  }

  public exportAsExcelFile(): void {
    const agentAdminSearchRequest: AgentAdminSearchRequest = {
      AgencyId: this.agencyId,
      RecordType: this.recordType.value,
      AgentName: "",
      AgentId: "",
      AgentEmail: "",
      Index: 1,
      Count: 0
    };
    const agentInfo: any = [];
    this.adminUserService.getAgentAdminSettingsGridBagsForAgency(agentAdminSearchRequest).subscribe((response) => {
        for (let i = 0; i < response.AgentAdminSettings.length; i++) {
          if (response.AgentAdminSettings[i] != undefined && response.AgentAdminSettings[i] != null) {
            agentInfo.push({
              'Agent Email Address': response.AgentAdminSettings[i].AgentEmailAddress,
              'Agent ID': response.AgentAdminSettings[i].AgentCode ? response.AgentAdminSettings[i].AgentCode : '',
              'Pending Agent': response.AgentAdminSettings[i].IsWebAccountPending,
              'Client Only': response.AgentAdminSettings[i].IsWebEnabled,
              'Allowed Reports': this.reportingRights.filter(x =>
                x.Value == response.AgentAdminSettings[i].AgentReportingRight.toString())[0].Key,
              'Address Options': this.addressOptions.filter(x =>
                x.Value == response.AgentAdminSettings[i].AddressOptionsSource.toString())[0].Key,
              'Full Web Access': response.AgentAdminSettings[i].IsWebAccountAccessFull,
              'Selling ACCAM': response.AgentAdminSettings[i].LoginSwitchToAccam,
              'Admin Role': this.administrativeRoles.filter(x =>
                x.Value == response.AgentAdminSettings[i].AgentAdministrativeRole.toString())[0].Key,
              'MaxMail Setup': this.maxMailOptionsType.filter(x =>
                x.Value == response.AgentAdminSettings[i].MaxMailOptionsSource.toString())[0].Key,
              'Locked Out': response.AgentAdminSettings[i].AgentAccountSpecialRestriction === this.agentLockedOut ? 'TRUE' : 'FALSE',
              'Selling Role': this.sellingRoles.filter(x =>
                x.Value == response.AgentAdminSettings[i].AgentSellingRole.toString())[0].Key,
              'Interface Options': this.interfaceOptionsType.filter(x =>
                x.Value == response.AgentAdminSettings[i].InterfaceOptionsSource.toString())[0].Key,
              'Allow Group Travel': response.AgentAdminSettings[i].AgentCanManageGroup
            });
          }
        }

        const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(agentInfo);
        const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, this.fileName);
      })
    }

  public saveAsExcelFile(buffer: any, fileName: string): void {
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_exported'+ EXCEL_EXTENSION);
    }

  public setPage(page: number): void {
    this.paging.currentPage = page;

    const agentAdminSearchRequest: AgentAdminSearchRequest = {
      AgencyId: this.agencyId,
      RecordType: this.recordType.value,
      AgentName: this.searchName.value,
      AgentId: this.searchId.value,
      AgentEmail: this.searchEmail.value,
      Index: this.paging.currentPage,
      Count: this.paging.itemsPerPage
    };
    this.getAgents(agentAdminSearchRequest);
  }

  public getAgents(agentAdminSearchRequest: AgentAdminSearchRequest): void {
    if ((this.searchName.value && this.searchName.value.length < 4) ||
      (this.searchEmail.value && this.searchEmail.value.length < 4))
    {
      this.showSearchInputInvalidMessage();
      return;
    }
    this.agentAdminSettingsSubscriptions.push(
      this.adminUserService.getAgentAdminSettingsGridBagsForAgency(agentAdminSearchRequest).subscribe((response) => {
        this.agentAdminSettingsGridbagsResponse = response;
        this.paging.updateRowsCount(response.Rowscount);
        if (this.recordType.value !== AgentAdminSettingsGridbagRecordType.InactiveOnly) {
          this.setupAgentManagementForm();
        }
      }))
  }

  public inputValidator(event: any) {
    const pattern = /^[a-zA-Z0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
    }
  }

  public inputValidatorSpecialCharacters(event: any) {
    const pattern = /^[a-zA-Z0-9,.-_ ]$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9,.-_ ]/g, "");
    }
  }

  showSearchInputInvalidMessage(): void {
    const initialState = { text: 'At least 4 characters are required to complete a search; please update your search criteria.' };
    this.modalRef = this.modalService.show(ModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }
}
