import { Environment } from 'src/app/models/interfaces/environment.interface';

export const environment: Environment = {
  Production: false,
  DefaultEmailAddress: 'agentservices@allianzassistance.com',
  DefaultHelpPhoneNumber: '855-524-3687',
  MaxmailQuoteService: '/api/QuoteToolService.svc',
  AgentMaxBaseService: '/api/QuoteToolService.svc',
  ProductTC: 'http://uat.allianztravelinsurance.com/AA/PopUps/TermsAndConditions.aspx?classic=true',
  CoveredSuppliers: 'http://uat.allianztravelinsurance.com/Documents/Library/uploadedfiles/CoveredSuppliers/Covered%20Supplier%20List.pdf',
  PurchaseAgreement: 'https://www.allianztravelinsurance.com/aa/PopUps/PurchaseAgreement.aspx',
  AMOPurchaseTermsAgreement: 'https://content.allianzpartnerservices.com/purchase-agreement/',
  AdminUserService: '/api/AdminUserService.svc',
  UserService: '/api/UserService.svc',
  QuoteServices: '/api/QuoteService.svc',
  GDSServices: '/api/GDSService.svc',
  UniversalConfigServices: '/api/UniversalConfigServices.svc',
  QueueServices: '/api/QueueService.svc',
  ReportServices: '/api/WebReportServices.svc',
  PolicyServices: '/api/policyservice.svc',
  StorefrontServices: '/api/StoreFrontService.svc',
  ClaimsUrl: 'http://uat.etravelprotection.com/claims',
  BookingService: '/api/BookingService.svc',
  SmallGroupServices: '/api/SmallGroupService.svc',
  StoreFrontURL: 'https://uat.agentmaxonline.com/agentmaxweb/storefront/index.html',
  QuoteToolURL: 'https://uat.agentmaxonline.com/agentmaxweb/widgets/quotetool.html',
  NotificationService: '/api/NotificationService.svc',
  SupplierURL: 'https://azcontent.us/covered-supplier-list-pdf?source=travel&medium=referral',
  AgentMaxInterfaceURL:'https://uatagentmax.allianztravelinsurance.com/clientsoftware/AgentMaxInterfaces.msi',
  BrochureOnlineOrderingURL: 'https://sellallianzpartners.com/',
  BrochurePrintedVersionURL: 'https://custompoint.rrd.com/xs2/?Option=2&username={0}&password=Kuu_dwOLie&account=ALLIANZ&datetime=8675309&UDF1={0}',
  ResourceCenter: 'https://uat.agentmaxonline.com/agentmaxweb/agentportal/index.html#/resourcecenter'
};
