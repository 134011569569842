"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GDSInformation = /** @class */ (function () {
    function GDSInformation() {
        this.AllowAddingTravelers = true;
        this.PNRLocatorNumber = '';
        this.PNRSource = '';
        this.PreviousPNRInfo = '';
        this.ResCardXML = '';
        this.WillBePNRPending = false;
    }
    return GDSInformation;
}());
exports.GDSInformation = GDSInformation;
