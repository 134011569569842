// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Models
import { KeyValue } from '@allianz/agent-max-core-lib';

// Serivces
import { AppStateService } from '../services/app-state.service';
import { UserService } from 'src/app/api/services/user.service';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class GetWorkLocationsByemailTokenResolver implements Resolve<Observable<KeyValue[]>> {
  constructor(
    private appStateService: AppStateService,
    private userService: UserService
  ) { }
  resolve(): Observable<KeyValue[]> {
    return this.userService.getWorkLocationsByToken(this.appStateService.getEmailToken());
  }
}
