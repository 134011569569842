// Angular Core
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

// Models
import { AddressAndLogo, UserInformation, AdminAddressAndLogo } from 'src/app/models/interfaces';
import { BookingAgentInformation, ErrorMessage, FileTypes } from '@allianz/agent-max-core-lib';

// Services
import { UserService } from 'src/app/api/services/user.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';

// Third Party
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AlertType } from 'src/app/models/enums';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnDestroy {
  validationMessages: ErrorMessage[] = [];
  addressLogoForm: FormGroup;
  address1: FormControl = new FormControl('', [Validators.required]);
  address2: FormControl = new FormControl();
  city: FormControl = new FormControl('', [Validators.required]);
  state: FormControl = new FormControl('', [Validators.required]);
  zipCode: FormControl = new FormControl('', [Validators.required]);
  phoneNumber: FormControl = new FormControl('', [Validators.required]);
  logo: File;
  adminRoute: boolean = false;
  previewLogo: ArrayBuffer | string;
  userAgent: BookingAgentInformation;
  userCredentials: UserInformation;
  isAgencyAddress: boolean = false;
  addressLogoSaved: boolean = false;
  alertType: typeof AlertType = AlertType;
  @Input('userOptions') userOptions: AddressAndLogo;
  @Input('isActive') isActive: boolean = true;
  addressSubscriptions: Subscription[] = [];
  public mask: Array<string | RegExp> = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private appStateService: AppStateService,
    private activatedRoute: ActivatedRoute,
    private adminUserService: AdminUserService
  ) {
    this.addressLogoForm = this.formBuilder.group({
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      phoneNumber: this.phoneNumber,
      logo: this.logo
    });
    this.addressLogoForm.valueChanges.subscribe(() => this.onClosedAlert());
  }

  ngOnInit(): void {

    this.userCredentials = this.appStateService.getCredentials();
    this.userAgent = this.appStateService.getAgentInformation();
    this.adminRoute = this.activatedRoute.snapshot.data.adminAgentRoute;
    this.updateAddressForm(this.userOptions);
  }

  /**
   * Restore default address
   * @returns {void}
   */
  restoreDefaultAddress(): void {
    this.addressSubscriptions.push(
      this.userService.getAgencyAddress(this.userCredentials)
        .subscribe((agencyAddress) => {
          this.addressLogoForm.markAsDirty();
          this.userOptions.Address = agencyAddress.Address;
          this.userOptions.ClientAddressBlock = agencyAddress.ClientAddressBlock;
          this.userOptions.PhoneNumber = agencyAddress.PhoneNumber;
          this.userOptions.IsAgencyAddress = true;
          this.updateAddressForm(this.userOptions);
        })
    );
  }

  /**
   * Import the logo for the address form
   * @param {FileList} files
   * @returns {void}
   */
  importLogo(files: FileList): void {
    const file = files.item(0);
    // Max size of image 1.5MB
    const MAX_SIZE = 1500000;
    if (file.size > MAX_SIZE) {
      alert('The image file is too large');
      return;
    }
    const allowedFileTypes: FileTypes[] = [
      FileTypes.PNG,
      FileTypes.GIF,
      FileTypes.JPG,
      FileTypes.JPEG,
      FileTypes.BMP
    ];
    if (allowedFileTypes.filter((type) => file.type === type).length === 0) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.previewLogo = reader.result;
    };
    this.addressLogoForm.markAsDirty();
  }

  /**
   * Delete curent logo
   * @returns {void}
   */
  onDeleteLogo(): void {
    this.addressSubscriptions.push(
      this.userService.getAgencyLogo(this.userCredentials)
        .subscribe((logo) => {
          this.userOptions.Logo = logo.Logo;
          this.userOptions.IsAgencyLogo = true;
          this.addressLogoForm.markAsDirty();
          this.previewLogo = logo.Logo.Value;
        })
    );
  }

  /**
   * determines whether the submit button should be enabled or not
   * @returns {boolean}
   */
  disableSubmit(): boolean {
    return this.addressLogoForm.pristine || this.addressLogoForm.invalid;
  }

  /**
   * Gets preview image
   * @returns {string}
   */
  getPreviewImage(): string {
    let image = `data:image/png;base64,${this.userOptions.Logo.Value}`;
    if (this.previewLogo) {
      image = this.previewLogo.toString();
    }
    return image;
  }

  /**
   * Submits the address and logo form section
   * @returns {void}
   */
  submit(): void {
    if (this.adminRoute) {
      if (this.addressLogoForm.valid && this.addressLogoForm.dirty) {
        const adminProfile = this.createAdminAddressAndLogoRequest(this.addressLogoForm);
        this.adminUserService.updateAgentAddressAndLogo(adminProfile).subscribe((updatedProfile) => {
          this.addressLogoSaved = true;
          this.validationMessages = updatedProfile.ErrorMessages;
          if (this.validationMessages.length === 0) {
            this.userOptions.Address = updatedProfile.Address;
            this.userOptions.PhoneNumber = updatedProfile.PhoneNumber;
            this.userOptions.Logo = updatedProfile.Logo;
            this.addressLogoForm.markAsPristine();
          }
        });
      }
    } else {
      if (this.addressLogoForm.valid && this.addressLogoForm.dirty) {
        const updatedProfile = this.getUpdatedUserAddressAndLogo(this.addressLogoForm);
        this.userService.saveAddressAndLogo(updatedProfile, this.userCredentials)
          .subscribe((addressAndLogo) => {
            this.addressLogoSaved = true;
            const userOptions = this.appStateService.getUserOptions();
            userOptions.AddressAndLogo = addressAndLogo;
            this.userOptions.IsAgencyAddress = false;
            this.validationMessages = addressAndLogo.ValidationMessages;
            this.userOptions = addressAndLogo;
            this.appStateService.setUserOptions(userOptions);
            this.addressLogoForm.markAsPristine();
          });
      }
    }
  }
  onClosedAlert(): void {
    this.addressLogoSaved = false;
  }
  ngOnDestroy(): void {
    this.addressSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Update address form values
   * @param {AddressAndLogo} userOptions
   * @returns {void}
   */
  private updateAddressForm(userOptions: AddressAndLogo): void {
    this.addressLogoForm.patchValue({
      address1: userOptions.Address.Address1.Value,
      address2: userOptions.Address.Address2.Value,
      city: userOptions.Address.City.Value ? userOptions.Address.City.Value.replace(',', '').trim() : null,
      state: userOptions.Address.State.Value,
      zipCode: userOptions.Address.ZipCode.Value,
      phoneNumber: userOptions.PhoneNumber.Value,
      logo: userOptions.Logo.Value
    });
    this.addressLogoForm.markAsPristine();
    this.addressLogoForm.markAsUntouched();
    if (!this.isActive) {
      this.addressLogoForm.disable();
    }
  }

  /**
   * Get updated user address and logo to send to server
   * @param {FormGroup} formValues
   * @returns {AddressAndLogo}
   */
  private getUpdatedUserAddressAndLogo(form: FormGroup): AddressAndLogo {
    const updatedUserAddressAndLogo = this.userOptions;
    updatedUserAddressAndLogo.Address.Address1.Value = form.value.address1 ? form.value.address1.trim() : null;
    updatedUserAddressAndLogo.Address.Address2.Value = form.value.address2 ? form.value.address2.trim() : null;
    updatedUserAddressAndLogo.Address.City.Value = form.value.city ? form.value.city.trim() : null;
    updatedUserAddressAndLogo.Address.State.Value = form.value.state ? form.value.state.trim() : null;
    updatedUserAddressAndLogo.Address.ZipCode.Value = form.value.zipCode ? form.value.zipCode.toString().trim() : null;
    updatedUserAddressAndLogo.PhoneNumber.Value = form.value.phoneNumber ? form.value.phoneNumber.replace(/\D/g, '').trim() : null;
    updatedUserAddressAndLogo.Logo.Value = (this.previewLogo) ? this.previewLogo.toString().split(',')[1] : form.controls.logo.value;
    return updatedUserAddressAndLogo;
  }

  /**
   * Converts the front end form to the admin back end request object
   * @param form - form being modified on front end
   * @returns {AdminAddressAndLogo} admin address request object for back end
   */
  private createAdminAddressAndLogoRequest(form: FormGroup): AdminAddressAndLogo {
    const adminAddressAndLogo: AdminAddressAndLogo = {
      Address: this.userOptions.Address,
      AgentId: this.activatedRoute.snapshot.params.agentId,
      Logo: this.userOptions.Logo,
      ErrorMessages: [],
      PhoneNumber: this.userOptions.PhoneNumber
    };
    adminAddressAndLogo.Address.Address1.Value = form.value.address1 ? form.value.address1.trim() : null;
    adminAddressAndLogo.Address.Address2.Value = form.value.address2 ? form.value.address2.trim() : null;
    adminAddressAndLogo.Address.City.Value = form.value.city ? form.value.city.trim() : null;
    adminAddressAndLogo.Address.State.Value = form.value.state ? form.value.state.trim() : null;
    adminAddressAndLogo.Address.ZipCode.Value = form.value.zipCode ? form.value.zipCode.toString().trim() : null;
    adminAddressAndLogo.PhoneNumber.Value = form.value.phoneNumber ? form.value.phoneNumber.replace(/\D/g, '').trim() : null;
    adminAddressAndLogo.Logo.Value = (this.previewLogo) ? this.previewLogo.toString().split(',')[1] : form.controls.logo.value;
    return adminAddressAndLogo;
  }

}
