export enum QuoteComponentEnum {
    CarRental = 'rental-car',
    None = 'none',
    Rail = 'rail',
    TravelNonAgeRated = 'travel',
    TravelAgeRated = 'travel-age-rated',
    EventTicket = 'event-ticket',
    EventRegistration = 'event-registration',
    Annual = 'annual',
    sessionSeason = 'session-season'
}
