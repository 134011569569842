"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_field_class_1 = require("./input-field.class");
var input_field_collection_class_1 = require("./input-field-collection.class");
var TripInformation = /** @class */ (function () {
    function TripInformation() {
        this.AverageTripCost = 0;
        this.CurrencyType = new input_field_class_1.InputField();
        this.Destination = new input_field_class_1.InputField();
        this.FormattedDepartureDate = new input_field_class_1.InputField();
        this.FormattedFinalDepositDate = new input_field_class_1.InputField();
        this.FormattedInitialDepositDate = new input_field_class_1.InputField();
        this.FormattedReturnDate = new input_field_class_1.InputField();
        this.HasFinalPaymentDateQuestionBeenAnswered = false;
        this.IsFinalPaymentDateRuleActive = false;
        this.NumberOfTravelers = 0;
        this.SumOfAverageTripCosts = 0;
        this.SupplierList = new input_field_collection_class_1.InputFieldCollection();
        this.TotalTripCost = new input_field_class_1.InputField();
        this.ValidationMessages = new Array();
    }
    return TripInformation;
}());
exports.TripInformation = TripInformation;
