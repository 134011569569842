// Angular Core
import { Component, OnInit, DoCheck, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { Location } from '@angular/common';

// 3rd Party Imports
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

// Models
import { AgencyGroup } from 'src/app/models/interfaces';
import { ErrorMessage, KeyValue } from '@allianz/agent-max-core-lib';
import { MainNavTabs, AdminNavTabs } from 'src/app/models/enums';

// Services
import { UtilityService } from 'src/app/shared/services/utility.service';
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';

// Components
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'app-agencies',
  templateUrl: './agencies.component.html',
  styleUrls: ['./agencies.component.scss']
})
export class AgenciesComponent implements OnInit, DoCheck, OnDestroy {
  agencies: AgencyGroup[] = Array<AgencyGroup>();
  isLoaded: boolean = false;
  isDesktop: boolean;
  validationMessages: ErrorMessage[] = Array<ErrorMessage>();
  getAgenciesSubscription: Subscription;
  agenciesForm: FormGroup;
  agenciesArray: FormArray = this.formBuilder.array([]);
  agenciesSaved: boolean = false;
  agenciesFormDirty: boolean = false;
  bsModalRef: BsModalRef;
  locationListener: any = null;

  isShortBranchNameRequired: boolean = false;
  isVisibilityGroupRequired: boolean = false;

  @Output() detailViewToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('agenciesErrors') agenciesErrors: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('nextActiveTab') nextActiveTab: EventEmitter<KeyValue> = new EventEmitter<KeyValue>();

  private readonly onDestroy: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private appStateService: AppStateService,
    private adminuserService: AdminUserService,
    private utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private bsModalService: BsModalService,
    private location: Location,
    private element: ElementRef
  ) {
    this.agenciesForm = this.formBuilder.group({
      agenciesArray: this.agenciesArray
    });

    // this.locationListener = this.location
    // .subscribe((event: PopStateEvent): void => {
    //   if (this.agenciesForm.dirty && event.type === 'popstate') {
    //     this.browserBackButtonClick();
    //   }
    // });
  }

  ngOnInit(): void {
    this.getAgenciesForAdministration();
    this.utilityService.getSize().subscribe((isDesktop: boolean) => {
      this.isDesktop = isDesktop;
    });
    this.appStateService.clearAgencyInformation();
  }

  ngDoCheck(): void {
    for (let index = 0; index < this.agencies.length; index++) {
      if (this.agenciesArray.controls.every((element) => element.value.visibilityGroup === '')) {
        this.getVisibilityGroupFormControl(index).setErrors(null);
        this.getVisibilityGroupFormControl(index).clearValidators();
      }
    }
    this.agenciesArray.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((val) => {
        if (this.agenciesArray.dirty) {
          this.utilityService.setTriggerAdminSaveAlert(this, true, MainNavTabs.AdminComponent, AdminNavTabs.Agencies);
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    if (this.getAgenciesSubscription) {
      this.getAgenciesSubscription.unsubscribe();
    }
    this.isShortBranchNameRequired = false;
    this.isVisibilityGroupRequired = false;
    this.agenciesFormDirty = false;
    this.agenciesErrors.emit(false);
  }

  /**
   * What happens when browser back button is clicked
   * @returns {void}
   */
  browserBackButtonClick(): void {
    const initialState = {
      text: `<p>Changes to this page have been made. Would you like to Apply the changes?</p>`,
      dialogIndicator: 'admin-save-group-settings'
    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
    this.bsModalRef.content.modalResponse
      .subscribe((ac: boolean) => {
        if (ac) {
          this.updateVisibilityGroupsForAgency();
        } else {
          return;
        }
      });
  }

  /**
   * If changes have been made show modal - if user answers yes update agency and redirect to group settings - if user answer no = do not update agency settings and redirect to group settings
   * @param agency {AgencyGroup}
   * @returns {void}
   */
  storeCurrentAgency(agency: AgencyGroup): void {
    const initialState = {
      text: `<p>Changes to this page have been made. Would you like to Apply the changes?</p>`,
      dialogIndicator: 'admin-save-group-settings'
    };
    if (this.agenciesForm.dirty) {
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .subscribe((ac: boolean) => {
          if (ac) {
            this.appStateService.setAgencyInformation(agency);
            this.updateVisibilityGroupsForAgency();
          } else {
            this.appStateService.setAgencyInformation(agency);
            // if click NO navigate to group settings - default behavior when Accam link is clicked
            const agenciesDetailUrlSeg = `/admin/agencies/${agency.AgencyId}/agency-info`;
            this.router.navigate([agenciesDetailUrlSeg]);
          }
        });
    } else {
      this.appStateService.setAgencyInformation(agency);
    }
  }

  /**
   * gets a single agency's info, but from a diffrent, more adminy endpoint
   * I haven't looked into the service code to see how these might be different,
   * so they could very well be identical on the inside
   * @returns {void}
   */
  getAgenciesForAdministration(): void {
    this.getAgenciesSubscription = this.adminuserService.getAgencyGroupsForAgency()
      .subscribe((response) => {
        this.agencies = response.AgencyGroupList;
        this.isLoaded = true;
        this.agencies.forEach((agency) => {
          this.agenciesArray.push(
            this.formBuilder.group({
              shortBranchName: agency.ShortBranchName,
              visibilityGroup: agency.AgencyVisibilityGroupCode
            })
          );
        });
      });
  }

  /**
   * Get short branch name form control
   * @returns {any}
   */
  getShortBranchNameFormControl(index: number): any {
    return this.agenciesArray.controls[index].get('shortBranchName');
  }

  /**
   * Get visibility group form control
   * @returns {any}
   */
  getVisibilityGroupFormControl(index: number): any {
    return this.agenciesArray.controls[index].get('visibilityGroup');
  }

  /**
   * Updates the agency groups to have the proper shared agency settings ids
   * @returns {void}
   */
  updateVisibilityGroupsForAgency(nextTab?: KeyValue, nextMainTab?: KeyValue, amoGoBack?: boolean): void {
    this.agenciesSaved =  false;
    this.validationMessages = this.updateAgenciesRequest();
    if (this.validationMessages.length === 0) {
      this.adminuserService.updateVisibilityGroupsForAgency(this.agencies)
      .subscribe((response) => {
        this.validationMessages = response.ErrorMessages;
        if (response.ErrorMessages.length === 0) {
          this.utilityService.setTriggerAdminSaveAlert(this, false);
          this.agenciesForm.markAsPristine();
          if (nextTab) {
            this.nextActiveTab.emit(nextTab);
          } else if (nextMainTab) {
            if (nextMainTab.Key === MainNavTabs.LoginComponent) {
              this.appStateService.clearAppState();
              this.router.navigate(['login']);
            } else {
              this.router.navigate([nextMainTab.Key]);
            }
          } else if (amoGoBack) {
            this.router.navigate(['home']);
          } else {
            const agenciesDetailUrlSeg = `/admin/agencies/${this.appStateService.getAgencyInformation().AgencyId}/agency-info`;
            this.agenciesSaved = true;
            this.agencies = response.AgencyGroupList;
            this.isLoaded = true;
            this.patchFormToAgencyGroupResponse();
            this.router.navigate([agenciesDetailUrlSeg]);
          }
        }
      });
    }
    if (nextTab && this.validationMessages.length !== 0) {
      // When errors on agencies tab Agencies tab should remain active but for some reason i can't get it to work - plw
      this.nextActiveTab.emit({ Value: 'Agencies', Key: 'agencies' });
      this.agenciesErrors.emit(true);
      const extras: NavigationExtras = {
        queryParams: {
          tab: 'agencies'
        }
      };
      this.router.navigate(['admin'], extras);
    }
  }

  /**
   * Returns true if visibility group has value and if short group name does not
   * @returns {boolean}
   */
  shortBranchNameRequired(): boolean {
    for (let index = 0; index < this.agencies.length; index++) {
      if (this.getVisibilityGroupFormControl(index).value && !this.getShortBranchNameFormControl(index).value) {
        this.isShortBranchNameRequired = true;
        this.getShortBranchNameFormControl(index).setValidators([Validators.required]);
        this.getShortBranchNameFormControl(index).setErrors({ incorrect: true });
      } else {
        this.getShortBranchNameFormControl(index).setErrors(null);
        this.getShortBranchNameFormControl(index).clearValidators();
      }
    }

    return this.isShortBranchNameRequired;
  }

  /**
   * Returns true if visibility group has value and if short group name does not
   * @returns {boolean}
   */
  visibilityGroupRequired(): boolean {
    for (let index = 0; index < this.agencies.length; index++) {
      if ((this.getVisibilityGroupFormControl(index).value === '' || !this.getVisibilityGroupFormControl(index).value) && this.agenciesArray.controls.some((element) => element.value.visibilityGroup !== '')) {
        this.isVisibilityGroupRequired = true;
        this.getVisibilityGroupFormControl(index).setValidators([Validators.required]);
        this.getVisibilityGroupFormControl(index).setErrors({ incorrect: true });
        this.getVisibilityGroupFormControl(index).markAsDirty();
      }
      if (this.agenciesArray.controls.every((element) => element.value.visibilityGroup === '')) {
        this.isVisibilityGroupRequired = false;
        this.getVisibilityGroupFormControl(index).setErrors(null);
        this.getVisibilityGroupFormControl(index).clearValidators();
      }
    }

    return this.isVisibilityGroupRequired;
  }

  /**
   * Updates the agencies form to reflect the changes saved to db.
   * Should theoretically always be the same, but will reflect if changes failed.
   * @returns {void}
   */
  private patchFormToAgencyGroupResponse(): void {
    for (let index = 0; index < this.agencies.length; index++) {
      this.agenciesArray.controls[index].patchValue({
        shortBranchName: this.agencies[index].ShortBranchName,
        visibilityGroup: this.agencies[index].AgencyVisibilityGroupCode
      });
    }
  }

  /**
   * Creates the request object to reflect whats in the form data.
   * @returns {ErrorMessage[]}
   */
  private updateAgenciesRequest(): ErrorMessage[] {
    const inValidMssg: ErrorMessage[] = [];
    if (this.shortBranchNameRequired()) {
      const shortBranchNameInValidMessage: ErrorMessage = {
        Code: 'shortBranchNameInValidMessage',
        Description: 'Short Branch Name is required.'
      };
      inValidMssg.push(shortBranchNameInValidMessage);
      this.isShortBranchNameRequired = false;
    }
    if (this.visibilityGroupRequired()) {
      const visibilityGroupInValidMessage: ErrorMessage = {
        Code: 'visibilityGroupInValidMessage',
        Description: 'Visibility Group is required.'
      };
      inValidMssg.push(visibilityGroupInValidMessage);
      this.isVisibilityGroupRequired = false;
    }
    if (!this.isShortBranchNameRequired && !this.isVisibilityGroupRequired) {
      for (let index = 0; index < this.agencies.length; index++) {
        this.agencies[index].ShortBranchName = this.agenciesArray.controls[index].value.shortBranchName;
        this.agencies[index].AgencyVisibilityGroupCode = this.agenciesArray.controls[index].value.visibilityGroup;
      }
    }

    // Allows time for form validators to be applied
    setTimeout(() => {
      this.scrollToFirstInvalidControl();
    });
    return inValidMssg;
  }

  /**
   * Scroll to first invalid form field
   * @returns {void}
   */
  private scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement = this.element.nativeElement.querySelector(
      'input.form-control.ng-invalid'
    );

    if (firstInvalidControl) {
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth'
      });

      fromEvent(window, 'scroll')
        .pipe(
          debounceTime(100),
          take(1)
        )
        .subscribe(() => firstInvalidControl.focus());
    }
  }

  /**
   * Add offset to top of invalid field
   * @returns {number}
   */
  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 40;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
}
