// Angular Core
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormArray, FormGroup, } from '@angular/forms';
import { Location } from '@angular/common';

// Models
import { AgencyGroup } from 'src/app/models/interfaces/agency-group.interface';
import { MainNavTabs, AdminNavTabs } from 'src/app/models/enums';

// 3rd Party Imports
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

// Components
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

// Services
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { ErrorMessage, KeyValue } from '@allianz/agent-max-core-lib';

@Component({
  selector: 'app-interface-settings',
  templateUrl: './interface-settings.component.html',
  styleUrls: ['./interface-settings.component.scss']
})
export class InterfaceSettingsComponent implements OnInit, OnDestroy {
  agencyId: string;
  groups: number[] = [1, 2];
  isLoaded: boolean = false;
  isDesktop: boolean;
  interfaceSettingsSaved: boolean = false;
  groupsArray: FormArray = this.formBuilder.array([]);
  subscriptionList: Subscription[] = [];
  agencyGroups: AgencyGroup[] = Array<AgencyGroup>();
  interfaceSettingsForm: FormGroup;
  validationMessages: ErrorMessage[] = Array<ErrorMessage>();
  bsModalRef: BsModalRef;
  sharedAgencyInterfacesId: number = null;
  agencyID: number = null;
  locationListener: any = null;

  @Output() detailViewToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('nextActiveTab') nextActiveTab: EventEmitter<KeyValue> = new EventEmitter<KeyValue>();

  private readonly onDestroy: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private adminuserService: AdminUserService,
    private appStateService: AppStateService,
    private formBuilder: FormBuilder,
    private bsModalService: BsModalService,
    private location: Location,
    private utilityService: UtilityService
  ) {
    this.interfaceSettingsForm = this.formBuilder.group({
      groupsArray: this.groupsArray
    });

    // this.locationListener = this.location
    // .subscribe((event: PopStateEvent): void => {
    //   if (this.interfaceSettingsForm.dirty && event.type === 'popstate') {
    //     this.browserBackButtonClick();
    //   }
    // });
  }

  ngOnInit(): void {
    this.getAgencyGroupsForAgency();
    this.utilityService.getSize().subscribe((isDesktop: boolean) => {
      this.isDesktop = isDesktop;
    });
  }

  ngDoCheck(): void {
    this.groupsArray.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((val) => {
        if (this.groupsArray.dirty) {
          this.utilityService.setTriggerAdminSaveAlert(this, true, MainNavTabs.AdminComponent, AdminNavTabs.InterfaceSettings);
        }
      });
  }

  /**
   * What happens when browser back button is clicked
   * @returns {void}
   */
  browserBackButtonClick(): void {
    const initialState = {
      text: `<p>Changes to this page have been made. Would you like to Apply the changes?</p>`,
      dialogIndicator: 'admin-save-group-settings'
    };
    this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
    this.bsModalRef.content.modalResponse
      .subscribe((ac: boolean) => {
        if (ac) {
          this.updateInterfaceGroupsForAgency();
        } else {
          return;
        }
      });
  }

  /**
   * Gets the name for the button.  Button name will either be
   * 'Agency Interfaces' or 'Group {x} Interfaces'.
   * @param {number} index - index of the row that the button is being grabbed for
   * @returns {string} - name of button
   */
  getButtonName(index: number): string {
    const groupValue: number = this.groupsArray.controls[index].value.groupChoice;
    if (groupValue === 0) { return 'Agency Interfaces'; }
    return `Group ${groupValue} Interfaces`;
  }

  /**
   * gets a single agency's info, but from a diffrent, more adminy endpoint
   * I haven't looked into the service code to see how these might be different,
   * so they could very well be identical on the inside
   * @returns {void}
   */
  getAgencyGroupsForAgency(): void {
    this.subscriptionList.push(
      this.adminuserService.getAgencyGroupsForAgency()
      .subscribe((response) => {
        this.agencyId = response.AgencyId;
        this.agencyGroups = response.AgencyGroupList;
        this.isLoaded = true;
        const maxGroupId = Math.max(...this.agencyGroups.map((agencyGroup) => agencyGroup.SharedAgencyInterfacesId));
        if (maxGroupId > 2) {
          this.groups = Array.from(Array(maxGroupId).keys()).map((group) => group + 1);
        }
        for (const agencyGroup of this.agencyGroups) {
          this.groupsArray.push(
            this.formBuilder.group({
              groupChoice: [agencyGroup.SharedAgencyInterfacesId]
            })
          );
        }
      }));
  }

  /**
   * Handle a change on the radio button form controls
   * @param {number} index - index of the row that the button is being grabbed for
   * @param {number} group - Group number of current index
   * @returns {void}
   */
  handleChange(index: number, group: number): void {
    if (!group) {
      this.agencyGroups[index].SharedAgencyInterfacesId = 0;
    } else {
      this.agencyGroups[index].SharedAgencyInterfacesId = group;
    }
  }

  /**
   * Updates the interface groups to have the proper shared interface settings ids
   * @returns {void}
   */
  updateInterfaceGroupsForAgency(nextTab?: KeyValue, nextMainTab?: KeyValue, amoGoBack?: boolean): void {
    this.subscriptionList.push(this.adminuserService.updateInterfaceGroupsForAgency(this.agencyGroups)
      .subscribe((response) => {
        this.validationMessages = response.ErrorMessages;
        this.interfaceSettingsSaved = this.validationMessages.length === 0 ?  true : false;
        if (response.ErrorMessages.length === 0) {
          this.utilityService.setTriggerAdminSaveAlert(this, false);
          this.interfaceSettingsForm.markAsPristine();
          if (nextTab) {
            this.nextActiveTab.emit(nextTab);
          } else if (nextMainTab) {
            this.router.navigate([nextMainTab.Key]);
          } else if (amoGoBack) {
            this.router.navigate(['home']);
          } else if (this.sharedAgencyInterfacesId !== null && this.agencyID !== null) {
            this.navigateInterfaceGroupSettings(this.sharedAgencyInterfacesId,  this.agencyID);
          } else {
            this.agencyGroups = response.AgencyGroupList;
            this.isLoaded = true;
            this.appStateService.setAgencyGroups(this.agencyGroups);
            for (let index = 0; index < this.agencyGroups.length; index++) {
              this.groupsArray.controls[index].patchValue({groupChoice: this. agencyGroups[index].SharedAgencyInterfacesId});
            }
          }
        }
      })
    );
  }

  /**
   * Routes to the appropriate settings based on the shared agency settings id
   * @param {number} index - index of the row that the button is being grabbed for
   * @returns {void}
   */
  routeToSettings(index: number): void {
    this.sharedAgencyInterfacesId = this.agencyGroups[index].SharedAgencyInterfacesId;
    this.agencyID = this.agencyGroups[index].AgencyId;
    this.navigateInterfaceAgencies(this.sharedAgencyInterfacesId, this.agencyID);
  }

  /**
   * Adds a new group column for more groups.  Maxes out at 4 (index of 0 so must be less than 3)
   * @returns {void}
   */
  addGroup(): void {
    const maxGroups = Math.max(...this.groups);
    if (maxGroups < 4) {
      this.groups.push(Math.max(...this.groups) + 1);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Routes to either group settings or default settings depending on whether or not a group is chosen
   * @param {number} sharedAgencyMaxMailSettingsId - agency id being passed in
   * @param {number} agencyId - base agency id used by details page
   * @returns {void}
   */
  navigateInterfaceGroupSettings(sharedAgencyInterfacesId: number, agencyId: number): void {
    if (sharedAgencyInterfacesId !== 0) {
      this.router.navigate(['admin/interfacegroups', agencyId, sharedAgencyInterfacesId]);
    } else {
      this.router.navigate(['admin/interfaces/', agencyId]);
    }
  }

  /**
   * Routes to proper channel, either agency group or a specific agency
   * @param {number} sharedAgencyInterfacesId - agency id being passed in
   * @param {number} agencyId - base agency id used by details page
   * @returns {void}
   */
  private navigateInterfaceAgencies(sharedAgencyInterfacesId: number, agencyId: number): void {
    if (!this.interfaceSettingsForm.pristine) {
      const initialState = {
        text: `<p>Changes to groupings on this page have been made. Would you like to Apply the changes?</p>`,
        dialogIndicator: 'admin-save-group-settings'
      };
      this.bsModalRef = this.bsModalService.show(ModalComponent, { initialState });
      this.bsModalRef.content.modalResponse
        .subscribe((ac: boolean) => {
          if (ac) {
            this.updateInterfaceGroupsForAgency();
          } else {
            // if click NO navigate to group settings - default behavior when clicking groupSettings button
            this.navigateInterfaceGroupSettings(sharedAgencyInterfacesId, agencyId);
          }
        });
    } else {
      this.navigateInterfaceGroupSettings(sharedAgencyInterfacesId, agencyId);
    }
  }

}
