import { Booking } from '@allianz/agent-max-core-lib';
import { SelectAllPaymentType } from '../enums/select-all-payment-type.enum';

export class PaySelectedGroupPoliciesData {
  SelectAllPaymentType: SelectAllPaymentType;
  ReadyAgencyCCPolicies: Booking[];
  ReadyCashWithheldPolicies: Booking[];

  constructor() {
    this.SelectAllPaymentType = SelectAllPaymentType.Neither;
    this.ReadyAgencyCCPolicies = [];
    this.ReadyCashWithheldPolicies = [];
  }
}
