// Angular Core
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Models
import { AgencyProducts, AddOnProgramProductInformation, ProgramProduct, Booking, BookingTypes } from '@allianz/agent-max-core-lib';

// Services
import { QuoteService } from 'src/app/api/services/quote.service';
import { AppStateService } from '../../services/app-state.service';

// Third Party
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/api/services/loading.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-program-product-list',
  templateUrl: './program-product-list.component.html',
  styleUrls: ['./program-product-list.component.scss']
})
export class ProgramProductListComponent implements OnInit {
  @Input('agencyProducts') agencyProducts: AgencyProducts;
  @Input('currentSmallGroupProduct') currentSmallGroupProduct: ProgramProduct;
  @Output() selectProductEventEmitter: EventEmitter<ProgramProduct> = new EventEmitter<ProgramProduct>();
  selectedProduct: ProgramProduct = null;
  isOpen: boolean = true;
  initalProductsLoaded: boolean = false;
  productSubscriptions: Subscription[] = [];
  isSmallGroup: boolean = false;
  isSmallGroupDetailsPage: boolean = false;
  productSelectable: boolean = true;
  productIndex: number = null;
  checkPriceButtonClick: boolean;
  policies: Booking[] = [];

  constructor(
    private appStateService: AppStateService,
    private quoteService: QuoteService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {
  }

  ngOnInit(): void {
    this.isSmallGroup = this.router.url.indexOf('small-group') !== -1;
    this.isSmallGroupDetailsPage = this.isSmallGroup && this.router.url.indexOf('details') !== -1;
    if (this.isSmallGroup) {
      if (this.router.url.indexOf('policies') !== -1 || this.policies.length > 0) {
        this.productSelectable = false;
      }
      if (this.currentSmallGroupProduct) {
        // getting the product index for mod/cancels
        this.productIndex = this.agencyProducts.ProgramProducts.findIndex((product) => product.ProductId === this.currentSmallGroupProduct.ProductId);
      }
    }
  }

  /**
   * On addon select
   * @param {AddOnProgramProductInformation} addOn
   * @param {Array<AddOnProgramProductInformation>} allAddOns
   * @returns {void}
   */
  onAddOnSelect(addOn: AddOnProgramProductInformation, allAddOns: AddOnProgramProductInformation[]): void {
    if (!addOn.IsSelected && !addOn.ProgramProductInformation.Product.IsRequiredAddOnProduct) {
      addOn.IsSelected = false;
      return;
    }

    if (addOn.ProgramProductInformation.Product.IsSwitchToAddOnProduct) {
      allAddOns.forEach((item) => {
        if (item.ProgramProductInformation.Product.IsSwitchToAddOnProduct) {
          item.IsSelected = false;
        }
      });
      addOn.IsSelected = true;
    } else if (!addOn.ProgramProductInformation.Product.IsSwitchToAddOnProduct && addOn.IsSelected) {
      addOn.IsSelected = true;
    } else {
      addOn.IsSelected = false;
    }

    if (addOn.ProgramProductInformation.Product.IsRequiredAddOnProduct) {
      addOn.IsSelected = true;
    }
  }

  /**
   * Get add on total
   * @param {ProgramProduct} programProduct
   * @return {number}
   */
  getAddOnTotal(programProduct: ProgramProduct): number {
    let totalPrice = programProduct.Price;
    programProduct.AddOnProgramProducts.forEach((addOn) => {
      if (addOn.IsSelected) {
        totalPrice += addOn.ProgramProductInformation.Price;
      }
    });
    return totalPrice;
  }

  /**
   * On product select route to booking info
   * @param {ProgramProduct} programProduct
   * @returns {void}
   */
  onProductSelect(programProduct: ProgramProduct): void {
    const booking: Booking = this.appStateService.getCurrentBookingQuote();
    booking.ProductInformation.ProgramProducts = [];
    booking.ProductInformation.ProgramProducts.push(programProduct);
    programProduct.AddOnProgramProducts.forEach((addon) => {
      if (addon.IsSelected) {
        booking.ProductInformation.ProgramProducts.push(addon.ProgramProductInformation);
      }
    });
    if (this.isSmallGroup) {
      this.appStateService.setBookingInfoViewType(BookingTypes.Quote);
      this.appStateService.setBookingInfoSourceType(BookingTypes.NewBooking);
      this.selectedProduct = programProduct;
      this.selectProductEventEmitter.emit(programProduct);
    } else {
      this.loadingService.open();
      this.productSubscriptions.push(
        this.quoteService.getQuoteForSelectedProducts(booking, this.appStateService.getCredentials())
          .pipe(finalize(() => this.loadingService.close()))
          .subscribe((response) => {
            if (response.IsValid) {
              this.appStateService.setCurrentBookingId(null);
              this.appStateService.setCurrentBookingQuote(response);
              this.appStateService.setBookingInfoViewType(BookingTypes.Quote);
              this.appStateService.setBookingInfoSourceType(BookingTypes.NewBooking);
              this.router.navigate(['bookinginfo']);
            } else {
              programProduct.IsValid = false;
              programProduct.ValidationMessages = response.ErrorMessages;
            }
          })
      );
    }
  }

  /**
   * On page load toggling open the appropriate product
   * for mods and cancels otherwise open the first
   * @param {number} $index
   * @returns {boolean}
   */
  toggleOpenProduct($index: number): boolean {
    return (this.productIndex && this.productIndex !== -1) ? this.productIndex === $index : 0 === $index;
  }

  /**
   * Accordion is open event handler
   * @param {boolean} $event
   * @param {number} $index
   */
  isOpenChange($event: boolean, $index: number): void {
    // avoids scrolling to products on page init
    if (!this.initalProductsLoaded) {
      this.initalProductsLoaded = true;
      return;
    }
    // navigates to the top of the header of the open accordion
    if ($event && this.checkPriceButtonClick) {
      // delay to let the accordion animation finish before scrolling
      setTimeout(() => {
        document.getElementById(`product-accordion-${$index}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 350);

      this.checkPriceButtonClick = false;
    }
  }

  ngOnDestroy(): void {
    this.productSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  openResourceCenter(): string {
    return environment.ResourceCenter;
  }
}
