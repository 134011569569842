// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Models
import { BookingAgentInformation } from '@allianz/agent-max-core-lib';

// Serivces
import { UserService } from 'src/app/api/services/user.service';
import { AppStateService } from '../services/app-state.service';

// Third Party
import { Observable, of } from 'rxjs';

@Injectable()
export class GetAgentInformationByUserResolver implements Resolve<Observable<BookingAgentInformation>> {
  constructor(
    private appStateService: AppStateService,
    private userService: UserService
  ) { }
  resolve(): Observable<BookingAgentInformation> {
    return this.appStateService.getAgentInformation() ? of(this.appStateService.getAgentInformation()) : this.userService.getAgentInformationByUser(this.appStateService.getCredentials());
  }
}
