import { ErrorMessage } from '@allianz/agent-max-core-lib';

export class WebObject {
    IsValid: boolean;
    ErrorMessages: ErrorMessage[];
    Message: string;
    TransactionNumber: string;
    TransactionStatus: string;
    constructor() {
        this.IsValid = true;
        this.ErrorMessages = [];
        this.Message = '';
        this.TransactionNumber = null;
        this.TransactionStatus = null;
    }
}
