import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppStateService } from '../services/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateGuard implements CanActivate {
  constructor(
    private appStateService: AppStateService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.appStateService.checkTimeStampisValid()) {
      this.appStateService.clearLocalStorage();
      this.appStateService.clearSessionStorage();
    } else {
       this.appStateService.setStorageTimeStamp();
    }
    const credentials = this.appStateService.getCredentials();

    if (route.url.toString() === 'login') {
      if (credentials) {
        this.appStateService.clearAppState();
      }
      return true;
    }

    if (!credentials) {
      this.router.navigate(['login']);
      return false;
    }
    if(this.appStateService.isAdmin()) {
      this.appStateService.setAdminStatus(true);
    }
    if (this.appStateService.IsFullAccess()) {
      this.appStateService.setFullAccess(true);
    }
    return true;
  }
}
