// Angular Core
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// MODELS
import { UserInformation, AgencyAddressAndLogo, AddressAndLogo } from 'src/app/models/interfaces';
import { ErrorMessage, BookingAgentInformation, FileTypes } from '@allianz/agent-max-core-lib';

// SERVICES
import { AdminUserService } from 'src/app/api/services/adminUser.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';

@Component({
  selector: 'app-admin-agency-info',
  templateUrl: './admin-agency-info.component.html',
  styleUrls: ['./admin-agency-info.component.scss'],
})
export class AdminAgencyInfoComponent implements OnInit {
  validationMessages: ErrorMessage[] = [];
  agencyAddressForm: FormGroup;

  logo: File;
  previewLogo: ArrayBuffer | string;
  userAgent: BookingAgentInformation;
  userCredentials: UserInformation;
  isAgencyAddress: boolean = false;
  addressLogoSaved: boolean = false;
  @Input('AgencyAddressAndLog') agencyAddressAndLog: AddressAndLogo;
  saveSuccessfuly: boolean = true;
  public mask: Array<string | RegExp> = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private activatedRoute: ActivatedRoute,
    private adminUserService: AdminUserService
  ) {
    this.constructForm();
  }

  ngOnInit(): void {
    this.userCredentials = this.appStateService.getCredentials();
    this.userAgent = this.appStateService.getAgentInformation();
    this.updateAddressForm(this.agencyAddressAndLog);
  }

  /**
   * Delete curent logo
   * @returns {void}
   */
  onDeleteLogo(): void {
    // delete from backend if there was one
    if (this.agencyAddressAndLog.Logo && this.agencyAddressAndLog.Logo.Value) {
      const agency = this.createAdminAddressAndLogoRequest(this.agencyAddressForm);
      agency.Logo.Value = null;
      this.saveAddressAndLogo(agency);
    } else {
      // note this shouldn't happen but it is here as safe guard
      // delete only from form
      this.agencyAddressForm.patchValue({logo: null});
    }

  }

  /**
   * determines whether the submit button should be enabled or not
   * @returns {boolean}
   */
  disableSubmit(): boolean {
    return this.agencyAddressForm.pristine || this.agencyAddressForm.invalid;
  }

  /**
   * Gets preview image
   * @returns {string}
   */
  getPreviewImage(): string {
    let image = `data:image/png;base64,${this.agencyAddressAndLog.Logo.Value}`;
    if (this.previewLogo) {
      image = this.previewLogo.toString();
    }
    return image;
  }

  /**
   * Submits the address and logo form section
   * @returns {void}
   */
  submit(): void {
    if (this.agencyAddressForm.valid && this.agencyAddressForm.dirty) {
      const agency = this.createAdminAddressAndLogoRequest(this.agencyAddressForm);
      this.saveAddressAndLogo(agency);
    }
  }

  /**
   * Import the logo for the address form
   * @param {FileList} files
   * @returns {void}
   */
  importLogo(files: FileList): void {
    const file = files.item(0);
    // Max size of image 1.5MB
    const MAX_SIZE = 1500000;
    if (file.size > MAX_SIZE) {
      alert('The image file is too large');
      return;
    }
    const allowedFileTypes: FileTypes[] = [
      FileTypes.PNG,
      FileTypes.GIF,
      FileTypes.JPG,
      FileTypes.JPEG,
      FileTypes.BMP
    ];
    if (allowedFileTypes.filter((type) => file.type === type).length === 0) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.previewLogo = reader.result;
    };
    this.agencyAddressForm.markAsDirty();
  }

  /**
   * called on dismissing alert panel
   * @returns {void}
   */
  public onClosedAlert(): void {
    this.addressLogoSaved = false;
  }
  /**
   * save agency address and logo
   * @param {AgencyAddressAndLogo} agencyAddressAndLog
   * @returns {void}
   */
  private saveAddressAndLogo(agencyAddressAndLog: AgencyAddressAndLogo): void {
    this.adminUserService.UpdateAgencyAddressAndLogo(agencyAddressAndLog).subscribe((addressAndLogo) => {
      this.validationMessages = addressAndLogo.ValidationMessages;
      this.addressLogoSaved = true;
      if (this.validationMessages.length === 0 && addressAndLogo.IsValid) {
        this.updateAddressForm(addressAndLogo);
        this.saveSuccessfuly = true;
        this.agencyAddressForm.markAsPristine();
      } else {
        this.saveSuccessfuly = false;
      }
    }, (error) => { this.saveSuccessfuly = false; });
  }

  /**
   * Update address form values
   * @param {AddressAndLogo} addressAndLogo
   * @returns {void}
   */
  private updateAddressForm(addressAndLogo: AddressAndLogo): void {
    this.agencyAddressForm.patchValue({
      agencyName: addressAndLogo.AgencyName.Value,
      address1: addressAndLogo.Address.Address1.Value,
      address2: addressAndLogo.Address.Address2.Value,
      city:  addressAndLogo.Address.City.Value ? addressAndLogo.Address.City.Value.replace(',', '').trim() : null,
      state: addressAndLogo.Address.State.Value,
      zipCode: addressAndLogo.Address.ZipCode.Value,
      phoneNumber: addressAndLogo.PhoneNumber.Value,
      logo: addressAndLogo.Logo.Value
    });
    this.agencyAddressForm.markAsPristine();
    this.agencyAddressForm.markAsUntouched();
  }

  /**
   * Converts the front end form to the admin back end request object
   * @param form - form being modified on front end
   * @returns {AdminAddressAndLogo} admin address request object for back end
   */
  private createAdminAddressAndLogoRequest(form: FormGroup): AgencyAddressAndLogo {
    const agencyAddressAndLogo: AgencyAddressAndLogo = {
      Address: this.agencyAddressAndLog.Address,
      AgencyName: form.value.agencyName,
      AgencyId: parseInt(this.activatedRoute.snapshot.params.agencyId, 10) || 0,
      Logo: this.agencyAddressAndLog.Logo,
      PhoneNumber: this.agencyAddressAndLog.PhoneNumber
    };
    agencyAddressAndLogo.Address.Address1.Value = form.value.address1 ? form.value.address1.trim() : null;
    agencyAddressAndLogo.Address.Address2.Value = form.value.address2 ? form.value.address2.trim() : null;
    agencyAddressAndLogo.Address.City.Value = form.value.city ? form.value.city.trim() : null;
    agencyAddressAndLogo.Address.State.Value = form.value.state ? form.value.state.trim() : null;
    agencyAddressAndLogo.Address.ZipCode.Value = form.value.zipCode ? form.value.zipCode.toString().trim() : null;
    agencyAddressAndLogo.PhoneNumber.Value = form.value.phoneNumber ? form.value.phoneNumber.trim() : null;
    agencyAddressAndLogo.Logo.Value = (this.previewLogo) ? this.previewLogo.toString().split(',')[1] : form.controls.logo.value;
    return agencyAddressAndLogo;
  }
  /**
   * build agency address form
   * @returns {void}
   */
  private constructForm(): void {
    this.agencyAddressForm = this.formBuilder.group({
      agencyName: new FormControl(),
      address1: new FormControl('', [Validators.required]),
      address2: new FormControl(),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
      logo: this.logo
    });
  }

}
