// Angular Core
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder,Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe } from '@angular/common';

// Services
import { AppStateService } from '../../services/app-state.service';

// Models
import { SecondChanceInformation, Booking, TripInformation, State, BookingTypes  } from '@allianz/agent-max-core-lib';
import { environment } from 'src/environments/environment';

// Components
import { SupplierListComponent } from '../supplier-list/supplier-list.component';

// Third Party
import { AccordionConfig, BsDatepickerConfig } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';

// TODO: Remove in favor of the one in utility service
export function getAccordionConfig(): AccordionConfig {
  return Object.assign(new AccordionConfig(), { isAnimated: true });
}

// TODO: Remove in favor of the one in utility service
export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    containerClass: 'theme-default',
    dateInputFormat: 'MM/DD/YYYY'
  });
}

@Component({
  selector: 'app-trip-information',
  templateUrl: './trip-information.component.html',
  styleUrls: ['./trip-information.component.scss'],
  providers: [
    { provide: AccordionConfig, useFactory: getAccordionConfig },
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig }
  ]
})
export class TripInformationComponent implements OnInit, OnDestroy {
  @Input('isModify') isModify: boolean = false;
  DepartureDate: FormControl = new FormControl('');
  ReturnDate: FormControl = new FormControl('');
  InitialDeposit: FormControl = new FormControl('');
  regexPattern: string = '(\\d+)(,\\d{3})*(\\.\\d{1,2})?$';
  TotalTripCost: FormControl = new FormControl(null, [Validators.pattern(this.regexPattern)]);
  AverageTripCostPerTraveler: FormControl = new FormControl(null);
  FinalPayment: FormControl = new FormControl(true);
  FinalPaymentDate: FormControl = new FormControl('');
  Destination: FormControl = new FormControl(null);
  TripInformationForm: FormGroup;
  isOpen: boolean = true;
  Destinations: State[];
  @Input('model') TripInformation: TripInformation;
  @Input() booking: Booking;
  @Input() set changedTravelers(value: number) {
    this.numberOfTravelers = value;
    this.AverageTripCostPerTraveler.patchValue(null);
    this.TotalTripCost.patchValue(null);
  }

  @ViewChild('supplierListComponent', { static: true })
  public supplierListComponent: SupplierListComponent;

  numberOfTravelers: number;
  isLoaded: boolean = false;
  order: string;
  bookingId: string;
  offerType: string;
  productOffered: string;
  priceOffered: string;
  secondChanceInfo: SecondChanceInformation;
  token: string;
  newVar: string;
  tripInformationSubscriptions: Subscription[] = [];
  isSmallGroup: boolean = false;
  departureMinDate: Date = new Date();
  returnMinDate: Date = new Date();
  depositMaxDate: Date = new Date();
  TripInfo: string = 'tripInfo';

  constructor(
    private currencyPipe: CurrencyPipe,
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute
  ) {
    this.TripInformationForm = this.formBuilder.group({
      DepartureDate: this.DepartureDate,
      ReturnDate: this.ReturnDate,
      InitialDeposit: this.InitialDeposit,
      TotalTripCost: this.TotalTripCost,
      AverageTripCostPerTraveler: this.AverageTripCostPerTraveler,
      Destination: this.Destination,
      FinalPayment: this.FinalPayment,
      FinalPaymentDate: this.FinalPaymentDate
    });
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.data.pageHeader) {
      this.isSmallGroup = true;
    }
    if (this.isModify) {
      this.InitialDeposit.disable();
    }

    this.departureMinDate.setDate(this.departureMinDate.getDate() + 1);
    this.returnMinDate.setDate(this.returnMinDate.getDate() + 1);
    this.Destinations = this.route.snapshot.data.destinations;

    this.TripInformationForm.patchValue({
      DepartureDate: this.TripInformation.FormattedDepartureDate.Value,
      ReturnDate: this.TripInformation.FormattedReturnDate.Value,
      InitialDeposit: this.TripInformation.FormattedInitialDepositDate.Value,
      TotalTripCost: ((Math.round((this.TripInformation.TotalTripCost.Value)*100)/100).toFixed(2)).toString(),
      AverageTripCostPerTraveler: this.TripInformation.AverageTripCost,
      Destination: this.TripInformation.Destination.Value,
      FinalPayment: this.TripInformation.HasFinalPaymentDateQuestionBeenAnswered,
      FinalPaymentDate: this.TripInformation.FormattedFinalDepositDate.Value
    });

    if (this.TripInformation.TotalTripCost.Value > 0) {
      this.TripInformationForm.patchValue({
        TotalTripCost: ((Math.round((this.TripInformation.TotalTripCost.Value)*100)/100).toFixed(2)).toString(),
        AverageTripCostPerTraveler: this.TripInformation.AverageTripCost
      });
    }

    if (this.booking.OrderInformation.IsPurchaseConfirmed) {
      this.TripInformationForm.controls.InitialDeposit.disable();
    }

    if (this.supplierListComponent) {
      this.tripInformationSubscriptions.push(this.supplierListComponent.SupplierListForm.valueChanges.subscribe(() => {
        if (this.supplierListComponent.SupplierListForm.dirty) {
          this.TripInformation.ValidationMessages.length = 0;
        }
      }));
    }

    this.tripInformationSubscriptions.push(this.TripInformationForm.valueChanges.subscribe(() => {
      if (this.TripInformationForm.dirty) {
        this.TripInformation.ValidationMessages.length = 0;
      }
    }));
  }

  /**
   * Selects change destination handler
   * @param {Event} $event
   * @returns {void}
   */
  changeDestination($event: Event): void {
    this.Destination.patchValue($event.target['value']);
  }

  /**
   * Destination typeahead blur handler
   * @param {Event} $event
   * @returns {void}
   */
   destinationOnBlur($event: Event): void {
    const listOfMatches = this.Destinations.filter((item) => (item.Desc).toLowerCase().includes($event.target['value'].toLowerCase()));
    if (listOfMatches.length === 1) {
      this.Destination.patchValue(listOfMatches[0].Desc);
    } else {
      this.Destination.reset();
    }
  }

  /**
   * Event handler for the accordion open functionality
   * @param {boolean} open
   * @returns {void}
   */
  isOpenChange(open: boolean): void {
    this.isOpen = open;
  }

  /**
   * Calculating the average cost per traveler
   * (running into issues with strong typing here)
   * @param {any} $event
   * @returns {void}
   */
  calcAvgCostPerTraveler($event: any): void {
    if (this.isSmallGroup && $event.target.value) {
      if ($event.target.id === 'TotalTripCost') {
        this.AverageTripCostPerTraveler.patchValue(
          this.currencyPipe.transform(parseFloat($event.target.value.replace(',', '')) / (this.numberOfTravelers + 1), 'USD', '').replace(',', '')
        );
      } else {
        this.TotalTripCost.patchValue(
          this.currencyPipe.transform(parseFloat($event.target.value.replace(',', '')) * (this.numberOfTravelers + 1), 'USD', '').replace(',', '')
        );
      }
    }
  }

  /**
   * Generate open covered suppliers link from envirnoment
   * @returns {string}
   */
  openCoveredSuppliers(): string {
    return environment.SupplierURL;
  }

  /**
   * Set the new return min date based on the departure date
   * @param {Date} value
   * @returns {void}
   */
  setNewReturnMinDate(value: Date): void {
    if (value) {
      // setting the day after the departure without hours minutes second miliseconds
      this.returnMinDate = new Date(value.getFullYear(), value.getMonth(), value.getDate() + 1, 0, 0, 0);
      // Only look to check the control if the control has a value
      if (this.ReturnDate.value) {
        // temp return date without hours minutes second miliseconds
        const tempReturnDate = new Date(new Date(this.ReturnDate.value).getFullYear(), new Date(this.ReturnDate.value).getMonth(), new Date(this.ReturnDate.value).getDate() + 1, 0, 0, 0);
        // Reset the return date control if the new min return date conflicts the return date listed
        if (this.returnMinDate.getTime() >= tempReturnDate.getTime()) {
          this.ReturnDate.reset();
        }
      }
    }
  }

  onSubmit(): void {
  }

  /**
   * Checks if block is disabled
   * @returns {boolean}
   */
  isDisabled(): boolean {
    return this.appStateService.getBookingInfoViewType() === BookingTypes.Pending;
  }

  ngOnDestroy(): void {
    this.tripInformationSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
