// Angular Core
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Models
import { MaxMail2ndChanceSettings } from '@allianz/agent-max-core-lib';

// Serivces
import { AppStateService } from '../services/app-state.service';
import { QuoteService } from 'src/app/api/services/quote.service';

// Third Party
import { Observable } from 'rxjs';

@Injectable()
export class GetMaxmailSecondChanceSettingsByBookingIdResolver implements Resolve<Observable<MaxMail2ndChanceSettings>> {
  constructor(
    private appStateService: AppStateService,
    private quoteService: QuoteService
  ) { }
  resolve(): Observable<MaxMail2ndChanceSettings> {
    return this.quoteService.getMaxMailSettingsByBookingId(this.appStateService.getCurrentBookingId(), this.appStateService.getCredentials());
  }
}
