import { Booking, ErrorMessage } from '@allianz/agent-max-core-lib';

export class GetGroupPoliciesBody {
    ErrorMessages: ErrorMessage[];
    IsValid: boolean;
    Message: string;
    TransactionNumber: string;
    TransactionStatus: string;
    Bookings: Booking[];

    constructor() {
        this.ErrorMessages = [];
        this.IsValid = false;
        this.Message = '';
        this.TransactionNumber = '';
        this.TransactionStatus = '';
        this.Bookings = [];
    }
}
