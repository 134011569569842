"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_field_class_1 = require("./input-field.class");
var BillingInformation = /** @class */ (function () {
    function BillingInformation() {
        this.CardExpirationYear = new input_field_class_1.InputField();
        this.CardNumber = new input_field_class_1.InputField();
        this.CardExpirationMonth = new input_field_class_1.InputField();
        this.IsPayMethodCashWithheld = new input_field_class_1.InputField();
        this.ReceiptNumber = new input_field_class_1.InputField();
        this.CardholderAddress1 = new input_field_class_1.InputField();
        this.CardholderAddress2 = new input_field_class_1.InputField();
        this.CardholderCity = new input_field_class_1.InputField();
        this.CardholderState = new input_field_class_1.InputField();
        this.CardholderZipCode = new input_field_class_1.InputField();
        this.CardholderCountryCode = new input_field_class_1.InputField();
        this.CardholderFullName = new input_field_class_1.InputField();
        this.FulfillmentPrimaryEmail = new input_field_class_1.InputField();
        this.FulfillmentSecondaryEmail = new input_field_class_1.InputField();
        this.CardholderPhoneNumber = new input_field_class_1.InputField();
        this.IsFulfillmentByMail = new input_field_class_1.InputField();
        this.CardTypeCodeString = new input_field_class_1.InputField();
        this.ValidationMessages = new Array();
    }
    return BillingInformation;
}());
exports.BillingInformation = BillingInformation;
