// Angular Core
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';

// Models
import { NotificationResponse, NotificationMessage, PrintOrEmailOptions } from 'src/app/models/interfaces';
import { NotificationSaleType, ConfirmationViewType, QueueTypes, PrintOrEmailTypes, PrintOrEmailOfferType, PrintOrEmailReportType, PrintOrEmailDocumentType } from 'src/app/models/enums';
import { BookingTypes, BookingAgentInformation, ErrorMessage } from '@allianz/agent-max-core-lib';

// Services
import { NotificationService } from 'src/app/api/services/notification.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { QuoteService } from 'src/app/api/services/quote.service';
import { LoadingService } from 'src/app/api/services/loading.service';

// Third Party
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-notification-tab',
  templateUrl: './notification-tab.component.html',
  styleUrls: ['./notification-tab.component.scss']
})
export class NotificationTabComponent {
  notificationsResponse: NotificationResponse;
  validationMessages: ErrorMessage[] = [];
  notificationSaleType: typeof NotificationSaleType = NotificationSaleType;
  description: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appStateService: AppStateService,
    private quoteService: QuoteService,
    private notificationService: NotificationService,
    private loadingService: LoadingService
  ) {
    this.route.data.subscribe((data) => {
      this.notificationsResponse = data.notifications;
    });
    this.description = this.route.snapshot.data.description;
  }

  /**
   * Update notification(s) to seen
   * @returns {void}
   */
  updateNotifications(message: NotificationMessage): void {
    const payload = cloneDeep(this.notificationsResponse);
    if (payload.Messages && payload.Messages.filter((msg) => msg.MessageConsumed === false && msg.MessageID === message.MessageID).length > 0) {
      payload.Messages = payload.Messages.filter((msg) => msg.MessageID === message.MessageID);
      payload.Count = payload.Messages.length;
      payload.Messages.map((msg) => msg.MessageConsumed = true);
      this.notificationService.updateNotifications(payload)
        .subscribe((response) => {
          if (response.ErrorMessages.length === 0) {
            if (response.Messages.length > 0) {
              const salesType = response.Messages[0].SaleType;
              switch (salesType) {
                case (NotificationSaleType.MaxMail):
                  this.appStateService.triggerMaxmailNotificationStatus(response.Messages.filter((msg) => msg.MessageConsumed === false).length > 0);
                  break;
                case (NotificationSaleType.QuoteMax):
                  this.appStateService.triggerQuoteMaxNotificationStatus(response.Messages.filter((msg) => msg.MessageConsumed === false).length > 0);
                  break;
                case (NotificationSaleType.PreExExpiring):
                  this.appStateService.triggerPreexpiringNotificationStatus(response.Messages.filter((msg) => msg.MessageConsumed === false).length > 0);
                  break;
                default:
                  break;
              }
            }
            this.notificationService.getNotificationCount().subscribe((res) => {
              this.appStateService.triggerNotificationCountStatus(res.Count);
            });
          }
        });
    }
  }

  /**
   * Format the date string from service for the UI
   * @param {string | Date} date
   * @returns {string}
   */
  formatMessageDate(date: string | Date): string {
    if (date) {
      return formatDate(date, 'MM/dd/yyyy', 'en-US');
    } else {
      return '';
    }
  }

  /**
   * Get the sales type as plain text
   * @param {string} saleType
   * @returns {string}
   */
  getSalesType(saleType: string): string {
    switch (saleType) {
      case NotificationSaleType.MaxMail:
        return 'Maxmail';
      case NotificationSaleType.QuoteMax:
        return 'Email Link';
      case NotificationSaleType.PreExExpiring:
        return 'Pre Ex Expiring';
      default:
        return saleType;
    }
  }

  /**
   * Triggers a different event depending on the notification type
   * @param {NotificationMessage} message
   * @returns {void}
   */
  notificationEventHandler(message: NotificationMessage): void {
    this.updateNotifications(message);
    this.appStateService.setCurrentBookingQuote(null);
    this.appStateService.setCurrentBookingId(message.BookingID);
    if (message.SaleType !== NotificationSaleType.PreExExpiring) {
      this.appStateService.setConfirmationViewType(ConfirmationViewType.NewConfirmation);
      this.router.navigate(['/purchaseconfirmation']);
    } else {
      this.appStateService.setBookingInfoViewType(BookingTypes.Quote);
      this.appStateService.setBookingInfoSourceType(BookingTypes.ExistingBooking);
      this.router.navigate(['bookinginfo']);
    }
  }

  /**
   * sends user to print/email said quote
   * @param {NotificationMessage} message
   * @returns {void}
   */
  onPrintOrEmailQuote(message: NotificationMessage): void {
    this.loadingService.open();
    this.quoteService.getQuoteById(message.BookingID, this.appStateService.getCredentials())
      .subscribe(
        (response) => {
          const agentInfo: BookingAgentInformation = this.appStateService.getAgentInformation();
          const options: PrintOrEmailOptions = {
            HeaderName: 'Print Or Email Quote',
            DocumentType: PrintOrEmailDocumentType.BookingDocument,
            ReportType: PrintOrEmailReportType.WebQuoteByAgent,
            BookingId: response.BookingID,
            OfferType: PrintOrEmailOfferType.QuoteMax,
            ShowViewButton: true,
            EmailInput: {
              FromName: `${agentInfo.FirstName} ${agentInfo.LastName}`,
              FromEmailAddress: agentInfo.EmailAddress,
              ToName: '',
              ToEmailAddress: '',
              SecondaryEmailAddress: ''
            }
          };
          this.appStateService.setPrintOrEmailReportName(PrintOrEmailTypes.Quote);
          this.appStateService.setPrintOrEmailData(response, options);
          this.appStateService.setQueueType(QueueTypes.Quotes);
          this.router.navigate(['printoremail']);
        },
        (error) => this.loadingService.close(),
        () => this.loadingService.close()
      );
  }

}
